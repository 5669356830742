<template>
        <div class="error-block">
            <div class="closeIcon" @click="moveHome">
              <v-icon color="white">close</v-icon>
            </div>
            <img width="150px" :src="`https://minio.takemybet.pro/ui-style/${logo}`" alt="logo"/>
            {{ $i18n.t('slotKnow') }}
            <br>
            {{ $i18n.t('interrogation') }}
          </div>
</template>

<script>
import { mapGetters } from 'vuex';

    export default {
        name: 'ErrorGame',
        methods:{
            async moveHome() {
      await this.$router.back();
    },
    
        },
        computed: {
      ...mapGetters({
        logo: 'styles/getLogo',
      })
    },
    }
</script>

<style lang="scss" scoped>
.error-block{
  background-color: var(--main-flamingo);
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    font-weight:600;
    color: var(--white);
    font-size: 25px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
.closeIcon {
    background-color: var(--red);
    border-radius: 4px;
    flex: 0 0 35px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    left: 5px;
    z-index: 300;
    @media (any-hover: hover){
      cursor: pointer;
    }
  }
</style>
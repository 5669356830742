<template>
    <div class="upper-padding" :class="{'is-noLogin' : user.id === null}">
      
        <v-container style="max-width: 900px;" :class="{'pa-0' : $route.path !== '/promotions-welcome'}">
              <welcome-mobile 
              v-if="$isMobile()"
              :spinsBonuses="filteredSpinsBonuses"
              :depositBonuses="filteredDepositBonuses"
              ></welcome-mobile>
              <WelcomePC 
              v-else
              :spinsBonuses="filteredSpinsBonuses"
              :depositBonuses="filteredDepositBonuses"
              ></WelcomePC>
        </v-container>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import WelcomeMobile from './WelcomeMobile'
import WelcomePC from './WelcomePC';
    export default {
        name: 'Welcome',
        components:{
            WelcomeMobile,
            WelcomePC,
        },
        data() {
            return {
                
            }
        },
        async mounted(){
          if (localStorage.getItem('jwt')) {
          await this.$store.dispatch('bonuses/getUserBonuses');
          }
        },
        computed:{
        ...mapGetters({
          user: 'getUserInfo',
          spinsBonuses: 'bonuses/getSpinsBonuses',
          depositBonuses: 'bonuses/getDepositBonuses',
        }),
          filteredSpinsBonuses(){
      if (this.user.id !== null) {
        // bonus.type === 'welcome' &&
    return this.spinsBonuses.filter(bonus => bonus.status === 'created');
      } else {
        return [];
      }
    },
    filteredDepositBonuses(){
      if (this.user.id !== null) {
        // && bonus.type !== 'next_deposit'
        return this.depositBonuses.filter(bonus => bonus.status === 'created');
      } else {
        return [];
      }
      
    },
    },
}
</script>

<style lang="scss" scoped>
.upper-padding{
    padding-top: 15px;
    @media (max-width:600px){
      padding-top: 48px;
        
    }
}
.is-noLogin{
    margin-top: 50px;
    @media (max-width:992px){
        //padding-top: 70px;  
    }
    background-color: var(--main-flamingo);
  }
.container{
    .is-noLogin &{
        background-color: var(--dark-flamingo);
        border-radius: 16px;
    }
}
</style>
<template>
  <div>
    <v-chip-group class="links-label">
      <v-chip active-class="_active" to="/profile/responsible-gambling/self-exclusion" label dense class="link-label">
        <div>  {{ $i18n.t('selfExclusion') }}</div>
      </v-chip>
      <v-chip active-class="_active" to="/profile/responsible-gambling/limits" label dense class="link-label">
        <div>  {{ $i18n.t('limits') }}</div>
      </v-chip>
      <v-chip active-class="_active" to="/profile/responsible-gambling/reality-check" label dense class="link-label">
        <div>  {{ $i18n.t('realityCheckTitle') }}</div>
      </v-chip>
    </v-chip-group>
    <router-view class="info_router">

    </router-view>
  </div>
</template>

<script>
export default {
  name: "ResponsibleGaming"
}
</script>

<style lang="scss" scoped>
.tabs-name__noburger{
    
  @media (max-width:992px){
       display: none; 
  }
}
.links{

  color: var(--white);
  font-size: 16px;
  text-transform: uppercase!important;
  padding: 0px;
}
.link{
  text-decoration: none;
  color: var(--white);
  transition: font-weight 0.3s;
  @media (any-hover: hover){
      &:hover{
          font-weight: 600;
      }
  }
  &._active{
      font-weight: 600;
  }
}
@media (max-width: 750px) {
  .divider_vertical{
    display: none;
  }
}
@media (max-width: 500px){
  .links{
    min-width: 200px!important;
    text-align: left;
    font-size: 16px;
  }
}
</style>
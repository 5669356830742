// styles.js
import axios from "axios";
const state = {
  logo: "",
  colors: {},
  title: "",
  favicon_img: "",
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
};

const getters = {
  getTitle: (state) => state.title,
  getFavicon: (state) => state.favicon_img,
  getLogo: (state) => state.logo,
  getColorVariables(state) {
    if (!state.colors) return [];
    return Object.entries(state.colors).map(([key, value]) => ({ key, value }));
  },
};

const actions = {
  async getUI({ commit }) {
    await axios
      .get(`/api/ui_style/?domain=takemybet.pro`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          commit("set", { type: "colors", items: response.data.css_json });
          commit("set", { type: "logo", items: response.data.logo_img });
          commit("set", {
            type: "favicon_img",
            items: response.data.favicon_img,
          });
          commit("set", { type: "title", items: response.data.title });
        }
      })
      .catch((error) => {});
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

<template>
    <v-card class="front-bonus">
        <v-card-title class="front-bonus__header header-bonus">
          <div class="header-bonus__label">
            
            <font-awesome-icon style="font-size: 12px;" icon="trophy" />
        </div>
        <!-- {{ $i18n.t('bonusesFree') }} -->
          <div class="header-bonus__title"> {{ $t('tournamentBonus') }}</div>
          
          <!-- @click="toggleCardFlip(index)"  -->
          <div @click="setBonusTournamentOneFlipped" class="header-bonus__label">
            <font-awesome-icon icon="info" style="font-size: 12px;" />
      </div>
        </v-card-title>
          <v-card-text style="width: auto;" class="py-0 front-bonus__main main-bonus">
             <!--<div class="main-bonus__image">
              <img src="@/assets/images/bonus.png" width="100%" /> 
            </div>-->
            <div class="main-bonus__title">
              <span>SLOT WARS</span>
              <p>5,000 {{ user.currency }} & 5,000 {{ user.currency }} Free Spins Weekly</p>
            </div>
            <v-simple-table dark style="background-color: transparent;width: 100%;">
              <template v-slot:default>
                <tbody color="var(--white)">
                  <tr
                    v-for="item in players"
                    :key="item.name"
                  >
                    <td style="font-size:14px;color:var(--white);border-bottom: 1px solid var(--gray)"> {{item.id}}. {{ item.name }}</td>
                    <td style="border-bottom: 1px solid var(--gray)"><b>{{ item.score }}</b></td>
                    <td style="color:#ffd22f;border-bottom: 1px solid var(--gray)"><b>{{ item.win }} {{ user.currency }} </b></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions class="justify-center px-5">
          <div class="font-weight-bold timer-block item-timer">
            <span>{{ $t('timeLeftBonus') }}</span>
            <!-- expire_in_seconds -->
            <Countdown :expireInSeconds="1" />
        </div>
      </v-card-actions>
      </v-card>
</template>

<script>
import Countdown from '@/components/Countdown/Timer'
import { mapGetters } from 'vuex'
    export default {
        name: 'BonusTournamentOneFront',
        components:{
          Countdown
        },
        data(){
            return{
              players:[
                {
                  id: '1',
                  name: 'Vasya',
                  score: '2000',
                  win: '2125',
                },
                {
                  id: '2',
                  name: 'Masha',
                  score: '1400',
                  win: '1125',
                },
                {
                  id: '3',
                  name: 'Petya',
                  score: '1000',
                  win: '825',
                },
                {
                  id: '4',
                  name: 'Sasha',
                  score: '800',
                  win: '525',
                },
              ]
            }
        },
        computed:{
          ...mapGetters(
            {
              user: 'getUserInfo',
            }
          ),
        },
        methods: {
            setBonusTournamentOneFlipped(){
            this.$store.commit('setBonusTournamentOneFlipped', true)
          }
        },
    }
</script>

<style lang="scss" scoped>
.front-bonus {
  //background: linear-gradient(173.41deg,#0094ff 4.14%,#5607d6 87.72%)!important;
  //background: var(--dark-flamingo)!important;
  background: url('../../../../../assets/images/tournamentCard.jpg') 0 70% / cover no-repeat;
  color: var(--white)!important;
  //height: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
    border-radius: 16px!important;
  &__header {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
  }
  &__content{
    
  }
  &__main {
    flex: 1 1 100%;
    position: relative;
    z-index: 2;
  }

  &__timer {
    position: relative;
    z-index: 2;
  }
  
}
.header-bonus {
  padding: 10px;

  &__label {
    height: 24px;
    justify-content: center;
    
    position: relative;
    width: 24px;
    align-items: center;
    display: flex;
    position: relative;
    transition: background-color 0.3s;
    &:last-child{
      cursor: pointer;
      
      @media (any-hover: hover){
        &:hover{
          &::before {
            background-color: hsla(0, 0%, 100%, .4);
          }
        }
      }
      
    }
    &::before {
      background-color: hsla(0, 0%, 100%, .2);
      border-radius: 50%;
      content: "";
      //height: calc(100% - 20px);
      height: 24px;
      width: 24px;
      position: absolute;
      //width: calc(100% - 20px);
    }
    
    }
  }
.main-bonus {
  
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content:space-around;
  align-items: center;
  &__image {
  }

  &__title {
    text-transform: uppercase;
    font-weight: 900;
    color: var(--white)!important;
    display: flex;
    flex-direction: column;
    justify-content:center;align-items: center;
    line-height: 1;
    span{
      font-size: 47px;
    
    }
    p{
      color: #ffd22f;
    max-width: 240px;
    align-items: center;
      font-size: 10px;
      background-color: var(--dark-flamingo);
      border-radius: 16px;
      padding: 5px 10px;
    }
  }
}
.timer-block{
  min-height: 38px;
  transform: translate(-3px,0px);
  display: flex;
  position: relative;
  z-index: 4;
  flex-direction: column;
  width: 100%;
  justify-content:center;align-items: center;
  border: 1px solid var(--red);
  border-radius: 4px;
  background-color: rgb(78 0 72 / 85%);
  div{
    font-size: 12px;
  }
  span{
    font-size: 12px;
    color: var(--white-design);
  }
}
</style>

<template>
    <v-alert type='info' dense class="info" :class="{ '_active' : infoLimitsUpdate || alreadyDepositBonusUsed}">
        <span v-if="infoLimitsUpdate">{{ $t('limitInfo') }}</span>
        <span v-if="alreadyDepositBonusUsed">{{ $t('alreadyDepositBonusUsed') }}!</span>
        
    </v-alert>
</template>

<script>
export default {
    name: 'AlertSuccess',
    data() {
        return {
            
        }
    },
    computed:{
        infoLimitsUpdate() {
            return this.$store.state.limitsUpdate.infoLimitsUpdate;
        },
        alreadyDepositBonusUsed() {
            return this.$store.state.depositView.isAlreadyDepositBonusUsed;
        },
    }
}
</script>

<style lang="scss" scoped>
.info{
position: absolute;
top: 55px;
color: var(--white);
left: 15px;
width: calc(100% - 30px);
z-index: 2;
opacity: 0;
visibility: hidden;
transition: opacity 0.3s, visibility 0.3s, top 0.3s;
@media (min-width:500.98px){
  right: 10px;
  left: unset;
    width: 350px;
}
&._active{
  opacity: 1;
  visibility: visible;
  top: 65px;
  @media (min-width:959.98px){
    top: 75px;
}
@media (min-width:992.98px){
  top: 85px;
}
}
}
</style>
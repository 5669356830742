<template>
    <div>
         <div class="error-block">
             <div class="closeIcon" @click="moveHome">
               <v-icon color="white">close</v-icon>
             </div>
             <!-- <img width="150px" :src="`https://minio.takemybet.pro/ui-style/${logo}`" alt="logo"/> -->
             <span>
                 <v-icon class="online">mdi-web-off</v-icon>
                 <v-icon class="online">mdi-gift-open-outline</v-icon>
             </span>
             <div>
                 <p>{{ $i18n.t('liveErrorBonus') }}</p>
                 <span>{{ $i18n.t('wagerBalanceForPlay') }}</span>
             </div>
           </div>
    </div>
 </template>
 
 <script>
     export default {
         name: 'BonusErrorGame',
         methods:{
             async moveHome() {
                await this.$router.back();
     },
         },
     }
 </script>
 
 <style lang="scss" scoped>
 .error-block{
     display: flex;
     flex-direction: column;
     justify-content:center;
     align-items: center;
     font-weight:600;
     padding: 0 8px;
     color: var(--white);
     .online{
         color: var(--white);
        font-size: 75px;
     }
     p{
         font-size: 25px;
            margin-bottom: 0px;
     }
     span{
        font-size: 16px;
        max-width: 750px;
        display: inline-block;
        margin: 0 auto;
     }
     @media (max-width:992px){
      p{
        font-size: 20px;
      } 
      span{
        font-size: 14px;
      }   
     }
   }
 .closeIcon {
     background-color: var(--red);
     border-radius: 4px;
     flex: 0 0 35px;
     width: 35px;
     height: 35px;
     display: flex;
     justify-content: center;
     align-items: center;
     position: absolute;
     top: 20px;
     left: 5px;
     z-index: 300;
     @media (any-hover: hover){
       cursor: pointer;
     }
   }
 </style>
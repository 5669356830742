<template>
  <v-card-title
    class="dialog__card-header dialog-card-header"
    :class="{ _mobile: $isMobile() }"
  >
    <v-row class="dialog-card-header__items">
      <v-col class="dialog-card-header__item">
        <img
          :src="`https://minio.takemybet.pro/ui-style/${logo}`"
          class="dialog-card-header__logo"
          alt="main-logo"
        />
      </v-col>
      <v-col class="dialog-card-header__item">
          <span>{{ $i18n.t("enterAva") }}</span>
      </v-col>
      <v-col class="dialog-card-header__item">
        <v-btn class="dialog-card-header__close" @click="closeRegDialog">
          <v-icon color="black">close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card-title>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "AvatarsHeader",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      logo: 'styles/getLogo',
    }),
  },
  methods: {
    async closeRegDialog() {
      if (!this.$isMobile()) {
        this.$store.commit("regComponents/setAvaReg", false);
        this.$store.commit("regComponents/setSelectedImageIndex", -1);
      } else {
        this.$store.commit("regComponents/setAvaReg", false);
        this.$store.commit("regComponents/setSelectedImageIndex", -1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-card-header {
  background: #f2f6f9;
  border-bottom: 1px solid #dfe4e8;
  padding: 3px 15px !important;
  @media (max-width: 850px) {
    &._mobile {
      overflow: hidden;
      position: relative;
      z-index: 5;
    }
  }
  @media (min-width: 850px) {
    padding: 10px 15px !important;
  }
  .dialog_pc & {
    padding: 15px !important;
  }

  &__items {
    margin: 0;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    z-index: 2;
    .dialog_pc & {
      justify-content: flex-end;
    }
  }

  &__item {
    flex: 0 1 33.333% !important;
    padding: 0;
    display: flex;
    justify-content: center;

    &:last-child {
      justify-content: flex-end;
    }

    &:first-child {
      justify-content: flex-start;
    }

    .dialog_pc & {
      &:first-child {
        justify-content: flex-end;
      }
    }

    span {
      color: var(--black);
      font-size: 15px;
      white-space: nowrap;
      font-weight: 600;
      line-height: 1.5;
      text-transform: uppercase;
    }

    @media (min-width: 850px) {
      flex: 1 1 100% !important;
      &:first-child {
        justify-content: center !important;
      }
      &:last-child {
        flex: 0 0 40px !important;
      }
      &:first-child {
        display: none;
      }
    }
  }

  &__logo {
    width: 65px;
    height: 65px;

    img {
    }
  }

  &__close {
    padding: 0 !important;
    cursor: pointer;
    min-width: 36px !important;
    height: 36px !important;
    box-shadow: none !important;
    background-color: var(--white);
    align-items: center;
    background-color: var(--white) !important;
    border: none;
    border-radius: 6px;
    transition: transform 0.3s;
    @media (any-hover: hover){
      &:hover{
        i {
          transform: rotate(-180deg);
        }
      }
    }
    
  }
}
</style>

import { render, staticRenderFns } from "./FlipCardTournamentOne.vue?vue&type=template&id=55be1fbe&scoped=true&"
import script from "./FlipCardTournamentOne.vue?vue&type=script&lang=js&"
export * from "./FlipCardTournamentOne.vue?vue&type=script&lang=js&"
import style0 from "./FlipCardTournamentOne.vue?vue&type=style&index=0&id=55be1fbe&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55be1fbe",
  null
  
)

export default component.exports
<template>
  <!-- Delete Doc -->
  <v-dialog
    v-model="isDeleteDocModal"
    content-class="passportDialog"
    max-width="550px"
    persistent
    transition="dialog-transition"
  >
    <v-card tile>
      <v-toolbar flat dark color="var(--red)">
        <v-spacer></v-spacer>
        <v-toolbar-title class="font-weight-bold">{{
          $t("VerifToDelete")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text>
        <v-col class="text px-0">
          <span>
            {{ `${$t("VerifToDeleteSure")}` }}
            
            <b>{{ docInfo.name }}</b>
          </span>
          
          <span>
            {{ $t("VerifToDeleteNoWay") }}
          </span>
        </v-col>
        <v-card-actions class="d-flex justify-space-between" style="gap: 10px">
          <v-col class="pa-0">
            <v-btn
              @click="closeModal"
              color="var(--red)!important"
              class="all-buttons-fw"
              :disabled="loading"
              >{{ $t("VerifNo") }}
            </v-btn>
          </v-col>

          <v-col class="pa-0">
            <v-btn
              @click="confirmDeleteDoc"
              :loading="loading"
              class="all-buttons-fw all-buttons-fw_green"
              >{{ $t("VerifYes") }}</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "VerifModalDelete",
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    isDeleteDocModal() {
      return this.$store.state.verification.isDeleteDocModal;
    },
    docInfo() {
      return this.$store.state.verification.docInfo;
    },
  },
  methods: {
    closeModal() {
      this.$store.commit("verification/isDeleteDocModal", false);
      const docInfo = {
        name: null,
        number: null,
      };
      this.$store.dispatch("verification/holdDocInfo", docInfo);
    },
    async confirmDeleteDoc() {
      this.loading = true;
      const success = await this.$store.dispatch("deleteDocs", {
        doc_number: this.docInfo.number,
      });
      if (success) {
        await this.$store.dispatch("awaitGetUserInfo");
        this.$store.commit("verification/isDeleteDocModal", false);
        const docInfo = {
          name: null,
          number: null,
        };
        this.$store.dispatch("verification/holdDocInfo", docInfo);
      } else {
        const notification = {
          type: "error",
          message: this.$t("justError"),
        };
        this.$store.dispatch("alerts/add", notification);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.text{
    color: #161421; 
    text-align: center;
    justify-content:center;align-items: center;
    font-size: 16px; 
    line-height: 1.5;
    display: flex;
    flex-direction: column;
}
</style>

import "@babel/polyfill";
import Vue from "vue";
import App from "./App.vue";
import blockedComponent from "../blocked-region-page/blocked.vue";
import router from "./router/router";
import store from "./store/store.js";
import vuetify from "./plugins/vuetify.js";
// import "./plugins/google-auth.js";
import "./plugins/fa-icons";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import axios from "axios";
import VLazyImage from "v-lazy-image/v2";
import VueCookie from "vue-cookie";
Vue.config.productionTip = false;
//Vue.use(VLazyImage);
Vue.component("v-lazy-image", VLazyImage);
Vue.use(VueCookie);
import VueLazyload from "vue-lazyload";

import VueMobileDetection from "vue-mobile-detection";

Vue.use(VueMobileDetection);
Vue.use(VueLazyload, {
  attempt: 2,
});
let local;
import chat from "./plugins/jivo-chat-functions";

Vue.prototype.$jivoChat = chat;
import VueRouter from "vue-router";
import { sync } from "vuex-router-sync";
Vue.use(VueRouter);
sync(store, router);
//import VueAnime from "vue-animejs";

//Vue.use(VueAnime);
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  //message: (fieldName) => {
  //  return local.required_field + " " + fieldName;
  //},
  message: "requiredField",
});
extend("email", {
  ...email,
  message: "emailNotValid",
});
extend("min8", {
  ...min,
  params: ["length"],
  message: "regPassAlert",
  validate(value, { length }) {
    return value.length >= 8;
  },
});
extend("min3", {
  ...min,
  params: ["length"],
  message: "minThreeChar",
  validate(value, { length }) {
    return value.length >= 3;
  },
});
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
import * as Fingerprint2 from "fingerprintjs2";

Vue.prototype.$fingerprint = Fingerprint2;

// import "./plugins/swHandler";
import { i18nPromise } from "./plugins/i18n";

async function createVueInstance() {
  //========================================================================================================================================================
  //styles
  await store.dispatch("styles/getUI");
  const variables = store.getters["styles/getColorVariables"];
  document.title = store.getters["styles/getTitle"];
  variables.forEach((element) => {
    document.documentElement.style.setProperty(element.key, element.value);
  });
  const favicon = store.getters["styles/getFavicon"];

  const faviconUrl = `https://minio.takemybet.pro/ui-style/${favicon}`;
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = faviconUrl;
  //========================================================================================================================================================

  if (localStorage.getItem("jwt")) {
    await store.dispatch("awaitGetUserInfo");
  }
  const i18n = await i18nPromise();
  //const firstLanguageKey = Object.keys(i18n._vm.messages)[0];
  //local = i18n._vm.messages[firstLanguageKey];

  axios.interceptors.response.use(
    function (response) {
      // success response
      // console.log("response = ", response);
      if (
        response.data &&
        response.data.error === 6000 &&
        response.data.redirect_url === "https://takemybet.pro/blocked"
      ) {
        store.commit("set", { type: "isBlockedCountry", items: true });
      } else if (response.data && response.data.error === 6000) {
        if (response.data.redirect_url) {
          window.location.href = response.data.redirect_url;
        }
      } else if (response.data && response.data.error === 6001) {
        // router.push("/maintenance");
        store.commit("set", { type: "isBlockedProduct", items: true });
      }
      return response;
    },
    function (error) {
      // error
      // console.log("error");
      if (error.response && error.response.status === 401) {
        store.dispatch("logOut");
      }
      return Promise.reject(error);
    }
  );

  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: function (h) {
      return h(App);
    },
  }).$mount("#app");
}

createVueInstance().catch((error) => {
  console.error("Failed to initialize Vue instance:", error);
});

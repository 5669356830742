<template>
  <div class="block-container">
    <div class="block" v-html="$i18n.t('siteRules')"></div>
    <ul class="bg-bubbles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Rules",
};
</script>

<style lang="scss" scoped>
.bg-bubbles{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  z-index: 1;
  
  li{
    position: absolute;
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    // background-color: rgba(255, 255, 255, 0.25);
    background: url('../assets/images/bubles/white-heart-bubble.svg') 0 / 100% no-repeat;
    bottom: -160px;
    opacity: .3;
    -webkit-animation: rules 120s infinite;
    animation:         rules 120s infinite;
    
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    &:nth-child(1){
      left: 10%;
      background: url('../assets/images/bubles/cards-spades-bubble.svg') 0 / 100% no-repeat;
    }
    
    &:nth-child(2){
      left: 20%;
      background: url('../assets/images/bubles/cards-diamonds-bubble.svg') 0 / 100% no-repeat;
      width: 80px;
      height: 80px;
      
      animation-delay: 2s;
      animation-duration: 60s;
    }
    
    &:nth-child(3){
      left: 25%;
      animation-delay: 4s;
      background: url('../assets/images/bubles/cards-clubs-bubble.svg') 0 / 100% no-repeat;
    }
    
    &:nth-child(4){
      left: 40%;
      width: 60px;
      height: 60px;
      background: url('../assets/images/bubles/cards-spades-bubble.svg') 0 / 100% no-repeat;
      animation-duration: 65s;
      
      // background-color: rgba(255, 255, 255, 0.25);
    }
    
    &:nth-child(5){
      left: 70%;
      background: url('../assets/images/bubles/cards-clubs-bubble.svg') 0 / 100% no-repeat;
    }
    
    &:nth-child(6){
      left: 80%;
      width: 120px;
      height: 120px;
      
      animation-delay: 3s;
      // background-color: rgba(255, 255, 255, 0.25);
    }
    
    &:nth-child(7){
      left: 32%;
      width: 160px;
      height: 160px;
      
      animation-delay: 7s;
    }
    
    &:nth-child(8){
      left: 55%;
      width: 20px;
      height: 20px;
      
      animation-delay: 15s;
      animation-duration: 80s;
    }
    
    &:nth-child(9){
      left: 25%;
      width: 10px;
      height: 10px;
      
      animation-delay: 2s;
      animation-duration: 80s;
      // background-color: rgba(255, 255, 255, 0.25);
    }
    
    &:nth-child(10){
      left: 90%;
      width: 160px;
      height: 160px;
      
      animation-delay: 11s;
    }
  }
}
@-webkit-keyframes rules {
  0%   { transform: translateY(0); }
  100% { transform: translateY(-6500px) rotate(600deg); }
}
@keyframes rules {
  0%   { transform: translateY(0); }
  100% { transform: translateY(-6500px) rotate(600deg); }
}
.block-container{
  background-color: var(--main-flamingo);
}
.block{
  position: relative;
  z-index: 2;
  margin: 0 auto;
  max-width: 1330px;
  padding: 85px 30px 30px 20px;
  @media (max-width:500px){
    padding: 65px 30px 30px 20px;
  }
  .is-email-confirmation-bar-visible &{
    padding: 115px 30px 30px 20px;
  @media (max-width:992.98px){
    padding: 135px 30px 30px 20px;
  }
  }
}
</style>

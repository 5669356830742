<template>
    <div class="mobile_grid_wrapper" v-if="games.length > 0">
      <div v-for="(game, index) in games" :key="index" class="game__item"
    >
      <div class="game-mobile">
        <div class="game-mobile__image-container">
          <img :src="game.logo" class="game-mobile__image " />

        </div>
        <div class="game-mobile__overlay">
          <span class="game-mobile__name">
            {{ game.name }}
          </span>
          <div class="game-mobile__content">
            <v-btn class="game-mobile__play all-buttons-t all-buttons-fw_green" @click="playGame(game)">
              <img src="@/assets/images/white-heart.svg" width="15px" height="15px" class="white-heart mr-1">
  
              {{ $i18n.t('play') }}
            </v-btn>
            <v-btn class="game-mobile__demo all-buttons-t" @click="playGame(game, true)">
              {{ $i18n.t('demo') }}
            </v-btn>
          </div>

        </div>
      </div>
    </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MobileGamesList",
  components: {},
  data() {
    return {
      searchFlag: false,
      navDrawer: false,
      showingGamesCount: 36, //28
      gamesLoadingFlag: false,
      loading: false,
      provider: "",
      searchGame: "",
      dialogMobile: false,
      selectedGame: {},
    };
  },
  computed: {
    ...mapGetters({
      games: 'gameModule/getSearchGames',
      user: "getUserInfo",
    }),
  },
  methods: {
    async loadAllGames() {
      this.loading = true;
      await this.$store.dispatch("gameModule/getSearchedGames", {});
      this.loading = false;
    },
    async playGame(game, demoFlag = false) {
      this.$root.$emit("gameClicked");
      if (this.user.id === null) {
        try {
          await this.$router.push("/reg");
        } catch (error) {
          if (error.name !== "NavigationDuplicated") {
            throw error;
          }
        }
        this.$store.commit("search/setSearchMobile", false);
      } else {
        if (this.user.balance == 0 && !demoFlag) {
          // mb === 0
          try {
            await this.$router.push("/deposit");
          } catch (error) {
            if (error.name !== "NavigationDuplicated") {
              throw error;
            }
          }
          this.$store.commit("search/setSearchMobile", false);
        } else {
          this.$store.commit("search/setSearchMobile", false);
          let params = { id: game.string_id };
                if (demoFlag) {
                  params.demo = 'demo';
                }
                await this.$router.push({ name: 'Game', params });
        }
      }
      await this.$store.dispatch("gameModule/getSearchedGames", {});
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin imageGame {
  max-width: 100%;
  max-height: 100%;
  border-radius: 16px;
  overflow: hidden;
  transition: opacity 0.3s;
}

@mixin logoLoading {
  position: relative;

  &::before {
    content: '';
    display: block;
    border-radius: 16px;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    background-color: var(--red);
    animation: disappear 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    opacity: 1;
    visibility: visible;
  }

  &::after {
    content: '';
    position: absolute;
    border-radius: 16px;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background: url('../../assets/images/main_logo.png') center / 90px no-repeat;
    animation: disappear2 1s;
    opacity: 0.6;
    animation-delay: 1s;
    animation-fill-mode: forwards;

    @media (max-width:992px) {
      background-size: 60px;
    }
  }
}
.white-heart {
  width: 15px !important;
  height: 15px !important;
  margin-right: 5px;

  @media (max-width: 500px) {
    width: 13px !important;
    height: 13px !important;
  }

  &_big {
    width: 15px !important;
    height: 15px !important;
  }
}

@keyframes disName {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

.mobile_grid_wrapper {
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  grid-gap: 6px;
  @media (max-height: 992px) and (orientation: landscape) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-height: 500px) and (orientation: landscape) {
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    align-items: center;
  }
}
.game-mobile {
  @include imageGame;
  perspective: 1000px;
  transition: transform 0.8s;
  transform-style: preserve-3d;

  &:hover {
    transform: rotateY(180deg);

    .game-mobile__overlay {
      opacity: 1;
      visibility: visible;
    }
  }

  &__image-container {
    @include logoLoading;
    @include imageGame;
  }

  &__image {
    @include imageGame;
  }

  &__overlay {
    position: absolute;
    
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transform: rotateY(180deg);
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.7);
    transition: opacity 0.5s, visibility 0.5s;
    transition-delay: 0.1s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  
  }
  &__content{
    padding: 0 30px;
    @media (max-width:600px) {
      padding: 0 20px;
    }
  }
  &__name {
    font-size: 14px;
    color: var(--white);
    line-height: 1;

    @media (max-width:600px) {
      font-size: 12px;
    }
  }

  &__play {
    margin-top: 5px;
    width: 100%;

    @media (max-width:600px) {
      margin-top: 3px;
      height: 30px !important;
      min-height: 30px !important;
    }
  }

  &__demo {
    margin-top: 10px;
    width: 100%;

    @media (max-width:600px) {
      margin-top: 5px;
      height: 30px !important;
      min-height: 30px !important;
    }
  }
}
@keyframes disappear {
  0% {
    opacity: 1;
    visibility: visible;

  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes disappear2 {
  0% {
    opacity: 0.6;
    visibility: visible;

  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}
</style>

<template>
  <v-overlay opacity="1" v-model="depositWindow" absolute style="overflow:hidden" class="pt-12" z-index="5">
    <v-container style="width: 100%;max-width: 500px" class="mb-10">
      <div class="overlayLogo">
        <img :src="`https://minio.takemybet.pro/ui-style/${logo}`" class="main__logo" />
      </div>
      <v-row>
        <v-col class="text-right">
          <font-awesome-icon style="cursor:pointer" icon="times" size="2x" @click="closeDialog" />
        </v-col>
      </v-row>
      <v-row class="my-0" align="center">
        <v-col class="mb-2 text-center">
          <v-lazy-image @load="loadingFlag = false" :src="require('@/assets/images/demo-button.png')" style="height: 80px;width:180px;"
            ></v-lazy-image>
          <!-- :src="selectedMethod.icon" -->
        </v-col>
      </v-row>
      <v-text-field type="number" color="var(--white)" outlined background-color="transparent" style="font-size: 18px"
        v-model="amount" :error-messages="amountErr" v-on:keyup.enter="getDepositUrl" hide-details class="mb-3">
        <template v-slot:append>
          <div style="padding-top: 2px">
            <b>{{ user.currency }}</b>

          </div>
        </template>
      </v-text-field>
      <div class="deposit-payment-wrapper small-text mt-0 align-start">
        <div class="deposit-payment-time">
          {{ $t('commision') }}: {{ `${selectedMethod.fee}%` }}
        </div>
      </div>

      
      <v-row>
        <v-col class="payBtn_col">
          <!-- -->
          <v-btn :loading="loadingFlag" :disabled="!amountValid" @click="getDepositUrl" class="all-buttons-fw all-buttons-fw_green">
            {{$t('payable')}}: {{`${totalAmount.toFixed(2)} ${user.currency}`}}</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="selectedMethod.payment_max_limit" class="text-center">
          {{ $i18n.t('from') }} {{ selectedMethod.payment_min_limit }}
          {{ $i18n.t('to') }} {{ selectedMethod.payment_max_limit.toLocaleString() }} <b style="">{{
            user.currency }}</b>
          <br>
          {{ $i18n.t('perOneTransaction') }}
        </v-col>
      </v-row>
    </v-container>
    <v-overlay absolute v-model="loadingFlag">
      <Preloader></Preloader>
    </v-overlay>
  </v-overlay>
</template>

<script>
import Preloader from "@/components/Preloader";
import { mapGetters } from 'vuex';
export default {
  name: 'DepositConfirmOverlay',
  components: {
    Preloader
  },
  data() {
    return {
      // selectedMethod: {},
      loadingFlag: false,
      // moneyToDeposit: localStorage.getItem('moneyToDeposit'),
      // amount: 2500,
    }
  },
  beforeRouteLeave (to, from, next) {
    next(vm=>{
      vm.$store.commit('depositView/setDepositWindowError', false);
    })
  },
  watch: {
    // moneyToDeposit(newVal) {
    //   this.amount = Number(newVal);
    // }
  },
  mounted() {
    // const amountStorage = localStorage.getItem('moneyToDeposit');
    // this.amount = Number(amountStorage);
  },
  computed: {
    ...mapGetters({
      logo: 'styles/getLogo',
      payMethods: 'getPayMethods',
      user: 'getUserInfo',
    }),
    amount: {
      get(){
        return this.$store.state.depositView.amount;
      },
      set(value){
        this.$store.commit('depositView/SET_AMOUNT', value);
      },
    },
    totalAmount(){
      const amount = Number(this.amount);
      const feePercentage = this.selectedMethod.fee / 100;
      const feeAmount = amount * feePercentage;
      const totalAmount = amount + feeAmount; 
      //const totalAmount = amount / (1 - this.selectedMethod.fee / 100); 
      return totalAmount;
    },
    feeAmount(){
      const amount = Number(this.amount);
      const feePercentage = this.selectedMethod.fee / 100;
      const feeAmount = amount * feePercentage;
      // const totalAmount = amount + feeAmount; 
      return feeAmount;
    },
    isBonusWillActive: {
      get() {
        return this.$store.state.depositView.isBonusWillActive;
      },
      set(value) {
        this.$store.commit('depositView/setBonusWillActive', value);
      },
    },
    amountValid() {
      let amount = Number.parseFloat(this.amount);
      return !Number.isNaN(amount) && amount >= this.selectedMethod.payment_min_limit && amount <= this.selectedMethod.payment_max_limit;
    },
    amountErr() {
      let amount = Number.parseFloat(this.amount);
      if (this.selectedMethod.payment_min_limit && this.selectedMethod.payment_max_limit) {
        if (Number.isNaN(amount) || amount < this.selectedMethod.payment_min_limit) {
          return [`${this.$i18n.t('minAmount')} ${this.selectedMethod.payment_min_limit} ${this.$store.getters.currency}`]
        } else if (amount > this.selectedMethod.payment_max_limit) {
          return [`${this.$i18n.t('maxAmount')} ${this.selectedMethod.payment_max_limit.toLocaleString()} ${this.$store.getters.currency}`]
        }
      }
      return [];
    },
    depositWindow: {
      get() {
        return this.$store.state.depositView.depositWindow;
      },
      set(value) {
        this.$store.commit('depositView/setDepositWindowError', value);
      },
    },
    selectedMethod: {
      get() {
        return this.$store.state.depositView.selectedMethod;
      },
      set(value) {
        this.$store.commit('depositView/setSelectedMethod', value);
      },
    },
  },  
  methods: {
    closeDialog() {
      this.$store.commit('depositView/setDepositWindowError', false)
      this.$store.commit('depositView/setDepositMethodsWindow', true);
      this.$store.commit("depositView/setMethodMoney", true);

    },
    async getDepositUrl() {
      const holdGame = localStorage.getItem('redirectToGame');
        if (!this.amountValid) {
          return;
        }
      
        this.amount = Number.parseFloat(this.amount);
        this.loadingFlag = true;
      
        const depositLinkParams = {
          // payWay: this.selectedMethod.codename,
          amount: this.amount,
          fee_amount: this.feeAmount,
          payment_id: this.selectedMethod.id,
        };
      // console.log(depositLinkParams);
        if (this.amount > 0 && this.isBonusWillActive) {
          depositLinkParams.first_deposit_bonus_enabled = true;
        }
          const [res, err] = await this.$store.dispatch('getDepositLink', depositLinkParams);
        
          if (res) {
          
          if (!this.user.has_first_dep && this.isBonusWillActive) {
            const notification = {
              type: "success",
              message: this.$t('bonusHasBeenActivated'),
            };
            this.$store.dispatch("alerts/add", notification);
            this.reloadAfterDelay(res);
            // if (holdGame) {
            //   const { id, flag } = JSON.parse(holdGame);
            //   this.$router.push(`/game/${id}/${flag}`);
            //   localStorage.removeItem('redirectToGame');
            // } else {
            //   this.reloadAfterDelay(res);
            // }
              
          } else {
            // if (holdGame) {
            //   const { id, flag } = JSON.parse(holdGame);
            //   this.$router.push(`/game/${id}/${flag}`);
            //   localStorage.removeItem('redirectToGame');
            // } else {
            //   this.reloadAfterDelay(res);
            // }
            this.reloadAfterDelay(res);
          } 
        } else if(err === 'Server error'){
                  const notification = {
              type: "error",
              message: this.$t("serverError"),
            };
            this.$store.dispatch("alerts/add", notification);
            }
        this.loadingFlag = false;
      },

      async reloadAfterDelay(res) {
        await this.$store.dispatch('awaitGetUserInfo');
        this.$store.commit('depositView/setDepositWindowError', false);
        await this.$router.push('/');
        // location.reload();
        setTimeout(() => {
          //window.location.href = res;
        }, 1500);
      },
  },
  
}
</script>

<style lang="scss" scoped>
.overlayLogo{
  width: 100%;
  height: 80px;
  display: flex;
  flex: 0 0 80px;
  justify-content:center;align-items: center;
  @media (max-height: 510px){
      position: absolute;
      z-index: 5;
      top: 50%;
      transform: translate(20%,-100%);
      right: 0%;
      width: 40px;
      height: 40px;
  }
  img{
    max-width: 100%;
    max-height: 100%;
  }
}
.main__logo {
  
}
.deposit-payment-wrapper {
  display: flex;
  flex-direction: column;
  justify-content:center;align-items: center;
  margin-top: 10px;
  color: var(--white);
  font-family: "Inter", Helvetica, Arial, sans-serif;
}
.deposit-payment-secure {
  display: flex;
  align-items: center;
}
</style>
<template>
    <v-card
    v-click-outside="clickOutsideNavbar"
                  ref="searchFrame"
                  class="search-pc-result"
                  flat
                >
                  <v-row class="search-pc-result_ctn">
                    <v-col class="search-pc-result__col" v-if="!loadingFlag">
                      <v-card-title class="search-pc-result__title">
                        {{ $i18n.t("resultSearch") }}:
                      </v-card-title>
                      <v-card-text
                        class="search-pc-result__results"
                        :class="{ '_enter-game-name': mobileSearchEmpty }"
                      >
                        <pc-games-list v-if="!mobileSearchEmpty"></pc-games-list>
                        <span v-else>{{ $t("searchEmpty") }}</span>
                      </v-card-text>
                    </v-col>
                    <v-col class="search-pc-result__col" v-else>
                      <v-card-text
                        class="search-pc-result__results _enter-game-name"
                      >
                      <v-progress-circular
                      indeterminate
                      color="var(--white)"
                    ></v-progress-circular>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
</template>

<script>
import PcGamesList from './PcGamesList';
    export default {
        name: 'searchNavbar',
        components:{
            PcGamesList
        },
        props:{
          loadingFlag: {
            type: Boolean,
          },
            isClickedInput: {
                type: Boolean,
                default: false,
            },
            isClickedOutside: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                
                mobileSearchEmpty: true,
            }
        },
        watch:{
            gameName(){
                if (this.gameName.trim() === "") {
                  this.mobileSearchEmpty = true;
                } else {
                  this.mobileSearchEmpty = false;
                }
            },
        },
        computed: {
            searchPC() {
                return this.$store.state.search.searchPC;
            },
            gameName() {
                return this.$store.state.search.searchName;
            },
        },
        methods:{
            clickOutsideNavbar(){
                if (!this.isClickedInput) {
                    this.clearGames();
                    this.$store.commit("search/setSearchPC", false);
                    this.mobileSearchEmpty = true;
                    this.$emit('clear-input')
                }
            },
            clearGames() {
              this.$store.dispatch("gameModule/getFilteredGames", { provider: "", game: "" });
            },
        },
    }
</script>

<style lang="scss" scoped>
.search-pc-result {
    background-color: var(--light-flamingo) !important;
    border-radius: 6px !important;
    left: 0;
    padding: 0 6px;
    position: absolute;
    top: 46px;
    transition: left 0.3s;
    min-width: 700px;
    width: 100%;
  
    z-index: 104;
    &::before {
      background-color: var(--light-flamingo);
      content: "";
      height: 8px;
      left: 12px;
      position: absolute;
      top: -4px;
      transform: rotate(45deg);
      transition: left 0.3s;
      width: 8px;
    }
    &_ctn {
      overflow: hidden;
    }
  
    &__col {
    }
  
    &__title {
      color: var(--white);
    }
  
    &__results {
      height: 565px;
      overflow: auto;
      padding-top: 15px !important;
  
      &._enter-game-name {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      span {
        color: var(--white);
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }
  .search-pc-result__results::-webkit-scrollbar {
    @media (any-hover: hover) {
      height: 10px;
      width: 10px;
      border-radius: 10px;
      background-color: var(--white);
    }
  }
  .search-pc-result__results::-webkit-scrollbar-thumb {
    @media (any-hover: hover) {
      border-radius: 10px;
      height: 10px;
      background-color: #4a1558;
    }
  }
</style>
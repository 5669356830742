<template>
    <div>
        <v-chip-group class="links-label">
            <v-chip active-class="_active" to="/deposit" label dense class="link-label">
              <div>  {{ $i18n.t('deposit2') }}</div>
            </v-chip>
            <v-chip active-class="_active" to="/profile/cashbox/payhistory" label dense class="link-label">
              <div>  {{ $i18n.t('paymentsHistory') }}</div>
            </v-chip>
            <v-chip active-class="_active" to="/cashout" label dense class="link-label">
              <div>  {{ $i18n.t('withdraw') }}</div>
            </v-chip>
          </v-chip-group>
        <router-view class="info_router">

        </router-view>
    </div>
</template>

<script>
export default {
    name: "CashBox"
}
</script>

<style lang="scss" scoped>
.tabs-name__noburger {

    @media (max-width:992px) {
        display: none;
    }
}

.links {

    color: var(--white);
    font-size: 16px;
    text-transform: uppercase !important;
    padding: 0px;
}

.link {
    text-decoration: none;
    color: var(--white);
    transition: font-weight 0.3s;

    @media (any-hover: hover) {
        &:hover {
            font-weight: 600;
        }
    }

    &._active {
        font-weight: 600;
    }
}
</style>
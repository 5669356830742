<template>
    <v-bottom-navigation app dense height="40" background-color="rgba(141, 36, 170, 0.7)" class="b-nav" ref="bottomNav">
        <transition name="bottomList">
        <BottomGamesList v-if="activeCategory !== null" :class="activeCategory !== null ? 'is-active' : null" :categoryName="categoryFromParent" />
    </transition>
        <ul class="b-nav__list" >
            <li ref="bottomNavItem" class="b-nav__item" v-for="(category, index) in filteredCategoryList" :key="index" :class="{ '_active': activeCategory === category.menu }"
                @click="openBottomCategory(category.menu)"
                
                >
                <v-tooltip top v-if="category.menu !== 'browser-notif'">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="item-category__icon item-category"
                        
                        v-if="category.vIcon">{{ category.icon
                        }}</v-icon>
                    </template>
                    <span>{{ category.tooltip }}</span>
                </v-tooltip>
                <v-icon class="item-category__icon item-category" v-if="category.vIcon && category.menu === 'browser-notif'">{{
                    category.icon }}</v-icon>
            </li>
        </ul>
        <v-spacer></v-spacer>
        <UserTime />
    </v-bottom-navigation>
</template>

<script>
import UserTime from '@/components/Countdown/UserTime'
import BottomGamesList from '@/components/BottomMenu/BottomGamesList'
import { mapGetters } from 'vuex';
export default {
    name: "BottomMenu",
    components:{
        UserTime,
        BottomGamesList,
    },
    data() {
        return {
            categoryFromParent: null,
            activeCategory: null,
            isHaveLastGame: false,
            categoryList: [
                {
                    vIcon: true,
                    menu: "browser-notif",
                    icon: "notifications",
                },
                {
                    vIcon: true,
                    menu: "top-games",
                    icon: "thumb_up",
                    tooltip: this.$t("top"),
                },
                {
                    vIcon: true,
                    menu: "last-games",
                    icon: "schedule",
                    tooltip: this.$t("lastGame"),
                },
                {
                    vIcon: true,
                    menu: "favourite-games",
                    icon: "mdi-heart",
                    tooltip: this.$t("favourite"),
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            favoriteGames: 'favoriteGames/getFavoriteGames',
        }),
        filteredCategoryList() {
          const haveFavourite = this.favoriteGames.items || [];
          return this.categoryList.filter(category => {
            return (
              (category.menu !== 'favourite-games' || haveFavourite.length > 0) &&
              (category.menu !== 'last-games' || this.isHaveLastGame)
            );
          });
        },
  },
  watch: {
    'favoriteGames.items':{
        deep: true,
        handler(newV) {
            if (newV.length === 0) {
                this.closeBottomGameList();
            }
        }
    }
  },
    created(){
    this.$root.$on('closeBottomGameList', this.closeBottomGameList);
  },
    mounted() {
      document.addEventListener('click', this.clickOutside);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.clickOutside);
    },
    
    methods: {
        closeBottomGameList(){
            this.activeCategory = null;
            this.categoryFromParent = null;
        },
        clickOutside(event) {
            const bottomNav = this.$refs.bottomNav;
            if ((bottomNav && !bottomNav.$el.contains(event.target))) {
              this.activeCategory = null;
              this.categoryFromParent = null;
            }
        
    },
        openBottomCategory(category) {
                    if (category === this.activeCategory) {
                  this.activeCategory = null;
                  this.categoryFromParent = null;
                } else {
                    if (category !== 'browser-notif') {
                        
                    
                  this.categoryFromParent = category;
                  this.activeCategory = category;
                }
                }
        },
    },
};
</script>

<style lang="scss" scoped>
.bottomList-enter-active, .bottomList-leave-active {
    transition: opacity 0.3s, bottom 0.3s linear;
  }
  
  .bottomList-enter, .bottomList-leave-to {
    opacity: 0;
    bottom: -110px;
  }
  
  .bottomList-leave-active {
    opacity: 0;
    bottom: -110px;
  }
  
  .bottomList-enter-to, .bottomList-leave {
    opacity: 1;
    bottom: 40px;
  }

ul,
li>* {
    list-style: none;
    padding: 0 !important;
}

.b-nav {
    backdrop-filter: blur(52px);
    z-index: 5!important;
    &__list {
        display: flex;
        align-items: center;
    }

    &__item {

        position: relative;
        &::after{
            content: "";
            height: 0;
            opacity: 0;
            position: absolute;
            transition: opacity .35s;
            width: 0;
            border-bottom: 6px solid hsla(0,0%,100%,.2);
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
        }
        &._active{
            &::after{
                opacity: 1;
            }
        }
    }
}

.item-category {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 8px 13px!important;
    transition: background-color 0.3s ease;
    width: 44px;
    &:hover {
        background-color: hsla(0, 0%, 100%, 0.2);
        color: var(--white) !important;
    }
    
    ._active &{
        background-color: hsla(0, 0%, 100%, 0.2);
        color: var(--white) !important;
        
    }
    &__icon {
        color: #9ea4ae !important;
        fill: var(--white);
    }
}
</style>

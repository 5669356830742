<template>
  <v-dialog
    v-model="depositMethodsWindow"
    :fullscreen="$isMobile() ? true : false"
    :content-class="$isMobile() ? 'whiteDialog' : 'whiteDialog br-16'"
    transition
    no-click-animation
    max-width="450"
    persistent
    scrollable
  >
  <div class="d-flex flex-column">
    <deposit-header></deposit-header>
    <v-card class="card" flat>
        <deposit-selected-method v-if="isSelectedMethod"/>
        <deposit-exit v-if="isMethodExit"></deposit-exit>
        <div class="tabs" v-if="!isMethodExit && !isSelectedMethod">
          <div class="tab" @click="toMoney" :class="{ '_active': isMethodMoney }">
            {{ $i18n.t("paymentsTitle") }}
          </div>
          <div
            @click="toCrypto"
            class="tab"
            :class="{ '_active': isMethodCrypto }"
          >
            {{ $i18n.t("cryptoTitle") }}
          </div>
        </div>
        <deposit-payments v-if="isMethodMoney"></deposit-payments>
        <deposit-crypto v-if="isMethodCrypto"></deposit-crypto>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import DepositSelectedMethod from "./DepositSelectedMethod.vue";
import DepositExit from "../DepositExit.vue";
import DepositPayments from "./DepositPayments.vue";
import DepositCrypto from "./DepositCrypto.vue";
import DepositHeader from "@/views/DepositPages/DepositHeader";
import { mapGetters } from "vuex";
export default {
  name: "DepositMethods",
  components: {
    DepositHeader,
    DepositPayments,
    DepositCrypto,
    DepositExit,
    DepositSelectedMethod,
  },
  data() {
    return {
      // methodCards: [
      //   {
      //     logo: require(`@/assets/images/methods/creditcard.svg`),
      //     name: "Credit Card",
      //   },
      //   {
      //     logo: require(`@/assets/images/methods/paysafecard.svg`),
      //     name: "Paysafecard",
      //   },
      // ],
    };
  },
  mounted() {
    
  },
  watch: {
    depositMethodsWindow: function (newValue) {
      if (!newValue) {
        //this.$store.commit("depositView/setDepositMethodsWindow", true);
        //this.$store.commit("depositView/setMethodExit", true);
        //this.$store.commit("depositView/setMethodMoney", false);
      }
    },
  },
  methods: {
    toMoney() {
      this.$store.commit("depositView/setMethodMoney", true);
      this.$store.commit("depositView/setMethodCrypto", false);
    },
    toCrypto() {
      this.$store.commit("depositView/setMethodMoney", false);
      this.$store.commit("depositView/setMethodCrypto", true);
    },
  },
  computed: {
    isSelectedMethod(){
      return this.$store.state.depositView.isSelectedMethod;
    },
    isMethodExit: {
      get() {
        return this.$store.state.depositView.isMethodExit;
      },
      set(value) {
        this.$store.commit("depositView/setMethodExit", value);
      },
    },
    isMethodMoney: {
      get() {
        return this.$store.state.depositView.isMethodMoney;
      },
      set(value) {
        this.$store.commit("depositView/setMethodMoney", value);
      },
    },
    isMethodCrypto: {
      get() {
        return this.$store.state.depositView.isMethodCrypto;
      },
      set(value) {
        this.$store.commit("depositView/setMethodCrypto", value);
      },
    },
    depositMethodsWindow: {
      get() {
        return this.$store.state.depositView.depositMethodsWindow;
      },
      set(value) {
        this.$store.commit("depositView/setDepositMethodsWindow", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
}
.tabs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  .tab {
    min-height: 50px;
    font-size: 14px;
    padding: 12px 5px;
    font-weight: 400;
    text-align: center;
    background-color: var(--light-flamingo) !important;
    color: var(--white) !important;
    &._active {
      color: var(--black) !important;
      background-color: var(--white) !important;
      font-weight: 600;
    }
  }
}
</style>


<template>
    <div>
        <RegHeader></RegHeader>
        <v-card-text class="is-reg">
            <ValidationObserver class="bubbles-content" ref="observer" v-slot="{ validate }">
                
                <div class="space-y-2">
                    <ValidationProvider
                      name="login"
                      rules="required"
                      v-slot="{ errors }"
                    > 
                          <v-text-field
                        autofocus
                        hide-details
                        outlined
                        color="var(--main-flamingo)"
                        dark
                        class="main-input input-sign"
                        :class="{'_error' : errorLoginFlag}"
                        :label="'E-mail / Id / ' + $i18n.t('phone')" 
                        v-model="login"
                        :error="errorLoginFlag"
                        @input="resetErrors"
                        dense
                        >
                        <!-- @blur="checkIsField('login')" -->
                      <v-icon slot="prepend-inner" color="var(--main-flamingo)">
                        person
                      </v-icon>
                    </v-text-field>
                        <span v-if="errors.length" class="input-error mt-1">
                            {{ $t(errors[0]) }}
                        </span>
                  </ValidationProvider>
                    <ValidationProvider
                      name="password"
                      rules="required|min8"
                      v-slot="{ errors }"
                    > 
                    <v-text-field :type="showPassword ? 'text' : 'password'"
                    outlined
                  hide-details
                  dense
                  class="main-input input-sign"
                  :class="{'_error' : errorPasswordFlag}"
                  :label="$i18n.t('password')"
                  v-model="password"
                  :error="errorPasswordFlag"
                  :append-icon="showPassword ? 'visibility_off' : 'visibility'" 
                  @click:append="showPassword = !showPassword"
                  @input="resetErrors"
                  
                  >
                  <!-- @blur="checkIsField('password')" -->
          
                    <v-icon slot="prepend-inner" color="var(--main-flamingo)">
                      lock
                    </v-icon>
                  </v-text-field>
                        <span v-if="errors.length" class="input-error mt-1">
                            <!-- {{ $t('regPassAlert') }} -->
                            {{ $t(errors[0]) }}
                        </span>
                  </ValidationProvider>
                  <div class="text-left">
                    <!-- <router-link to="/reset" class="hover-link hover-link_green">
                        {{$i18n.t('forgetPass')}}
                      </router-link> -->
                    <div style="cursor: pointer;" class="hover-link hover-link_green">
                        {{$i18n.t('forgetPass')}}
                      </div>
                  </div>
                </div>
                <!-- :class="{'_success' : isEmailUniqueOne}" -->
                <v-btn :loading="loading" class="mt-2 all-buttons-fw all-buttons-fw_green"
                @click="handleSubmit(validate)">
                <span>{{ $i18n.t('enter') }}</span>
            </v-btn>
            <div class="d-flex align-center">
              <span class="hint hint-all" :class="{'_visible-all' : errorFlag}"> {{ $t(errorText) }}</span>
              <span class="hint hint-all" :class="{'_visible-all _date' : errorFlag}"> {{ banDate }}</span>
            </div>
            <login-banner-bonuses
            class="mt-2"
            v-if="$isMobile()"
            />
        </ValidationObserver>
        <ul class="bg-bubbles-reg pa-0" v-if="$isMobile()">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        </v-card-text>
    </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
//import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
//import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { startTimer } from '../../../timerWorker';
import LoginBannerBonuses from './LoginBannerBonuses.vue';
import { vMaska } from "maska"
import fingerprint from "@/plugins/fingerprint";
import disableAutocomplete from '@/disableAutocomplete'
import RegHeader from '../Registration/RegHeader'
import { mapGetters } from "vuex";
export default {
    directives: { maska: vMaska },
    components: {
        RegHeader,
        LoginBannerBonuses
    },
    name: 'LoginForm',
    data() {
        return {
            loading: false,
            login:'',
            password:'',
            errorFlag: false,
            errorText: '',
            errorLoginFlag: false,
            errorPasswordFlag: false,
            errorMsgs:[],
            banDialog: false,
            banDate: '',
            showPassword: false,
        }
    },
    mounted() {
        disableAutocomplete.disable();
        this.$refs.observer.reset();
    },
    watch: {
        test(newV) {
            console.log('newV', newV);
        },
        '$route'(to, from) {
            this.$refs.observer.reset();
        }
},
beforeRouteEnter (to, from, next) {
    next((vm) => {
        disableAutocomplete.disable();
    })
},
computed:{
        ...mapGetters({
            currencies: 'getCurrent',
            user: 'getUserInfo',
        }),
    },
    methods: {
        handleSubmit(validate) {
            validate().then((success) => {
                if (success) {
                    this.signIn();
                }
            })
        },
        async signIn(){
        //signIn
        this.loading = true;
        this.checkIsField('password');
        if ((this.errorPasswordFlag || this.errorLoginFlag)) {
          return this.loading = false;
        }
        try {
          await this.$store.dispatch('singIn',{
            username: this.login,
            password: this.password,
            fingerprint: await fingerprint.get(),
          });
          this.login = '';
          this.password = '';
          const savedMaxSessionTime = this.user.time_limit;
          if (savedMaxSessionTime) {
            startTimer(Number(savedMaxSessionTime));  // Converting to number
          }
          await this.$store.dispatch('notifications/awaitGetNotifications');
          this.$store.dispatch('initializeWebSocket');
          if (this.user.balance === 0) {
            await this.$router.push('/deposit');
          } else {
            await this.$router.push('/');
          }
        } catch (error) {
          if (error && error.response && error.response.data) {
            const { detail } = error.response.data;
            this.errorFlag = true;
            this.banDate = '';
            if (detail.reason.includes('Incorrect')) {
              this.errorText = 'incorrectLoginOrPass';
            } else if (detail.loc === 'is_multiaccount') {
              this.errorText = 'isBannedForMultiaccount';
            } else if (detail.loc === 'block_permanent') {
              this.errorText = 'isBannedForever';
            } else if (detail.loc === 'block_temporary') {
              this.errorText = 'isBannedForPeriod';
              const dateString = detail.reason.split(' - ')[1]; 
              const date = new Date(dateString);
              const formattedDate = date.toLocaleDateString() + ', ' + date.toLocaleTimeString();
              this.banDate = formattedDate;
            } else if (detail.loc === 'agent_id') {
              this.errorText = 'typeUserCantLogin';
            }else{
              this.errorText = 'justError';
            }
          }
        } finally{
          this.loading = false;
        }
      },
      resetErrors(){
        this.errorLoginFlag = false;
        this.errorPasswordFlag = false;
        this.errorFlag = false;
      },
      checkIsField(input) {
      if (input === 'login') {
        if (this.login.trim().length === 0) {
          this.errorLoginFlag = true;
        } else {
          this.errorLoginFlag = false;
        }
      }
      if (input === 'password') {
        if (this.password.trim().length === 0) {
          this.errorPasswordFlag = true;
        } else {
          this.errorPasswordFlag = false;
        }
      }
    },
    },
}
</script>

<style lang="scss" scoped>
.bubbles-content{
  position: relative;
  z-index: 1;
}
.bg-bubbles-reg {
pointer-events: none;
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
overflow: hidden;
z-index: 0;

li {
  position: absolute;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  // background-color: rgba(255, 255, 255, 0.25);
  background: url("../../../assets/images/bubles/reg/white-heart-bubble.svg") 0 /
    100% no-repeat;
  bottom: -160px;
  opacity: 0.3;
  -webkit-animation: square 25s infinite;
  animation: square 25s infinite;

  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  &:nth-child(1) {
    left: 10%;
    background: url("../../../assets/images/bubles/reg/cards-spades-bubble.svg") 0 /
      100% no-repeat;
  }

  &:nth-child(2) {
    left: 20%;
    background: url("../../../assets/images/bubles/reg/cards-diamonds-bubble.svg") 0 /
      100% no-repeat;
    width: 80px;
    height: 80px;

    animation-delay: 2s;
    animation-duration: 17s;
  }

  &:nth-child(3) {
    left: 25%;
    animation-delay: 4s;
    background: url("../../../assets/images/bubles/reg/cards-clubs-bubble.svg") 0 /
      100% no-repeat;
  }

  &:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    background: url("../../../assets/images/bubles/reg/cards-spades-bubble.svg") 0 /
      100% no-repeat;
    animation-duration: 22s;

    // background-color: rgba(255, 255, 255, 0.25);
  }

  &:nth-child(5) {
    left: 70%;
    background: url("../../../assets/images/bubles/reg/cards-clubs-bubble.svg") 0 /
      100% no-repeat;
  }

  &:nth-child(6) {
    left: 80%;
    width: 120px;
    height: 120px;

    animation-delay: 3s;
    // background-color: rgba(255, 255, 255, 0.25);
  }

  &:nth-child(7) {
    left: 32%;
    width: 160px;
    height: 160px;

    animation-delay: 7s;
  }

  &:nth-child(8) {
    left: 55%;
    width: 20px;
    height: 20px;

    animation-delay: 15s;
    animation-duration: 40s;
  }

  &:nth-child(9) {
    left: 25%;
    width: 10px;
    height: 10px;

    animation-delay: 2s;
    animation-duration: 40s;
    // background-color: rgba(255, 255, 255, 0.25);
  }

  &:nth-child(10) {
    left: 90%;
    width: 160px;
    height: 160px;

    animation-delay: 11s;
  }
}
}

.is-reg {
    //background-color: transparent;
    padding: 20px!important;
    background-color: var(--white);
   span{
    display: inline-block;
    width: 100%;
   }
    &__form {
        
    }
    &__button{
        transition: all 0.3s;
        display: flex;
        justify-content:center;
        align-items: center;
        p{
            transition: all 0.3s;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            margin: 0;
        }
        span{
            transition: all 0.3s;
            position: static;
        }
        &._success{
            background-color: var(--green-success)!important;
            p{
                opacity: 1;
                overflow: visible;
                position: static;
            }
           span{
            opacity: 0;
            overflow: hidden;
            position: absolute;
           }
        }
    }
}

.hints {
    position: relative;
    transition: opacity 0.3s;
}

.hint{
    position: absolute;
    right: 13px;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    color: var(--red);
    justify-content: flex-end;
    font-size: 14px;
    transition: all 0.3s;
    &-all{
      right: 0px;
      transform: translate(0,0px);
    }
    &._visible{
    bottom: 5px;
    opacity: 1;
    visibility: visible;
    }
    &._visible-all{
    bottom: -25px;
    right: 0;
    font-weight: bold;
    transform: translate(0,0px);
    opacity: 1;
    visibility: visible;
    &._date{
      bottom: -45px;
    }
    }
  }
</style>
<template>
  <div class="container-to-color margin-ctn" style="">
      <div class="container-banner" style="position: relative;z-index: 1;">
        <!-- :options="sliderOptions" -->
        <swiper  class="slider slider-fade"  
        v-if="banners.length"
        :class="{'pb-0': loadingFlag}"
        :breakpoints="{
          '320': {
            slidesPerView: 'auto',
            spaceBetween: 10
          },
          '425': {
            slidesPerView: 'auto',
            spaceBetween: 10
          },
          '600': {
            slidesPerView: 'auto',
            spaceBetween: 10
          },
          '768': {
            slidesPerView: 'auto',
            spaceBetween: 10
          },
          '1024': {
            slidesPerView: 'auto',
            spaceBetween: 10
          }
        }"
        >
          <!--  
          :class="{ 'carousel-cell_first': index === 0, 'carousel-cell_second': index === 1, 'carousel-cell_third': index === 2 }" -->
          <swiper-slide v-for="(banner, index) in banners"  :key="index" class="fade"
          style="height: 100%;width: 100%;">
          <div class="bannerImg" @click="toRedirect(banner.link)">
            <img v-if="banner.image" :src="`https://minio.takemybet.pro/banners/${banner.image}`">
          </div>
        </swiper-slide>
        <!-- <template v-if="banners.length && loadingFlag">
          </template>
          <template v-else>
            <swiper-slide class="fade"
             style="height: 100%;width: 100%;">
             <v-skeleton-loader width="100%" height="100%" type="image"
             class="skeleton-full skeleton-square"
             v-if="loadingFlag"
             ></v-skeleton-loader>
            </swiper-slide>
          </template> -->
          
          <!-- <div class="carousel-cell carousel-cell_second" style="height: 100%;width: 100%">
            <div class="bannerImg">
              <img src="@/assets/images/home_banners/banner_2.jpg"  >
            </div>
          </div>
          <div class="carousel-cell carousel-cell_third" style="height: 100%;width: 100%">
            <div class="bannerImg">
              <img src="@/assets/images/home_banners/banner_3.jpg"  >
            </div>
          </div> -->
          <!-- <div class="carousel-cell carousel-cell_four" style="height: 100%;width: 100%">
            <div class="bannerImg">
              <DepositBanner/>
            </div>
          </div> -->
        </swiper>
        <swiper  class="slider slider-fade"  
        v-else
        :breakpoints="{
          '320': {
            slidesPerView: 'auto',
            spaceBetween: 10
          },
          '425': {
            slidesPerView: 'auto',
            spaceBetween: 10
          },
          '600': {
            slidesPerView: 'auto',
            spaceBetween: 10
          },
          '768': {
            slidesPerView: 'auto',
            spaceBetween: 10
          },
          '1024': {
            slidesPerView: 'auto',
            spaceBetween: 10
          }
        }"
        >
          <!--  
          :class="{ 'carousel-cell_first': index === 0, 'carousel-cell_second': index === 1, 'carousel-cell_third': index === 2 }" -->
          <swiper-slide class="fade"
          style="height: 100%;width: 100%;">
          <v-skeleton-loader  class="skeleton-full" width="100%" height="100%" type="image">
          </v-skeleton-loader>
        </swiper-slide>
        <!-- <template v-if="banners.length && loadingFlag">
          </template>
          <template v-else>
            <swiper-slide class="fade"
             style="height: 100%;width: 100%;">
             <v-skeleton-loader width="100%" height="100%" type="image"
             class="skeleton-full skeleton-square"
             v-if="loadingFlag"
             ></v-skeleton-loader>
            </swiper-slide>
          </template> -->
          
          <!-- <div class="carousel-cell carousel-cell_second" style="height: 100%;width: 100%">
            <div class="bannerImg">
              <img src="@/assets/images/home_banners/banner_2.jpg"  >
            </div>
          </div>
          <div class="carousel-cell carousel-cell_third" style="height: 100%;width: 100%">
            <div class="bannerImg">
              <img src="@/assets/images/home_banners/banner_3.jpg"  >
            </div>
          </div> -->
          <!-- <div class="carousel-cell carousel-cell_four" style="height: 100%;width: 100%">
            <div class="bannerImg">
              <DepositBanner/>
            </div>
          </div> -->
        </swiper>
      </div>
  </div>
</template>

<script>
import Flickity from 'vue-flickity';
import { mapGetters } from 'vuex';
import DepositBanner from '@/components/Banners/DepositBanner'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Autoplay } from 'swiper';
import "swiper/swiper-bundle.css";
export default {
  name: "BannersSlider",
  components: {
    //Flickity,
    Swiper,
    SwiperSlide,
    //DepositBanner,
  },
  data() {
    return {
      swiperOption: {
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        // Другие опции Swiper
      },
      sliderOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false, // true
        wrapAround: true,
        autoPlay: 3000,
      },
      loadingFlag: false,
      lastType: '',
    }
  },
  async mounted(){
    if (this.banners.length === 0) {
      this.getBanners();
    }
    
  },
  computed:{
    ...mapGetters({
      banners: 'banners/getBanners',
      screenParams: 'screenParams',
    }),
    deviceType() {
      
    const width = this.screenParams.width || window.innerWidth;
    if (width >= 1024) {
      return 'PC';
    } else if (width >= 481 && width < 1024) {
      return 'Tablet';
    } else {
      return 'Mobile';
    }
  },
  },
  watch: {
  deviceType: 'getBanners', 
  '$route':{
    //immediate: true,
    handler(newValue, oldValue) {
      if (this.lastType !== this.deviceType) {
        this.getBanners();
      }
    }
  },
},
  methods: {
    toRedirect(link){
      if (this.$route.path !== link) {
        this.$router.push({ path: link });
      }
    },
    async getBanners(){
      if (this.$route.path === '/') {
        this.lastType = this.deviceType;
        this.loadingFlag = true;
        await this.$store.dispatch('banners/getBannersByType', this.deviceType)
        this.loadingFlag = false;
      }
    },
  }
}
</script>

<style lang="scss">
.swiper-container{
  overflow: hidden!important;
}
.margin-ctn{
  position: relative;
  z-index: 2;
  margin-top: 6px;
  @media (max-width:992px){
    margin-top: 5px;
      
  }
}
.fade{
  cursor: pointer;
  position: relative;
  min-width: 380px;
  &::after{
    content: '';
    position: absolute;
    top: calc(100% - 75px);
    left: 0;
    width: 100%;
    height: 150px;
    background-image: linear-gradient(transparent, var(--dark-flamingo) 50%);
  }
}
.carousel-cell{
 
    
}
//.carousel-cell{
//  &::before{
//    content: '';
//    position: absolute;
//   
//    left: 0;
//    width: 100%;
//    height: 100%;
//    
//    
//    background-size: cover;
//    background-position: center;
//    background-repeat: no-repeat;
//    transform: scaleY(-1);
//    opacity: 0.2;
//  }
//    &::after{
//    content: '';
//    position: absolute;
//    
//    left: 0;
//    width: 100%;
//    height: 100%;
//    background-image: linear-gradient(transparent, var(--main-flamingo) 50%);
//  }
//  &::before, &::after{
//    top: 249px;
//    z-index: 1;
//  }
//  &_first{
//    &::before{
//      background-image: url(../../assets/images/home_banners/banner_1.jpg);
//    }
//      
//   
//  }
//  &_second{
//    &::before{
//      background-image: url(../../assets/images/home_banners/banner_2.jpg);
//    }
//  }
//  &_third{
//    &::before{
//      background-image: url(../../assets/images/home_banners/banner_3.jpg);
//    }
//  }
//}
.container-banner{
  @media (min-width:1920px){
    border-radius: 16px;
    margin: 0 auto;
    max-width: 1330px;
  }
  @media (max-width:500px){
      
    //padding: 0 15px;
  }
}
.bannerImg{
  img{
    position: absolute;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    @media (max-width: 375px) {
      object-fit: fill;
    }
  }
  
  
}

.flickity-slider {
  transform: none !important;
}

.carousel-cell {
  transition: opacity 0.5s ease-in-out;
  transform: translateX(0px) !important;
  left: 0 !important;
  z-index: -1;
  opacity: 0;
}

.is-selected {
  opacity: 1;
  z-index: 0;

}

.slider {
  height: 450px;
  z-index: 5;
  padding-bottom: 5px;

  @media (max-width:1140px) {
    height: 450px !important;
    margin: 0 -10px!important;
  }
  
  @media (max-width: 775px) {
    height: 440px !important;
    
  }
  @media (max-width: 700px) {
    height: 350px !important;
  }
  @media (max-width: 600px) {
    height: 310px !important;
  }
  @media (max-width: 475px) {
    height: 250px !important;
  }
  

  
  .carousel-cell{

  }
  .flickity-viewport {
    overflow: visible;
    height: 450px !important;
    @media (min-width: 375px) {
      border-radius: 10px;
    }
    @media (max-width:1140px) {
      height: 450px !important;
      margin: 0 -10px!important;
    }
    
    @media (max-width: 775px) {
      height: 440px !important;
    }
    @media (max-width: 700px) {
      height: 350px !important;
    }
    @media (max-width: 600px) {
      height: 310px !important;
    }
    @media (max-width: 475px) {
      height: 250px !important;

    }
    


  }


  .flickity-page-dots {
    bottom: -10px;
    left: -15px;
  }

  .flickity-page-dots .dot {
    width: 15px;
    height: 15px;
    opacity: 1;
    background: none;
    border: 1px solid var(--red);
  }

  .flickity-page-dots .dot.is-selected {
    background: var(--red);
  }

  

  .flickity-prev-next-button {
    background: transparent;
    border: 1px solid var(--white);
    border-radius: 50%;
    
  }

  .flickity-button-icon {
    fill: #FFF;
    //width: 50px;
  }

  .flickity-button {

  }
  .arrow{
    
  }
  
}

@media (any-hover: none) {
  .flickity-prev-next-button {
    display: none;
  }
}




@media (max-width: 1140px) {

 

  .bannersRow {
    height: 20px;
  }
}

//@media (max-width: 500px),
//(max-height: 500px) {
//  .bannerImg {
//    //width: 350px;
//    //height: 113px;
//    width: 100vw;
//    height: 100%;
//
//  }
//}

//@media (max-width: 440px){
//  .flickity-page-dots{
//    bottom: calc( -100vw / 3 + 137px) !important;
//  }
//}</style>

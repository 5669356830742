// currencies.js
// import axios from "axios";
const state = {
  currencyUpdate: false,
  // currencies: [],
};

const mutations = {
  setCurrencyUpdate(state, value) {
    state.currencyUpdate = value;
  },
};

const getters = {
  // getCurrent(state) {
  //   return state.currencies;
  // },
};

const actions = {
  // async GetCurrencies({ commit }) {
  //   await axios
  //     .get(`/api/informational/all_currencies`)
  //     .then((response) => {
  //       console.log("awaitGetCurrencies response = ", response);
  //       if (response.status === 200) {
  //         const currencies = response.data.currencies.map((currency) => ({
  //           text: currency,
  //           selected: false,
  //           selectToDeposit: false,
  //         }));
  //         commit("set", { type: "currencies", items: currencies });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("error = ", error);
  //     });
  // },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

<template>
    <div :class="{ '_grid' : providerList.items.length !== 0 || loadingFlag }" >
    <template v-if="providerList.items.length === 0 && !loadingFlag">
      <no-work-page
      :textError="textError"
      :loadingFlag="loadingFlag"
      @refresh-request="getProviders"
      class="pa-3"
      >
      <template v-slot:icon>
        <img :src="`https://minio.takemybet.pro/ui-style/${logo}`" width="90" height="90" alt="logo">
        </template>
      </no-work-page>
      </template>
    <template v-else-if="providerList.items.length > 0 && !loadingFlag">
        <div
              v-for="(provider, index) in providerList.items"
              :key="index"
              class="provider__item"
              @click="selectProvider(provider)"
            >
              <div class="provider__body" >
                <v-lazy v-model="isActive" class="provider__image">
                  <v-lazy-image
                  :src-placeholder="require('@/assets/images/loading.gif')"
                  @load="isActive = true" v-if="provider.logo" :src="provider.logo" />
              </v-lazy>
                <div class="provider__content">
                  <span class="provider__name"> {{ provider.text }} </span>
                </div>
              </div>
            </div>
    </template>
    <template v-else-if="loadingFlag">
      <div
            v-for="index in 10"
            :key="index"
            class="provider__item"
            @click="selectProvider(provider)"
          >
            <div class="provider__body">
              <v-skeleton-loader 
              
              height="100"
              type="card"  />
            </div>
          </div>
    </template>
    </div>
</template>

<script>
import NoWorkPage from '@/components/NoWorkPage'
import { mapGetters } from 'vuex';
    export default {
      components:{
        NoWorkPage
      },
        name: 'GridProviders',
        computed: {
    ...mapGetters({
      providerList: "getGameProviders",
      logo: 'styles/getLogo',
    }),
  },
  async mounted() {
    if (!this.providerList.items.length) {
      
      await this.getProviders();
    }
            // this.errorFlag = false;
            // try {
              
            // } catch (error) {
            //   this.errorFlag = true;
            // } finally{
            //   this.loadingFlag = false;
            // }
  },
  data() {
    return {
      isActive: false,
      loadingFlag: false,
      textError: 'no_providers_available'
    }
  },
  methods:{
    async getProviders(){
      this.loadingFlag = true;
      await this.$store.dispatch('GetProviders');
      this.loadingFlag = false;
    },
    selectProvider(prov){
      const provider = prov.string_id;
      this.$router.push(
            {
              name: 'GridBlock',
              params: {
                type: `game-provider`,
                info: `${provider}`
              },
            }
            )
    },
  },
    }
</script>

<style lang="scss" scoped>
.provider {
    &__item {
      display: flex;
      flex-direction: column;
      min-height: 100px;
      height: auto;
      width: 100%;
      @media (any-hover: hover){
        &:hover{
          .provider__image{
            transform: scale(1.08);
          }
        }
      }
      @media (max-width:500px){
        flex: 1 1 100%;
      }
    }
  
    &__body {
      height: 100%;
      //border: 1px solid var(--white);
      box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.3) !important;
      border-radius: 16px !important;
      background-color: var(--dark-d-flamingo);
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      transition: all 0.3s ease-out;
      overflow: hidden;
      color: var(--white);
      &::before {
        content: "";
        background-color: rgba(white, 0.4);
        width: 60%;
        height: 100%;
        top: 0px;
        left: -125%;
        transform: skew(45deg);
        z-index: 0;
        position: absolute;
        opacity: 0;
        transition: left 0.5s ease-out, opacity 0.1s;
      }
      &:hover {
        cursor: pointer;
        &::before {
          left: 150%;
          opacity: 1;
        }
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      justify-content:center;align-items: center;
      padding: 5px 0;
      background-color: var(--dark-flamingo);
    }
    &__name {
      text-transform: capitalize;
    }
    &__count {
    }
    &__image {
        flex: 1 1 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 0;
      transition: transform 0.3s;
      img {
        vertical-align: middle;
        max-height: 50px;
        max-width: 140px;
        object-fit: cover;
        -webkit-transform: translateZ(0);
        object-position: center center;
      }
    }
  }
</style>
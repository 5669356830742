<template>
  <div>
    <div class="container-mod"> 
    <v-card class="basic__card" style="">
      <v-card-title style="color: var(--white)">
        <v-col class="py-0">
          {{$i18n.t('passRestore')}}
        </v-col>
        
      </v-card-title>
      <v-card-text class="text-left pl-7 py-0">
        <span   style="font-size: 14px; 
        margin: 0 0 20px; color: var(--white)">
      {{ $i18n.t('passForget') }}
      </span>
        <span style="font-size: 14px;
        margin: 0 0 20px;"></span>
      </v-card-text>
      <v-card-text v-if="!codeSended && !resetSuccess">
        <v-col>
          <v-text-field
              outlined
              dense
              color="#201937"
              class="labels_color"
              label="E-mail"
              v-model="account"
              :error-messages="emailErrors"
              @input="emailErrors = []"
          >
          </v-text-field>
          <v-btn style="width: 100%;font-size: 16px;font-weight: 700;" class="buttons-reg" :disabled="account.length < 5" @click="sendCode">
            {{$i18n.t('restore')}}
          </v-btn>
        </v-col>
      </v-card-text>
      <v-card-text v-if="codeSended && !resetSuccess">
        <v-col>
          <v-text-field
              outlined
              color="#201937"
              class="labels_color"
              :label="$i18n.t('emailCode')"
              v-model="emailCode"
              dense
          >
          </v-text-field>
          <v-text-field
              outlined
              color="#201937"
              class="labels_color"
              :label="$i18n.t('newPass')"
              v-model="newPass"
              type="password"
              dense
          >
          </v-text-field>
          <v-btn style="" class="all-buttons-t" :disabled="newPass.length < 1" @click="resetPass">
            {{$i18n.t('change')}}
          </v-btn>
        </v-col>
      </v-card-text>
      <v-card-text v-if="resetSuccess" style="color: var(--white)">
        {{$i18n.t('successPassReset')}}
        <v-icon color="green">check</v-icon>
        <v-row>
          <v-col>
            <v-btn style=""  class="all-buttons-t mt-3" to="/enter" >
              {{$i18n.t('enter')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-overlay absolute v-if="loading">
        <Preloader></Preloader>
      </v-overlay>
    </v-card>
  </div>
  </div>
</template>

<script>
  import Preloader from "../components/Preloader";
  export default {
    name: "ResetPass",
    components: {Preloader},
    data() {
      return {
        account: '',
        loading: false,
        codeSended: false,
        emailErrors: [],
        newPass: '',
        emailCode: '',
        resetSuccess: false,
      }
    },
    methods: {
      async sendCode() {
        this.loading = true;
        let [res, error_type] = await this.$store.dispatch('sendResetCode', {email: this.account});
        if (res && !error_type) {
          this.codeSended = true;
        } else if (error_type === 'email') {
          this.emailErrors = [this.$i18n.t('emailNotFound')];
        } else {
          this.emailErrors = [this.$i18n.t('unknownError')];
        }
        this.loading = false;
      },
      async resetPass() {
        this.loading = true;
        this.resetSuccess = await this.$store.dispatch('resetPass', {
          code: this.emailCode, newPass: this.newPass
        });
        this.loading = false;
      }
    }
  }
</script>

<style lang="scss" scoped>
.buttons-reg {
  width: 100%;
  color: var(--white)!important;
  font-size: 16px!important;
    font-weight: 700;
  background-color: var(--red)!important;
  min-height: 40px!important;
}
.container-mod{
  
  @media (max-width:500px){
      padding: 0;
  }
}
.v-text-field__details{
  padding-left: 0!important;
}

.router_view{
  flex:  1 1 auto;
}
.basic__card{
  margin-top: 120px;
  margin-bottom: 20px;
  max-width: 900px!important;
  border-radius: 16px !important;
    background-color: var(--main-flamingo) !important;
    border-color: var(--red) !important;
    @media (max-width:500px){
      border-radius: 0px !important;
      margin-top: 55px;
      margin-bottom: 0px;
  }
}
</style>
const disableAutocomplete = {
  disable() {
    const elements = document.querySelectorAll('[autocomplete="off"]');

    if (!elements.length) {
      return;
    }

    elements.forEach((element) => {
      element.setAttribute("readonly", "readonly");
      element.style.backgroundColor = "inherit";
      setTimeout(() => {
        element.removeAttribute("readonly");
      }, 500);
    });
  },
};

export default disableAutocomplete;

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-col',{staticClass:"d-flex align-center justify-space-between"},[_c('v-card-title',{staticClass:"pa-0 text-uppercase title-card",staticStyle:{"color":"var(--white)"}},[_vm._v(" "+_vm._s(_vm.$i18n.t('bonusesTitle'))+" ")]),_c('v-btn',{staticClass:"all-buttons-t",on:{"click":_vm.openPromocodeDialog}},[_vm._v(_vm._s(_vm.$t('promoTitle')))]),_c('PromoCode')],1),_c('v-row',{staticClass:"mt-0",staticStyle:{"overflow":"hidden"}},[_c('v-col',{staticClass:"py-10"},[_c('swiper',{staticStyle:{"height":"450px"},attrs:{"slides-per-view":1,"breakpoints":{
                '320': {
                  slidesPerView: 'auto',
                  spaceBetween: 10
                },
                '425': {
                  slidesPerView: 'auto',
                  spaceBetween: 10
                },
                '600': {
                  slidesPerView: 'auto',
                  spaceBetween: 10
                },
                '768': {
                  slidesPerView: 'auto',
                  spaceBetween: 10
                },
                '1024': {
                  slidesPerView: 'auto',
                  spaceBetween: 10
                }
              }}},[_vm._l((_vm.depositBonuses),function(cardDeposit,index){return _c('swiper-slide',{key:'welcomeCard_' + index},[(_vm.user.id !== null)?_c('deposit-bonus',{attrs:{"index":index,"bonusId":cardDeposit.id,"cardDeposit":cardDeposit}}):_vm._e()],1)}),(_vm.user.id === null)?_c('swiper-slide',[_c('bonus-no-login')],1):_vm._e(),_vm._l((_vm.spinsBonuses),function(cardSpins,index){return _c('swiper-slide',{key:'spinsCard_' + index},[_c('spins-bonus',{attrs:{"index":index,"bonusId":cardSpins.id,"cardSpins":cardSpins}})],1)}),_c('swiper-slide',[_c('BonusTournamentOne')],1)],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-dialog class="dialog-timer" persistent v-model="showTimeoutModal" max-width="625">
      <v-card flat class="timer">

        <v-card-title class="timer__header">
          {{ $i18n.t('realityCheckTitle') }}
        </v-card-title>
        <div class="timer__flex">
          <div class="timer__image">
            <img src="@/assets/images/realityTimerOut.jpg" alt="">
          </div>
          <div class="timer__content">
            <v-card-text class="timer__text-top">
              <p>{{ $t('realityCheckExceed') }} {{ Math.ceil(user.time_limit / 60) }} {{ $t('mins') }}.</p>
              <span>{{ $t('realityCheckChoice') }}</span>
            </v-card-text>
            <v-card-actions class="timer__buttons">
              <v-btn @click="continueSession" :loading="loadingFlag" class="all-buttons-t timer__continue">{{ $t('realityCheckKeepPlay') }}</v-btn>
              <v-btn @click="logout" :disabled="loadingFlag" class="all-buttons-t timer__logout">{{ $t('exit') }}</v-btn>
            </v-card-actions>
            <v-card-text class="timer__text-bottom d-flex justify-center">
              <span>{{ $t('realityCheckChangeText') }}</span> <a @click="redirectToSettings"
                class="timer__link">{{ $t('realityCheckChangeLink') }}</a>
            </v-card-text>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { startTimer } from '../../../timerWorker';
import { mapGetters } from 'vuex';
export default {
  name: 'RealityTimer',
  data() {
    return {
      //dialogTimer: false,
      loadingFlag: false,
    }
  },

  methods: {

    async redirectToSettings() {
      try {
        await this.$router.push('/profile/responsible-gambling/reality-check');
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      }
      localStorage.removeItem("endTime");
      localStorage.removeItem("remainingTimeInSeconds");
      this.$store.commit("setTimeoutModal", false);
      console.log('userTime', this.user.time_limit);
      if (this.user.time_limit % 60 !== 0) {
        this.user.time_limit = Math.ceil(this.user.time_limit / 60) * 60;
      }
      this.$store.commit('limitsUpdate/setDepositLimitError', false);
      this.$store.dispatch('updateUserInfo', { time_limit: this.user.time_limit })
      await this.$store.dispatch('awaitGetUserInfo');
      startTimer(this.user.time_limit);
    },
    async continueSession() {
      // Reset timer
      this.loadingFlag = true;
      localStorage.removeItem("endTime");
      localStorage.removeItem("remainingTimeInSeconds");
      if (this.user.time_limit % 60 !== 0) {
        this.user.time_limit = Math.ceil(this.user.time_limit / 60) * 60;
      }
      console.log('userTime', this.user.time_limit);
      this.$store.dispatch('updateUserInfo', { time_limit: this.user.time_limit })
      await this.$store.dispatch('awaitGetUserInfo');
      startTimer(this.user.time_limit);
      this.$store.commit("setTimeoutModal", false);
      this.loadingFlag = false;
      
    },
    async logout() {
      this.$store.commit("setTimeoutModal", false);
      await this.$store.dispatch('updateUserInfo', { time_limit: 0 })
      this.$store.commit('depositView/setMethodMoney', false)
      this.$store.commit('depositView/setMethodCrypto',false)
      this.$store.commit('depositView/setMethodExit',false)
      this.$store.commit('depositView/setDepositExitWindow',false)
      this.$store.commit('depositView/setDepositMethodsWindow',false)
      this.$store.commit('depositView/setDepositWindowError',false)
      this.$store.commit('depositView/setSelectedMethod', null)
      this.$store.commit('setDepositWindowError', false);
      if (this.$route.path !== '/') {
        this.$router.push('/')
      }
      try {
        await this.$store.dispatch("logOut");
        //location.reload();
      } catch (error) {
        if (error.name == "NavigationDuplicated") {
          throw error;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUserInfo',
    }),
    showTimeoutModal: {
      get() {
        return this.$store.state.showTimeoutModal;
      },
      set(value) {
        this.$store.commit('setTimeoutModal', value);
      },
    },
    time(){
      // return this.$store.state.maxSessionTime;
      return localStorage.getItem("maxSessionTime") 
    },
  },
}
</script>

<style lang="scss" scoped>
.timer {
  max-width: 400px;
  border-radius: 16px!important;
  @media (max-height: 500px) and (orientation: landscape) {
    max-width: 625px;
  }

  color: var(--white) !important;

  &__flex {
    @media (max-height: 500px) and (orientation: landscape) {
      display: flex;
    }
  }

  &__image {
    max-width: 100%;
    height: 200px;

    @media (max-height: 500px) and (orientation: landscape) {
      width: 300px;
      display: flex;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__header {
    background-color: #4a1558;
    justify-content: center;
    align-items: center;
    font-size: 22px !important;
    //margin-bottom: 10px;
  }

  &__content {
    @media (max-height: 500px) and (orientation: landscape) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__text-top {
    padding: 5px 12px 10px!important;
    @media (max-height: 500px) and (orientation: landscape) {
      padding: 3px 12px!important;
    }

    color: var(--black) !important;

    span {
      font-weight: 700;
    }

    @media (max-height: 500px) and (orientation: landscape) {
      p {
        margin-bottom: 0;
      }
    }
  }

  &__buttons {
    padding: 5px 15px!important;
    display: grid!important;
    grid-template-columns: repeat(2, 1fr);
    @media (min-width:1140px){
      gap: 5px;
    }
    gap: 10px;
    
    justify-content:center;
    @media (max-height: 500px) and (orientation: landscape) {
      padding: 3px 12px!important;
    }

    button {
      min-width: 135px !important;
      justify-self: center;
    }
  }

  &__continue {
    max-width: 165px !important;
    @media (min-width:769px){
      max-width: 190px !important;
    }
    @media (min-width:1140px){
      max-width: 205px !important;
    }
  }

  &__logout {
    margin-left: 0px !important;
  }

  &__text-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--black) !important;
    padding: 5px 12px 10px!important;
    font-size: 12px !important;

    @media (max-height: 500px) and (orientation: landscape) {
      padding: 3px 12px!important;
    }
  }

  &__link {
    color: var(--main-flamingo);
  }
}</style>
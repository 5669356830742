<template>
  <v-dialog
    v-model="rulesModal"
    max-width="850"
    scrollable
    content-class="white-dialog"
  >
    <!-- Content -->
    <v-card class="basic-card">
      <v-card-title class="pl-0 font-weight-bold" style="font-size: 16px">{{
        $i18n.t("privacyPolicy")
      }}</v-card-title>
      <v-btn
        icon
        @click="rulesModalClose"
        height="36"
        width="36"
        class="button-delete"
      >
        <v-icon color="white">close</v-icon>
      </v-btn>
      <v-card-text
        class="text-left px-2"
        v-html="$i18n.t('privacyPolicyText')"
      ></v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RulesModal",
  data() {
    return {};
  },
  methods: {
    rulesModalClose() {
      this.$store.commit("regComponents/setRulesModal", false);
    },
  },
  computed: {
    rulesModal: {
      get() {
        return this.$store.state.regComponents.isRulesModal;
      },
      set(value) {
        this.$store.commit("regComponents/setRulesModal", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.basic-card {
  padding: 0px 15px 15px 15px;
  border-radius: 16px !important;
  background-color: var(--white) !important;
  color: var(--third-flamingo) !important;
}
.button-delete {
  background-color: var(--main-flamingo);
  position: absolute;
  border-radius: 5px;
  right: 10px;
  top: 10px;
}
</style>

<template>
  
    <div class="block">
      <div class="block__header header" style="">
        <div class="header__title">
          😎 {{ $i18n.t('newPlayer') }}
        </div>
      </div>
        <div class='block__view previews' 
        ref="scrollContainer"
        @touchstart="startDrag"
        @touchend="stopDrag"
        @touchmove="drag"
        @mousedown="startDrag"
        @mouseleave="stopDrag"
        @mouseup="stopDrag"
        @mousemove="drag"
        >
          <v-lazy v-model="isActive" class="previews__item" v-for="(player, index) in newPlayersWithImages.slice()" :key="'new_' + index">
            <div class="previews__body">
              <div style="padding: 0" class="previews__image">
                <v-lazy-image @load="isActive = true" :src="player.image" height="65" width="65" style=" " />
              </div>
              <div class="previews__content">
                <div class="previews__name"><span>{{ player.username }}</span></div>
                <div class="previews__victory" >
                  {{ $i18n.t('victories') }}
                </div>
                <div class="previews__deposit pb-2">
                  <div class="previews__deposit-block deposit-block d-flex align-center">
                    <p class="deposit-block__quantity" v-if="player.total_deposit">{{ player.total_deposit }}</p>
                    <p class="deposit-block__quantity" v-else>0</p>
                    <b class="deposit-block__currency" style="font-weight:400" >{{ player.currency }}</b>
                    
                  </div>
                </div>
              </div>
        </div>
      </v-lazy>
        </div>
        
      </div>
</template>

<script>
import { SwiperCore, Swiper, SwiperSlide} from 'swiper-vue2'
import "swiper/swiper-bundle.css";
import {mapGetters} from "vuex";
export default {
  name: 'NewPlayersSection',
  
  components: {
   //Swiper, 
   //SwiperSlide,
  },
  computed: {
    newPlayersWithImages() {
      
      return this.$store.state.newPlayers.map((player, index) => {
        //console.log(this.$store.state.newPlayers)
        const avatarUrl = player.avatar
            ? `https://minio.takemybet.pro/avatars/${player.avatar.preview}`
            : this.newUsers[index % this.newUsers.length].image;

        return {
          ...player,
          image: avatarUrl
        };
      });
      
      
    }
   },

  data() {
    return {
      isActive: false,
      newUsers: [],
      isDragging: false,
      startX: 0,
      scrollLeft: 0,
    }
  },
  async mounted(){
    await this.$store.dispatch('awaitGetNewPlayer');
  },
  methods:{
    startDrag(e) {
      if (!this.$isMobile()) {
        
     
              this.isDragging = true;
              this.startX = e.type === 'touchstart' ? e.touches[0].pageX : e.pageX - this.$refs.scrollContainer.offsetLeft;
              this.scrollLeft = this.$refs.scrollContainer.scrollLeft;
            }
            },
            stopDrag() {
              if (!this.$isMobile()) {
              this.isDragging = false;
              }
            },
            drag(e) {
              if (!this.$isMobile()) {
              if (!this.isDragging) return;
              e.preventDefault();
              const x = e.type === 'touchmove' ? e.touches[0].pageX : e.pageX - this.$refs.scrollContainer.offsetLeft;
              const walk = (x - this.startX) * 1; // Настройте скорость скролла по вашему усмотрению
              this.$refs.scrollContainer.scrollLeft = this.scrollLeft - walk;
            }
            },
  },
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  &__title {
      font-weight: bold;
      text-align: left;
      flex: 1 1 100%;
      text-transform: uppercase;
      color: var(--white);
      font-size: 24px;
      margin-right: 5px;
      @media (max-width:992px){
          font-size: 18px;
      }
  }

  &__show-more {
  }
}

.block {
  @media (min-width:320px) {
    height: 310px;

  }


  @media (min-width:850.98px) {
    height: 310px;
  }

  @media (min-width:1330px) {
    height: 295px;

    @media (any-hover: hover) {
      height: 315px;
    }
  }
  @media (min-width:320px) {
    flex-direction: column;
  
  }

  &_top {
    height: auto;
  }
  &__view {}
}
.previews::-webkit-scrollbar {
  @media (any-hover: hover) {
    height: 10px;
    border-radius: 10px;
    background-color: var(--white);
  }

}

.previews::-webkit-scrollbar-thumb {
  @media (any-hover: hover) {
    border-radius: 10px;
    height: 10px;
    background-color: #4a1558;
  }

}

.previews {
  @media (max-width:1329px) {
    position: absolute;
    z-index: 4;
  }
  
  left: 0;
  width: 100%;
  display: flex;
  overflow: auto;
  @media (min-width:992px){
    overflow: hidden;
      
  }
  padding: 5px 15px 15px 0px;
  &__item {
    flex: 0 0 170px;
    margin: 0 5px;
    &_top {}

  }

  &__body {
    display: flex;
    flex-direction: column;
    min-height: 250px;
    height: 100%;
    //border: 1px solid var(--white);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    background-color: #4a1558;
  }

  &__image {
    overflow: hidden;
    border-radius: 16px 16px 0 0;
    
    height: 170px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  &__number {
    flex: 0 0 40px;
    color: var(--white);

    @media (max-width:375px) {
      flex: 0 0 30px;
    }

    font-weight: 600;
    text-align: center;
    display: flex;
    justify-content:center;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    color: var(--white) !important;
    text-align: right !important;
    padding: 0 12px;
    margin-top: 3px;
  }

  &__name {
    display: flex;
    justify-content: flex-end;
    span{
      max-width: 130px;
      text-overflow: ellipsis;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__victory {
    font-size: 12px !important;
  }

  &__deposit {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
}
.deposit-block {
  justify-content: flex-end;

  &__quantity {
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media (min-width:320px) {
      max-width: 85px;
    }
  }

  &__currency {
    margin-left: 3px;
  }
}
</style>
<template>
  <v-card
    class="pa-3 d-flex flex-column"
    elevation="3"
    :color="getColorVerification(user.doc1_verified)"
    style="border-radius: 16px; min-height: 100%"
  >
    <v-btn
    
      v-if="user.doc1_verified == 1"
      icon
      height="20"
      width="20"
      @click="isDeleteDocModal"
      class="button-delete"
    >
      <v-icon color="white" style="font-size: 20px">close</v-icon>
    </v-btn>
    <v-card-text class="px-2 py-0 d-flex flex-column" style="color: var(--white);flex: 1 1 auto">
      <div class="mb-2 d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <font-awesome-icon
            icon="id-card"
            style="margin: 0 4px 0 0; font-size: 20px"
          />
          <p class="mb-0 text-left">
            {{ $i18n.t("VerifDialogCardTitle") }}
          </p>
        </div>
        <v-card dark style="background-color: transparent; box-shadow: none">
          <v-col
            v-if="user.doc1_verified === 1"
            cols="12"
            class="pa-0 d-flex align-center text-left text-uppercase font-weight-bold"
          >
            <span style="font-size: 12px">{{ $i18n.t("VerifSended") }}</span>
            <v-icon class="ml-1">timer</v-icon>
          </v-col>
          <v-col
            v-if="user.doc1_verified === 2"
            cols="12"
            class="pa-0 d-flex align-center text-left text-uppercase font-weight-bold"
          >
            <span>{{ $i18n.t("VerifSuccess") }}</span>
            <v-icon class="ml-1" color="white">done</v-icon>
          </v-col>
          <v-col
            v-if="user.doc1_verified === 3"
            cols="12"
            class="pa-0 d-flex align-center text-left text-uppercase font-weight-bold"
          >
            <span>{{ $i18n.t("denied") }}</span>
            <v-icon class="ml-1">do_not_disturb_on</v-icon>
          </v-col>
        </v-card>
      </div>
      <v-col class="px-0 pt-0 text-left">
        {{ $i18n.t("VerifPhotoFaceAndCard") }}
      </v-col>
      <v-col class="pa-0 d-flex align-center" :style="{'max-height': user.doc1_verified === 3 ? 'auto' : '40px'}" v-if="user.doc1_verified !== 0">
        <v-col class="pa-0 text-left photo-text-size">
          {{ $t("VerifUploadedDocsFile") }}: <a class="hover-link" @click="openDocView">{{ this.docName }}</a>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="pa-0 text-right d-flex flex-column" style="white-space: nowrap">
          <span>{{ $t("VerifUploadedDocsDate") }}: </span>
          <b>{{ uploadDocDate }}</b>
        </v-col>
      </v-col>
    </v-card-text>
    <v-card-actions class="py-0 d-flex align-center" v-if="user.doc1_verified === 0 || user.doc1_verified === 3">

     
      <!-- triggerInput -->
      <v-btn
        class="all-buttons-fw all-buttons-fw_verif"
        @click="uploadDocDialog = true"
      >
        {{ $i18n.t("VerifChooseButton") }}
      </v-btn>
      
    </v-card-actions>
    <!-- Input for doc -->
    <input
        type="file"
        ref="imagePassport"
        accept="image/png, image/jpeg"
        style="display: none"
        id="files_input-face_card"
        @change="holdNewDoc($event)"
      />
      <!-- Upload Dialog -->
    <v-dialog
    :content-class="$isMobile() ? 'dialog' : 'passportDialog'"
    scrollable
    transition
    width="450"
    v-model="uploadDocDialog"
    :fullscreen="$isMobile() ? true : false" 
  >
    <v-card color="var(--white)">
      <div
      
        class="tmb-style_title-header tmb-style_bg-dark"
        
      >
        <v-toolbar-title class="tmb-style_title-header__content tmb-style_white"
          >{{ $i18n.t("VerifDialogCardTitle") }}
        </v-toolbar-title>
        <v-btn
          icon
          @click="closeUploadDocDialog"
          class="rounded-lg tmb-style_title-header__close tmb-style_bg-main"
          height="36"
          width="36"
          
        >
          <v-icon color="white">close</v-icon>
        </v-btn>
      </div>
      <v-card-text class="pb-0 pt-5" style="background-color: var(--white)">
        <v-row>
          <v-col cols="12">
            <v-list flat style="background-color: var(--white) !important">
              <v-list-item-group v-for="li in list" :key="li.id">
                <v-list-item class="px-0" style="min-height: auto">
                  <v-icon
                    color="#00bd71"
                    style="font-size: 14px"
                    class="mr-2"
                  >
                    done
                  </v-icon>
                  <v-list-item-content
                    class="py-0"
                    style="color: var(--gray-text)"
                  >
                    <v-list-item-title
                      style="color: inherit; font-size: 12px"
                      >{{ li.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="12" class="pt-0" :class="{'pr-0' : !$isMobile()}">
            <v-col style="font-size: 12px" class="load-photo">
              <span
                style="max-width: 160px"
                v-if="!selectedDoc.doc"
                @click="triggerInput"
              >
                {{ $i18n.t("VerifChooseFileText") }}</span
              >
              <div
                class="d-flex flex-column align-center justify-center"
                v-if="
                  selectedDoc &&
                  selectedDoc.doc
                "
              >
                <img
                  :src="selectedDoc.previewDoc"
                  style="object-fit: contain; width: min(100%, 310px)"
                  max-height="300"
                />
                <v-btn
                  color="var(--red)"
                  max-width="10"
                  max-height="30"
                  class="mt-1"
                  @click="deleteImage"
                >
                  <v-icon class="icon__delete-t" style="color: var(--white)"
                    >close</v-icon
                  >
                </v-btn>
              </div>
            </v-col>
            <v-row
              class="mt-1"
              v-if="noValidSize"
              style="color: var(--red)"
            >
              <v-col class="py-0">
                {{ $i18n.t("VerifLimitExceed") }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="isSubmitButtonFaceDisabled"
          :loading="loading"
          class="all-buttons-fw"
          @click="uploadDoc"
        >
          {{ $i18n.t("VerifChooseButton") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "VerifFaceAndCard",
  data() {
    return {
      selectedDoc: { doc: null, previewDoc: null },
      uploadDocDialog: false,
      isSubmitButtonFaceDisabled: true,
      toDelete: false,
      noValidSize: false,
      modalToDeleteDoc: false,
      loading: false,
    };
  },
  computed: {
    // Doc name
    docName() {
      if (this.user && this.user.doc1) {
        return this.user.doc1.split("user-docs/")[1];
      }
      return null;
    },
    // Doc Date
    uploadDocDate() {
      if (this.user && this.user.doc1_uploaded_at) {
        return this.user.doc1_uploaded_at.split("T")[0];
      }
      return null;
    },
    ...mapGetters({
      // User
      user: "getUserInfo",
    }),
  },
  methods: {
    openDocView() {
      const docData = {
        imagePreview: this.user.doc1,
        docNumber: 'doc1'
      }
      this.$emit("open-doc-view", docData);
    },
    // Get the color block for the state number
    getColorVerification(doc1_verified) {
      if (doc1_verified === 0) {
        return "var(--dark-flamingo)";
      }
      if (doc1_verified === 1) {
        return "var(--light-flamingo)";
      }
      if (doc1_verified === 2) {
        return "var(--green-success)";
      }
      if (doc1_verified === 3) {
        return "#ab1c47";
      }
    },
    // Close dialog
    closeUploadDocDialog(){
      this.uploadDocDialog = false;
      this.deleteImage();
    },
    // Open a modal window to delete the uploaded document
    isDeleteDocModal() {
      this.$store.commit("verification/isDeleteDocModal", true);
      const docInfo = {
        name: this.docName,
        number: "doc1",
      };
      this.$store.dispatch("verification/holdDocInfo", docInfo);
    },
    // Upload Doc to server
    async uploadDoc() {
      this.loading = true;
      const formData = new FormData();
      formData.append("doc1", this.selectedDoc.doc);
      const success = await this.$store.dispatch("uploadDocs", { formData });
      if (success) {
          await this.$store.dispatch("awaitGetUserInfo");
          this.uploadDocDialog = false;
          this.deleteImage();
        const notification = {
          type: "success",
          message: this.$t("VerifDocSuccessUpload"),
        };
        this.$store.dispatch("alerts/add", notification);
      } else {
        this.uploadDocDialog = false;
        const notification = {
          type: "error",
          message: this.$t("verifError"),
        };
        this.$store.dispatch("alerts/add", notification);
      }
      this.loading = false;
    },
    // Trigger doc input
    triggerInput() {
      document.getElementById("files_input-face_card").click();
    },
    // Catch new Doc
    holdNewDoc(event) {
      const fileFaC = event.target.files[0];

      if (fileFaC && fileFaC.size <= 15000000) {
        this.selectedDoc.doc = fileFaC;
        this.selectedDoc.previewDoc =
          URL.createObjectURL(fileFaC);
        this.noValidSize = false;
      } else {
        this.selectedDoc = {
          doc: null,
          previewDoc: null,
        };
        this.selectedDocName = null;
        this.noValidSize = true;
        event.target.value = null;
      }

      this.isSubmitButtonFaceDisabled = false;
    },
    // Locale Delete Preview Doc
    deleteImage() {
      URL.revokeObjectURL(this.selectedDoc.previewDoc);
      this.selectedDoc = {
        doc: null,
        previewDoc: null,
      };
      this.isSubmitButtonFaceDisabled = true;
      this.$refs.imagePassport.value = "";
    },
  },
  // List of items
  created() {
    this.list = [
      {
        text: this.$t("VerifPassportLiOne"),
      },
      {
        text: this.$t("VerifPassportLiTwo"),
      },
      {
        text: this.$t("VerifPassportLiThree"),
      },
      {
        text: this.$t("VerifPassportLiFour"),
      },
      {
        text: this.$t("VerifPassportLiFive"),
      },
    ];
  },
};
</script>

<style lang="scss" scoped>
.button-delete {
  background-color: var(--dark-flamingo);
  right: -5px;
  top: -5px;
  position: absolute;
}

.load-photo {
  align-items: center;
  background: #f2f6f9 !important;
  border: 2px dashed #dfe4e8 !important;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 5px !important;

  span {
    
    @media (any-hover: hover){
      cursor: pointer;
    }
  }
}
</style>

<template>
    <FlipCardTournamentOne>
        <template slot="front">
            <BonusTournamentOneFront></BonusTournamentOneFront>
        </template>
        <template slot="back">
            <BonusTournamentOneBack></BonusTournamentOneBack>
        </template>
      </FlipCardTournamentOne>
</template>

<script>
import BonusTournamentOneBack from './BonusTournamentOneBack.vue';
import BonusTournamentOneFront from './BonusTournamentOneFront.vue';
import FlipCardTournamentOne from './FlipCardTournamentOne.vue'
    export default {
        name: 'BonusTournamentOne',
        components: {
            BonusTournamentOneFront,
            BonusTournamentOneBack,
            FlipCardTournamentOne,
        },
        data() {
            return {
                
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <v-card class="front-bonus" :class="{ '_lock': bonusLock }">
        <v-card-title class="front-bonus__header header-bonus">
          <div class="header-bonus__label">
            
          <font-awesome-icon style="font-size: 12px;" icon="gift" />
        </div>
        <!-- {{ $i18n.t('bonusesFree') }} -->
          <div class="header-bonus__title"> {{ $t('bonusSecondDeposit') }}</div>
          <!-- @click="toggleCardFlip(index)"  -->
          <div @click="flipCard" class="header-bonus__label">
            <font-awesome-icon icon="info" style="font-size: 12px;" />
      </div>
        </v-card-title>
          <v-card-text style="width: auto;" class="py-0 front-bonus__main main-bonus">
             <!--<div class="main-bonus__image">
              <img src="@/assets/images/bonus.png" width="100%" /> 
            </div>-->
            <div v-if="!bonusLock" class="main-bonus__title">
              <span>200% BONUS</span>
              <p>+ 100 FREESPINS</p>
            </div>
            <div v-else class="main-bonus__title_lock">
              <font-awesome-icon icon="lock" style="" />
            </div>
          </v-card-text>
          
          <v-card-text style="width: auto;" class="front-bonus__timer timer-bonus" :class="{ 'py-0': !bonusLock }">
            <div class="timer-bonus__icon">
              <v-icon>
                access_time
              </v-icon>
            </div>
            <div class="timer-bonus__text">
              <span v-if="!bonusLock">{{$t('timeLeftBonus')}}  11:11:11</span>
             <span v-else> {{ $t('bonusDisabled') }}</span>
            </div>
          </v-card-text>
          <v-card-actions v-if="!bonusLock" class="main-bonus__activate">
            <div class="all-buttons-t main-bonus__button">{{ $t('bonusesWillActive')}}</div>
          </v-card-actions>
      </v-card>
</template>

<script>

    export default {
        name: 'BonusFutureOneFront',
        props: ['index'],
        data(){
            return{
              bonusLock: true,
            }
        },
        computed:{
    //      bonusWelcomeOneFlipped() {
    //  return this.$store.state.bonusWelcomeCards[this.index].flipped;
    //},
        },
        methods: {
          flipCard() {
            this.$store.commit('bonuses/flipBonus', { bonuses: 'depositBonuses', index: this.index });
    },
          
         //setBonusWelcomeOneFlipped() {
         //    this.$store.commit('setBonusWelcomeCardFlipped', {
         //      cardId: this.cardId, // Передайте уникальный идентификатор карточки
         //    flipped: true // Измените состояние карточки на перевернутую
         //    });
         //      },
          //  setBonusWelcomeOneFlipped(){
          //    this.$store.commit('setBonusWelcomeOneFlipped', true)
          //  }
        },
        }
</script>

<style lang="scss" scoped>
.front-bonus {
  //background: linear-gradient(173.41deg,#0094ff 4.14%,#5607d6 87.72%)!important;
  //background: var(--dark-flamingo)!important;
  background: url('../../../../../assets/images/bonusCard.jpg') 0 70% / cover no-repeat;
  
  color: var(--white)!important;
  //height: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
    border-radius: 16px!important;
    &._lock{
      background: var(--light-flamingo);
      
    }
  &__header {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
  }
  &__content{
    
  }
  &__main {
    flex: 1 1 100%;
    position: relative;
    z-index: 2;
  }

  &__timer {
    position: relative;
    z-index: 2;
  }
  
}
.header-bonus {
  padding: 10px;

  &__label {
    height: 24px;
    justify-content: center;
    
    position: relative;
    width: 24px;
    align-items: center;
    display: flex;
    position: relative;
    transition: background-color 0.3s;
    &:last-child{
      cursor: pointer;
      
      @media (any-hover: hover){
        &:hover{
          &::before {
            background-color: hsla(0, 0%, 100%, .4);
          }
        }
      }
      
    }
    &::before {
      background-color: hsla(0, 0%, 100%, .2);
      border-radius: 50%;
      content: "";
      //height: calc(100% - 20px);
      height: 24px;
      width: 24px;
      position: absolute;
      //width: calc(100% - 20px);
    }
    
    }

  &__title {
    display: block;
    line-height: 1.5;
    max-width: calc(100% - 68px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    
  }
}
.main-bonus {
  
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content:center;align-items: center;
  &__image {
  }

  &__title {
    text-transform: uppercase;
    font-weight: 900;
    color: var(--white)!important;
    line-height: 1;
    &_lock{
      font-size: 40px;
      color: var(--white);
      position: relative;
      display: flex;
      justify-content:center;
      align-items: center;
      width: 96px;
      height: 96px;
      background-color: var(--dark-flamingo);
      border-radius: 50%;
      svg{
        position: relative;
      z-index: 2;
      margin: 0;
      color: var(--white);
      }
    }
    span{
      font-size: 75px;
    }
    p{
      font-size: 30px;
    }
  }
  &__activate{
    position: relative;
    z-index: 2;
  }
  &__button{
    display: flex;
    justify-content:center;align-items: center;
    text-transform: uppercase;
    font-size: 16px!important;
    width: 100%;
  }
}
.timer-bonus {
  display: flex;
  gap: 10px;
  justify-content:center;
  align-items: center;
  &__icon {
    i{
      color: var(--white)!important;
    }
    
  }

  &__text {
    font-weight: 700;
    color: var(--white);
  }
}
</style>
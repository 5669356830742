<template>
    <!-- v-click-outside="toCloseMenu" -->
    <div class="nav nav--translucent nav--compact nav--right"
    :class="{ 'nav--hide': !isOpen }"
    >
        
    <!-- :class="openMenuButton" -->
        <button class="nav__btn nav__btn--hide btn btn--icon"
        @click="toOpenMenu">
            <v-icon color="var(--white)">
                {{ !isOpen ? 'mdi-chevron-left' : 'mdi-chevron-right' }}
            </v-icon>
        </button>
        <button class="nav__btn nav__btn--back button button_icon" @click="$router.push('/')">
            <v-icon color="var(--white)">mdi-login</v-icon>
        </button>
        <button class="nav__btn nav__btn--menu button button_icon" @click="$emit('menu-open')">
            <v-icon color="var(--white)">list</v-icon>
        </button>
        <button v-if="!isIOS" class="nav__btn nav__btn--fullscreen button button_icon" @click.stop="$emit('fullscreen')">
            <v-icon color="var(--white)">
                {{ IsFullScreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}


            </v-icon>
        </button>
    </div>
</template>

<script>
    export default {
        name: "MobileGameHeader",
        props:{
            IsFullScreen:{
                type: Boolean,
            },
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
      vm.toCloseMenu();
    });
        },
        data() {
            return {
                isIOS: /iPhone|iPad|iPod/i.test(navigator.userAgent),
                game: {},
                id: localStorage.getItem("lastGame"),
                isOpen: false,
            }
        },
        async mounted() {
        },
        methods:{
            toCloseMenu(){
                this.isOpen = false
                // this.$store.commit('set', { type: 'openMenu', items: false })
            },
            toOpenMenu(){
                this.isOpen = !this.isOpen;
                this.$store.commit('set', { type: 'openMenu', items: !this.isOpen })
            },
        },
        computed:{
            // isOpen(){
            //     return this.$store.state.openMenu
            // },
        },
    }
</script>

<style lang="scss" scoped>
.nav {
    top: 5%;
    height: 64px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    position: absolute;
    -webkit-transition: background .25s ease, -webkit-transform .5s ease;
    transition: background .25s ease, -webkit-transform .5s ease;
    transition: background .25s ease, transform .5s ease;
    transition: background .25s ease, transform .5s ease, -webkit-transform .5s ease;
    overflow: hidden;
    z-index: 110;

    &__btn {
        width: 58px;
        display: flex;
        justify-content:center;align-items: center;
        svg{
            width: 50%;
            height: 50%;
        }
    }
    &__btn--hide {
        transform: scale(1.4);
        
    }
    &__btn--menu{
        transform: scale(1.4);
    }
    &__btn--fullscreen {
        transform: scale(1.4);
        
    }
    &__btn--back {
    }
}
.nav--translucent {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 0 0 5px 5px;
}
.nav--compact {
    height: 58px;
    .nav__btn--hide {
        width: 28px;
    padding: 0 5.6px;
    margin-right: 5.6px;
    }
}
.nav--right {
    right: 0;
    padding-right: 4px;
    &.nav--hide{
        transform: translateX(calc(100% - 32px))
    }
}
.button {

    &_icon {
    }
}


</style>
let chat = {
  setUserInfo(userInfo) {
    if ("jivo_api" in window) {
      window.jivo_api.setContactInfo({
        name: userInfo.name,
        email: userInfo.email,
        description: `id пользователя: ${userInfo.id};\n ник пользователя: ${userInfo.username}`,
      });
    }
  },
  resetChat() {
    if ("jivo_api" in window) {
      window.jivo_init();
      window.jivo_api.setContactInfo({
        name: "-",
        description: "незарегистрованный пользователь",
      });
    }
  },
  openChat() {
    if ("jivo_api" in window) {
      this.showChat();
      window.jivo_api.open();
    }
  },
  hideChat() {
    let jdiv = document.getElementsByTagName("jdiv");
    if (jdiv.length >= 1) {
      document.getElementsByTagName("jdiv")[1].style.display = "none";
    } else {
      setTimeout(this.hideChat, 1500);
    }
  },
  showChat() {
    let jdiv = document.getElementsByTagName("jdiv");
    if (jdiv.length >= 1) {
      document.getElementsByTagName("jdiv")[1].style.display = "block";
    }
  },
};
export default chat;

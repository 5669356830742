<template>
    <div>

    </div>
</template>

<script>
    export default {
        name: 'DepositBanner',
        data() {
            return {
                
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
<template>
  <footer class="footer" :class="{'_isReg' : user.id !== null}">
  <div class="footer_wrapper">
    <div class="footer-copyright__top">
      <div class="pa-0 d-flex align-center tmb-style_white footer-copyright__copy"> 
        <img src="@/assets/images/white-heart.svg" width="15px" height="15px" class="white-heart">
        <div>Take My Bet, 2024</div>
        </div>
            <ul class="footer-copyright__nav">
              <li class="footer-copyright__item" v-for="(item, index) in copyright" :key="index">
                <router-link v-if="item.isInternal" :to="item.link" class="footer-copyright__link hover-link">
                  {{ item.text }}
                </router-link>
                
                <a v-else :href="item.link" target="_blank" class="footer-copyright__link hover-link">
                  {{ item.text }}
                </a>
              </li>
            </ul>
            <ul class="footer-copyright__icons">
              <v-lazy v-model="isActive" class="footer-copyright__item-icons" v-for="(item, index) in iconsFooter" :key="index">
                <v-lazy-image @load="isActive = true" class="footer-copyright__icon" :alt="item.alt" :src="require(`@/assets/images/footer/${item.src}.png`)"/>
              </v-lazy>
            </ul>
    </div>
    <div class="footer-copyright__disclaimer">
          <div class="footer-copyright__disclaimer-main">
            {{ $t('footerText') }}
          </div>
          <a href="mailto:support@takemybet.pro">support@takemybet.pro</a>
        <div class="text-right" v-html="$t('footerTextYours')"></div>
    </div>  
    <v-col class="terms_text">
    <v-col class="px-0 text-center">{{  $i18n.t('footerTextBlessed') }}</v-col>
  </v-col>
  </div>
</footer>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    name: "Footer",
    data(){
      return{
        copyright:[
          {
            link: '/rules',
            text: this.$t('userTerms'),
            isInternal: true,  
          },
          {
            link: 'https://affiliates.takemybet.pro/',
            text: this.$t('forPartners'),
            isInternal: false,  
          },
        ],
        isActive: false,
        iconsFooter:[
          {
            src:'01',
            alt: '18+',
          },
          {
            src:'02',
            alt: 'gamecare',
          },
          {
            src:'03',
            alt: 'ga',
          },
        ],
      }
    },
    computed:{
      ...mapGetters({
        user: 'getUserInfo',
      })
    },
    methods: {
      telegramRedirect() {
        window.location.href ='https://t.me/joinchat/AAAAAFEjZCGfsC08QS38ow'
      },
      async profileRedirect() {
        if (this.$store.getters.getUserInfo.id != null ) {
          if (this.$route.path !== '/profile/info/data') {
            await this.$router.push('/profile/info/data')
          }
        } else {
          if (this.$route.path != '/reg') {
            await this.$router.push('/reg')
          }
        }
      },
      async bonusRedirect() {
        if(this.$store.getters.getUserInfo.id != null){
          await this.$router.push('/profile/promo/bonus')
        } else {
          await this.$router.push('/reg')
        }
      },
      async filterGamesRedirect(type){
        if(this.$route.name !== 'Home'){
          await this.$router.push({ name: 'Home', params: { gamesFilter: type} })
        }else{
          await this.$router.push('/#')
        }

      },
      tvBetRedirect(){
        this.$router.push('/live')
      },
      async rulesRedirect() {
        if (this.$route.name !== 'Rules') {
          await this.$router.push('/rules');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.white-heart{
  margin-right: 5px;
}
//.fade {
//  position: relative;
//  padding-top: 50px !important;
//  margin-top: 50px;
//  &_flamingo {
//    &::after {
//      content: '';
//      position: absolute;
//      top: -50px;
//      left: 0;
//      width: 100%;
//      height: 100px;
//      background-image: linear-gradient(transparent, var(--main-flamingo) 50%);
//    }
//  }
//
//}
.footer {
  padding-top: 5px;
  position: relative;
  z-index: 4;
  &__block-t {
    margin: 0 0 10px 0;
    position: relative;
  }
  &._isReg{
    padding: 0 0 25px 0; 
  }
}
ul, li{
  list-style-type: none;
  margin: 0;
  padding: 0!important;
}
.footer-copyright {
  &__copy{
    white-space: nowrap;
  }
  &__top{
    align-items: center;
    display: flex;
    gap: 30px;
    @media (max-width:992px){
      gap: 15px;
        flex-direction: column;
    }
  }
  &__nav {
    display: flex;
    gap: 20px;
    flex: 1 1 100%;
    flex-wrap: wrap;
    @media (max-width:992px){
      gap: 15px;
    }
  }

  &__list {
  }

  &__item {
  }

  &__link {
    text-decoration: none;
    color: var(--white);
  }
  &__icons {
    align-items: center;
    display: flex;
    gap: 18px;
  }

  &__item {
   color: var(--white);
   font-weight: 500;
   line-height: 1.5;
   text-align: left;
  }
  &__item-icons {
    height: 32px;
    flex: 0 0 32px;
    width: 32px;
    display: flex;
    justify-content:center;align-items: center;
  }

  &__icon {
    max-width: 100%;
    max-height: 100%;
  }
  &__disclaimer{
    margin: 15px 0 0 0;
    text-align: justify;
    color: var(--white);
    font-size: 14px;
    line-height: 1.5;
  }
  &__disclaimer-main{
    &::first-letter{
      margin-left: 7px;
    }
  }
}
.footer__circle{
  position: absolute;bottom: 0;right:  10px;
}
.brand__logo{
  width: 100px;
  height: 100px;
  @media (max-width:1140px){
    margin-bottom: 15px;
  }
}
a{
  color: inherit!important;
}
.footer{
  background-color: var(--main-flamingo);
}  
.footer_wrapper{
    max-width: 1330px;
    margin: 0 auto;
    padding: 15px 15px 0 15px;
    background-image: none!important;
  }
  
  .terms_desktop{
    align-items: center;
  }
  .terms_text{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: var(--white);
    text-align: justify;
    @media (min-width:1140.98px){
        flex: 1 1 100%;
    }
  }
  .footer_tabs{
    color: var(--white);
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    white-space: nowrap;
    
    a{
      transition: font-weight 0.3s;
      @media (any-hover: hover){
        &:hover{
          font-weight: 600;
        }
      }
    }
    
  }
  .footer_tags{
    color: var(--red);
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    white-space: nowrap;
  }
  .terms_mobile{
    display: none!important;

  }
  @media (max-width: 1140px) {
    .footer_wrapper{
      //min-width: 800px;
    }
    .win_bottom_line{
      display: none!important;
    }
    .terms_mobile{
      display: flex!important;
      .terms_text{
        font-size: 16px;
      }
    }
    .terms_desktop{
      display: none!important;

    }
  }
  @media(max-width: 500px), (max-height: 500px) {
    .footer_wrapper{
      min-width: 320px;

    }
    .footer_tags{
      font-size: 11px;
      white-space: nowrap;
    }
    .terms_desktop{
      display: none!important;

    }
    .terms_mobile{
      display: flex!important;
      .terms_text{
        font-size: 14px;

      }
    }
    .footer_tabs{
      font-size: 12px;
    }
    .win_bottom_line{
      display: none!important;
    }
  }
</style>

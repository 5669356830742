<template>
    <div v-bind:class="bonusTournamentOneFlipped ? 'flip-container flipped': 'flip-container'">
      <div class="flipper" >
        <div class="front" style="height:450px">
          <slot name="front"></slot>
        </div>
        <div class="back" style="height:450px">
          <slot name="back"></slot>

        </div>
      </div>
    </div>
</template>
  
<script>
export default {
    name: 'FlipCardTournamentOne',
    data() {
      return {
      };
    },
  computed: {
    bonusTournamentOneFlipped: {
      get() {
        return this.$store.state.bonusTournamentOneFlipped;
      },
      set(value) {
        this.$store.commit('setBonusTournamentOneFlipped', value);
      },
    },
  },
    methods: {
      toggleFlip() {
      this.$emit('update:flipped', !this.flipped);
    },
    }
  };
  </script>
  
  <style type='text/css' scoped>
  .front{
    position: relative;

  
  }
  .front::before{

      content:'';
      opacity: .6;
      z-index: 1;
      overflow: hidden;
      border-radius: 16px;
      position: absolute;width: 100%;height: 100%;top: 0;left: 0;
      background-color: var(--dark-d-flamingo);
  
  }
  .frontFlipBtn, .backFlipBtn{
    color:var(--white);
    height: 44px;
    padding: 10px;
    position: relative;
    width: 44px;
  }
  .frontFlipBtn:before{
    background: hsla(0,0%,100%,.2);
    border-radius: 50%;
    content: "";
    height: calc(100% - 20px);
    position: absolute;
    width: calc(100% - 20px);
    z-index: -1;
  }
  .backFlipBtn:before{
    background: hsla(0,0%,100%,.2);
    border-radius: 50%;
    content: "";
    height: calc(100% - 20px);
    position: absolute;
    width: calc(100% - 20px);
    z-index: -1;
  }
  i.backFlipBtn {
      -webkit-transform: rotateY(-180deg);
      -moz-transform: rotateY(-180deg);
      -o-transform: rotateY(-180deg);
      -ms-transform: rotateY(-180deg);
      transform: rotateY(-180deg);
  }
  .flip-container {
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -o-perspective: 1000;
    perspective: 1000;
  }
  .flip-container {
    min-height: 416px;
    max-height: 416px;
  }
  .flipper {
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    position: relative;
  }
  .front,
  .back {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 0.6s;
    -moz-transform-style: preserve-3d;
    -o-transition: 0.6s;
    -o-transform-style: preserve-3d;
    -ms-transition: 0.6s;
    -ms-transform-style: preserve-3d;
    transition: 0.6s;
    transform-style: preserve-3d;
    top: 0;
    left: 0;
    width: 100%;
  }
  .back {
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    position: absolute;
  }
  .flip-container.flipped .back {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  .flip-container.flipped .front {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
  .front {
    z-index: 2;
  }
  </style>
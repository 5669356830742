<template>
    <v-container>
        <v-row style="color: #867CA6;">
            <v-col style="max-width: 300px">
                Дата / время
            </v-col>
            <v-col style="max-width: 60px">
                ГЕО
            </v-col>
            <v-col style="max-width: 300px">
                IP адрес
            </v-col>
            <v-col>
                Пользовательское приложение
            </v-col>
        </v-row>
        <v-container style="max-height: 350px;overflow-y: scroll;">
            <div v-for="i in 5" v-bind:key="i">
                <v-row class="row_style" :style="i % 2 == 0 ? 'background-color:#352B57;':'background-color:#372D5F'">
                    <v-col style="max-width: 300px">
                        15.02.2020 / 11:16:25
                    </v-col>
                    <v-col style="max-width: 60px">
                        RU
                    </v-col>
                    <v-col style="max-width: 300px">
                        162.185.0.2
                    </v-col>
                    <v-col>
                        Chrome 79.0.3945.130 (Windows 10)
                    </v-col>
                </v-row>
            </div>
        </v-container>


    </v-container>
</template>

<script>
    export default {
        name: "UserSessions"
    }
</script>

<style lang="scss" scoped>
    .row_style{
        color: #FFFFFF;font-family: Montserrat;border-radius: 4px;
        margin-top: 5px;
    }
    //#352B57
</style>
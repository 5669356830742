<template>
       <div>
            <v-card-text>
                <v-row
                class="justify-center">
                    <v-col class="text-left px-10">
                        <v-text-field
                  outlined
                  class="main-input input-sign"
                  :label="$i18n.t('email')"
                  type="text"
                  :rules="[v => !!v || $t('requiredField'), v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || $t('regEmailError')]"
                  v-model="email"
                  filled
                  dense
              >
              </v-text-field>
                        <v-text-field
                  outlined
                  class="main-input input-sign"
                  :label="`${$i18n.t('amount')}(${$i18n.t('from')} ${payMethod.payment_min_limit} ${$i18n.t('to')} ${payMethod.payment_max_limit}) `"
                  type="text"
                  :rules="[v => !!v || $t('requiredField'), v => v > 0 || $t('min_dep_amount')]"
                  v-model="amount"
                  filled
                  dense
              >
              <v-icon slot="prepend-inner" color="var(--main-flamingo)">
                attach_money
              </v-icon>
              </v-text-field>
              <div class="deposit-payment-wrapper small-text mt-0 align-start">
                  <div class="deposit-payment-time">
                    {{ $t('commision') }}: {{ `${payMethod.fee}%` }}
                  </div>
              </div>
              <v-btn :loading="loadingFlag" :disabled="!amountValid" @click="getDepositUrl" class="all-buttons-fw all-buttons-fw_green">
                {{$t('payable')}}: {{`${totalAmount} ${user.currency}`}}</v-btn>
              <div class="deposit-payment-wrapper small-text">
                  <div class="deposit-payment-secure">
                    <v-icon>lock</v-icon>
                  {{ $t('secure') }}
                </div>
              </div>
                    </v-col>
                </v-row>
            </v-card-text>
       </div>
</template>

<script>
import { mapGetters } from 'vuex';

    export default {
        name: 'DepositSelectedMethod',
        data() {
            return {
                email: '',
                amount: '',
                loadingFlag: false,
            }
        },
        mounted(){
            const amountStorage = localStorage.getItem('moneyToDeposit');
            this.amount = Number(amountStorage);
            if (this.user.email !== null) {
                this.email = this.user.email
            }
        },
        computed:{
            ...mapGetters({
                user: 'getUserInfo',
                payMethod: 'depositView/getPayMethod'
            }),
            totalAmount(){
                const amount = Number(this.amount);
                const feePercentage = this.payMethod.fee / 100;
                const feeAmount = amount * feePercentage;
                const totalAmount = amount + feeAmount;
                return totalAmount;
            },
            amountValid() {
            let amount = Number.parseFloat(this.amount);
              return !Number.isNaN(amount) && amount >= this.payMethod.payment_min_limit && amount <= this.payMethod.payment_max_limit;
            },
            isBonusWillActive() {
            return this.$store.state.depositView.isBonusWillActive;
            },
        },
        methods:{
            async getDepositUrl() {
      const holdGame = localStorage.getItem('redirectToGame');

        if (!this.amountValid) {
          return;
        }
      
        this.amount = Number.parseFloat(this.amount);
        this.loadingFlag = true;
      
        const depositLinkParams = {
          payWay: this.payMethod.codename,
          paySum: this.amount,
          payment_id: this.payMethod.id,
        };
      
        if (this.amount > 0 && this.isBonusWillActive) {
          depositLinkParams.first_deposit_bonus_enabled = true;
        }
      
          const [res, err] = await this.$store.dispatch('getDepositLink', depositLinkParams);
        
          if (res) {
          
          if (!this.user.has_first_dep && this.isBonusWillActive) {
            const notification = {
              type: "success",
              message: this.$t('bonusHasBeenActivated'),
            };
            this.$store.dispatch("alerts/add", notification);
          
            if (holdGame) {
              const { id, flag } = JSON.parse(holdGame);
              this.$router.push(`/game/${id}/${flag}`);
              localStorage.removeItem('redirectToGame');
            } else {
              this.reloadAfterDelay(res);
            }
          } else {
            if (holdGame) {
              const { id, flag } = JSON.parse(holdGame);
              this.$router.push(`/game/${id}/${flag}`);
              localStorage.removeItem('redirectToGame');
            } else {
              this.reloadAfterDelay(res);
            }
          } 
        } else if(err === 'Server error'){
                  const notification = {
              type: "error",
              message: this.$t("serverError"),
            };
            this.$store.dispatch("alerts/add", notification);
            }
        this.loadingFlag = false;
      },
      reloadAfterDelay(res) {
      setTimeout(() => {
        // this.$router.push(res);
        //window.location.href = res;
        window.location.reload();
      }, 1500);
    },
        },
    }
</script>

<style lang="scss" scoped>
.deposit-payment-wrapper {
    display: flex;
    flex-direction: column;
    justify-content:center;align-items: center;
    margin-top: 10px;
    color: var(--gray-text);
    font-family: "Inter", Helvetica, Arial, sans-serif;
}
.deposit-payment-secure {
    display: flex;
    align-items: center;
}
.deposit-payment-time {
}

</style>
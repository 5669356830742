<template>
  <div>
    <!--<div v-else>
      <v-btn class="tickerHideBtn closeBtnPos" @click="visited = true">
        <font-awesome-icon icon="plus" style="transform: rotate(45deg);"></font-awesome-icon>
      </v-btn>
      <div class="quotes_wrapper" style="color: white;">
        <div class="cookieText">
          {{$i18n.t('cookieText')}}
        </div>
      </div>
    </div>-->
    <!--<div v-if="tickerVisible" class="ticker-wrap">
          <div class="ticker " id="bottom_ticker">

            <div class="ticker__item game__img"
                 style="width: 260px;text-align:left;font-size:10px!important;height: 100%"
                 v-for="(win, i) in $store.getters.tickerTopSpins" v-bind:key="i"
                 :style="{ animationDuration: `${i % 7 + 3}s`}"
            >
              <v-row @click="playGame(win.game)" style="align-items: center" class="tickerItem__row">
                <v-col
                    style="max-width: 75px;text-align: right"

                >
                  <img :src="win.logo" height="40" width="68" />
                </v-col>
                <div style="position: relative; top: 10px;height: 70px;text-transform: none;max-width: 100px;text-overflow: ellipsis">
                  {{win.name}} <b style="font-weight: normal">выиграл</b>
                  <p style="margin-bottom: 0px">
                    <b style="color: #fde700">{{win.win}}
                      <img src="../assets/images/rubl_small.png" height="10" width="10"/>
                    </b>
                  </p>
                  <p style="max-width: 110px;text-overflow: clip;overflow: hidden">
                    в
                    <a
                        style="color: white;text-decoration: underline;"
                    >
                      {{win.game}}
                    </a>
                  </p>
                </div>
                <v-col style="max-width: 60px;text-align: right">
                  <img src="../assets/images/Bullet_small.png">
                </v-col>
              </v-row>
            </div>
          </div>
        </div>-->
    <!-- Welcome Cookie Dialog -->
    <v-dialog
      v-model="cookieDialog"
      persistent
      max-width="400"
    >
      <!-- Content -->
      <v-card class="cookie-card" color="var(--white)">
        <v-card-title class="justify-center font-weight-bold">{{
          $i18n.t("cookieTitle")
        }}</v-card-title>
        <v-card-text class="cookie-content pb-0">
          <div v-html="$i18n.t('cookieText1')" class="first-symbol"></div>
        </v-card-text>
        <v-card-actions class="d-flex flex-column justify-center align-center">
              <v-btn
                color="var(--red)"
                class="all-buttons-t"
                style=""
                @click="acceptCookie"
              >
                {{ $i18n.t("accept") }}
              </v-btn>
              <a class="hover-link mt-2" @click="rulesModalOpen">
                {{ $i18n.t("privacyPolicy") }}
              </a>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog Privacy Policy -->
    <RulesModal />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import quotes from "../assets/quotesText";
import RulesModal from "@/views/RulesModal";
export default {
  name: "Ticker",
  components: {
    RulesModal,
  },
  data() {
    return {
      loadingFlag: false,
      visible: true,
      visited: false,
      cookieDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      tickerVisible: "tickerVisible",
    }),
  },
  watch: {
    tickerVisible(newVal) {
      //console.log('visible changed', newVal)
      if (this.animation !== null) {
        //console.log(this.animation)
        if (!newVal) {
          this.animation.pause();
        } else {
          setTimeout(this.updateQuote, 500);
        }
        //newVal ? this.animation.play() :
      }
    },
  },
  async mounted() {
    if (window.localStorage) {
    this.cookieDialog = !localStorage.getItem("cookieAccepted");
  } else {
    this.cookieDialog = false;
  }
    // this.updateQuote();
  },
  methods: {
    rulesModalOpen() {
      this.$store.commit("regComponents/setRulesModal", true);
    },
    acceptCookie() {
      localStorage.setItem("cookieAccepted", "true");
      this.cookieDialog = false;
    },
    async playGame(game) {
      this.loadingFlag = true;
      console.log("game = ", game);
      let url = await this.$store.dispatch("getGameUrl", { game: game });
      //getGameUrl
      if (url) {
        await this.$router.push({ name: "Game", params: { url: url } });
      } else {
        this.loadingFlag = false;
      }
    },
    invertState() {
      if (this.quoteExpanded) {
        this.quoteExpanded = false;
      } else {
        this.$store.commit("invertTickerState");
      }
    },
    updateQuote() {
      if (this.tickerVisible) {
        let textWrapper = document.querySelector(".quote_item");
        if (textWrapper !== null) {
          textWrapper.style.opacity = 1;
          textWrapper.innerHTML = "";
          textWrapper.innerHTML =
            this.$i18n.t("advice") +
            this.quotes[(this.displayingQuoteIndex + 1) % this.quotes.length];
          textWrapper.innerHTML = textWrapper.textContent.replace(
            /\S/g,
            "<span class='letter'>$&</span>"
          );
          if (this.quoteExpanded) {
            this.quoteHeight = Math.max(
              60,
              document.getElementById("tickerQuote").offsetHeight
            );
          } else {
            this.quoteHeight = 60;
          }
          this.displayingQuoteIndex += 1;
          //this.animateScroll();
          this.animate();
        } else {
          setTimeout(this.updateQuote, 2000);
        }
      }
    },
    animate() {
      this.animation = this.$anime.timeline();
      this.animation.add({
        targets: ".letter",
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 2250,
        delay: (el, i) => 150 * (i + 1),
      });
      /*if (overflowHeight > 0) {
          this.animation.add({
            targets: '.quote_item',
            translateY: -overflowHeight,
            duration: 2000,
            delay: 2000
          })
        }*/
      this.animation.add({
        targets: ".quote_item",
        opacity: 0,
        duration: 1000,
        easing: "easeOutExpo",
        delay: 10000,
      });
      this.animation.finished.then(this.updateQuote);
    },
    invertExpanded() {
      if (this.$isMobile()) {
        const el = document.getElementById("tickerQuote");
        const overflowHeight = el.offsetHeight - 64;
        //console.log('overflow = ', overflowHeight)
        if (overflowHeight > 0) {
          this.quoteHeight = el.offsetHeight;
          this.quoteExpanded = !this.quoteExpanded;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.first-symbol{
  &::first-letter{
    margin-left: 5px;
  }
}
.button-delete {
  background-color: var(--main-flamingo);
  position: absolute;
  border-radius: 5px;
  right: 10px;
  top: 10px;
}
.cookie-card {
  border-radius: 12px !important;
  border: 1px solid var(--white);
  background-color: var(--main-flamingo) !important;
  color: var(--white) !important;
  @media (min-width: 700px) {
    width: 500px;
  }
  @media (max-width: 700px) {
    width: 300px;
  }
}
.cookie-content {
  padding: 0 20px 20px !important;
  color: var(--white) !important;
  text-align: justify;
  font-size: 14px !important;
}

.cookie-button {
  color: var(--white) !important;
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  box-shadow: 0px 0.01em 0.01em rgb(45 35 66 / 100%),
    0px 0.3em 0.7em -0.01em rgb(45 35 66 / 100%),
    inset 0px -0.01em 0px rgb(58 65 111 / 100%) !important;
  &:active {
    box-shadow: inset 0px 0.1em 0.6em #3c4fe0 !important;
    transform: translateY(0em);
  }
  @media (any-hover: hover) {
    &:hover {
      box-shadow: 0px 0.1em 0.2em rgb(45 35 66 / 40%),
        0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #3c4fe0 !important;
      transform: translateY(-0.1em);
    }
  }
}
.cookieText {
  margin-right: 50px;
}
</style>

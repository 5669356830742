// verification.js
const state = {
  errorUploadDoc: false,
  successUploadDoc: false,
  isDeleteDocModal: false,
  docInfo: {
    name: null,
    number: null,
  },
};

const mutations = {
  isDeleteDocModal(state, value) {
    state.isDeleteDocModal = value;
  },
  SET_DOC_INFO(state, doc) {
    state.docInfo = doc;
  },
};

const getters = {};

const actions = {
  holdDocInfo({ commit }, doc) {
    commit("SET_DOC_INFO", doc);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

// search.js
const state = {
  searchMobile: false,
  searchPC: false,
  searchQuantity: 0,
  searchName: "",
};

const mutations = {
  setSearchMobile(state, value) {
    state.searchMobile = value;
  },
  setSearchPC(state, value) {
    state.searchPC = value;
  },
  setSearchQuantity(state, value) {
    state.searchQuantity = value;
  },
  setSearchName(state, value) {
    state.searchName = value;
  },
};

const getters = {
  //lengthOfGames: (state, getters, rootState, rootGetters) => {
  //  return rootGetters['gameArrayLength'];
  //},
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

// gameModule.js
import axios from "axios";
const state = {
  holdGames: [],
  searchGames: [],
  holdPaggination: null,
  selectedBlock: null,
};

const mutations = {
  HOLD_CURRENT_GAMES(state, games) {
    state.holdGames = games;
  },
  HOLD_CURRENT_PAGGINATION(state, paggination) {
    state.holdPaggination = paggination;
  },
  APPEND_CURRENT_GAMES: (state, games) => {
    state.holdGames = state.holdGames.concat(games);
  },
  set(state, { type, items }) {
    state[type] = items;
  },
};

const getters = {
  getCurrentGames: (state) => state.holdGames,
  getSearchGames: (state) => state.searchGames,
  getCurrentPaggination: (state) => state.holdPaggination,
  getSelectedBlock: (state) => state.selectedBlock,
};

const actions = {
  async getSelectedBlock({ commit }, blockId) {
    try {
      const response = await axios.get(`/api/games/blocks/${blockId}`);
      if (response.status === 200) {
        commit("set", { type: "selectedBlock", items: response.data });
      }
    } catch (error) {
      console.error("Error game block", error);
    }
  },
  async getFilteredGames({ commit }, params) {
    const queryString = Object.keys(params)
      .filter((key) => params[key] !== undefined && params[key] !== null)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    try {
      let endpoint = `/api/games/filter?${queryString}`;
      let config = {};

      if (localStorage.getItem("jwt")) {
        endpoint = `/api/games/my/filter?${queryString}`;
        config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        };
      }

      const response = await axios.get(endpoint, config);
      if (response.status === 200) {
        const { items, ...responseWithoutItems } = response.data;
        const paggination = responseWithoutItems;
        const games = items
          .filter(({ Game }) => Game.active === true)
          .map(({ Game }) => Game);

        commit("HOLD_CURRENT_GAMES", games);
        commit("HOLD_CURRENT_PAGGINATION", paggination);
        return games;
      }
    } catch (error) {
      console.error("Error fetching games:", error);
    }
  },
  async getSearchedGames({ commit }, params) {
    const queryString = Object.keys(params)
      .filter((key) => params[key] !== undefined && params[key] !== null)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    try {
      let endpoint = `/api/games/filter?${queryString}`;
      let config = {};

      if (localStorage.getItem("jwt")) {
        endpoint = `/api/games/my/filter?${queryString}`;
        config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        };
      }

      const response = await axios.get(endpoint, config);
      if (response.status === 200) {
        const { items, ...responseWithoutItems } = response.data;
        const games = items.map(({ Game }) => Game);
        commit("set", {
          type: "searchGames",
          items: games,
        });
      }
    } catch (error) {
      console.error("Error fetching games:", error);
    }
  },
  async getGameBlockGames({ commit }, params) {
    const queryString = Object.keys(params)
      .filter((key) => params[key] !== undefined && params[key] !== null)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    try {
      let endpoint = `/api/games/filter?${queryString}`;
      let config = {};

      if (localStorage.getItem("jwt")) {
        endpoint = `/api/games/my/filter?${queryString}`;
        config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        };
      }

      const response = await axios.get(endpoint, config);
      if (response.status === 200) {
        const { items, ...responseWithoutItems } = response.data;
        const paggination = responseWithoutItems;
        const games = items
          .filter(({ Game }) => Game.active === true)
          .map(({ Game }) => Game);

        return games;
      }
    } catch (error) {
      console.error("Error fetching games:", error);
    }
  },
  async getMoreGames({ commit }, params) {
    const queryString = Object.keys(params)
      .filter((key) => params[key] !== undefined && params[key] !== null)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    try {
      let endpoint = `/api/games/filter?${queryString}`;
      let config = {};

      if (localStorage.getItem("jwt")) {
        endpoint = `/api/games/my/filter?${queryString}`;
        config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        };
      }

      const response = await axios.get(endpoint, config);
      if (response.status === 200) {
        const { items, ...responseWithoutItems } = response.data;
        const paggination = responseWithoutItems;
        const games = items
          .filter(({ Game }) => Game.active === true)
          .map(({ Game }) => Game);
        commit("HOLD_CURRENT_PAGGINATION", paggination);
        return games;
      }
    } catch (error) {
      console.error("Error fetching games:", error);
    }
  },
  async getGamesMenu({ commit }, params) {
    const queryString = Object.keys(params)
      .filter((key) => params[key] !== undefined && params[key] !== null)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    try {
      let endpoint = `/api/games/filter?${queryString}`;
      let config = {};

      if (localStorage.getItem("jwt")) {
        endpoint = `/api/games/my/filter?${queryString}`;
        config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        };
      }

      const response = await axios.get(endpoint, config);
      if (response.status === 200) {
        const { items, ...responseWithoutItems } = response.data;
        const paggination = responseWithoutItems;
        const games = items
          .filter(({ Game }) => Game.active === true)
          .map(({ Game }) => Game);

        return response.data;
      }
    } catch (error) {
      console.error("Error fetching games:", error);
    }
  },
  holdGames({ commit }, games) {
    commit("HOLD_CURRENT_GAMES", games);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

<template>
  <div>
    <div class="page">
      <mobile-game-header class="pc-hide"
      @fullscreen="toFullScreen"
      :IsFullScreen="isFullScreen"
      @menu-open="menuDrawer = !menuDrawer"
      />
      <div class="game">
        <v-skeleton-loader width="100%" height="100%" type="image"
        class="skeleton-full"
        v-if="loadingFlag"
        ></v-skeleton-loader>
        <iframe 
        allowfullscreen
        frameborder="0"
        v-if="!errorFlag && !loadingFlag"
        :src="frameUrl"
        id="frame_id_mobile"
        class="game-frame"
        ></iframe>
      </div>
      <error-game 
      v-if="errorFlag" />
      <div class="mobile-hide">
        <desktop-mini-footer
        :IsFullScreen="isFullScreen"
        @menu-open="menuDrawer = !menuDrawer"
        @fullscreen="toFullScreen"
          />
      </div>
      <v-dialog v-model="menuDrawer" transition="slide-x-transition" overlay-opacity="0.8"
      content-class="noBoxShadow zIndex" max-width="fit-content">
      <v-row class="mx-auto">
        <v-col>
          <img :src="`https://minio.takemybet.pro/ui-style/${logo}`" class="logo" alt="">
        </v-col>
      </v-row>
      <v-row class="my-0 mx-auto" style="width:100%">
        <v-col class="py-2">
          <v-btn class="all-buttons-fw" @click="switchMode"
          v-if="demoFlag">
          {{ $i18n.t('playOnMoney') }}
        </v-btn>
        </v-col>
      </v-row>
      <v-row class="my-0 mx-auto" style="width:100%">
        <v-col class="py-2">
          <v-btn class="all-buttons-fw"
          to="/">
          <v-icon class="mr-1">mdi-home</v-icon>
          <span>{{ $i18n.t('lobby') }}</span>
        </v-btn>
        
        </v-col>
      </v-row>
      <v-row class="my-0 mx-auto" style="width:100%">
        <v-col class="py-2">
          <v-btn @click="goToCashBox('deposit')" class="all-buttons-fw">
            <v-icon class="mr-1">mdi-bank</v-icon>
           <span> {{ $i18n.t('deposit2') }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="my-0 mx-auto" style="width:100%">
        <v-col class="py-2">
          <v-btn @click="goToCashBox('cashout')" class="all-buttons-fw">
            <v-icon class="mr-1">mdi-cash</v-icon>
            <span>{{$t('doWithdraw')}}</span>
        </v-btn>
        
        </v-col>
      </v-row>
    </v-dialog>
    </div>
  </div>

  </template>
  
  <script>
  //import DepositBonusCards from "../components/DepositBonusCards";
  // import chat from "../plugins/jivo-chat-functions";
  import ErrorGame from '../views/Game/ErrorGame'
import MobileGameHeader from '../views/Game/MobileGameHeader.vue';
import DesktopMiniFooter from '../views/Game/DesktopMiniFooter.vue';
  import { mapGetters } from 'vuex';
  import Preloader from "../components/Preloader";
  
  export default {
    name: "Sport",
    //components: {DepositBonusCards},
    components: {
      ErrorGame,
      MobileGameHeader,
      DesktopMiniFooter,
      // Preloader,
      // 'vue-flip': VueFlip
    },
    data() {
      return {
        isIOS: /iPhone|iPad|iPod/i.test(navigator.userAgent),
        loadingCount: 0,
        isFullScreen: false,
        menuDrawer: false,
        fullScreenFlag: false,
        gameBarItems: [
          {
            icon: 'list',
            clickFunction: () => {
              this.menuDrawer = !this.menuDrawer;
            },
          },
          {
            icon: 'fullscreen',
            clickFunction: this.toFullscreen,
          },
        ],
        frameUrl: null,
        gameUrls: {
        },
        demoFlag: false,
        errorFlag: false,
        loadingFlag: true,
      }
    },
    computed: {
      windowWidth() {
        return window.innerWidth
      },
      ...mapGetters({
        logo: 'styles/getLogo',
        user: 'getUserInfo',
        screenParams: 'screenParams',
      }),
      deviceType() {
      const width = this.screenParams.width || window.innerWidth;
      if (width >= 1024) {
        return 1;
      } else {
        return 2;
      }
    }
    },
    destroyed() {
      //window.removeEventListener('resize', this.resizeFrame);
      if (this.user.id !== null) {
        this.$store.dispatch('awaitGetUserInfo');
      }
  
    },
    async mounted() {
      //if ('id' in this.$route.params && 'demo' in this.$route.params) {
      //  console.log(`id:${ this.$route.params.id}, demo:${ this.$route.params.demo}`);
      //  this.demoFlag = this.$route.params.demo === 'true';
      //  console.log('this.user.balance', this.user.balance);
      //  console.log('this.demoFlag', this.demoFlag);
      //  if (this.demoFlag === false && this.user.balance === '0.00') {
      //    await this.$router.push('/deposit')
      //  } 
      //  console.log('Demo', this.demoFlag);
      //  await this.getSportUrl(this.$route.params.id);
      //} else {
      //  await this.$router.push('/');
      //}
      await this.getSportUrl();
      this.iframeRef = this.$refs.iframeRef;
    },
//     async beforeRouteLeave(to, from, next) {
//       try {
//           // await this.$store.dispatch("awaitGetUserInfo");
//           const userId = this.$store.getters.getUserInfo.id;
//           if (userId) {
//               await this.$store.dispatch('levels/fetchBetAmount', userId);
//               await this.$store.dispatch('levels/fetchLevelData', this.$store.getters.getUserInfo.level_name);
//               await new Promise(resolve => setTimeout(resolve, 50));
//           }
//           const progressValue = this.$store.getters['levels/progressValue'];
//           this.$store.commit('levels/setProgressValue', progressValue);
//         } catch (error) {
//           console.error(error);
//         }
//         next();
//   },
    watch: {
      '$store.state.showTimeoutModal'(newValue) {
        if (newValue) {
          this.exitFullscreen();
        }
      },
      '$store.state.wagerLimitError'(newValue) {
        if (newValue) {
          this.exitFullscreen();
        }
      },
      '$store.state.lossLimitError'(newValue) {
        if (newValue) {
          this.exitFullscreen();
        }
      },
      deviceType(newValue) {
        if (newValue) {
          this.getSportUrl();
        }
      },
    },
    methods: {

      goToCashBox(link){
        const id = this.$route.params.id;
          const flag = this.demoFlag;
        if (link === 'cashout') {
          this.$router.push('/cashout')
          
          // localStorage
          localStorage.setItem('redirectToGame', JSON.stringify({ id, flag }));
          const storedData = JSON.parse(localStorage.getItem('redirectToGame'));
          console.log(storedData.id, storedData.flag);
        } else if(link === 'deposit'){
          this.$router.push('/deposit')
          // localStorage
          localStorage.setItem('redirectToGame', JSON.stringify({ id, flag }));
          const storedData = JSON.parse(localStorage.getItem('redirectToGame'));
          console.log(storedData.id, storedData.flag);
        }
      },
      async achieveRedirect() {
        try {
          await this.$router.push("/profile/promo-my/level");
  
        } catch (error) {
          if (error.name !== 'NavigationDuplicated') {
            throw error;
          }
        }
      },
      async getSportUrl() {
        // console.log(`id:${ id}, demo:${ this.demoFlag}`);
          this.gameUrls = await this.$store.dispatch('sports/getSportUrl', 
          { 
            platform: this.deviceType, 
          });
          // 
          if (!this.gameUrls.Data) {
            this.errorFlag = true;
          } else {
            const url = `${this.gameUrls.Data}&isAPP=true&skincolor=pp001`;
            this.frameUrl = url;
            if (this.$isMobile()) {
              this.mobileDialog = true;
            } 
            // this.frameUrl = `${this.gameUrls.Data}&skincolor=pp001`
          //const sessionData = await this.$store.dispatch('sports/getSessionSportUrl', this.gameUrls.Data);
          //if (sessionData) {
          //  const sessionСontentData = sessionData.content;
          //  const formattedUrl = this.gameUrls.Data.split('/DepositProcessLogin')[0];
          //} else{
          //  this.errorFlag = true;
          //}
          // console.log('frameUrl', this.frameUrl);
          // console.log('sessionData', sessionData);
        }
        this.loadingFlag = false;
      },
      toggleFullScreen() {
        //console.log('full screen triggered',);
        // let frame = document.getElementById('frame_id');
        const iframeElement = document.getElementById('frame_id');
        const doc = document.documentElement;
        if (iframeElement) {
          if (iframeElement.requestFullscreen) {
            iframeElement.requestFullscreen();
          } else if (iframeElement.mozRequestFullScreen) {
            iframeElement.mozRequestFullScreen();
          } else if (iframeElement.webkitRequestFullscreen) {
            doc.webkitRequestFullscreen();
            iframeElement.webkitRequestFullscreen();
          } else if (iframeElement.msRequestFullscreen) {
            iframeElement.msRequestFullscreen();
          } else if (iframeElement.webkitEnterFullscreen) {
  
            doc.webkitRequestFullscreen();
            iframeElement.webkitEnterFullscreen();
          }
        }
      },
      async moveHome() {
        await this.$router.push('/');
      },
      async frameLoad(event, context) {
        const iframe = event.target;
        if (iframe.requestFullscreen) {
          iframe.requestFullscreen();
        } else if (iframe.mozRequestFullScreen) {
          iframe.mozRequestFullScreen();
        } else if (iframe.webkitRequestFullscreen) {
          iframe.webkitRequestFullscreen();
        } else if (iframe.msRequestFullscreen) {
          iframe.msRequestFullscreen();
        }
        iframe.style.width = '100%';
        iframe.style.height = '100vh';
        if (this.loadingCount === 2) {
          await this.moveHome();
        } else {
          this.loadingCount += 1;
        }
      },
      toFullScreen() {
      this.isFullScreen = !this.isFullScreen
      var doc = window.document;
  var docEl = doc.documentElement;
  
  var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen;
  var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen;
  
  if(!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement) { 
    requestFullScreen.call(docEl);
  }
  else {
    cancelFullScreen.call(doc);  
  }

    },
    exitFullscreen() {
      if (this.errorFlag) {
        return;
      } else if (!this.isFullScreen) {
        return
      }
      this.isFullScreen = false;
      var doc = window.document;
      var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen;
      cancelFullScreen.call(doc);  
    },
    }
  }
  </script>
  
  
  
  <style lang="scss" scoped>
  .mobile-hide{
    @media (max-width:1024px){
      display: none; 
    }
  }
  .pc-hide{
    @media (min-width:1024px){
      display: none; 
    }
  }
  .page{
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  .game{
    width: 100%;
    flex: 1 1 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    position: relative;
    overflow: hidden;
  }
  .game-frame{
    width: 100%;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 10;
    border-top-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .button-icon-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .v-icon{
      flex: 0 0 24px;
    }
  }
  .closeIcon {
    background-color: var(--red);
    border-radius: 4px;
    flex: 0 0 35px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 25px;
    left: 10px;
    z-index: 300;
    @media (max-width:768px){
        display: none;
    }
    &_error{
      display: flex;
    }
    @media (any-hover: hover){
      cursor: pointer;
    }
  }
  .error-block{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    font-weight:600;
    color: var(--white);
    font-size: 25px;
  }
  .game-bar {
    @media (min-width:992px){
        display: none;
    }
    background-color: transparent !important;
    pointer-events: none;
  
    border-radius: 0 !important;
    width: 100%;
    height: 40px;
    position: fixed;
    z-index: 300;
    display: flex;
    padding: 0;
  
    &::before {
      content: '';
      z-index: 0;
      position: absolute;
      width: 100%;
      height: 35px;
      top: 0px;
      left: 0;
      background-image: linear-gradient(var(--main-flamingo), transparent);
  
      @media (orientation: landscape) and (min-width: 600px) {
        background-image: linear-gradient(to right, var(--main-flamingo), transparent);
        width: 35px;
        height: 100%;
        top: 0;
        left: 10px;
      }
    }
  
    flex-direction: column-reverse;
  
    &__navigation {
      padding: 0;
      display: flex;
      position: relative;
      z-index: 2;
  
      .logo-bar {
        width: 50px;
        height: 50px;
        position: absolute;
        right: 0;
        top: 3px;
  
        img {
          max-width: 100%;
          max-height: 100%;
        }
  
        @media (orientation: landscape) and (min-width: 600px) {
          right: -15px;
          top: 3px;
        }
        pointer-events: all;
      }
    }
  
    &__row {
      display: flex;
      pointer-events: all;
      margin: 0;
      //flex-direction: column-reverse;
  
    }
  
    &__column {
  
      padding: 5px;
  
    }
  
    &__icon {
  
      padding: 5px;
      background-color: var(--red);
      border-radius: 16px;
      flex: 0 0 30px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
  
      .v-icon {
        font-size: 20px;
  
      }
    }
  
    &__info {}
  
  }
  
  .info-game-bar {
    display: flex;
    position: absolute;
    justify-content:center;
    width: 100%;
    height: 100%;
    top: 5px;
    left: 53%;
    line-height: 1;
    transform: translate(-50%,0px);
    font-size: 9px;
    max-width: 250px;
    color: var(--white);
    &__wager {
      display: flex;
      flex-direction: column;
      margin-right: 10px;
    }
  
    &__level {}
  }
  
  .logo {
    width: 100px;
  
    @media (orientation: landscape) and (min-width: 500px) {
      display: none;
    }
  
    @media (max-height: 500px) {
      display: none;
    }
  }
  
  @media (orientation: landscape) and (min-width: 600px) {
    .game-bar {
      height: 100%;
      padding-top: 0;
      width: 40px;
  
  
      &__navigation {
        height: 100%;
  
  
      }
  
      &__row {
        justify-content: flex-end;
        flex-direction: column;
      }
  
      &__column {
        height: 50px;
        scrollbar-width: none;
      }
  
      &__icon {
  
        height: auto;
        justify-content: center;
        margin-bottom: 10px;
        width: 100%;
  
      }
    }
  }
  
  .col__order-f {
    @media (max-width:992px) {
      order: 1;
      position: relative;
      flex: 0 1 50%;
  
    }
  }
  
  .col__order-s {
    @media (min-width:992px) {
      align-self: center;
      text-align: center;
      max-width: 225px;
    }
  
    @media (max-width:992px) {
      display: none;
    }
  }
  
  .col__order-t {
    @media (max-width:992px) {
      order: 3;
      flex: 0 1 50%;
  
    }
  }
  .row__adaptive-t {
    margin: 0;
    height: 100%;
    @media (max-width:992px) {
      //display: none;
      flex-direction: column;
    }
  
    .logo-pc {
      @media (max-width:992px) {
        display: none;
      }
    }
  }
  .all-buttons-fw{
    font-size: 14px !important;
    .v-btn__content{
      justify-content: flex-start;
      span{
        flex: 1 1 100%;
      }
    }
    @media (min-width:992px) {
      &:not(:last-child) {
        margin-bottom: 6px;
      }
  
      width: 100%;
    }
  }
  .game_container {
    padding: 0px;
    max-height: 100vh;
    overflow: hidden;
    //height: calc(var(--vh, 1vh) * 100);
    //overflow: hidden;
    //min-height: 100vh;
    background-color: var(--main-flamingo);
    &._error{
      display: flex;
      flex-direction: column;
      justify-content:center;align-items: center;
    }
  }
  
  .game_frame {
    width: 100%;
    height: 100%;
    position: relative;
    //margin-bottom: -7px;
    @media (max-width:992px){
      padding-top: 20px;
    }
    @media (orientation: landscape) and (min-width: 600px) and (max-width:992px){
      padding-left: 20px;
      padding-top: 0;
    }
  }
  .btn-pc-full{
    @media (max-width:992px){
      display: none;
    }
  }
  .game_container::-webkit-scrollbar-thumb{
    display: none!important;
  }
  .game_frame__mob {
    object-fit: contain;
    position: absolute;
    border: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100% !important;
    //@media (min-height:500px) {
    //  height: 90vh !important;
    //}
  
    &._isNoIos {
      padding-top: 10px;
  
      @media (orientation: landscape) and (min-width: 600px) {
        padding-left: 10px;
        padding-top: 0;
      }
  
      //@media (min-height:500px) {
      //  height: 100vh !important;
      //}
    }
  }
  
  .router_view {
    max-width: 100% !important;
    flex: 1 1 100% !important;
  }
  
  #app {
    background-image: none;
  }
  
  .ratio-16x9 {
    height: 100% !important;
  }
  
  body {}
  
  .bonus_card {
    height: 120px;
  
    width: 90%;
    background: linear-gradient(99.4deg, var(--light-blue) 0%, var(--blue) 100%), var(--red);
    border-radius: 5px;
    margin: 0 auto;
    margin-bottom: 15px;
    color: #FFFFFF;
  }
  
  @media (max-width: 600px),
  (max-height: 450px) {
    body {
      zoom: 99.99999;
    }
  
    html {
      height: 100%;
      position: fixed;
    }
  
  }</style>
  
<template>
  <flip-spins-bonus :index="index">
    <template slot="front">
      <front-spins-bonus
        :index="index"
        :bonusId="cardSpins.id"
        :cardSpins="cardSpins"
        :status="cardSpins.status"
        :type="cardSpins.type"
        :count="cardSpins.count"
        :expire_in_days="cardSpins.expire_in_days + ' ' + this.$t('day')"
        :expire_in_seconds="cardSpins.expire_in_seconds"
        :fs_count_remaining="cardSpins.fs_count_remaining"
        :game_name="cardSpins.game.game_name"
        :game_id="cardSpins.game.game_id"
        :wager="cardSpins.wager"
        :wagering="cardSpins.wagering"
        :wager_amount="cardSpins.wager_amount"
        :game_logo="cardSpins.game.game_logo"
        :game_string_id="cardSpins.game.game_string_id"
        :lines="cardSpins.lines"
      ></front-spins-bonus>
    </template>
    <template slot="back">
      <back-spins-bonus
        :index="index"
        :type="cardSpins.type"
        :bonusId="cardSpins.id"
        :status="cardSpins.status"
        :expire_in_days="cardSpins.expire_in_days + ' ' + this.$t('day')"
        :game_name="cardSpins.game.game_name"
        :wager="cardSpins.wager"
        :wagering="cardSpins.wagering"
        :max_win="cardSpins.max_win"
        :wager_amount="cardSpins.wager_amount"
        :currency_bet="cardSpins.currency_bet"
      ></back-spins-bonus>
    </template>
  </flip-spins-bonus>
</template>

<script>
import BackSpinsBonus from "./BackSpinsBonus.vue";
import FrontSpinsBonus from "./FrontSpinsBonus.vue";
import FlipSpinsBonus from "./FlipSpinsBonus.vue";
import { mapGetters } from "vuex";
export default {
  name: "SpinsBonus",
  components: {
    FrontSpinsBonus,
    BackSpinsBonus,
    FlipSpinsBonus,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    bonusId: {
      type: Number,
      //   required: true,
    },
    cardSpins: {
      type: Object,
      //   required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "getUserInfo",
    }),
  },
};
</script>

<style lang="scss" scoped></style>

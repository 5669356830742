<template>
  <div class="wrapper">
    <!-- <main opacity="1" z-index="400" v-if="loading">
      <v-container>
        <img :src="`https://minio.takemybet.pro/ui-style/${logo}`" height="50" width="100"/>
      </v-container>
      <p>
        <Preloader></Preloader>
      </p>
    </main> -->
    <main class="page">
      <div class="page__container container-mod">
        <div class="page__logo">

          <img :src="`https://minio.takemybet.pro/ui-style/${logo}`" alt="logo"/>
        </div>
        <div class="page__text">
          {{isBlockedProduct ? $i18n.t('blockedProductText') : $i18n.t('blockedText')}}
        </div>
        <v-row class="page__cards cards">
          <v-col>
            
            <v-card class="cards rounded-xl">
              <v-card-title class="cards__title">
                {{ isBlockedProduct ? $t('maintenance_card_title') : $i18n.t('using_vpn_title')}}
              </v-card-title>
              <v-card-text class="cards__text">
                {{ isBlockedProduct ? $t('maintenance_card_text') : $i18n.t('using_vpn_text')}}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            
            <v-card class="cards _click">
              <v-card-title class="cards__title" primary-title>
                {{ $t('any_questions_title') }}
              </v-card-title>
              <v-card-text class="cards__text" v-html="$t('any_questions_text')">
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </main>

  </div>
</template>

<script>
    import Preloader from "@/components/Preloader";
import { mapGetters } from "vuex";
    export default {
      name: "blocked",
      // components: {Preloader},
      data(){
        return{
          
        }
      },
      computed:{
        ...mapGetters({
          logo: 'styles/getLogo'
        }),
        isBlockedProduct(){
      return this.$store.state.isBlockedProduct;
    },
      },
      async mounted() {
       
      },
      methods:{
        
      }
    }
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: var(--main-flamingo);
  padding-bottom: 10px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @supports (overflow: clip) {
    overflow: clip;
  }
  > main {
    flex: 1 1 auto;
  }
  > * {
    min-width: 0;
  }
}
.page{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content:center;align-items: center;
  &__container{
    display: flex;
    justify-content:center;align-items: center;
    flex-direction: column;
  }
  &__logo {
    img{
      width: 150px;
      max-height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    font-weight: bold;
    font-size: 30px;
    @media (max-width:992px){
      font-size: 25px;
        
    }
  }
  
  &__cards {
    margin-top: 30px;
    @media (max-width:992px){
      margin-top: 15px;
    }
  }
}
.cards {
  &.v-card{
    color: var(--white);
    background-color: var(--light-flamingo);
    display: flex;
    flex-direction: column;
    justify-content:center;align-items: center;
    padding-left: 55px;
    padding-right: 55px;
    height: 100%;
    border-radius: 16px;
    box-shadow: 0 0 8px var(--dark-flamingo)!important;
    &._click{
      cursor: pointer;
    }
  }
  &__title {
    flex: 1 1 auto;
    white-space: nowrap;
  }
  
  &__text {
    color: var(--white)!important;
    
  }
}

</style>
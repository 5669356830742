<template>

            <v-card class="isExit" flat>
                <v-card-title style="line-height: 21px;">
                    <v-row>
                        <v-col class="text-left font-weight-bold" style="font-size: 14px;">
                            {{$t('methodExitTextBold')}}
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row
                    class="justify-center">
                        <v-col class="text-left"
                        style=""
                        >{{$t('methodExitText')}}</v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="mx-auto" style="width: 100%;">
                    <v-row class="justify-center">
                        <v-col class="d-flex flex-column isExit__buttons">
                            <!-- @click="sendMessage" -->
                            <v-btn  disabled class="all-buttons-fw all-buttons-fw_green">{{$t('methodExitButton')}}</v-btn>
                            <span class="mt-3" style="color: var(--gray-text);font-size: 14px;
                            font-weight: 400;"><span class="isExit__close" @click="returnToDeposit">{{$t('methodExitReturn')}}</span></span>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
    
</template>

<script>
import DepositHeader from '@/views/DepositPages/DepositHeader'
    export default {
        name: 'DepositExit',
        components:{
            // DepositHeader
        },
        data() {
            return {
                
            }
        },
        computed:{
        },
        methods:{
            async returnToLobby(){
                await this.$router.push('/deposit');
                this.$store.commit('depositView/setMethodMoney', false)
                this.$store.commit('depositView/setMethodCrypto',false)
                this.$store.commit('depositView/setMethodExit',false)
                this.$store.commit('depositView/setDepositExitWindow',false)
                this.$store.commit('depositView/setDepositMethodsWindow',false)
                this.$store.commit('depositView/setDepositWindowError',false)
                this.$store.commit('depositView/setSelectedMethod', null)


                
  
            },
            returnToDeposit(){
                this.$store.commit('depositView/setDepositMethodsWindow', true)
                this.$store.commit('depositView/setMethodCrypto', false)
                this.$store.commit('depositView/setMethodMoney', true)
                this.$store.commit('depositView/setDepositExitWindow', false)
                this.$store.commit('depositView/setMethodExit', false)
            },
        },
    }
</script>

<style lang="scss" scoped>
.isExit{
    display: flex;
    flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0px!important;
  @media (min-width:850px){
    border-radius: 16px!important;
  }
  //height: 450px !important;
  //width: 720px !important;
  margin: 0 auto;
  overflow: hidden;
    
  .col{
    max-width: 450px;
  }
  &__buttons{
    max-width: 330px!important;
}
    &__close{
        @media (any-hover: hover){
            &:hover{
                text-decoration: underline;
                cursor:pointer
            }
        }
    }
}
</style>
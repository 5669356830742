<template>
    <div>
      <v-col class="d-flex align-center justify-space-between">
              <v-card-title class='pa-0 text-uppercase title-card' style="color: var(--white)">
                {{ $i18n.t('bonusesTitle')}}
            </v-card-title>
            <v-btn @click="openPromocodeDialog" class="all-buttons-t">{{ $t('promoTitle') }}</v-btn>
          <PromoCode></PromoCode>
        </v-col>
          <!-- slider bonuses -->
          <v-row>
            <v-col>
              <div class="d-flex flex-column">
                <div class="bonus-wrapper">
                  <div class="bonus-card-wrapper" v-for="(cardDeposit, index) in depositBonuses" :key="'depositBonus_' + index">
                    <deposit-bonus v-if="user.id !== null" :index="index" :bonusId="cardDeposit.id" :cardDeposit="cardDeposit"> 
                    </deposit-bonus>
                  </div>
                  <div class="bonus-card-wrapper" v-for="(cardSpins, index) in spinsBonuses" :key="'spinsBonus_' + index">
                    <spins-bonus :index="index" :bonusId="cardSpins.id" :cardSpins="cardSpins"></spins-bonus>
                  </div>
                  <div class="bonus-card-wrapper" v-if="user.id === null">
                    <bonus-no-login> 
                    </bonus-no-login>
                  </div>
                  <div class="bonus-card-wrapper">
                    <bonus-tournament-one> 
                    </bonus-tournament-one>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
</div>
</template>

<script>
import DepositBonus from '@/components/Profile/Promo/BonusCards/DepositBonus/DepositBonus.vue'
import SpinsBonus from '@/components/Profile/Promo/BonusCards/SpinsBonus/SpinsBonus.vue'
import BonusTournamentOne from '@/components/Profile/Promo/BonusCards/BonusTournamentOne/BonusTournamentOne.vue'
import BonusFutureOne from '@/components/Profile/Promo/BonusCards/BonusFutureOne/BonusFutureOne.vue'
import PromoCode from '@/components/Profile/Promo/PromoCode/Promocode.vue'
import BonusNoLogin from '@/components/Profile/Promo/BonusCards/BonusNoLogin/BonusNoLogin.vue'
import { mapGetters } from 'vuex';
    export default {
        name: 'WelcomePC',
        components:{
            DepositBonus,
            BonusTournamentOne,
            SpinsBonus,
            PromoCode,
            //BonusFutureOne,
            BonusNoLogin,
        },
        data() {
            return {
            }
        },
        props:{
        spinsBonuses: Array,
        depositBonuses: Array,
      },
        computed:{
    ...mapGetters({
      user: 'getUserInfo',
    }),
        },
        methods: {
          openPromocodeDialog() {
      this.$store.commit('promoCode/setDialogPromo', true)
    },
        },
    }
</script>

<style lang="scss" scoped>
.title-card{
    font-size: 18px!important; 
  }
</style>
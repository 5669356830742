<template>
  <v-card-title class="dialog__card-header deposit-header">
    <div class="deposit-header__wrapper ma-0 pa-0">
      <div class="deposit-header__item">
        <v-btn
            v-if="isSelectedMethod"
            class="deposit-header__close _back"
            @click="goToMethods"
          >
          <v-icon color="white">arrow_back</v-icon>
        </v-btn>
        <div class="deposit-header__logo" v-else>
          <img
            :src="`https://minio.takemybet.pro/ui-style/${logo}`"
            alt="main-logo"
          />
        </div>
        
      </div>
      <div class="deposit-header__item deposit-header__item_title">
        <div class="deposit-header__content">
          <span v-if="isMethodMoney || isMethodCrypto">
            {{ $t("depositTitle") }}
          </span>
          <span v-if="isMethodExit">
            {{ $t("methodExitTitle") }}
          </span>
          <span v-if="isSelectedMethod">
            <v-lazy-image :src="require(`@/assets/images/${payMethod.logo}`)" style="height: 60px;width:150px;"
              ></v-lazy-image>
          </span>
        </div>
      </div>
      <div class="deposit-header__item">
        <div class="deposit-header__close">
          <v-btn
            v-if="!isMethodExit || isSelectedMethod"
            class="deposit-header__close"
            @click="toExitMethodsWindow"
          >
            <v-icon color="white">close</v-icon>
          </v-btn>
          <v-btn
            v-if="isMethodExit && !isSelectedMethod"
            class="deposit-header__close"
            @click="returnToLobby"
          >
            <v-icon color="white">close</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </v-card-title>
</template>

<script>
import { mapGetters } from 'vuex/dist/vuex.common.js';

export default {
  name: "DepositHeader",
  data() {
    return {};
  },
  methods: {
    goToMethods() {
      this.$store.commit("depositView/setMethodMoney", true);
      this.$store.commit("depositView/setMethodSelected", false);
    },
    toExitMethodsWindow() {
      this.$store.commit("depositView/setMethodExit", true);
      this.$store.commit("depositView/setMethodMoney", false);
      this.$store.commit("depositView/setMethodCrypto", false);
      this.$store.commit("depositView/setMethodSelected", false);
    },
    returnToLobby() {
      this.$store.commit("depositView/setMethodMoney", false);
      this.$store.commit("depositView/setMethodCrypto", false);
      this.$store.commit("depositView/setMethodExit", false);
      this.$store.commit("depositView/setMethodSelected", false);
      this.$store.commit("depositView/setDepositMethodsWindow", false);
      this.$store.commit("depositView/setDepositWindowError", false);
      this.$store.commit("depositView/setSelectedMethod", null);
      localStorage.removeItem("moneyToDeposit");
    },
  },
  computed: {
    ...mapGetters({
      payMethod: 'depositView/getPayMethod',
      logo: 'styles/getLogo',
    }),
    isSelectedMethod(){
      return this.$store.state.depositView.isSelectedMethod;
    },
    isMethodMoney: {
      get() {
        return this.$store.state.depositView.isMethodMoney;
      },
      set(value) {
        this.$store.commit("depositView/setMethodMoney", value);
      },
    },
    isMethodCrypto: {
      get() {
        return this.$store.state.depositView.isMethodCrypto;
      },
      set(value) {
        this.$store.commit("depositView/setMethodCrypto", value);
      },
    },
    isMethodExit: {
      get() {
        return this.$store.state.depositView.isMethodExit;
      },
      set(value) {
        this.$store.commit("depositView/setMethodExit", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.deposit-header {

  &__items {
    margin: 0;
    align-items: center;
    flex-wrap: nowrap;

    .dialog_pc & {
      justify-content: flex-end;
    }
  }

  &__logo {
    width: 65px;
    height: 65px;

    img {
    }
  }

  &__close {
    padding: 0 !important;
    cursor: pointer;
    min-width: 36px !important;
    height: 36px !important;
    box-shadow: none !important;
    background-color: var(--dark-flamingo) !important;
    align-items: center;
    border: none;
    border-radius: 6px;
    transition: transform 0.3s;
    @media (any-hover: hover) {
      &:hover {
        i {
          transform: rotate(-180deg);
        }
      }
    }
  }
}
.deposit-header {
  background: var(--light-flamingo);
  padding: 5px !important;
  @media (min-width: 850px) {
    padding: 10px 15px !important;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    flex: 1 1 100%;
  }

  &__item {
    display: flex;
    flex: 0 1 33.333%;
    justify-content:center;
    align-items: center;
    &_title{
      flex: 1 1 100%;
    }
    &:first-child{
      justify-content: flex-start;
    }
    &:last-child{
      justify-content: flex-end;
    }
  }

  &__logo {
    width: 65px;
    height: 65px;
    flex: 0 0 65px;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__content {
    max-width: 100%;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
    span {
      color: var(--white);
      font-size: 15px;
      white-space: nowrap;
      font-weight: 600;
      line-height: 1;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__close {
    padding: 0 !important;
    cursor: pointer;
    max-width: 36px !important;
    height: 36px !important;
    box-shadow: none !important;
    background-color: var(--dark-flamingo) !important;
    align-items: center;
    border: none;
    border-radius: 6px;
    transition: transform 0.3s;
    @media (any-hover: hover) {
      &:hover {
        i {
          transform: rotate(-180deg);
        }
      }
    }
    &._back{
      i{

        transform: rotate(0deg);
      }
    }
  }
}

</style>

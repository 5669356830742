<template>
      <v-overlay v-if="$route.path === '/enter'" absolute class="blur-overlay" z-index="200">
        <v-dialog 
        :retain-focus="false" 
        persistent
        v-model="signInModal" content-class="blur-dialog" 
         :fullscreen="$isMobile() ? true : false">

    <v-card class="reg_card reg_card_pc" color="var(--white)" style="max-width: 400px;margin-bottom: 35px">
      <v-card-title style="padding: 0px;">
        <v-col style="font-size: 20px;color: var(--black);padding-bottom: 0px">
          {{$i18n.t('enterSite')}}
        </v-col>
      </v-card-title>
      <v-card-text v-on:keyup.enter="signIn">
        <v-col>
          <v-row>
            <v-col class="column-input">
              <!-- -->
              <v-text-field
                  autofocus
                  hide-details
                  outlined
                  color="var(--main-flamingo)"
                  dark
                  class="main-input input-sign"
                  :class="{'_error' : errorLoginFlag}"
                  :label="'E-mail / Id / ' + $i18n.t('phone')" 
                  v-model="login"
                  :error-messages="errorMsgs"
                  :error="errorLoginFlag"
                  @input="resetErrors"
                  @blur="checkIsField('login')"
                  dense
              >
                <v-icon slot="prepend-inner" color="var(--main-flamingo)">
                  person
                </v-icon>
              </v-text-field>
              <span class="hint" :class="{'_visible' : errorLoginFlag}"> {{ $t('requiredField') }}</span>
            </v-col>
          </v-row>
              <v-row class="mt-0">
            <v-col class="column-input">
              <v-text-field
                  outlined
                  hide-details
                  class="main-input input-sign"
                  :class="{'_error' : errorPasswordFlag}"
                  :label="$i18n.t('password')"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password"
                  :error-messages="errorMsgs"
                  :error="errorPasswordFlag"
                  :append-icon="showPassword ? 'visibility_off' : 'visibility'" 
                  @click:append="showPassword = !showPassword"
                  @input="resetErrors"
                  @blur="checkIsField('password')"
                  dense
              >
                <v-icon slot="prepend-inner" color="var(--main-flamingo)">
                  lock
                </v-icon>
              </v-text-field>
              <span class="hint" :class="{'_visible' : errorPasswordFlag}"> {{ $t('requiredField') }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col style="" class="enter__block-t column-input" :class="{'_error' : errorFlag}">
              <v-btn :loading="loadingFlag" class="all-buttons-fw all-buttons-fw_green"
              
              @click="signIn">
                {{$i18n.t('enter')}}
              </v-btn>
              <span class="hint hint-all" :class="{'_visible-all' : errorFlag}"> {{ $t('smthWrong') }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col style="color: var(--black);" class="pa-0">
              <router-link to="/reset" style="color: var(--green-success)" class="hover-link hover-link_green">
                {{$i18n.t('forgetPass')}}
              </router-link>
            </v-col>
          </v-row>
          <v-row class="mt-1">
            <v-col style="color: var(--black);" class="mt-4 pa-0">
              {{$i18n.t('noAcc')}}
              <router-link to="/reg" style="color: var(--green-success)" class="hover-link hover-link_green">
                {{$i18n.t('goReg')}}
              </router-link>
            </v-col>
          </v-row>
        </v-col>
        <v-btn class="button__close" :class="{'is-mobile' : $isMobile()}" @click="moveHome">
          <v-icon color="var(--black)">close</v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
    <v-dialog v-model="banDialog" max-width="fit-content">
      <v-card class="reg_card" style="border: 1px solid var(--white);width: 310px;height: 200px">
        <v-card-title style="color: var(--red)">
          <v-col>
            {{$i18n.t('authError')}}
          </v-col>
        </v-card-title>
        <v-card-text style="color: var(--red);">
          <v-row >
            <v-col style="height: 100%;text-align: center;align-items: center;font-size: 18px;padding-top: 40px">
              {{$i18n.t('block') }} {{banDate === null ? $i18n.t('forever') : `${$i18n.t('to')} ${new Date(banDate).toLocaleString()}`}}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  <ul class="bg-bubbles-reg pa-0" v-if="!$isMobile()" style="overflow: hidden;">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul>
</v-dialog>
</v-overlay>
</template>

<script>
  import Preloader from "../components/Preloader";
  import { startTimer } from '../timerWorker';
  import fingerprint from "../plugins/fingerprint";
  import { mapGetters } from "vuex";
  import axios from "axios";
  export default {
    name: "SignIn",
    components:{
      //Preloader,
    },
    data(){
      return{
        loadingFlag: false,
        login:'',
        password:'',
        errorFlag: false,
        errorLoginFlag: false,
        errorPasswordFlag: false,
        errorMsgs:[],
        banDialog: false,
        banDate: null,
        showPassword: false,
      }
    },
    beforeRouteEnter(to, from, next){
    next(vm => {
      vm.$store.commit("setSignInModal", true);
      const userInfo = vm.$store.getters.getUserInfo;
    if (!userInfo || userInfo.id !== null) {
      next('/');
    }
    });
    },
    mounted(){
      this.$store.commit("setSignInModal", true);
    },
    //    beforeRouteLeave(to, from, next) {
    //  next(vm => {
    //    vm.errorLoginFlag = false;
    //    vm.errorPasswordFlag = false;
    //  });
    //},
    computed:{
      ...mapGetters({
        userInfo: 'getUserInfo',
      }),
      signInModal: {
      get() {
        return this.$store.state.signInModal;
      },
      set(value) {
        this.$store.commit("setSignInModal", value);
      },
    },
    },
    methods:{
      async moveHome() {
      this.$store.commit("setSignInModal", false);
      this.errorLoginFlag = false;
      this.errorPasswordFlag = false;
      this.errorFlag = false;
      this.login = '';
      this.password = '';
      await this.$router.push('/');
      // this.$router.go(-1);
    },
      async signIn(){
        //signIn
        this.loadingFlag = true;
        this.checkIsField('password');
        if ((this.errorPasswordFlag || this.errorLoginFlag)) {
          return this.loadingFlag = false;
        }
        let [res, error, date] = await this.$store.dispatch('singIn',{
          username: this.login,
          password: this.password,
          fingerprint: await fingerprint.get(),
        });
        if (res) {
          this.login = '';
          this.password = '';
          const savedMaxSessionTime = this.userInfo.time_limit;
          if (savedMaxSessionTime) {
            startTimer(Number(savedMaxSessionTime));  // Converting to number
          }
          if (this.userInfo.balance === 0) {
            await this.$router.push('/deposit');
          } else {
            await this.$router.push('/');
          }
        } else {

          switch (error) {
            case 'ban':
              this.banDate = date;
              this.banDialog = true;
              break
            default:
              this.password = '';
              this.errorFlag = true;
          }
        }
        this.loadingFlag = false;
      },
      resetErrors(){
        this.errorLoginFlag = false;
        this.errorPasswordFlag = false;
        this.errorFlag = false;
      },
      checkIsField(input) {
      if (input === 'login') {
        if (this.login.trim().length === 0) {
          this.errorLoginFlag = true;
        } else {
          this.errorLoginFlag = false;
        }
      }
      if (input === 'password') {
        if (this.password.trim().length === 0) {
          this.errorPasswordFlag = true;
        } else {
          this.errorPasswordFlag = false;
        }
      }
    },
    }
  }
</script>

<style lang="scss" scoped>
::v-deep input:-webkit-autofill,
::v-deep input:-webkit-autofill:hover, 
::v-deep input:-webkit-autofill:focus, 
::v-deep input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
}
.to-color-1{
  backdrop-filter: blur(40px);
  background-color: rgba(141, 36, 170, 0.295);
  //filter: blur(20px);
}
.column-input{
  position: relative;
  transition: all 0.3s;
  &._error{
    margin-bottom: 15px;
  }
}
.hint{
  position: absolute;
  right: 13px;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  color: var(--red);
  justify-content: flex-end;
  font-size: 12px;
  transition: all 0.3s;
  &-all{
    right: 0px;
    transform: translate(-25%,0px);
  }
  &._visible{
  bottom: 5px;
  opacity: 1;
  visibility: visible;
  }
  &._visible-all{
  bottom: -15px;
  right: 0%;
  transform: translate(-25%,0px);
  opacity: 1;
  visibility: visible;
  }
}
.bg-bubbles{
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  
  z-index: 1;
  
  li{
    position: absolute;
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    // background-color: rgba(255, 255, 255, 0.25);
    background: url('../assets/images/bubles/white-heart-bubble.svg') 0 / 100% no-repeat;
    bottom: -160px;
    opacity: .3;
    -webkit-animation: square 25s infinite;
    animation:         square 25s infinite;
    
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    &:nth-child(1){
      left: 10%;
      background: url('../assets/images/bubles/cards-spades-bubble.svg') 0 / 100% no-repeat;
    }
    
    &:nth-child(2){
      left: 20%;
      background: url('../assets/images/bubles/cards-diamonds-bubble.svg') 0 / 100% no-repeat;
      width: 80px;
      height: 80px;
      
      animation-delay: 2s;
      animation-duration: 17s;
    }
    
    &:nth-child(3){
      left: 25%;
      animation-delay: 4s;
      background: url('../assets/images/bubles/cards-clubs-bubble.svg') 0 / 100% no-repeat;
    }
    
    &:nth-child(4){
      left: 40%;
      width: 60px;
      height: 60px;
      background: url('../assets/images/bubles/cards-spades-bubble.svg') 0 / 100% no-repeat;
      animation-duration: 22s;
      
      // background-color: rgba(255, 255, 255, 0.25);
    }
    
    &:nth-child(5){
      left: 70%;
      background: url('../assets/images/bubles/cards-clubs-bubble.svg') 0 / 100% no-repeat;
    }
    
    &:nth-child(6){
      left: 80%;
      width: 120px;
      height: 120px;
      
      animation-delay: 3s;
      // background-color: rgba(255, 255, 255, 0.25);
    }
    
    &:nth-child(7){
      left: 32%;
      width: 160px;
      height: 160px;
      
      animation-delay: 7s;
    }
    
    &:nth-child(8){
      left: 55%;
      width: 20px;
      height: 20px;
      
      animation-delay: 15s;
      animation-duration: 40s;
    }
    
    &:nth-child(9){
      left: 25%;
      width: 10px;
      height: 10px;
      
      animation-delay: 2s;
      animation-duration: 40s;
      // background-color: rgba(255, 255, 255, 0.25);
    }
    
    &:nth-child(10){
      left: 90%;
      width: 160px;
      height: 160px;
      
      animation-delay: 11s;
    }
  }
}
.bg-bubbles-reg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  pointer-events: none;
  
  li{
    position: absolute;
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    // background-color: rgba(255, 255, 255, 0.25);
    background: url('../assets/images/bubles/reg/white-heart-bubble.svg') 0 / 100% no-repeat;
    bottom: -160px;
    opacity: .3;
    -webkit-animation: square 25s infinite;
    animation:         square 25s infinite;
    
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    &:nth-child(1){
      left: 10%;
      background: url('../assets/images/bubles/reg/cards-spades-bubble.svg') 0 / 100% no-repeat;
    }
    
    &:nth-child(2){
      left: 20%;
      background: url('../assets/images/bubles/reg/cards-diamonds-bubble.svg') 0 / 100% no-repeat;
      width: 80px;
      height: 80px;
      
      animation-delay: 2s;
      animation-duration: 17s;
    }
    
    &:nth-child(3){
      left: 25%;
      animation-delay: 4s;
      background: url('../assets/images/bubles/reg/cards-clubs-bubble.svg') 0 / 100% no-repeat;
    }
    
    &:nth-child(4){
      left: 40%;
      width: 60px;
      height: 60px;
      background: url('../assets/images/bubles/reg/cards-spades-bubble.svg') 0 / 100% no-repeat;
      animation-duration: 22s;
      
      // background-color: rgba(255, 255, 255, 0.25);
    }
    
    &:nth-child(5){
      left: 70%;
      background: url('../assets/images/bubles/reg/cards-clubs-bubble.svg') 0 / 100% no-repeat;
    }
    
    &:nth-child(6){
      left: 80%;
      width: 120px;
      height: 120px;
      
      animation-delay: 3s;
      // background-color: rgba(255, 255, 255, 0.25);
    }
    
    &:nth-child(7){
      left: 32%;
      width: 160px;
      height: 160px;
      
      animation-delay: 7s;
    }
    
    &:nth-child(8){
      left: 55%;
      width: 20px;
      height: 20px;
      
      animation-delay: 15s;
      animation-duration: 40s;
    }
    
    &:nth-child(9){
      left: 25%;
      width: 10px;
      height: 10px;
      
      animation-delay: 2s;
      animation-duration: 40s;
      // background-color: rgba(255, 255, 255, 0.25);
    }
    
    &:nth-child(10){
      left: 90%;
      width: 160px;
      height: 160px;
      
      animation-delay: 11s;
    }
  }
}
.button__close{
    position: absolute;
    top: 17px;
    left: 100%;
    transform: translate(50%,0px);
    padding: 0!important;
    cursor: pointer;
    min-width: 36px!important;
    height: 36px!important;
    box-shadow: none!important;
    align-items: center;
    background-color: var(--white)!important;
    border-radius: 6px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3)!important;
    @media (max-width:959px){
      transform: translate(-150%,-20%);
    }
    &.is-mobile{
      transform: translate(-150%,-20%);
    }
}
.btn__anim-t{
  
  transition: transform 0.3s;
  @media (any-hover: hover){
    &:hover{
      transform: rotate(-180deg);
    }
  }
}
.auth_container{
  padding: 115px 0 12px 0;
  
  flex: 1 1 auto;
  @media (min-width:959px){
    max-width: 900px;
  }
  @media (max-width:959px){
    padding: 45px 0 0 0;
  }
}
  .reg_card{
    text-align: center;
    margin-top: 5px;
    width: 100%;
    position: relative;
    transition: none !important;
    transition-timing-function: unset !important;
    overflow: visible!important;
    background-color: var(--white)!important;
      &_pc{
        z-index: 201;
        max-width: 400px!important;
        border-radius: 16px!important;
        @media (max-width:959px){
          margin-top: 50px;
        }
    }
    .is-mobile &{
      border-radius: 0px!important;
    }
  }


  .socialBtn__row{
    justify-content: space-between;
    max-width: 70%;
    margin: auto;
  }
  @media (max-width: 1024px) {
    .socialBtn__row{
      max-width: 85%;
    }

  }
  @media (max-width: 500px) {
    .sign_enter_methods{
      margin-bottom: 0px;
    }
    .socialBtn__row{
      max-width: 100%;
    }
  }

</style>

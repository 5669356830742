<template>
    <v-chip-group column class="chip-items">
        <v-chip v-for="(currency, index) in currencies" style="background-color:#f2f6f9!important;"
        :key="index" :value="currency" label class="chip-item" outlined
        @click="toggleSelectedCurrency(currency)">
        <div class="is-selected" :class="{'is-selected_success' : currency.selected}">
          <v-icon color="white">done</v-icon>
        </div>
          {{ currency.text }}
        </v-chip>
      </v-chip-group>
</template>

<script>
import { mapGetters } from 'vuex';
    export default {
        name: 'Currencies',
        data() {
            return {
                holdCurrency: '',
            }
        },
        methods: {
           async toggleSelectedCurrency(currency) {
    this.currencies.forEach(currency => {
      currency.selected = false;
    });
    this.holdCurrency = currency.text;
    currency.selected = true;
    const currencyToSubmit = this.holdCurrency;
    setTimeout(() => {
       currency.selected = false;
       this.$store.commit('currencyY/setCurrencyUpdate', false);
    }, 1000);
    
        
      console.log(this.holdCurrency);
    const [res, error] = await this.$store.dispatch('awaitEmailReg', currencyToSubmit);
    if (res) {
        console.log("Успешно сменил валюту");

      } else if (error) {
        console.log("Успешно НЕ сменил валюту");
      }
},
        },
        computed:{
            ...mapGetters({
                currencies: 'getCurrent',
            })
        }
    }
</script>

<style lang="scss" scoped>
.chip-item { 
    border-radius: 6px!important;
    background: #f2f6f9!important;
    position: relative;
    
    
    .is-selected{
      display: flex;
      justify-content:center;
      align-items: center;
      opacity: 0;
      overflow: hidden;
      background: #00bd71;
      transition: all 0.3s;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      .v-icon{
        font-weight: 600;
      }
      &_success{
          opacity: 1;
          overflow: visible;
        
      }
    }
    
      }

</style>
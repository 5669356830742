<template>
  <v-container style="max-width: 900px;">
    <!-- Description -->
    <!-- <v-row class="title__link-v flex-column"> 
        <v-col class="d-flex align-center justify-center">
            <v-icon color="white" class="mr-1">
              network_locked
            </v-icon>
            <span class="tmb-style_white">{{ $i18n.t('limits') }}</span>
          
        </v-col>    
      
      <v-col class="pa-0">
        <v-divider dark></v-divider>
      </v-col>
    </v-row> -->
    <v-row>
      <!-- Deposit limits -->
    <v-col cols="12" lg="6" sm="6">
      <v-col class="block-limit">
      <v-row class="row_style">
        <v-col>
        {{ $i18n.t('limitsDeposite') }}
        </v-col>
      </v-row>
      <v-row class="row_style">
        <v-col cols="12" class="py-0">
          <v-text-field
              style="margin: 0 auto;"
              :label="$i18n.t('limitsDepositeLabel')"
              type="number"
              v-model="depositLimit"
              class="white-label limit-input"
              color="white"
              :disabled="noTimerDeposit"
              :style="{'opacity' : noTimerDeposit ? '0.7' : '1'}"
          >
          <template v-slot:append>
            <div>{{ user.currency }}</div>
          </template>
        </v-text-field>
        </v-col>
      </v-row>
      
      <v-row class="row_style">
        <v-col cols="12" class="py-0">
          <v-select
              style="margin: 0 auto;"
              :items="timePeriods"
              :label="!noDepositSelect ? $i18n.t('limitsSelect') : $t('limitsSelectError')"
              v-model="selectedDepositTimePeriod"
              class="white-label selectMenuSelfExclusion_select limit-input"
              color="white"
              hide-details
              :error="noDepositSelect"
              :disabled="noTimerDeposit"
              :style="{'opacity' : noTimerDeposit ? '0.7' : '1'}"
              :menu-props="{
                contentClass: 'selectMenuSelfExclusion'
             }"
          ></v-select>
          
        </v-col>
      </v-row>
      <div class="d-flex mt-7 py-0 align-center">
        <div v-if='turnTimerDeposit' class="mr-3 text-uppercase text-left" style="flex: 1 1 auto;color: var(--white);line-height: 1;font-size:14px;">
          
          {{$t('limitsTurnOff')}}
          </div>
          <div v-else class="mr-3 text-uppercase text-left" style="flex: 1 1 auto;color: var(--white);line-height: 1;font-size:14px;">
            {{ $t('limitsTurnOn') }}
            </div>
            <div @click="checkDepositSelect">
          <v-switch 
          v-model="turnTimerDeposit"
          hide-details
      color="green accent-4"
      inset
      class="mt-0 pt-0 custom-switch"
      value=""
      style="margin-right: -10px"
      @change="toggleNoTimerDeposit"
      :disabled="isInputsDepositEmpty"
    ></v-switch>
        </div>
  </div>
</v-col>
    </v-col>
     <!-- Loss limits -->
    <v-col cols="12" lg="6" sm="6">
      <v-col class="block-limit">
      <v-row class="row_style">
        <v-col>
          {{ $i18n.t('limitsLost') }}
          
        </v-col>
      </v-row>
      <v-row class="row_style">
        <v-col cols="12" class="py-0">
          <v-text-field
              style="margin: 0 auto;"
              :label="$i18n.t('limitsLostLimit')"
              type="number"
              v-model="lossLimit"
              class="white-label limit-input"
              color="white"
              :disabled="noTimerLoss"
              :style="{'opacity' : noTimerLoss ? '0.7' : '1'}"
          >
          <template v-slot:append>
            <div>{{ user.currency }}</div>
          </template>
        </v-text-field>
        </v-col>
      </v-row>
      <v-row class="row_style">
        <v-col cols="12" class="py-0">
          <v-select
              style="margin: 0 auto;"
              :items="timePeriods"
              :label="!noLossSelect ? $i18n.t('limitsSelect') : $t('limitsSelectError')"
              v-model="selectedLossTimePeriod"
              hide-details
              class="white-label selectMenuSelfExclusion_select limit-input"
              color="white"
              :error="noLossSelect"
              :menu-props="{
                contentClass: 'selectMenuSelfExclusion'
             }"
             :disabled="noTimerLoss"
              :style="{'opacity' : noTimerLoss ? '0.7' : '1'}"
          ></v-select>
        </v-col>
      </v-row>
      <div class="d-flex mt-7 py-0 align-center">
        <div v-if='turnTimerLoss' class="mr-3 text-uppercase text-left" style="flex: 1 1 auto;color: var(--white);line-height: 1;font-size:14px;">
          
          {{$t('limitsTurnOff')}}
          </div>
          <div v-else class="mr-3 text-uppercase text-left" style="flex: 1 1 auto;color: var(--white);line-height: 1;font-size:14px;">
            {{ $t('limitsTurnOn') }}
            </div>
       <div @click="checkLossSelect">
          <v-switch 
          v-model="turnTimerLoss"
          hide-details
      color="green accent-4"
      inset
      class="mt-0 pt-0 custom-switch"
      value=""
      style="margin-right: -10px"
      @change="toggleNoTimerLoss"
      :disabled="isInputsLossEmpty"
    ></v-switch>
       </div>
  </div>
</v-col>
    </v-col>
     <!-- Wager limits -->
    <v-col cols="12" lg="6" sm="6">
      <v-col class="block-limit">
      <v-row class="row_style">
        <v-col>
          {{ $i18n.t('limitsWager') }}
        </v-col>
      </v-row>
      <v-row class="row_style">
        <v-col cols="12" class="py-0">
          <v-text-field
              style="margin: 0 auto;"
              :label="$i18n.t('limitsWagerLimit')"
              type="number"
              v-model="wagerLimit"
              class="white-label limit-input"
              color="white"
              :disabled="noTimerWager"
              :style="{'opacity' : noTimerWager ? '0.7' : '1'}"
          >
          <template v-slot:append>
            <div>{{ user.currency }}</div>
          </template>
        </v-text-field>
        </v-col>
      </v-row>
     
      <v-row class="row_style">
        <v-col cols="12" class="py-0">
          <v-select
              style="margin: 0 auto;"
              :items="timePeriods"
              :disabled="noTimerWager"
              :label="!noWagerSelect ? $i18n.t('limitsSelect') : $t('limitsSelectError')"
              v-model="selectedWagerTimePeriod"
              class="white-label selectMenuSelfExclusion_select limit-input"
              color="white"
              :error="noWagerSelect"
              hide-details
              :style="{'opacity' : noTimerWager ? '0.7' : '1'}"
              :menu-props="{
                contentClass: 'selectMenuSelfExclusion'
             }"
          ></v-select>
        </v-col>
      </v-row>
      <div class="d-flex mt-7 py-0 align-center">
        <div v-if='turnTimerWager' class="mr-3 text-uppercase text-left" style="flex: 1 1 auto;color: var(--white);line-height: 1;font-size:14px;">
          
          {{$t('limitsTurnOff')}}
          </div>
          <div v-else class="mr-3 text-uppercase text-left" style="flex: 1 1 auto;color: var(--white);line-height: 1;font-size:14px;">
            {{ $t('limitsTurnOn') }}
            </div>
            <div @click="checkWagerSelect">
        <v-switch 
        v-model="turnTimerWager"
        hide-details
    color="green accent-4"
    inset
    
    class="mt-0 pt-0 custom-switch"
    value=""
    style="margin-right: -10px"
    @change="toggleNoTimerWager"
    :disabled="isInputsWagerEmpty"
  ></v-switch>
</div>
  </div>
    </v-col>
  </v-col>

  </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    const user = this.$store.getters.getUserInfo;
    return {
      user: user,
      //========================================================================================================================================================
      
      depositLimit: user && user.balance_limit !== null ? user.balance_limit : '1000000.00',
      lossLimit: user && user.loss_limit !== null ? user.loss_limit : '1000000.00',
      wagerLimit: user && user.wagering_limit !== null ? user.wagering_limit : '1000000.00',
      //========================================================================================================================================================
      
      timePeriods: [this.$t('selfExclusionDay'), this.$t('selfExclusionWeek'), this.$t('selfExclusionMonth')],
      //========================================================================================================================================================
      
      selectedDepositTimePeriod: null,
      selectedLossTimePeriod: null,
      selectedWagerTimePeriod: null,
      
      turnTimerDeposit: user && user.balance_limit !== null ? true : false,
      turnTimerLoss: user && user.loss_limit !== null ? true : false,
      turnTimerWager: user && user.wagering_limit !== null ? true : false,
      //========================================================================================================================================================
      
      noTimerWager: user && user.wagering_limit !== null ? true : false,
      noTimerLoss: user && user.loss_limit !== null ? true : false,
      noTimerDeposit: user && user.balance_limit !== null ? true : false,
      //========================================================================================================================================================
      
      disableTimerLoss: user && user.loss_limit !== null ? true : false,
      disableDepositLoss: user && user.balance_limit !== null ? true : false,
      disableWagerLoss: user && user.wagering_limit !== null ? true : false,
      //========================================================================================================================================================
      
      successWagerUpdate: user && user.wagering_limit !== null ? true : false,
      successLossUpdate: user && user.loss_limit !== null ? true : false,
      successDepositUpdate: user && user.balance_limit !== null ? true : false,
      //========================================================================================================================================================
      
      noWagerSelect: user && user.wagering_limit !== null ? true : false,
      noDepositSelect: user && user.balance_limit !== null ? true : false,
      noLossSelect: user && user.loss_limit !== null ? true : false,
      
    };
  },
  watch: {
    isInputsLossEmpty(newVal) {
      this.disableTimerLoss = newVal;
    },
    isInputsWagerEmpty(newVal) {
      this.disableWagerLoss = newVal;
    },
    isInputsDepositEmpty(newVal) {
      this.disableDepositLoss = newVal;
    },
  },
  computed: {
    isInputsLossEmpty() {
      return !this.selectedLossTimePeriod || !this.lossLimit;
    },
    isInputsWagerEmpty() {
      return !this.selectedWagerTimePeriod || !this.wagerLimit;
    },
    isInputsDepositEmpty() {
      return !this.selectedDepositTimePeriod || !this.depositLimit;
    },

  },
mounted() {
  this.selectedLossTimePeriod = this.calculateSelectedLossTimePeriod();
  this.selectedDepositTimePeriod = this.calculateSelectedDepositTimePeriod();
  this.selectedWagerTimePeriod = this.calculateSelectedWagerTimePeriod();
},
  methods: {
      calculateSelectedLossTimePeriod() {
    const user = this.$store.getters.getUserInfo;

    if (!user || !user.loss_limit_start_date || !user.loss_limit_end_date) {
      return null; // default
    }

    const startDate = new Date(user.loss_limit_start_date.split('T')[0]);
    const endDate = new Date(user.loss_limit_end_date.split('T')[0]);

    // Different in days
    const differenceInDays = Math.floor((endDate - startDate) / (24 * 60 * 60 * 1000));

    if (differenceInDays === 1) {
      return this.$t('selfExclusionDay');
    } else if (differenceInDays <= 7) {
      return this.$t('selfExclusionWeek');
    } else if (differenceInDays <= 30) {
      return this.$t('selfExclusionMonth');
    } else {
      return null; // default
    }
    },
      calculateSelectedDepositTimePeriod() {
    const user = this.$store.getters.getUserInfo;

    if (!user || !user.balance_limit_start_date || !user.balance_limit_end_date) {
      return null; // default
    }

    const startDate = new Date(user.balance_limit_start_date.split('T')[0]);
    const endDate = new Date(user.balance_limit_end_date.split('T')[0]);

    // Different in days
    const differenceInDays = Math.floor((endDate - startDate) / (24 * 60 * 60 * 1000));

    if (differenceInDays === 1) {
      return this.$t('selfExclusionDay');
    } else if (differenceInDays <= 7) {
      return this.$t('selfExclusionWeek');
    } else if (differenceInDays <= 30) {
      return this.$t('selfExclusionMonth');
    } else {
      return null; // default
    }
    },
      calculateSelectedWagerTimePeriod() {
    const user = this.$store.getters.getUserInfo;

    if (!user || !user.wagering_limit_start_date || !user.wagering_limit_end_date) {
      return ''; // default
    }

    const startDate = new Date(user.wagering_limit_start_date.split('T')[0]);
    const endDate = new Date(user.wagering_limit_end_date.split('T')[0]);

    // Different in days
    const differenceInDays = Math.floor((endDate - startDate) / (24 * 60 * 60 * 1000));

    if (differenceInDays === 1) {
      return this.$t('selfExclusionDay');
    } else if (differenceInDays <= 7) {
      return this.$t('selfExclusionWeek');
    } else if (differenceInDays <= 30) {
      return this.$t('selfExclusionMonth');
    } else {
      return null; // default
    }
    },
    //========================================================================================================================================================
    
    checkWagerSelect(){
      if (this.selectedWagerTimePeriod === null) {
        this.noWagerSelect = true;
      } else {
        return;
      }
    },
    checkDepositSelect(){
      if (this.selectedDepositTimePeriod === null) {
        this.noDepositSelect = true;
      } else {
        return;
      }
    },
    checkLossSelect(){
      if (this.selectedLossTimePeriod === null) {
        this.noLossSelect = true;
      } else {
        return;
      }
    },
    //========================================================================================================================================================
    
    getBlockEnd(timePeriod) {
      const currentDate = new Date();

      switch (timePeriod) {
        case 'Day':
          currentDate.setDate(currentDate.getDate() + 1);
          break;
        case 'Week':
          currentDate.setDate(currentDate.getDate() + 7);
          break;
        case 'Month':
          currentDate.setMonth(currentDate.getMonth() + 1);
          break;
        default:
          currentDate.setDate(currentDate.getDate() + 1);
      }


      return [
        currentDate.getFullYear(),
        String(currentDate.getMonth() + 1).padStart(2, '0'),  // JS month is 0-based
        String(currentDate.getDate()).padStart(2, '0')
      ].join('-');
    },
    //========================================================================================================================================================
    
    async toggleNoTimerLoss(){
      
      this.noTimerLoss = !this.noTimerLoss;
      this.noLossSelect = false;
      function formatDateToPythonCompatible(date) {
        if (date === null) return null;

        return [
          date.getFullYear(),
          String(date.getMonth() + 1).padStart(2, '0'), // JS month is 0-based
          String(date.getDate()).padStart(2, '0')
        ].join('-');
      }
      const loss_block_start_date = this.lossLimit ? formatDateToPythonCompatible(new Date()) : null;
      const loss_block_end = this.lossLimit ? this.getBlockEnd(this.selectedLossTimePeriod) : null;
      if(this.noTimerLoss){
        
        //localStorage.setItem('lossLimit', this.lossLimit);
        //localStorage.setItem('selectLossLimit', this.selectedLossTimePeriod);
        //localStorage.setItem('turnTimerLoss', this.turnTimerLoss);
        //localStorage.setItem('noTimerLoss', this.noTimerLoss);
      let loss = {
        loss_limit: parseFloat(this.lossLimit),
        loss_limit_start_date: loss_block_start_date,
        loss_limit_end: loss_block_end,
      };
      this.successLossUpdate = await this.$store.dispatch('selfLimit', loss);
      this.$store.commit('limitsUpdate/setSuccessLimitsUpdate', true)
      this.$store.commit('limitsUpdate/setInfoLimitsUpdate', false);
      setTimeout(() => {
        this.$store.commit('limitsUpdate/setSuccessLimitsUpdate', false);
      }, 1500);
      
      //console.log(wagering)
      //console.log(this.successWagerUpdate);
      //console.log(this.user);
       //setTimeout(() => {
       //  location.reload();
       //}, 1500); 
      } else{
        //localStorage.removeItem('lossLimit', this.lossLimit);
        //localStorage.removeItem('selectLossLimit', this.selectedLossTimePeriod);
        //localStorage.removeItem('turnTimerLoss', this.turnTimerLoss);
        //localStorage.removeItem('noTimerLoss', this.noTimerLoss);
        let loss = {
          loss_limit: null,
          loss_limit_start_date: null,
          loss_limit_end: null,
      };
      this.successLossUpdate = await this.$store.dispatch('selfLimit', loss);
      //this.$store.commit('setSuccessWagerUpdate', false);
      this.$store.commit('limitsUpdate/setInfoLimitsUpdate', true);
      setTimeout(() => {
        this.$store.commit('limitsUpdate/setInfoLimitsUpdate', false);
      }, 1500);
      }
      await this.$store.dispatch('awaitGetUserInfo');
    },
    async toggleNoTimerDeposit(){
      this.noTimerDeposit = !this.noTimerDeposit;
      this.noDepositSelect = false;
      function formatDateToPythonCompatible(date) {
        if (date === null) return null;

        return [
          date.getFullYear(),
          String(date.getMonth() + 1).padStart(2, '0'), // JS month is 0-based
          String(date.getDate()).padStart(2, '0')
        ].join('-');
      }
      const dep_block_end = this.depositLimit ? this.getBlockEnd(this.selectedDepositTimePeriod) : null;
      const dep_block_start_date = this.depositLimit ? formatDateToPythonCompatible(new Date()) : null;
      if(this.noTimerDeposit){
        
        //localStorage.setItem('depositLimit', parseFloat(this.depositLimit));
        //localStorage.setItem('selectDepositLimit', this.selectedDepositTimePeriod);
        //localStorage.setItem('turnTimerDeposit', this.turnTimerDeposit);
        //localStorage.setItem('noTimerDeposit', this.noTimerDeposit);
        
      let deposit = {
        dep_limit: parseFloat(this.depositLimit),
        dep_block_start_date: dep_block_start_date,
        dep_block_end: dep_block_end,
      };
      this.successDepositUpdate = await this.$store.dispatch('selfLimit', deposit);
      this.$store.commit('limitsUpdate/setSuccessLimitsUpdate', true)
      this.$store.commit('limitsUpdate/setInfoLimitsUpdate', false);
      setTimeout(() => {
        this.$store.commit('limitsUpdate/setSuccessLimitsUpdate', false);
      }, 1500);
      
      //console.log(wagering)
      //console.log(this.successWagerUpdate);
      //console.log(this.user);
       //setTimeout(() => {
       //  location.reload();
       //}, 1500); 
      } else{
        //localStorage.removeItem('depositLimit', this.depositLimit);
        //localStorage.removeItem('selectDepositLimit', this.selectedDepositTimePeriod);
        //localStorage.removeItem('turnTimerDeposit', this.turnTimerDeposit);
        //localStorage.removeItem('noTimerDeposit', this.noTimerDeposit);
        let deposit = {
        dep_limit: null,
        dep_block_start_date: null,
        dep_block_end: null,
      };
      this.successDepositUpdate = await this.$store.dispatch('selfLimit', deposit);
      this.$store.commit('limitsUpdate/setSuccessLimitsUpdate', false);
      this.$store.commit('limitsUpdate/setInfoLimitsUpdate', true);
      setTimeout(() => {
        this.$store.commit('limitsUpdate/setInfoLimitsUpdate', false);
      }, 1500);
      }
      await this.$store.dispatch('awaitGetUserInfo');
    },
    async toggleNoTimerWager(){
      this.noTimerWager = !this.noTimerWager;
      this.noWagerSelect = false;
      function formatDateToPythonCompatible(date) {
        if (date === null) return null;

        return [
          date.getFullYear(),
          String(date.getMonth() + 1).padStart(2, '0'), // JS month is 0-based
          String(date.getDate()).padStart(2, '0')
        ].join('-');
      }
      const wager_block_end = this.wagerLimit ? this.getBlockEnd(this.selectedWagerTimePeriod) : null;
      const wager_block_start_date = this.wagerLimit ? formatDateToPythonCompatible(new Date()) : null;
      if(this.noTimerWager){
        //localStorage.setItem('wagerLimit', this.wagerLimit);
        //localStorage.setItem('selectWagerLimit', this.selectedWagerTimePeriod);
        //localStorage.setItem('turnTimerWager', this.turnTimerWager);
        //localStorage.setItem('noTimerWager', this.noTimerWager);
      let wagering = {
        wagering_limit: parseFloat(this.wagerLimit),
        wagering_limit_start_date: wager_block_start_date,
        wagering_limit_end: wager_block_end,
      };
      this.successWagerUpdate = await this.$store.dispatch('selfLimit', wagering);
      this.$store.commit('limitsUpdate/setSuccessLimitsUpdate', true)
      this.$store.commit('limitsUpdate/setInfoLimitsUpdate', false);
      setTimeout(() => {
        this.$store.commit('limitsUpdate/setSuccessLimitsUpdate', false);
      }, 1500);
      
      //console.log(wagering)
      //console.log(this.successWagerUpdate);
      //console.log(this.user);
       //setTimeout(() => {
       //  location.reload();
       //}, 1500); 
      } else{
        //localStorage.removeItem('wagerLimit', this.wagerLimit);
        //localStorage.removeItem('selectWagerLimit', this.selectedWagerTimePeriod);
        //localStorage.removeItem('turnTimerWager', this.turnTimerWager);
        //localStorage.removeItem('noTimerWager', this.noTimerWager);
        let wagering = {
        wagering_limit: null,
        wagering_limit_start_date: null,
        wagering_limit_end: null,
      };
      this.successWagerUpdate = await this.$store.dispatch('selfLimit', wagering);
      this.$store.commit('limitsUpdate/setSuccessLimitsUpdate', false);
      this.$store.commit('limitsUpdate/setInfoLimitsUpdate', true);
      setTimeout(() => {
        this.$store.commit('limitsUpdate/setInfoLimitsUpdate', false);
      }, 1500);
      }
      await this.$store.dispatch('awaitGetUserInfo');
    },
  }
}

</script>

<style lang="scss" scoped>


.block-limit{
  border-radius: 16px;
  background-color: #4a1558;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.selectMenuLimits{
  text-transform: uppercase;
}

.row_style {
  color: var(--white);
  
}
</style>

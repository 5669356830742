<template>
  <v-container class="px-0" style="max-width: 900px">
    <!-- header title&button -->
    <v-row class="">
      <v-col class="d-flex align-center justify-space-between">
        <span
          class="pa-0 text-uppercase title-card"
          style="color: var(--white)"
        >
          {{ $t("bonusesTitle") }}
        </span>
      </v-col>
    </v-row>
    <template
      v-if="depositBonusActive || spinsBonusActive || nextDepositBonusActive"
    >
      <!-- title deposit bonus -->
      <v-row class="align-center">
        <v-col class="py-0">
          <v-card-title
            class="pa-0 mb-n6 text-uppercase title-card"
            style="font-size: 14px; color: var(--white)"
            >{{ $i18n.t("depositBonuses") }}
          </v-card-title>
        </v-col>
      </v-row>
      <!-- deposit bonus slider -->
      <v-row style="overflow: hidden">
        <v-col class="py-10 pr-0">
          <template v-if="depositBonusActive">
            <swiper
              style="height: 450px"
              :slides-per-view="1"
              :breakpoints="{
                '320': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '425': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '600': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '768': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '1024': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
              }"
            >
              <swiper-slide
                class="width-slide"
                v-for="(deposit, index) in arrayDepositBonuses"
                :key="'welcomeCard_' + index"
              >
                <deposit-bonus
                  :index="index"
                  :bonusId="deposit.id"
                  :cardDeposit="deposit"
                >
                </deposit-bonus>
              </swiper-slide>
            </swiper>
          </template>
          <v-col
            v-if="!depositBonusActive"
            class="d-flex flex-column mx-auto"
            style="max-width: 600px"
          >
            <span
              class="mb-3"
              style="color: var(--white); font-size: 22px; font-weight: 800"
            >
              {{ $t("noActivBonuses") }}
            </span>
            <v-btn class="all-buttons-fw" to="/profile/promotions">{{
              $t("selectBonus")
            }}</v-btn>
          </v-col>
        </v-col>
      </v-row>
      <!-- title freespins -->
      <v-row class="align-center">
        <v-col class="py-0">
          <v-card-title
            class="pa-0 mb-n6 text-uppercase title-card"
            style="font-size: 14px; color: var(--white)"
            >{{ $i18n.t("spinsBonuses") }}
          </v-card-title>
        </v-col>
      </v-row>
      <!-- freespins bonus slider -->
      <v-row style="overflow: hidden">
        <v-col class="py-10 pr-0">
          <template v-if="spinsBonusActive">
            <!-- centeredSlides="true" -->
            <swiper
              style="height: 450px"
              :slides-per-view="1"
              :breakpoints="{
                '320': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '425': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '600': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '768': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '1024': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
              }"
            >
              <swiper-slide
                v-for="(spins, index) in arraySpinsBonuses"
                class="width-slide"
                :key="'spinsCard_' + index"
              >
                <spins-bonus
                  :index="index"
                  :bonusId="spins.id"
                  :cardSpins="spins"
                ></spins-bonus>
              </swiper-slide>
            </swiper>
          </template>
          <v-col
            v-else
            class="d-flex flex-column mx-auto"
            style="max-width: 600px"
          >
            <span
              class="mb-3"
              style="color: var(--white); font-size: 22px; font-weight: 800"
            >
              {{ $t("noActivBonuses") }}
            </span>
            <v-btn class="all-buttons-fw" to="/profile/promotions">{{
              $t("selectBonus")
            }}</v-btn>
          </v-col>
        </v-col>
      </v-row>
      <!-- title future  -->
      <v-row class="align-center">
        <v-col cols="12" class="pb-0">
          <v-card-title
            class="pa-0 mb-n6 text-uppercase title-card"
            style="font-size: 14px; color: var(--white)"
            >{{ $i18n.t("nextDepositBonuses") }}
          </v-card-title>
        </v-col>
      </v-row>
      <!-- future slider  -->
      <v-row style="overflow: hidden">
        <v-col class="py-10 pr-0">
          <template v-if="nextDepositBonusActive">
            <swiper
              style="height: 450px"
              :slides-per-view="1"
              :breakpoints="{
                '320': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '425': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '600': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '768': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
                '1024': {
                  slidesPerView: 'auto',
                  spaceBetween: 10,
                },
              }"
            >
              <swiper-slide
                class="width-slide"
                v-for="(futureCard, index) in arrayNextDepositBonuses"
                :key="'futureCard_' + index"
              >
                <!-- <bonus-future-one
                  :index="index"
                  :bonusId="futureCard.id"
                  :cardFuture="futureCard"
                > 
              </bonus-future-one>-->
                <deposit-bonus
                  :index="index"
                  :bonusId="futureCard.id"
                  :cardDeposit="futureCard"
                ></deposit-bonus>
              </swiper-slide>
            </swiper>
          </template>
          <v-col
            v-else
            class="d-flex flex-column mx-auto"
            style="max-width: 600px"
          >
            <span
              class="mb-3"
              style="color: var(--white); font-size: 22px; font-weight: 800"
            >
              {{ $t("noActivBonuses") }}
            </span>
            <v-btn class="all-buttons-fw" to="/profile/promotions">{{
              $t("selectBonus")
            }}</v-btn>
          </v-col>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-col class="d-flex flex-column mx-auto" style="max-width: 600px">
        <span
          class="mb-3"
          style="color: var(--white); font-size: 22px; font-weight: 800"
        >
          {{ $t("noActivBonuses") }}
        </span>
        <v-btn class="all-buttons-fw" to="/profile/promotions">{{
          $t("selectBonus")
        }}</v-btn>
      </v-col>
    </template>
  </v-container>
</template>

<script>
import Preloader from "../../Preloader";
import DepositBonus from "@/components/Profile/Promo/BonusCards/DepositBonus/DepositBonus.vue";
import SpinsBonus from "@/components/Profile/Promo/BonusCards/SpinsBonus/SpinsBonus.vue";
import BonusTournamentOne from "@/components/Profile/Promo/BonusCards/BonusTournamentOne/BonusTournamentOne.vue";
import BonusFutureOne from "@/components/Profile/Promo/BonusCards/BonusFutureOne/BonusFutureOne.vue";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
import { mapGetters } from "vuex";
export default {
  name: "Bonuses",
  components: {
    // Preloader,
    Swiper,
    SwiperSlide,
    DepositBonus,
    // BonusTournamentOne,
    SpinsBonus,
    // BonusFutureOne,
  },

  data() {
    return {
      getBonusDialog: false,
      selectedBonus: {},
      loadingFlag: false,
      errorFlag: false,
      errorText: "",
      isBonusInfo: false,
      //localFlipped: false
      //someBonusActiveFlag: false
      flipped: false,
    };
  },
  async mounted() {
    await this.$store.dispatch("bonuses/getUserBonuses");
  },
  computed: {
    ...mapGetters({
      spinsBonuses: "bonuses/getSpinsBonuses",
      depositBonuses: "bonuses/getDepositBonuses",
    }),
    arraySpinsBonuses() {
      const freeRoundsSpinsBonuses = this.spinsBonuses.filter(
        (bonus) => bonus.type === "free_rounds" && bonus.status !== "created"
      );

      const activeSpinsWelcomeBonuses = this.spinsBonuses.filter(
        (bonus) => bonus.type === "welcome" && bonus.status !== "created"
      );

      freeRoundsSpinsBonuses.sort((a, b) =>
        ((a.status === "active") === b.status) === "active"
          ? 0
          : a.status === "active"
          ? -1
          : 1
      );
      activeSpinsWelcomeBonuses.sort((a, b) =>
        ((a.status === "active") === b.status) === "active"
          ? 0
          : a.status === "active"
          ? -1
          : 1
      );

      //  'id'
      const uniqueFreeRoundsBonuses = freeRoundsSpinsBonuses.filter(
        (freeRoundBonus) =>
          !activeSpinsWelcomeBonuses.some(
            (welcomeBonus) => welcomeBonus.id === freeRoundBonus.id
          )
      );

      if (
        freeRoundsSpinsBonuses.length > 0 &&
        activeSpinsWelcomeBonuses.length > 0
      ) {
        return [...activeSpinsWelcomeBonuses, ...uniqueFreeRoundsBonuses];
      } else if (activeSpinsWelcomeBonuses.length > 0) {
        return activeSpinsWelcomeBonuses;
      } else if (freeRoundsSpinsBonuses.length > 0) {
        return freeRoundsSpinsBonuses;
      } else {
        return [];
      }
    },
    arrayDepositBonuses() {
      return this.depositBonuses.filter(
        (bonus) => bonus.type !== "next_deposit"
      );
    },
    arrayNextDepositBonuses() {
      return this.depositBonuses.filter(
        (bonus) => bonus.type === "next_deposit"
      );
    },
    spinsBonusActive() {
      const activeSpinsBonuses = this.spinsBonuses.some(
        (bonus) => bonus.status !== "created"
      );
      return activeSpinsBonuses;
    },
    depositBonusActive() {
      return this.depositBonuses.some(
        (bonus) => bonus.status !== "created" && bonus.type !== "next_deposit"
      );
    },
    nextDepositBonusActive() {
      return this.arrayNextDepositBonuses.some(
        (bonus) => bonus.status !== "created" && bonus.type === "next_deposit"
      );
    },
  },
  methods: {
    toggleFlip() {
      this.flipped = !this.flipped;
    },
    toggleCardFlip(index) {
      this.cards[index].flipped = !this.cards[index].flipped;
    },
    showBonusInfo() {
      this.isBonusInfo = !this.isBonusInfo;
    },
    getBonus(bonus) {
      this.getBonusDialog = true;
      this.selectedBonus = bonus;
    },
    async moveDeposit() {
      await this.$router.push("/deposit");
    },
    async activateBonus() {
      this.loadingFlag = true;
      let [res, error] = await this.$store.dispatch("activateUserBonus", {
        id: this.selectedBonus.id,
      });

      [this.errorFlag, this.errorText] = [!res, error];
      if (!res) {
        setTimeout(() => {
          this.errorFlag = false;
          this.getBonusDialog = false;
        }, 1500);
      }
      //console.log('[res, error]',this.errorFlag, this.errorText);
      this.loadingFlag = false;
    },
    async cancelBonus() {
      this.loadingFlag = true;
      await this.$store.dispatch("cancelUserBonus");
      this.loadingFlag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//.swiper-slide{
//  max-width: 310px!important;
//
//}
.width-slide {
  width: 310px !important;
}

//========================================================================================================================================================

.title-card {
  @media (max-width: 500px) {
    font-size: 14px !important;
  }

  font-size: 18px !important;
}

.swiper-container {
  height: 100%;
}

.white-heart {
  margin-right: 5px;
}

.bonus {
  &__items {
    flex-wrap: wrap;

    //margin: 0 -15px -20px;
    //flex: 0 1 33.333%;
    //padding: 0 15px;
    @media (min-width: 620px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 870px) {
      grid-template-columns: repeat(3, 1fr);
    }

    display: grid;
    gap: 15px;
  }

  &__item {
    min-width: 250px;
    min-height: 250px;
    position: relative;

    @media (max-width: 992px) {
      flex: 0 1 50%;
    }

    &_info {
      perspective: 1000px;
      transition: transform 0.8s;
      transform-style: preserve-3d;
    }
  }

  &__name-content {
    padding: 12px 12px 0;
  }

  &__name {
  }

  &__title {
    font-size: 25px;
    font-weight: 500;
    padding: 0;
    text-align: left;

    @media (max-width: 992px) {
      font-size: 18px;
    }
  }

  &__quantity {
    padding: 0;

    > div {
      justify-content: flex-end;
    }
  }

  &__subtitle {
    text-align: left;
    display: inline-block;
    padding: 0 12px;

    @media (max-width: 992px) {
      font-size: 12px;
    }
  }

  &__time {
    margin-top: 20px !important;
    padding: 0;
  }

  &__time-content {
    padding: 0;
    background-color: hsla(0, 0%, 100%, 0.05);
    border-radius: 4px;
  }

  &__text {
    text-align: left;
    padding: 12px 20px;
  }

  &__timer {
    text-align: right;
    padding: 12px 20px;
  }

  &__button-t {
  }

  &__button-info-t {
    background-color: hsla(0, 0%, 100%, 0.1) !important;
    border-radius: 50%;
    cursor: pointer;
    height: 26px !important;
    padding: 0 !important;
    margin-left: 20px !important;
    min-width: 26px !important;
    padding-top: 5px !important;
    width: 26px !important;

    svg {
      transform: translate(0px, -2px);
    }
  }
}

//========================================================================================================================================================
.bonus {
  //background: linear-gradient(173.41deg,#0094ff 4.14%,#5607d6 87.72%)!important;
  //background: var(--dark-flamingo)!important;
  background: url("../../../assets/images/bonusCard.jpg") 0 70% / cover
    no-repeat;

  color: var(--white) !important;
  //height: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px !important;

  &__header {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
  }

  &__content {
  }

  &__main {
    flex: 1 1 100%;
    position: relative;
    z-index: 2;
  }

  &__timer {
    position: relative;
    z-index: 2;
  }
}

.bonus-back {
  background: linear-gradient(to right, var(--white), var(--white)) !important;
}

.header-bonus {
  padding: 0px;

  &__label {
    height: 24px;
    justify-content: center;
    padding: 10px;
    position: relative;
    width: 24px;
    align-items: center;
    display: flex;
    position: relative;

    &::before {
      background: hsla(0, 0%, 100%, 0.2);
      border-radius: 50%;
      content: "";
      height: calc(100% - 20px);
      position: absolute;
      width: calc(100% - 20px);
    }
  }

  &__title {
    display: block;
    line-height: 1.5;
    max-width: calc(100% - 68px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
  }

  &_back {
    background-color: var(--dark-flamingo);
  }
}

.main-bonus-back {
  padding: 0;
  padding-bottom: 50px;

  &__list {
    background-color: var(--white) !important;
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: inherit !important;
  }

  &__item {
    line-height: 1.5;
    padding: 10px 20px 9px;
    min-height: auto;

    &:nth-child(even) {
      background: #f2f6f9 !important;
    }
  }

  &__title {
    color: var(--gray-text) !important;
    margin-right: 10px;
  }

  &__text {
    font-weight: 600;
    margin-left: auto;
    text-align: right;
    color: var(--black) !important;
  }
}

.main-bonus {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;

  &__image {
  }

  &__title {
    text-transform: uppercase;
    font-weight: 900;
    color: var(--white) !important;
    line-height: 1;

    span {
      font-size: 75px;
    }

    p {
      font-size: 30px;
    }
  }

  &__activate {
    position: relative;
    z-index: 2;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 16px !important;
    width: 100%;
  }
}

.timer-bonus {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;

  &__icon {
  }

  &__text {
    font-weight: 700;
    color: var(--white);
  }
}

.bonus {
  &__header {
  }

  &__main {
  }
}

//========================================================================================================================================================

.button-info-column-t {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.bonus {
}

.bonus_card {
  //background: linear-gradient(96.43deg, #3B2E67 0%, #332B50 100%), var(--light-blue);
  background: linear-gradient(#1c2240, #1c2240) padding-box,
    linear-gradient(180deg, #4d5a85, rgba(70, 82, 121, 0)) border-box;
  min-height: 100%;
  border-radius: 10px;
  display: flex;
  border: solid 1px var(--red);
  min-height: 350px;
  position: relative;
  transition: transform 0.3s;
  perspective: 1000px;

  .bonus__item_info & {
    opacity: 0;
    visibility: hidden;
  }
}

.bonus_back {
  position: absolute;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  top: 0;
  left: 0;
  transition: transform 0.3s;
  transform: rotateY(180deg);

  .bonus__item_info & {
    opacity: 1;
    visibility: visible;
  }

  &__content {
    transition: transform 0.3s;
  }
}

.bonus__button-t {
  width: 100%;
  font-size: 14px !important;
  //min-height: 40px !important;
  //padding: 0 15px !important;
  //font-weight: 400 !important;
  //
  //border-radius: 5px;
  //
  //@media (max-width:992px) {
  //  min-height: 40px !important;
  //}
  //
  //@media (max-width:600px) {
  //  padding: 0 5px !important;
  //  font-size: 12px !important;
  //}
  //
  //font-style: normal;
  //line-height: 17px;
  //background-color: var(--red) !important;
  //color: var(--white) !important;
  //transition: box-shadow 0.15s ease,
  //transform 0.15s ease;
  //box-shadow: 0px 0.01em 0.01em rgb(45 35 66 / 100%),
  //0px 0.3em 0.7em -0.01em rgb(45 35 66 / 100%),
  //inset 0px -0.01em 0px rgb(58 65 111 / 100%) !important;
  //
  //&:active {
  //  box-shadow: inset 0px 0.1em 0.6em #3c4fe0 !important;
  //  transform: translateY(0em);
  //}
  //
  //@media (any-hover: hover) {
  //  &:hover {
  //    box-shadow: 0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #3c4fe0 !important;
  //    transform: translateY(-0.1em);
  //  }
  //}
}

.bonus_col {
  color: var(--white);
  font-size: 20px;
  padding-top: 20px;
  border: solid 1px var(--white);
  border-radius: 5px;
  margin-top: 15px;
}

.card_col {
}

@media (max-width: 500px) {
  .card_col {
    min-width: 100%;
    max-width: 100%;
    font-size: 16px;
  }

  .bonus_col {
    font-size: 18px;
  }
}
</style>

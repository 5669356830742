<template>
    <v-card class="login-card"
    :class="$isMobile() ? '_mobile' : ''"
    >
        <v-card-text class="pa-0 login-card__content">
          <img :src="`https://minio.takemybet.pro/ui-style/${logo}`" alt="" class="login-logo">
          <div class="login-text">{{ $t('loginText') }}</div>
        </v-card-text>
      </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
    export default {
        name: 'LoginBannerBonuses',
        methods: {
            toInstant() {
        this.$store.commit("regComponents/setInstantReg", !this.isInstant);
    },
        },
        computed: {
          ...mapGetters({
            logo: 'styles/getLogo',
          }),
            isInstant: {
      get() {
        return this.$store.state.regComponents.isInstantReg;
      },
      set(value) {
        this.$store.commit("regComponents/setInstantReg", value);
      },
    },
            isSwitchInstant: {
      get() {
        return this.$store.state.regComponents.isSwitchInstant;
      },
      set(value) {
        this.$store.commit("regComponents/setSwitchInstant", value);
      },
    },
}
    }
</script>

<style lang="scss" scoped>
.login-card{
    align-items: center;
    background: linear-gradient(
      180deg,
      var(--main-flamingo) 0%,
      var(--red) 100%
    ),
    var(--red);
    background-image: url('../../../assets/images/login-background.png'), linear-gradient(156.92deg, var(--light-flamingo), var(--main-flamingo));
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 16px 0 0 16px !important;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 10px 30px;
    width: 100%;
    &._mobile{
        padding: 15px;
        min-height: auto;
        border-radius: 16px!important;
    }
    &__content{
      ._mobile &{
        
        display: flex;
        align-items: center;
      }
    }
}
.login-logo{
  margin-top: -70px;
    max-width: 150px;
    ._mobile &{
      margin-right: 10px;
      margin-top: 0;
      max-width: 100px;
  }
  }
  .login-text{
    color: var(--white);
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
    max-width: 295px;
    text-align: center;
    ._mobile &{
      font-size: 14px;
      text-align: left;
  }
}
</style>
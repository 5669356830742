//levels.js;
import axios from "axios";
const state = {
  progressValue: "",
  nextBonusAmount: "",
  betAmount: "",
  lvlItems: [],
  //   nextBonusAmount: 0,
  //   betAmount: 0,к
};

const mutations = {
  SET_LVL_ITEMS(state, lvlItems) {
    state.lvlItems = lvlItems;
  },
  FLIP_CARD(state, index) {
    state.lvlItems[index].flip = !state.lvlItems[index].flip;
  },
  CLOSE_COMPLETED_CARD(state, index) {
    state.lvlItems[index].flip = false;
  },
  SET_NEXT_BONUS_AMOUNT(state, nextBonusAmount) {
    state.nextBonusAmount = nextBonusAmount;
  },
  SET_BET_AMOUNT(state, betAmount) {
    state.betAmount = betAmount;
  },
  setProgressValue(state, value) {
    state.progressValue = value;
  },
};

const getters = {
  progressValue: (state) => {
    if (state.nextBonusAmount > 0 && state.betAmount != null) {
      return (state.betAmount / state.nextBonusAmount) * 100;
    }
    return 0;
  },
};

const actions = {
  async fetchLevelData({ commit, rootGetters }, currentLevel) {
    try {
      const userCurrency = rootGetters["getUserInfo"].currency;
      const response = await axios.get("/api/levels", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      const lvlItems = response.data
        .map((level) => ({
          ...level,
          next_level_amount: level.next_level_amount[userCurrency],
          showIcon: level.name === currentLevel,
          flip: false,
        }))
        .filter((level) => typeof level.next_level_amount !== "undefined");
      commit("SET_LVL_ITEMS", lvlItems);
      const nextLevel = Number(currentLevel) + 1;
      const nextLevelData = lvlItems.find(
        (level) => level.name === String(nextLevel)
      );

      // filter next_level_amount by user currency
      const nextBonusAmount = nextLevelData
        ? nextLevelData.next_level_amount
        : 0;
      commit("SET_NEXT_BONUS_AMOUNT", nextBonusAmount);
    } catch (error) {
      console.error("Error fetching level data:", error);
    }
  },
  async fetchBetAmount({ commit }, userId) {
    try {
      const response = await axios.get(
        `/stat-api/game_sessions/sum_bet_by_user_id/${userId}`
      );
      const betAmount = response.data;
      // console.log(betAmount);

      commit("SET_BET_AMOUNT", betAmount);
    } catch (error) {
      console.error("Error fetching bet amount:", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

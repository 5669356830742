// limitsUpdate.js
const state = {
  successLimitsUpdate: false,
  infoLimitsUpdate: false,
  depositLimitError: false,
};

const mutations = {
  setSuccessLimitsUpdate(state, value) {
    state.successLimitsUpdate = value;
  },
  setInfoLimitsUpdate(state, value) {
    state.infoLimitsUpdate = value;
  },
  setDepositLimitError(state, value) {
    state.depositLimitError = value;
  },
};

const getters = {};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

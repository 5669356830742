<template>
    <div>
        <v-dialog
        class=""
        no-click-animation
        persistent
        max-width="420"
        v-model="regExit"
        content-class="br-16"
      >
        <v-card class="close-dialog-reg">
        <v-card-text>
            <h2 class="close-dialog-reg__title">{{ $t('sureEndReg') }}</h2>
            <span class="close-dialog-reg__text">{{ $t('sureEndRegText') }}</span>
        </v-card-text>
        <v-card-actions>
            <div class="d-flex flex-column fw align-center">
                <v-btn @click="returtToReg" class="all-buttons-fw">{{$t('toNext')}}</v-btn>
                <button type="button" class="mt-3 close-dialog-reg__close">
                <span class="" @click="returnToLobby">{{$t('toClose')}}</span>
            </button>
            </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
    </div>
</template>

<script>
    export default {
        name: 'RegExit',
        computed:{
            regExit: {
      get() {
        return this.$store.state.regComponents.regExitDialog;
      },
      set(value) {
        this.$store.commit("regComponents/setRegExitDialog", value);
      },
    },
        },
        methods:{
            returtToReg(){
                this.$store.commit("regComponents/setRegExitDialog", false);
            },
            returnToLobby(){
                this.$router.push('/');
        },
    }
    }
</script>

<style lang="scss" scoped>
.close-dialog-reg {
    padding: 20px;
    &__title {
        color: var(--black);
        font-size: 18px;
        font-weight: 700;
        margin: 0 0 10px;
        text-align: center;
    }

    &__text {
        color: #585d6d;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        margin: 0 0 15px;
        text-align: center;
    }
    &__close{
        color: var(--gray-text);
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        @media (any-hover: hover){
            &:hover{
                text-decoration-line: underline;
            }
        }
    }
}

</style>
<template>
  <v-container style="max-width: 600px">
    <!-- Title -->
    <!-- <v-row class="title__link-v flex-column"> 
      <v-col class="d-flex align-center justify-center">
          <v-icon color="white" style="margin-right: 3px;">
            alarm_on
          </v-icon>
          <span style="color: var(--white);">{{ $i18n.t('realityCheckTitle') }}</span>
        
      </v-col>    
    
      <v-col class="pa-0">
        <v-divider dark></v-divider>
      </v-col>
    </v-row> -->
    <!-- Description -->
    <v-row>
      <v-col>
        <span style="color: var(--white);">{{ $i18n.t('realityCheck') }} </span>
      </v-col>
    </v-row>
    <!-- Time Chips -->
    <v-row>
    <v-col class="py-0">
      <v-chip-group 
      column
      class="chip-items-reality">
        <v-chip v-for="(getTime, index) in getTimes"
        :key="index" 
        :disabled="noTimer"
        label 
        color="var(--white)"
        class="all-buttons-t chip-items__item mb-3" 
        @click="selectTime(getTime.text)"
        style="background-color: var(--red)!important;"
        >
          {{ getTime.text }}
        </v-chip>
      </v-chip-group>
    </v-col>
  </v-row>
    <!-- Time Input -->
    <v-row>
      <!-- :error-messages="$i18n.t('sessionTimeErrors')" -->
      <v-col>
        <v-form ref="form">
          <v-text-field
              :label="$i18n.t('realityLabel')" 
              v-model="maxSessionTime"
              hide-details="auto"
              :disabled="noTimer"
              @input="validateSessionTime"
              :rules="[v => v > 0 || $t('min_time')]"
              required
              outlined
              :error="noValueInput"
              class="white-label"
              type="number"
              color="white"
              :style="{'opacity' : noTimer ? '0.6' : '1'}"
              min="1"
          ></v-text-field>
        </v-form>
      </v-col>
      
    </v-row>
    <!-- Time left -->
    <!-- <v-row v-if='turnTimer'>
      <v-col class="d-flex align-center justify-center pa-0">
        <v-icon color="var(--light-flamingo)">mdi-timer-sand</v-icon>
          <span class="tmb-style_white text-uppercase">
            {{ `${$t('timeLeft')}: ` }} <Countdown :expireInSeconds="remainingTimeInSeconds" />
          </span>
      </v-col>    
    </v-row> -->
    <!-- Save Changes Button -->
    <v-row>
      <v-col class="d-flex align-center justify-center">
          <div v-if='turnTimer' class="mr-3 text-uppercase" style="color: var(--white);line-height: 1;font-size:14px;">
            
            {{$t('realityCheckTurnOffTimer')}}
            </div>
            <div v-else class="mr-3 text-uppercase" style="color: var(--white);line-height: 1;font-size:14px;">
              {{ $t('realityCheckTurnOnTimer') }}
              </div>
          <div @click="checkValueInput">
            <v-switch 
            v-model="turnTimer"
            hide-details
        color="green accent-4"
        inset
        class="mt-0 pt-0 custom-switch"
        value=""
        :disabled="inputIsEmpty"
        style=""
        @change="toggleNoTimer"
      ></v-switch>
          </div>
      </v-col>    
    </v-row>
  </v-container>
</template>


<script>
import {startTimer, validateSessionTime, stopTimer  } from '../../../timerWorker';
import Countdown from '@/components/Countdown/Timer'
export default {
  components:{
    //Countdown,
  },
  data() {
    const user = this.$store.getters.getUserInfo;
    return {
      user: user,
      timer: null,
      //maxSessionTime: 0, // store inputted max time
      maxSessionTime: user && user.time_limit !== null ? Math.ceil(user.time_limit / 60) : '', // store inputted max time
      sessionTimeErrors: [], // store validation errors
      timerStarted: user && user.time_limit !== null ? true : false, // track if timer started
      //turnTimer: localStorage.getItem('turnTimer'),
      turnTimer: user && user.time_limit !== null ? true : false,
      noTimer: user && user.time_limit !== null ? true : false,
      noValueInput: user && user.time_limit !== null ? true : false,
    };
  },
  created() {
    this.getTimes = [
    {
      text: '10 ' + this.$t('mins'),
    },
    {
      text: '20 ' + this.$t('mins'),
    },
    {
      text: '30 ' + this.$t('mins'),
    },
    {
      text: '60 ' + this.$t('mins'),
    },
    {
      text: '90 ' + this.$t('mins'),
    },
    ];
  },
  computed: {
    inputIsEmpty() {
      return !this.maxSessionTime; 
    },
    
  },
  methods: {
    checkValueInput(){
      if (this.maxSessionTime === '') {
        this.noValueInput = true;
      } else {
        return;
      }
    },
    selectTime(value) {
      this.$refs.form.resetValidation();
      this.maxSessionTime = value.replace(' ' + this.$t('mins'), '');
    },
    async toggleNoTimer(){
      this.$refs.form.resetValidation();
      this.noTimer = !this.noTimer;
      if (this.turnTimer) {
    
      const holdTime = this.maxSessionTime * 60;
      let notification
        const [res, err] = await this.$store.dispatch('updateUserInfo', { time_limit: holdTime })
        if (res) {
          startTimer(holdTime);
          notification = {
            type: "success",
            message: this.$t('realitySuccess'),
            };
        } else{
          this.noTimer = false;
            this.turnTimer = false;
        notification = {
          type: "error",
          message: this.$t('justError'),
        }
      }
      this.$store.dispatch("alerts/add", notification);
      
  } else {
      localStorage.removeItem("endTime");
      localStorage.removeItem("remainingTimeInSeconds");
      let notification
        const [res, err] = await this.$store.dispatch('updateUserInfo', { time_limit: null })
        if (res) {
          this.noValueInput = false;
          stopTimer();
        notification = {
          type: "info",
          message: this.$t('realityInfo'),
        };
        } else{
          this.turnTimer = true;
          notification = {
            type: "error",
            message: this.$t('justError'),
          };
        }
      this.$store.dispatch("alerts/add", notification);
  }
    await this.$store.dispatch('awaitGetUserInfo');
    },
    validateSessionTime() {
  if (this.maxSessionTime === '0') {
    this.maxSessionTime = '';
    return;
  }

  const errors = validateSessionTime(this.maxSessionTime);

  if (errors.length === 0) {
    //localStorage.setItem('maxSessionTime', this.maxSessionTime);
  }

  this.sessionTimeErrors = errors;
},
    // startTimer() {
    //   if (validateSessionTime(this.maxSessionTime).length > 0) {
    //     this.validateSessionTime();
    //     return;
    //   }
    //   this.timerStarted = true;
    //   startTimer(this.maxSessionTime);
    // }
  },
};
</script>


<style lang="scss" scoped>
.chip-items-reality {

  &__item {
  }
}


.info__btn {
  cursor: pointer;
  //background: url('../../../assets/images/more_games_bg.png');
  //background-size: cover;
  background-color: var(--red) !important;
  color: var(--white) !important;
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  box-shadow: 0px 0.01em 0.01em rgb(45 35 66 / 100%), 0px 0.3em 0.7em -0.01em rgb(45 35 66 / 100%), inset 0px -0.01em 0px rgb(58 65 111 / 100%)!important;
  &:active {
    box-shadow: inset 0px 0.1em 0.6em #3c4fe0!important;
    transform: translateY(0em);
  }
  @media (any-hover: hover){
    &:hover{
      box-shadow: 0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #3c4fe0!important;
    transform: translateY(-0.1em);
    }
  }
  width: auto;
  flex: 0 0 auto!important;
  display: inline-flex;
  justify-content:center;
  align-items: center;
  cursor: pointer;
  min-height: 48px !important;
  padding: 0 15px!important;
  font-weight: 400;
  font-size: 14px!important;
  border-radius: 5px;
  @media (max-width:992px){
    min-height: 40px !important;
  }
  @media (max-width:600px){
    padding: 0 5px!important;
    font-size: 12px!important;
  }
}

::v-deep .v-label,
::v-deep .v-select__selection {
  color: white !important;
}
</style>
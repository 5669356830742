// regComponents.js
const state = {
  // header

  //========================================================================================================================================================
  // Dialog
  regDialog: true,
  loginDialog: true,
  regInstantDialog: false,
  regUserDialog: false,
  regExitDialog: false,
  //========================================================================================================================================================
  // Rules
  isRulesModal: false,
  //========================================================================================================================================================

  // Titles
  isUsernameReg: false,
  isPassReg: false,
  isCurrencyReg: false,
  isRulesReg: false,
  isAvaReg: false,
  isExitReg: false,
  //========================================================================================================================================================
  // Value
  emailValueReg: "",
  usernameValueReg: "",
  passwordValueReg: "",
  currencyValueReg: "",
  //========================================================================================================================================================
  // View
  regAfterEmail: false,
  //========================================================================================================================================================
  // Instant
  isInstantReg: false,
  isSwitchInstant: true,
  //========================================================================================================================================================

  // Ava
  selectedImageIndex: -1,
  successAvaUpdate: false,
};

const mutations = {
  // Ava
  setSelectedImageIndex(state, index) {
    state.selectedImageIndex = index;
  },
  setSuccessAvaUpdate(state, value) {
    state.successAvaUpdate = value;
  },
  //========================================================================================================================================================

  // Dialog
  setRegDialog(state, value) {
    state.regDialog = value;
  },
  setLoginDialog(state, value) {
    state.loginDialog = value;
  },
  setRegUserDialog(state, value) {
    state.regUserDialog = value;
  },
  setRegInstantDialog(state, value) {
    state.regInstantDialog = value;
  },

  setRegExitDialog(state, value) {
    state.regExitDialog = value;
  },
  //========================================================================================================================================================
  // Rules
  setRulesModal(state, value) {
    state.isRulesModal = value;
  },

  //========================================================================================================================================================
  // Titles
  setUsernameReg(state, value) {
    state.isUsernameReg = value;
  },
  setPassReg(state, value) {
    state.isPassReg = value;
  },
  setCurrencyReg(state, value) {
    state.isCurrencyReg = value;
  },
  setRulesReg(state, value) {
    state.isRulesReg = value;
  },
  setAvaReg(state, value) {
    state.isAvaReg = value;
  },
  setExitReg(state, value) {
    state.isExitReg = value;
  },
  //========================================================================================================================================================
  // Value
  setEmailValueReg(state, value) {
    state.emailValueReg = value;
  },
  setUsernameValueReg(state, value) {
    state.usernameValueReg = value;
  },
  setPasswordValueReg(state, value) {
    state.passwordValueReg = value;
  },
  setCurrencyValueReg(state, value) {
    state.currencyValueReg = value;
  },
  //========================================================================================================================================================
  // View
  setRegAfterEmail(state, value) {
    state.regAfterEmail = value;
  },
  //========================================================================================================================================================
  // Instant
  setInstantReg(state, value) {
    state.isInstantReg = value;
  },
  setSwitchInstant(state, value) {
    state.isSwitchInstant = value;
  },
  //========================================================================================================================================================
};

const getters = {};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

<template>
    <div>
    <section class="container-to-color container-to-color_category-filter">
        <div class="container-mod">
            <div class="category">
                <div class="category__content">
                    <div class="side-gradient-left side-gradient-left_dark"></div>
                <ul class="category__list" 
                ref="scrollContainer"
                @touchstart="startDrag"
                @touchend="stopDrag"
                @touchmove="drag"
                @mousedown="startDrag"
                @mouseleave="stopDrag"
                @mouseup="stopDrag"
                @mousemove="drag"
                >
                
                    <li class="category__item item-category" v-for="(category, index) in categoryList" :key="index" @click="openOneOfFilter(category.link)">
                        <v-icon  class="item-category__icon">{{ category.icon }}</v-icon>
                        <span class="item-category__name">{{ category.name }}</span>
                    </li>
                </ul>
                <div class="side-gradient-right side-gradient-right_dark"></div>
            </div>
                <v-spacer></v-spacer>
                <div class="category__menu">
                    <v-btn class="category__button all-buttons-t" @click="goToProviders">
                        {{$t('providers')}}
                    </v-btn>
                    <v-btn class="category__customize all-buttons-t" @click="randomSlot">
                        <v-icon class="mr-1">mdi-clover</v-icon>
                        {{$t('randomSlot')}}
                    </v-btn>
                </div>
            </div>
        </div>
        <v-dialog
            v-model="customizeCategory"
            scrollable 
            :fullscreen="$isMobile() ? true : false"
            max-width="500px"
        >
            
        </v-dialog>
    </section>
    <section class="container-to-color container-to-color_bottom-menu">
        <div class="container-mod container-mod_category-filter">
            <div class="category__bottom-menu">
                <v-btn class="category__button all-buttons-t" @click="goToProviders">
                    {{$t('providers')}}
                </v-btn>
                <v-btn class="category__customize all-buttons-t" @click="randomSlot">
                    <v-icon class="mr-1">mdi-clover</v-icon>
                    {{$t('randomSlot')}}
                </v-btn>
            </div>
        </div>
       
    </section>
    <v-dialog
    v-model="customizeCategory"
    scrollable 
    :fullscreen="$isMobile() ? true : false"
    max-width="500px"
>
    
</v-dialog>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
    export default {
        name: 'CategoryFilter',
        data(){
            return{
                customizeCategory: false,
                categoryList: [
                    {
                        needAuth: true,
                        icon: 'mdi-soccer',
                        name: this.$t('sport'),
                        link: 'Sport',
                    },
                    {   
                        icon: 'mdi-slot-machine',
                        name: this.$t('slots'),
                        link: 'SLOT',
                    },
                    // {
                    //     icon: 'mdi-thumb-up',
                    //     name: this.$t('top'),
                    //     link: 'top',
                    // },
                    {
                        icon: 'mdi-poker-chip',
                        name: this.$t('roulette'),
                        link: 'ROULETTE',
                    },
                    {
                        icon: 'mdi-account-group-outline',
                        name: this.$t('liveDealers'),
                        link: 'LIVE_CASINO',
                    },
                   
                ],
                isDragging: false,
                startX: 0,
                scrollLeft: 0,
            }
        },
        computed:{
        ...mapGetters({
          user: 'getUserInfo',
        }),
        filteredCategories() {
      return this.categoryList.filter(category => {
        return (!category.needAuth || (category.needAuth && this.user.id !== null));
      });
    }
    },
        methods:{
            goToProviders(){
                this.$router.push({
                        name: 'GridBlock',
                        params: {
                          type: `game-provider`,
                          info: 'all',
                        },
                        }
                        )
            },
            async randomSlot(){
                if (!this.user.id) {
                     try {
                       await this.$router.push('/reg')
                   } catch (error) {
                  if (error.name !== 'NavigationDuplicated') {
                   throw error; 
                 }
         }
            } else {
                if (this.user.balance == 0) {
                  try {
                    await this.$router.push('/deposit');
              } catch (error) {
             if (error.name !== 'NavigationDuplicated') {
              throw error; 
            }
        }
              } else {
                await this.$router.push({ name: 'Game', params: { random: true,  } });
              }
            }
            },
            async openOneOfFilter(link){
                if (link === 'YeeBet') {
                    this.$router.push({
                        name: 'GridBlock',
                        params: {
                          type: `game-provider`,
                          info: `yeebet`,
                        },
                        }
                        )
                } else if(link === 'Sport'){
                    if (!this.user.id) {
                     try {
                       await this.$router.push('/reg')
                   } catch (error) {
                  if (error.name !== 'NavigationDuplicated') {
                   throw error; 
                 }
         }
            } else {
                if (this.user.balance === 0) {
                  try {
                    await this.$router.push('/deposit');
              } catch (error) {
             if (error.name !== 'NavigationDuplicated') {
              throw error; 
            }
        }
              } else {
                this.$router.push({
                    name: 'Sport',
                    }
                    )
              }
            }
                } else{
                    this.$router.push({
                        name: 'GridBlock',
                        params: {
                          type: `games`,
                          info: `${link}`,
                        },
                        }
                        )
                }
            },
            startDrag(e) {
              this.isDragging = true;
              this.startX = e.type === 'touchstart' ? e.touches[0].pageX : e.pageX - this.$refs.scrollContainer.offsetLeft;
              this.scrollLeft = this.$refs.scrollContainer.scrollLeft;
            },
            stopDrag() {
              this.isDragging = false;
            },
            drag(e) {
              if (!this.isDragging) return;
              e.preventDefault();
              const x = e.type === 'touchmove' ? e.touches[0].pageX : e.pageX - this.$refs.scrollContainer.offsetLeft;
              const walk = (x - this.startX) * 1; // Настройте скорость скролла по вашему усмотрению
              this.$refs.scrollContainer.scrollLeft = this.scrollLeft - walk;
            },
        },
    }
</script>

<style lang="scss" scoped>
ul, li >*{
    list-style: none;
    padding: 0!important;
}
.container-to-color{
    &_category-filter{
        background-color: var(--dark-flamingo);
        padding: 0!important;
        position: relative;
        z-index: 2;
    }
    &_bottom-menu{
        background-color: var(--main-flamingo);
        padding: 0!important;
        padding-top: 1px!important;
    }
}

.category {
    display: flex;
        align-items: center;
    &__content{
        display: flex;
        align-items: center;
        position: relative;
        flex: 1 1 auto;
        overflow: hidden;
    }
		&__list {
            display: flex;
            align-items: center;
            overflow: hidden;
            padding-left: 5px!important;
		}

		&__item {
		}

		&__menu {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-left: 120px;
            @media (max-width:992px){
                display: none;
             }
		}
        &__bottom-menu{
            margin-top: 15px;
            @media (min-width:992px){
                display: none;
             }
            @media (max-width:992px){
                display: flex;
                align-items: center;
             }
        }
		&__button {
            @media (max-width:992px){
                flex: 0 1 50%;
             }
		}

		&__customize {
            margin-left: 8px;
            @media (max-width:992px){
               flex: 0 1 50%;
            }
		}
}
.item-category {
    cursor: pointer;
    align-items: center;
    border-bottom: 3px solid transparent;
    color: var(--white);
    display: flex;
    font-size: 14px;
    font-weight: 400;
    height: 64px;
    line-height: 64px;
    margin: 0 35px 0 0;
    -webkit-text-decoration: none;
    text-decoration: none;
    text-transform: none;
    transition: all .25s ease;
    @media (any-hover: hover){
        &:hover{
            color: var(--red);
            border-color: var(--red);
            .item-category__icon{
                color: var(--red)!important;
            }
        }
        
    }
		&__icon {
            display: flex;
            justify-content:center;align-items: center;
            margin-right: 8px;
            height: 24px;
            width: 24px;
            transition: all .25s ease;
            color: var(--white)!important;
            fill: var(--white);
            img{
                max-width: 100%;
                max-height: 100%;
            }
           
		}

		&__name {
            font-size: 14px;
            white-space: nowrap;
		}
}

</style>
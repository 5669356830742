<template>
    <div>
      <v-dialog class="dialog-limit" persistent v-model="depositWagerError" content-class="limit-popup"
        :max-width="dialogMaxWidth">
        <v-card class="limit">
  
          <v-card-title class="limit__header">
            {{ $t('limitsDepositeError') }}
          </v-card-title>
          <div class="limit__flex">
            <div class="limit__image">
              <img src="@/assets/images/limitExceed.jpg" alt="">
            </div>
            <div class="limit__content mt-2">
              <v-card-text class="pb-1 limit__text-top">
                <p class="mb-0"> {{ $t('limitsWagerErrorText') }} <b>{{ parseFloat(limitQuantity) }} {{
                  this.$store.getters.getUserInfo.currency }} </b> {{ $t('limitsDepositeErrorTextReached') }}</p>
                <span> </span>
              </v-card-text>
              <v-card-actions class="limit__buttons d-flex align-center justify-center">
                <v-btn @click="okayButton" class="all-buttons-t limit__continue">{{ $t('toNext') }}</v-btn>
              </v-card-actions>
              <v-card-text class="limit__text-bottom d-flex justify-center">
                <span>{{ $t('realityCheckChangeText') }}</span> <a @click="redirectToSettings" class="limit__link">{{
                  $t('realityCheckChangeLink') }}</a>
              </v-card-text>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LimitError',
    data() {
      return {
        dialogMaxWidth: "625",
        depositWagerError: false,
      }
    },
    methods: {
      okayButton() {
        this.$store.commit('setWagerLimitError', false);
        console.log(this.depositLimitError);
  
      },
      async redirectToSettings() {
        try {
          await this.$router.push('/profile/responsible-gambling/limits');
        } catch (error) {
          if (error.name !== 'NavigationDuplicated') {
            throw error;
          }
        }
        this.$store.commit('setWagerLimitError', false);
        this.depositWagerError = false;
      },
    },
    computed: {
      limitQuantity() {
        return localStorage.getItem('wagerLimit')
      },
    //  depositLimitError: {
    //    get() {
    //      return this.$store.state.depositLimitError;
    //    },
    //    set(value) {
    //      this.$store.commit('setDepositLimitError', value);
    //    },
    //  },
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .limit {
    max-width: 400px;
  
    @media (max-height: 500px) and (orientation: landscape) {
      max-width: 625px;
    }
  
    color: var(--white) !important;
  
    &__flex {
      @media (max-height: 500px) and (orientation: landscape) {
        display: flex;
      }
    }
  
    &__image {
      max-width: 100%;
      height: 200px;
  
      @media (max-height: 500px) and (orientation: landscape) {
        width: 370px;
        display: flex;
      }
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  
    &__header {
      background-color: var(--dark-flamingo);
      justify-content: center;
      align-items: center;
      font-size: 14px !important;
      padding: 12px 16px !important;
      word-break: unset;
      //margin-bottom: 10px;
    }
  
    &__content {
      @media (max-height: 500px) and (orientation: landscape) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  
    &__text-top {
      padding-top: 5px !important;
      padding-bottom: 10px !important;
  
      @media (max-height: 500px) and (orientation: landscape) {
        padding-bottom: 3px !important;
        padding-top: 3px !important;
      }
  
      color: var(--black) !important;
  
      span {
        font-weight: 700;
      }
  
      @media (max-height: 500px) and (orientation: landscape) {
        p {
          margin-bottom: 0;
        }
      }
    }
  
    &__buttons {
      @media (max-height: 500px) and (orientation: landscape) {
        padding-bottom: 3px !important;
        padding-top: 3px !important;
      }
  
      button {
        min-width: 120px !important;
      }
    }
  
    &__continue {}
  
    &__logout {
      margin-left: 20px !important;
    }
  
    &__text-bottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--black) !important;
      padding: 10px 10px 20px !important;
      font-size: 12px !important;
  
      @media (max-height: 500px) and (orientation: landscape) {
        padding: 3px 10px !important;
      }
    }
  
    &__link {
      color: var(--main-flamingo);
    }
  }</style>
<template>
    <div>
      <v-alert
      dense
      outlined
      type="error"
    >
      <div class="d-flex justify-center fz-20 text-uppercase">
        {{ $t(textError) }}
      </div>
      <template v-slot:prepend>
        <slot name="icon"></slot>
      </template>
    </v-alert>
      <v-btn @click="toRefreshRequest" :disabled="loadingFlag" :loading="loadingFlag"
      class="mt-1 all-buttons-t" >
      {{ $t('refresh') }}
                   </v-btn>
    </div>
</template>

<script>

    export default {
        name: 'NoWorkPage',
        props:{
            loadingFlag:{
                type: Boolean,
                required: true,
            },
            textError: {
                type: String,
                required: true,
            },
        },
        methods: {
            toRefreshRequest(){
                this.$emit('refresh-request')
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
<template>
  <transition name="fade">
    <div class="home" style=""
      >
      <BannersSlider /> <!--v-if="$store.getters.getUserInfo.id === null" -->
      <CategoryFilter/>
      <div class="container-to-color">
        <div class="container-mod" >
          <div style="position: relative;z-index:2">
          <!-- <SliderProviders></SliderProviders> -->
          <!-- <CasinoGames></CasinoGames> -->
        </div>
        </div>
        <ul class="bg-bubbles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul> 
      </div>
      <section class="container-to-color game-blocks" 
      :class="{ '_first' : index === 0, '_no-games' : block.games && block.games.length === 0}"
      v-for="(block, index) in sortedGameBlocks"
      :key="'game_' + index"
      v-intersect="createIntersectHandler(block)"
      >
        <div class="container-mod" >
          <component :is="'GamesSection'" 
          :loadingFlag="block.loadingBlock"
          :block_id="block.id" 
          :games="block.games" 
          :block_name="block.name">

          </component>
        </div>
        
      </section>
        <section class="container-to-color game-blocks" 
        :class="{ '_provider' : block.component === 'ProviderSection', '_first' : index === 0 }"
        v-for="(block, index) in blocks" :key="index">
          <div class="container-mod">
            <component :is="block.component" 
            :category="block.category" 
            :isType="block.isType"
            >
  
            </component>
          </div>
          
        </section>
    </div>
  </transition>
</template>

<script>
import BannersSlider from "@/components/Home/BannersSlider";
import SliderProviders from "@/components/slider/SliderProviders";
import CasinoGames from "@/components/Home/CasinoGames";
import CategoryFilter from "@/components/CategoryFilter/CategoryFilter";

import { mapGetters, mapActions } from "vuex";
//========================================================================================================================================================
import ProviderSection from "@/components/SectionContent/ProviderSection"
import NewPlayersSection from "@/components/SectionContent/NewPlayersSection"
import TopPlayersSection from "@/components/SectionContent/TopPlayersSection"

import GamesSection from "@/components/SectionContent/GamesSection"
//========================================================================================================================================================
export default {
  name: 'Home',
  components: {
    // SliderProviders,
    BannersSlider,
    CasinoGames,
    ProviderSection,
    NewPlayersSection,
    TopPlayersSection,
    CategoryFilter,
    GamesSection,
  },
  data() {
    return {
      // gameBlocks:[
      //   {
      //     component: 'GamesSection',
      //     category: 'All',
      //   },
      //   {
      //     component: 'GamesSection',
      //     category: 'top',
      //     isType: 'sub',
      //   },
      //   {
      //     component: 'GamesSection',
      //     category: 'slots',
      //     isType: 'gameType',
      //   },
      //   {
      //     component: 'GamesSection',
      //     category: 'roulette',
      //     isType: 'gameType',
      //   },
      //   {
      //     component: 'GamesSection',
      //     category: 'new',
      //     isType: 'sub',
      //   },
      
      // ],
      blocks:[
        {
          component: 'NewPlayersSection'
        },
        {
          component: 'TopPlayersSection'
        },
        {
          component: 'ProviderSection',
        },
      
      ],
    }
  },
  async mounted() {
    await this.$store.dispatch('getGameBlocks');
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 350)

    window.addEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapGetters({
      headerVisible: 'headerVisible',
      user: 'getUserInfo',
      gameBlocks: 'getGameBlocks',
    }),
    sortedGameBlocks() {
      if (!this.gameBlocks) return [];
      return this.gameBlocks.slice().sort((a, b) => a.block_order - b.block_order);
    },
  },
  watch: {
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit("gameModule/HOLD_CURRENT_PAGGINATION", null);
      vm.$store.commit("gameModule/HOLD_CURRENT_GAMES", null);
    });
  },
  methods: {
    createIntersectHandler(block) {
    return async (entries, observer) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        if (!block.gamesLoaded) {
          block.loadingBlock = true;

          try {
            const filters = {
              page: 1,
              size: 20,
              game_block_id: block.id,
            };
            block.games = await this.$store.dispatch('gameModule/getGameBlockGames', filters);
            block.gamesLoaded = true;
          } finally {
            block.loadingBlock = false;
          }
        }
      }
    };
  },
    async onIntersect(index, block) {
          if (block.loadingBlock || block.gamesLoaded) {
        return;
      }
    
      block.loadingBlock = true;

      try {
        const filters = {
          page: 1,
          size: 20,
          game_block_id: block.id,
        };
        block.games = await this.$store.dispatch('gameModule/getGameBlockGames', filters);
        block.gamesLoaded = true;
      } finally {
        block.loadingBlock = false;
      }
    },
  },
}
</script>
<style lang="scss">
.game-blocks{
  padding-top: 1px!important;
  &:not(:last-child) {
    padding-bottom: 15px!important;
  }
  &._provider{
    background-color: var(--light-flamingo);
  }
  &._no-games{
    padding: 0px!important;
  }
  &._first{
    padding-top: 15px!important;
  }
}
.bg-bubbles{
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  margin-top: -10px;
  li{
    position: absolute;
    list-style: none;
    display: block;
    
    width: 40px;
    height: 40px;
    // background-color: rgba(255, 255, 255, 0.25);
    background: url('../assets/images/bubles/white-heart-bubble.svg') 0 / 100% no-repeat;
    bottom: -160px;
    opacity: .3;
    -webkit-animation: bubbles 25s infinite;
    animation:         bubbles 25s infinite;
    
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    &:nth-child(1){
      left: 10%;
      background: url('../assets/images/bubles/cards-spades-bubble.svg') 0 / 100% no-repeat;
    }
    
    &:nth-child(2){
      left: 20%;
      background: url('../assets/images/bubles/cards-diamonds-bubble.svg') 0 / 100% no-repeat;
      width: 80px;
      height: 80px;
      
      animation-delay: 2s;
      animation-duration: 17s;
    }
    
    &:nth-child(3){
      left: 25%;
      animation-delay: 4s;
      background: url('../assets/images/bubles/cards-clubs-bubble.svg') 0 / 100% no-repeat;
    }
    
    &:nth-child(4){
      left: 40%;
      width: 60px;
      height: 60px;
      background: url('../assets/images/bubles/cards-spades-bubble.svg') 0 / 100% no-repeat;
      animation-duration: 22s;
      
      // background-color: rgba(255, 255, 255, 0.25);
    }
    
    &:nth-child(5){
      left: 70%;
      background: url('../assets/images/bubles/cards-clubs-bubble.svg') 0 / 100% no-repeat;
    }
    
    &:nth-child(6){
      left: 80%;
      width: 120px;
      height: 120px;
      
      animation-delay: 3s;
      // background-color: rgba(255, 255, 255, 0.25);
    }
    
    &:nth-child(7){
      left: 32%;
      width: 160px;
      height: 160px;
      
      animation-delay: 7s;
    }
    
    &:nth-child(8){
      left: 55%;
      width: 20px;
      height: 20px;
      
      animation-delay: 15s;
      animation-duration: 40s;
    }
    
    &:nth-child(9){
      left: 25%;
      width: 10px;
      height: 10px;
      
      animation-delay: 2s;
      animation-duration: 40s;
      // background-color: rgba(255, 255, 255, 0.25);
    }
    
    &:nth-child(10){
      left: 90%;
      width: 160px;
      height: 160px;
      
      animation-delay: 11s;
    }
  }
}
@-webkit-keyframes bubbles {
  0%   { transform: translateY(0); }
  100% { transform: translateY(calc(-500vh)) rotate(600deg); }
}
@keyframes bubbles {
  0%   { transform: translateY(0); }
  100% { transform: translateY(calc(-500vh)) rotate(600deg); }
}
.bg-bubble-main{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  margin: -10px 0;
  li{
    position: absolute;
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    // background-color: rgba(255, 255, 255, 0.25);
    background: url('../assets/images/bubles/feature/heart.svg') 0 / 100% no-repeat;
    bottom: -160px;
    opacity: .3;
    -webkit-animation: square 25s infinite;
    animation:         square 25s infinite;
    
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    &:nth-child(1){
      left: 10%;
      background: url('../assets/images/bubles/feature/spades.svg') 0 / 100% no-repeat;
    }
    
    &:nth-child(2){
      left: 20%;
      background: url('../assets/images/bubles/feature/diamonds.svg') 0 / 100% no-repeat;
      width: 60px;
      height: 60px;
      
      animation-delay: 2s;
      animation-duration: 17s;
    }
    
    &:nth-child(3){
      left: 25%;
      animation-delay: 4s;
      background: url('../assets/images/bubles/feature/clubs.svg') 0 / 100% no-repeat;
    }
    
    &:nth-child(4){
      left: 40%;
      width: 50px;
      height: 50px;
      background: url('../assets/images/bubles/feature/spades.svg') 0 / 100% no-repeat;
      animation-duration: 22s;
      
      // background-color: rgba(255, 255, 255, 0.25);
    }
    
    &:nth-child(5){
      left: 70%;
      background: url('../assets/images/bubles/feature/clubs.svg') 0 / 100% no-repeat;
    }
    
    &:nth-child(6){
      left: 80%;
      width: 80px;
      height: 80px;
      
      animation-delay: 3s;
      // background-color: rgba(255, 255, 255, 0.25);
    }
    
    &:nth-child(7){
      left: 32%;
      width: 90px;
      height: 90px;
      
      animation-delay: 7s;
    }
    
    &:nth-child(8){
      left: 55%;
      width: 20px;
      height: 20px;
      
      animation-delay: 15s;
      animation-duration: 40s;
    }
    
    &:nth-child(9){
      left: 25%;
      width: 10px;
      height: 10px;
      
      animation-delay: 2s;
      animation-duration: 40s;
      // background-color: rgba(255, 255, 255, 0.25);
    }
    
    &:nth-child(10){
      left: 90%;
      width: 160px;
      height: 160px;
      
      animation-delay: 11s;
    }
  }
}


@-webkit-keyframes square {
  0%   { transform: translateY(0); }
  100% { transform: translateY(-1500px) rotate(600deg); }
}
@keyframes square {
  0%   { transform: translateY(0); }
  100% { transform: translateY(-1500px) rotate(600deg); }
}








.search {

  &_row-mobile-full {
    display: none !important;

    @media (max-width:992.98px) {
      display: flex !important;
    }
  }

  &_mobile-full {}
}

.v-menu__content::-webkit-scrollbar {
  width: 7px;
  background-color: var(--white);
}

.v-menu__content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--gray);
}


.column__search-icon-t {
  max-width: 60px !important;
  text-align: left !important;
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-right: 0px !important;
  justify-content: flex-end !important;
  display: flex !important;
  flex: 0 !important;

  .col {}

}


#providers_select_icon {
  transition: transform 0.3s;
}

.v-select--is-menu-active {
  #providers_select_icon {
    transform: rotate(-180deg);
  }
}

.svg-inline--fa {
  vertical-align: 0;
  margin: 0 5px;
}


.container-to-color {
  background-color: var(--main-flamingo);
}

.container-mod {
  max-width: 1330px !important;
  margin: 0 auto;
  padding: 0 15px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--third-flamingo);
  -webkit-box-shadow: 0 0 0px black inset;
}

.v-overlay__scrim {
  background-color: var(--main-flamingo) !important;
}


.v-select:not(.v-autocomplete) .v-select__slot input {
  visibility: hidden
}

.home {
  background-color: var(--main-flamingo);
  padding-top: 50px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  overflow: hidden;
  position: relative;
  @media (max-width:992px){
    padding-top: 45px;
  }
  @media (max-width:992.98px) {
    margin-top: 0 !important;
  }
  .is-email-confirmation-bar-visible &{
    padding-top: 83px;
    @media (max-width:992px){
      padding-top: 124px;
    }
  }
}

.theme--light.v-list {
  text-align: left;
  background-color: var(--main-flamingo) !important;
  color: var(--white);

  :not(.v-list-item--active):not(.v-list-item--disabled) {
    color: var(--white);
  }
}




.selectMenuClass {
  border: solid 1px var(--white);
}







.heart {
  background-color: var(--red);
  display: inline-block;
  height: 7px;
  left: -17px;
  position: relative;
  top: -4px;
  transform: rotate(-60deg);
  width: 7px;
  opacity: 0;

}

.heart:before,
.heart:after {
  content: "";
  background-color: var(--red);
  border-radius: 50%;
  height: 7px;
  position: absolute;
  width: 7px;
}

.heart:before {
  top: -3px;
  left: 0;
}

.heart:after {
  left: 3px;
  top: 0;
}





</style>

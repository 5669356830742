<template>
  <v-container style="max-width: 600px;" fluid>
    <!-- Title -->
    <!-- <v-row class="title__link-v flex-column"> 
      <v-col class="d-flex align-center justify-center">
          <v-icon color="white" style="margin-right: 3px;">
            phonelink_erase
          </v-icon>
          <span style="color: var(--white);">{{ $i18n.t('selfExclusion') }}</span>
        
      </v-col>    
    
    <v-col class="pa-0">
      <v-divider dark></v-divider>
    </v-col>
  </v-row> -->
  <!-- Title text -->
    <v-row>
      <v-col>
        
        <span style="color: var(--white);">{{ $i18n.t('selfExclusionText') }}</span>
      </v-col>
    </v-row>
    <!-- Limit -->
    <v-row>
      <v-col cols="12">
        <v-select
        dense
        outlined
        hide-details
            :items="timePeriods"
            :label="$i18n.t('selfExclusionLabel')"
            v-model="selectedTimePeriod"
            class="white-label selectMenuSelfExclusion_select limit-input"
            color="white"
            :menu-props="{
               contentClass: 'selectMenuSelfExclusion'
            }"
        ></v-select>
      </v-col>
    </v-row>
    <!-- Save Changes Button -->
    <v-row>
      <v-col>
      <v-btn 
      class="all-buttons-fw_green" dark @click="setUserBlock"
             v-if="!successBlockUpdate"
             :disabled="isButtonDisabled">
        {{ $i18n.t('saveChanges') }}
      </v-btn>
      <v-btn color="green" style="width: 250px" outlined icon dark v-if="successBlockUpdate">
        <v-icon dark>
          done
        </v-icon>
      </v-btn>
    </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      successBlockUpdate: false,
      timePeriods: [this.$t('selfExclusionDay'), this.$t('selfExclusionWeek'), this.$t('selfExclusionMonth'), this.$t('selfExclusionSixMonths'), this.$t('selfExclusionForever')],
      selectedTimePeriod: null
    };
  },
  computed: {
    isButtonDisabled() {
      return !this.selectedTimePeriod;
             
    },
  },
  methods: {
    getBlockEnd() {
      const currentDate = new Date();

      switch (this.selectedTimePeriod) {
        case 'Day':
          currentDate.setDate(currentDate.getDate() + 1);
          break;
        case 'Week':
          currentDate.setDate(currentDate.getDate() + 7);
          break;
        case 'Month':
          currentDate.setMonth(currentDate.getMonth() + 1);
          break;
        case '6 months':
          currentDate.setMonth(currentDate.getMonth() + 6);
          break;
        case 'Forever':
          return null;
      }


      return [
        currentDate.getFullYear(),
        String(currentDate.getMonth() + 1).padStart(2, '0'),  // JS month is 0-based
        String(currentDate.getDate()).padStart(2, '0')
      ].join('-');
    },
    async setUserBlock() {
      const block = this.selectedTimePeriod === 'Forever' ? 1 : 0;
      const block_end = this.getBlockEnd();

      const payload = {
        block: block,
        block_end: block_end
      };
      this.successBlockUpdate = await this.$store.dispatch('selfBlock', payload);
      localStorage.clear();
      location.reload();
    }
  }
}

</script>

<style lang="scss" scoped>
.selectMenuSelfExclusion{
  text-transform: uppercase;
}
::v-deep .v-label,
::v-deep .v-select__selection {
  color: white !important;
}
</style>

import axios from "axios";

// realityCheck.js
const state = {
  avatars: [],
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
};

const getters = {
  getAvatars: (state) => state.avatars,
};

const actions = {
  async awaitGetAvatars({ commit }) {
    try {
      axios
        .get(`/api/avatars/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response && response.status && response.status === 200) {
            const avatars = response.data.items.map((item) => ({
              id: item.id,
              src: `https://minio.takemybet.pro/avatars/${item.image}`,
              thumbnail: `https://minio.takemybet.pro/avatars/${item.preview}`,
              // thumbnail: `https://minio.takemybet.pro/mini-avatars/mini-avatars/mini_player_avatar_${item.image}`,
              title: item.id,
              w: 1200,
              h: 900,
            }));
            commit("set", { type: "avatars", items: avatars });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

<template>
  <!-- v-if="$isMobile()" -->
  <v-dialog
            v-model="searchMobile"
            scrollable
            v-if="$isMobile()"
            fullscreen
            persistent
            transition
            :retain-focus="false" 
          >
            <v-card class="search__card card-search">
              <v-card-title class="card-search__header">
                <v-row class="ma-0 card-search__items">
                  <v-col class="px-0 py-0 card-search__search-column card-search-column">
                    <v-row class="ma-0 card-search-column__row">
                      <v-col class="card-search-column__column px-0 py-0">
                        <v-icon color="white" class="card-search-column__icon">
                          search
                        </v-icon>
          
                        <v-text-field
                          hide-details
                          :placeholder="$i18n.t('enterNameGame')"
                          background-color="none"
                          dense
                          color="var(--white)"
                          class="card-search-column__input"
                          @input="debounceFilters"
                          @click:clear="loadAllGames"
                          v-model="searchGame"
                          autofocus
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="px-0 py-0 card-search__item card-search__item_button">
                    <v-btn class="card-search__close" @click="closeDialogSearch">
                      <v-icon color="white">close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text v-if="!loadingSearch" class="card-search__card-content card-search-content" :class="{'_enter-game-name': mobileSearchEmpty}">
                <v-col class="card-search-content__title text-left pl-0"
                  >{{ $i18n.t("resultSearch") }}:</v-col
                >
                <mobile-games-list v-if="!mobileSearchEmpty"></mobile-games-list>
                <span v-else>{{$t('searchEmpty')}}</span>
                <v-col v-if="games.length === 0 && !mobileSearchEmpty" class="tmb-style_white text-center" style="font-size: 20px">
                  <p>
                  {{ $t('searchNotFound') + ' ' + `'${searchName}'`}}
                  </p>
                  <p>
                    {{ $t('searchNotFoundSpan') }}
                  </p>
                </v-col>
              </v-card-text>
              <v-card-text v-else class="card-search__card-content card-search-content _enter-game-name">
                <v-col class="text-center d-flex justify-center align-center" style="font-size: 20px">
                  <v-progress-circular
                      indeterminate
                      color="var(--white)"
                    ></v-progress-circular>
                </v-col>
              </v-card-text>
            </v-card>
          </v-dialog>
</template>

<script>
import {debounce} from "lodash";
import {mapGetters} from 'vuex'
import MobileGamesList from "@/components/SearchGames/MobileGamesList";
export default {
  name: "MobileSearch",
  components: {
    MobileGamesList,
  },
  data() {
    return {
      loadingSearch: false,
      selectedGamesFilter: "slots",
      searchGame: "",
      mobileSearchEmpty: true,
      //mobileSearch: this.$store.state.openMobileSearch,
      currentProviderIndex: 0,
    };
  },
  created() {
    this.gameProviders = this.$store.getters.getGameProviders;
    this.$root.$on('gameClicked', this.gameSearchCleanup);
  },
  computed: {
    ...mapGetters({
      games: 'gameModule/getSearchGames',
    }),
    searchMobile: {
      get() {
        return this.$store.state.search.searchMobile;
      },
      set(value) {
        this.$store.commit("search/setSearchMobile", value);
      },
    },
    mobileSearch() {
      return this.$store.state.openMobileSearch;
    },
    searchName: {
      get() {
        return this.$store.state.search.searchName;
      },
      set(value) {
        this.$store.commit("search/setSearchName", value);
      },
    },
  },
  methods: {
    debounceFilters: debounce(function() {
      this.filterGames();
    }, 750),
    gameSearchCleanup(){
      this.searchGame = "";
      this.mobileSearchEmpty = true;
    },
    async closeDialogSearch() {
      this.$store.commit("search/setSearchMobile", false);
      this.mobileSearchEmpty = true;
      this.searchGame = "";
      await this.$store.dispatch("gameModule/getSearchedGames", { name: "", });
    },
    async filterGames() {

      if (this.searchGame.trim() === "") {
        this.mobileSearchEmpty = true;
      } else {
        this.mobileSearchEmpty = false;
      }
      this.loadingSearch = true;
      this.selectedGamesFilter = "";
      if (this.searchGame === null) {
        this.searchGame = "";
      }
      this.$store.commit('search/setSearchName', this.searchGame)
      await this.$store.dispatch("gameModule/getSearchedGames", {
        name: this.searchGame,
      });
      this.loadingSearch = false;
    },
    async loadAllGames() {
      this.loadingSearch = true;
      this.selectedGamesFilter = "";
      this.searchGame = "";
      this.provider = "";
      await this.$store.dispatch("gameModule/getSearchedGames", { name: "", });
      this.loadingSearch = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  &__card {
  }
}
.card-search {
  background-color: var(--main-flamingo) !important;
  height: 100%;
  &__header {
    background-color: #4a1558;
    border-radius: 0 !important;
    width: 100%;
    top: 0;
    position: sticky;
    z-index: 50;
    padding: 0px !important;
    min-height: 50px;
  }

  &__items {
    align-items: center;
    padding: 0 10px !important;
  }

  &__search-column {
  }

  &__item {
    flex: 1 1 100%;
    &_button {
      margin-left: 10px;
      flex: 0 0 32px;
    }
  }

  &__close {
    background-color: #3c3955 !important;
    flex: 0 0 32px !important;
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    padding: 0 !important;
  }
  &__card-content {
    background-color: var(--main-flamingo);
  }
}
.card-search-column {
  &__row {
  }

  &__column {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 10px;
  }

  &__input {
    margin-top: 0;
  }
}
.card-search-content {
  &__title {
    color: var(--white);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  &._enter-game-name{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
  }
  span{
    flex: 1 1 100%;
    display: flex;
    justify-content:center;align-items: center;
    color: var(--white);
    font-weight: 500;
    text-transform: uppercase;
  }
}
</style>

<template>
  <div class="providers-select">
    <swiper
      slides-per-view="auto"
      :spaceBetween="5"
      class="providers-select__view select-view"
    >
      <swiper-slide
        v-for="(provider, index) in $store.getters.getGameProviders"
        :key="index"
        class="select-view__item"
        :class="{
          '_all-games': provider.text === 'All Games',
          _active: activeProvider === provider.text,
        }"
      >
        <span>{{
          provider.enabled_games_count ? provider.enabled_games_count : 0
        }}</span>
        <div class="select-view__body" @click="selectProvider(provider)">
          <v-col class="select-view__image">
            <img v-if="provider.logo" :src="provider.logo" />
            <span> {{ $i18n.t("allGames") }} </span>
          </v-col>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
export default {
  name: "SliderProviders",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      searchFlag: false,
      gamesLoadingFlag: false,
      provider: "",
      searchGame: "",
      selectedGame: {},
      selectedGamesFilter: "slots",
      activeProvider: "YggDrasil",
    };
  },
  mounted() {
    console.log("game", this.game);
  },
  computed: {
    ...mapGetters({
      game: "getGames",
    }),
    getProviderGameCount() {
      return (providerName) => {
        if (this.game.items) {
          const providerGames = this.game.items.filter(
            (game) => game.prov_name === providerName
          );
          return providerGames.length;
        }
        return 0;
      };
    },
  },
  created() {
    this.gameProviders = this.$store.getters.getGameProviders;
  },
  methods: {
    async selectProvider(provider) {
      // Обновляем текущий провайдер
      this.provider = provider.text;
      this.activeProvider = this.provider;
      //const currentProviderIndex = this.gameProviders.findIndex(p => p.text === this.provider);
      //const totalProviders = this.gameProviders.length;
      // Вычисляем индекс следующего провайдера
      //const nextProviderIndex = (currentProviderIndex + 1) % totalProviders;
      //const nextProvider = this.gameProviders[nextProviderIndex].text;
      //const previousProviderIndex = (currentProviderIndex - 1 + totalProviders) % totalProviders;
      //const previousProvider = this.gameProviders[previousProviderIndex].text;
      // if (this.provider === 'All Providers' || this.provider === 'Все провайдеры') {
      //   this.loadAllGames()
      //    }
      //    if (nextProvider === 'All Providers' || nextProvider === 'Все провайдеры') {
      //     this.loadAllGames()
      //    }
      await this.$store.dispatch("awaitGetGames", {
        provider: this.provider,
        game: this.searchGame,
      });
      // Отправляем запрос на сервер с использованием текущего, следующего и предыдущего провайдеров
      //await this.$store.dispatch('awaitGetGames', {
      //  provider: this.provider,
      //  providerNext: nextProvider,
      //  providerPrevious: previousProvider,
      //  game: this.searchGame
      //});
    },
    async filterGames(providerFlag) {
      this.gamesLoadingFlag = true;
      this.selectedGamesFilter = "";

      if (this.searchGame === null) {
        this.searchGame = "";
      }

      if (providerFlag) {
        this.searchGame = "";
      } else {
        this.provider = "";
      }
      //await this.$store.dispatch('awaitGetGames', { provider: '', providerNext: '', providerPrevious: ''});
      this.gamesLoadingFlag = false;
    },
  },
};
</script>

<style lang="scss">
.providers-select {
  @media (min-width: 320px) {
    margin-top: 0;
    margin-bottom: 10px;
  }
  @media (min-width: 850px) {
    margin-bottom: 20px;
  }

  @media (min-width: 1140px) {
    padding: 5px 5px 7px 5px;
    overflow: hidden;
  }

  &__title {
    font-size: 18px !important;
    color: var(--white) !important;
    text-align: left !important;
    line-height: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 12px 12px 0 !important;

    span {
      text-transform: uppercase;
    }
  }
  @media (min-width: 320px) {
    flex-direction: column;
  }

  &__view {
  }
}
//.select-view::-webkit-scrollbar {
//  @media (any-hover: hover){
//      height: 10px;
//      border-radius: 10px;
//      background-color: var(--white);
//  }
//
//}
//.select-view::-webkit-scrollbar-thumb {
//  @media (any-hover: hover){
//      border-radius: 10px;
//      height: 10px;
//      background-color: #4a1558;
//  }
//
//}
.select-view {
  &__item {
    max-height: 50px;
    height: 50px;
    width: 170px;
    display: flex;
    flex: 0 0 170px;
    flex-direction: column;
    > span {
      position: absolute;
      min-width: 18px;
      height: 18px;
      flex: 0 1 18px;
      border-radius: 5px;
      background-color: var(--light-flamingo);
      color: var(--white);
      font-weight: 600;
      font-size: 12px;
      z-index: 5;
      top: 0px;
      right: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &._all-games {
      .select-view__body {
        .select-view__image {
          height: 30px;
          max-height: 30px;

          img {
            transform: translate(0px, 2px);
            object-fit: contain;
            -webkit-transform: translate(0px, 2px);
          }
          span {
            display: block;
            white-space: nowrap;
            font-size: 12px;
            transform: translate(0px, 2px);
            color: var(--white);
          }
        }
      }
    }
  }

  &__body {
    aspect-ratio: 3;
    height: 100%;
    padding: 0 8px;
    //border: 1px solid var(--white);
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.3) !important;
    border-radius: 16px !important;
    background-color: #4a1558;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    transition: all 0.3s ease-out;
    overflow: hidden;
    &::before {
      content: "";
      background-color: rgba(white, 0.4);
      width: 60%;
      height: 100%;
      top: 0px;
      left: -125%;
      transform: skew(45deg);
      z-index: 0;
      position: absolute;
      opacity: 0;
      transition: left 0.5s ease-out, opacity 0.1s;
    }
    &:hover {
      cursor: pointer;
      &::before {
        left: 150%;
        opacity: 1;
      }
    }
    ._active & {
      border: 1px solid var(--red);
    }
  }

  &__image {
    padding: 5px 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    //min-height: 25px;
    max-height: 45px;
    max-width: 125px !important;
    @media (max-width: 500px) {
      max-width: 100px !important;
    }
    span {
      display: none;
    }
    img {
      vertical-align: middle;
      max-width: 100%;
      max-height: 100%;
      -webkit-transform: translateZ(0);
      object-position: center center;
    }
  }
}
</style>

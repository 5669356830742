<template>
        <v-alert type='success' dense class="success" :class="{ '_active' : 
        successLimitsUpdate || 
        successAvaUpdate || 
        successDepositBonusActivated || 
        successUploadDoc
    }">
            <span v-if="successLimitsUpdate">{{ $t('limitSuccess') }}</span>
            <span v-if="successAvaUpdate">{{ $t('avaWasSelected') }}</span>
            <span v-if="successUploadDoc">{{ $t('VerifDocSuccessUpload') }}</span>
        </v-alert>
</template>

<script>
    export default {
        name: 'AlertSuccess',
        data() {
            return {
                
            }
        },
        computed:{
            successUploadDoc(){
                return this.$store.state.verification.successUploadDoc;
            },
            //========================================================================================================================================================
            
            successLimitsUpdate() {
                return this.$store.state.limitsUpdate.successLimitsUpdate;
            },
            //========================================================================================================================================================
            //========================================================================================================================================================
            
            successAvaUpdate() {
                return this.$store.state.regComponents.successAvaUpdate;
            },
            //========================================================================================================================================================
            successDepositBonusActivated() {
                return this.$store.state.bonuses.depositBonusActivated;
            },
        }
    }
</script>

<style lang="scss" scoped>
.success{
    position: absolute;
    top: 55px;
    color: var(--white);
    left: 15px;
    width: calc(100% - 30px);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s, top 0.3s;
    @media (min-width:500.98px){
      right: 10px;
      left: unset;
        width: 350px;
    }
    &._active{
      opacity: 1;
      visibility: visible;
      top: 65px;
      @media (min-width:959.98px){
        top: 75px;
    }
    @media (min-width:992.98px){
      top: 85px;
  }
    }
}
</style>
<template>
    <div class="games-list">
        <span class="games-list__title">{{ title }}</span>
        <div class="games-list__content">
            <swiper
          slides-per-view="auto"
          class="games-list__games game"
          v-if="!loadingFlag && currentGames.items.length > 0"
        >
          <swiper-slide
            v-for="(game, index) in currentGames.items"
            :key="index"
            class="game__item"
          >
              <div class="game__image-container">
              <img v-if="game.logo" :src="game.logo" class="game__image"
                 />
                <div class="game__overlay" @click="playGame(game)">
                  <v-btn class="game__play all-buttons-t all-buttons-fw_green" style="color: var(--white)!important;">
                    {{ $i18n.t('play') }}
                    
                  </v-btn>
                  <v-btn class="game__demo all-buttons-t" style="color: var(--white)!important;" @click="playGame(game, true)">
                    {{ $i18n.t('demo') }}
                  </v-btn>
                  <span class="game__name" v-if="game.name">{{ game.name }}</span>
                </div>
              </div>
          </swiper-slide>
        </swiper>
        <div class="d-flex" v-else-if="loadingFlag">

          <v-skeleton-loader 
          v-for="index in 20"
          class="mr-2"
            :key="index"
            style="flex: 0 0 125px"
          width="125"
          height="125 "
          type="card"  />
        </div>
        <div class="d-flex" v-else-if="currentGames.items.length === 0 && !loadingFlag">
          <span>{{ $t('no_games_available') }}</span>
        </div>
        </div>
    </div>
</template>

<script>
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
import { mapGetters } from "vuex";
    export default {
        name: 'BottomGamesList',
        components:{
            Swiper,
            SwiperSlide,
        },
        props: {
          categoryName: String,
        },
        data() {
            return {
              topGames: [],
              lastGames: [],
              title: null,
              loadingFlag: false,
            }
        },
        computed: {
          ...mapGetters({
            favoriteGames: 'favoriteGames/getFavoriteGames',
          }),
          currentGames() {
            if (this.categoryName === 'top-games') {
              return this.topGames;
            } else if (this.categoryName === 'last-games') {
              return this.lastGames;
            } else if (this.categoryName === 'favourite-games') {
              return this.favoriteGames;
            }
            return {
              items: [],
            };
          },
        },
        watch: {
          categoryName: {
            immediate: true, 
            handler(newCategory, oldCategory) {
              this.loadData(newCategory);
            },
          },
        },
        methods: {
          async loadData(category) {
            this.loadingFlag = true;
            if (category === 'top-games') {
              this.title = this.$t('top');
              if (this.topGames.length !== 0) {
                return;
              }
              const params = {
                size: 35,
                sub: 'top',
              };
              this.topGames = await this.$store.dispatch('gameModule/getGamesMenu', params);
            } else if (category === 'last-games') {
              this.title = this.$t('lastGame');
            } else if (category === 'favourite-games') {
              this.title = this.$t('favourite');
            }
            this.loadingFlag = false;
          },
          async playGame(game, demoFlag = false) {
            this.$root.$emit('closeBottomGameList');
            if (this.$store.getters.getUserInfo.id === null) {
              try {
                await this.$router.push('/reg')
            } catch (error) {
           if (error.name !== 'NavigationDuplicated') {
            throw error; 
          }
        }
            } else {
              if (this.$store.getters.getUserInfo.balance == 0 && !demoFlag) {
                try {
                  await this.$router.push('/deposit');
            } catch (error) {
           if (error.name !== 'NavigationDuplicated') {
            throw error; 
          }
        }
              } else {
                const params = { id: game.string_id };
                if (demoFlag) {
                  params.demo = 'demo';
                }
                await this.$router.push({ name: 'Game', params});
              }
            }
          },
        },
    }
</script>

<style lang="scss" scoped>
.games-list {
  background-color: rgba(141, 36, 170, 0.7);
  backdrop-filter: blur(52px);
  opacity: 1;
  bottom: 40px;
  transition-delay: 0.3s;
  min-height: 150px;
  padding: 15px;
  position: fixed;
  width: 100%;

    &__title {
      display: flex;
      color: var(--white);
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 10px;
    }

    &__content {
      height: 100%;
      color: var(--white)
    }

    &__games {
    }
}
@mixin imageGame{
  max-width: 100%;
  max-height: 100%;
  border-radius: 16px;
  overflow: hidden;
  transition: opacity 0.3s;
}
.game {
    position: relative;
    &__item {
      display: flex;
      width: 125px;
      flex: 0 0 125px;
      height: 125px;
      margin-right: 5px;
    }
  
    &__image-container{

        &:hover{
          .game__overlay{
            opacity: 1;
            visibility: visible;
            &::before {
              left: 150%;
            }
          }
          .game__image{
            opacity: 0.5;
            
          }
      }
      
      
      
    }
  
    &__image {
        @include imageGame;
    }
  
    &__overlay {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height:100%;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    border-radius: 16px;
    overflow: hidden;
    text-decoration: none;
    backdrop-filter: blur(2px);
    transition: all 0.3s ease-out;
    @media (min-width:849.98px) {
      &::before {
        content: '';
        background-color: rgba(white, 0.4);
        width: 60%;
        height: 100%;
        top: 0px;
        left: -125%;
        transform: skew(45deg);
        z-index: 3;
        position: absolute;
        transition: left 0.5s ease-out, background-color 0.5s;
      }
    }
    }
    &__play{
      height: 30px!important;
      min-height: 30px!important;
      max-width: 60px!important;
      min-width: 50px!important;
      padding: 0 5px!important;
    }
    &__demo {
      margin-top: 5px;
      height: 30px!important;
      min-height: 30px!important;
      max-width: 60px!important;
      min-width: 50px!important;
      padding: 0 5px!important;
    }
    &__name {
      font-size: 12px;
    max-width: 100%;
    overflow: hidden;
    padding: 0 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--white);
    position: relative;
    }
  }
  //========================================================================================================================================================
  // Kf
  @keyframes disappear {
    0% {
      opacity: 1;
      visibility: visible;
  
    }
  
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
  @keyframes disappear2 {
    0% {
      opacity: 0.6;
      visibility: visible;
  
    }
  
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
</style>
<template>
    <!-- attach -->
    <!-- content-class="ps-relative" -->
    <!-- hide-overlay -->
    <v-overlay absolute class="blur-overlay" z-index="200">
      <!-- :transition="$isMobile() ? '' : 'fade-transition'" -->
      <v-dialog
        class=""
        transition
        :fullscreen="$isMobile() ? true : false"
        no-click-animation
        persistent
        content-class="blur-dialog"
        v-model="regDialog"
      >
          <div class="reg-flex-pc"
          :class="$isMobile() ? '' : 'fix-modal'"
          >
            <reg-banner-bonuses 
            v-if="!$isMobile()"
            />
            <v-card
              class="dialog"
              :class="$isMobile() ? '' : 'dialog_pc'"
            >
            <header-mobile 
            @exit-dialog="toExitDialog"
            v-if="$isMobile()"/>
                <RegNoInstant v-if="!isInstant"></RegNoInstant>
                <RegInstant v-if="isInstant"></RegInstant>
              </v-card>
              <button v-if="!$isMobile()" class="close-dialog" @click="toExitDialog">
                <v-icon color='var(--main-flamingo)'>mdi-close</v-icon>
              </button>
            </div>
    </v-dialog>
    <reg-exit />
    </v-overlay>
</template>

<script>
import HeaderMobile from "../HeaderMobile.vue";
import RegBannerBonuses from "./RegBannerBonuses.vue";
import RegExit from "./RegExit.vue";
import RegInstant from "./RegInstant.vue";
import RegNoInstant from "./RegNoInstant.vue";
export default {
  name: "Registration",
  components: {
    //PromoCode,
    HeaderMobile,
    RegExit,
    RegBannerBonuses,
    RegInstant,
    RegNoInstant,
    //Preloader,
    // VueRecaptcha,
  },
  data() {
    return {
      //regDialog: true,
      dialogEmail: false,
      loadingFlag: false,
    };
  },
  beforeRouteEnter(to, from, next) {
  next(vm => {
    vm.$store.commit("regComponents/setRegDialog", true);
    const userInfo = vm.$store.getters.getUserInfo;
    if (!userInfo || userInfo.id !== null) {
      next('/');
    }
  });
},
mounted() {
  this.$store.commit("regComponents/setRegDialog", true);
},
  computed: {
    isInstant: {
      get() {
        return this.$store.state.regComponents.isInstantReg;
      },
      set(value) {
        this.$store.commit("regComponents/setInstantReg", value);
      },
    },
    regDialog: {
      get() {
        return this.$store.state.regComponents.regDialog;
      },
      set(value) {
        this.$store.commit("regComponents/setRegDialog", value);
      },
    },
  },
  watch: {
  },
  methods: {
    toExitDialog(){
      this.$store.commit('regComponents/setRegExitDialog', true);
    },
  },
};
</script>
<style lang="scss" scoped>
//========================================================================================================================================================
.hints {
  position: relative;
  transition: opacity 0.3s;
}
.hint {
  position: absolute;
  top: 0;
  left: 0;
  color: #9ea4ae;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.hint-hide {
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: opacity 0.3s;
}

//========================================================================================================================================================

.reg-flex-pc {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  min-height: 450px !important;
  //width: 720px !important;
  margin: 0 auto;
  position: relative;
  z-index: 3;
}
.fix-modal{
      max-width: 720px;
      padding: 1px;
}
.reg {

  &__promo-link {
    @media (min-width: 320px) {
      text-align: left;
      cursor: pointer;
      bottom: 65px;
      color: var(--white) !important;
      font-size: 13px;
      font-weight: 600;
      left: 50%;
      transform: translate(-50%, 0px);
      line-height: 20px;
      padding: 0;
      opacity: 0;
      position: absolute;
      
      @media (any-hover: hover) {
        &:hover {
          text-decoration-line: underline;
        }
      }
      @media (any-hover: none) {
        text-decoration-line: underline;
      }
      animation: fade-in 0.3s ease 3.3s forwards;
      width: auto;
    }

    @keyframes fade-in {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }
}

.reg {
  &__row {
    margin: 0;
  }

  &__column {
    padding: 0;
    padding-bottom: 35px;
    &_to-login {
      padding-bottom: 70px;
    }
  }

  &__line {
    @media (min-width: 320px) {
      background-color: var(--white-design);
      display: block;
      height: 37px;
      left: 8px;
      position: absolute;
      
      top: 18px;
      width: 2px;
      z-index: 0;
    }

    &_to-login {
      height: 70px !important;
    }
  }

  &__circle {
    @media (min-width: 320px) {
      align-items: center;
      border: 2px solid var(--white-design);
      border-radius: 50%;
      display: flex;
      height: 18px;
      justify-content: center;
      position: relative;
      width: 18px;
      z-index: 1;
      flex: 0 0 18px;
      .v-icon {
        color: transparent !important;
        font-size: 12px;
        font-weight: 900;
      }
    }
  }

  &__checked {
  }

  &__text {
    @media (min-width: 320px) {
      margin-left: 17px;
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      margin-left: 17px;
      position: relative;
      transition: transform 0.3s, color 0.3s;
      color: var(--white);
    }
  }
}

.dialog {
  width: 100%;
  height: 100%;
  &_pc {
    box-shadow: none !important;
    border-radius: 0 16px 16px 0 !important;
  }
}



.dialog-email-content {
  position: relative;
  
  &__chip-items {
  }

  &__chip-item {
    border-radius: 6px !important;
    background: #f2f6f9 !important;
    position: relative;
    z-index: 3;
    .is-selected {
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      overflow: hidden;
      background: #00bd71;
      transition: all 0.3s;
      position: absolute;
      z-index: 3;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      .v-icon {
        font-weight: 600;
      }
      &_success {
        opacity: 1;
        overflow: visible;
      }
    }
  }

  .v-application .error--text {
  }

  &__form {
    padding: 20px 0px;
  }

  &__input {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      z-index: 50;
      width: 100%;
      top: 43px;
      left: 0;
      background: #dfe4e8;
      height: 2px;
    }

    &_promo {
      &::before {
        content: none;
      }

      z-index: 5;
      background-color: var(--white);
    }
  }

  .v-btn--disabled {
    display: none;
  }

  &__button {
  }

  &__success-message-promo {
    background-color: var(--main-flamingo);
    color: var(--white);
    padding: 5px;
    text-align: center;
    position: relative;
    top: -50px;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: top 0.3s ease, opacity 0.3s ease, visibility 0.3s;

    &._active {
      top: 0;
      opacity: 1;
      visibility: visible;
    }
  }
  &__error-message-promo {
    background-color: var(--main-flamingo);
    color: var(--red);
    padding: 5px;
    text-align: center;
    position: relative;
    top: -50px;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: top 0.3s ease, opacity 0.3s ease, visibility 0.3s;

    &._active {
      top: -32px;
      opacity: 1;
      visibility: visible;
    }
  }
}

//========================================================================================================================================================


.reg_container {
  text-align: center;
  backdrop-filter: blur(20px);
  flex: 1 1 auto !important;
}

.column__width {
  @media (min-width: 400px) {
    min-width: 300px;
  }
}
</style>
<style lang="scss">
.grecaptcha-badge {
  visibility: hidden;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--main-yellow) !important;
  opacity: 0.7;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--main-yellow) !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--main-yellow) !important;
}

.regButton {
  font-weight: 600;
  min-height: 50px !important;
  min-width: 220px;
  //background-color: var(--main-yellow)!important;
  //background-image: url('../assets/images/btn_bg.png');

  border-radius: 5px;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  background-color: var(--red) !important;
  color: var(--white) !important;
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  box-shadow: 0px 0.01em 0.01em rgb(45 35 66 / 100%),
    0px 0.3em 0.7em -0.01em rgb(45 35 66 / 100%),
    inset 0px -0.01em 0px rgb(58 65 111 / 100%) !important;

  &:active {
    box-shadow: inset 0px 0.1em 0.6em #3c4fe0 !important;
    transform: translateY(0em);
  }

  @media (any-hover: hover) {
    &:hover {
      box-shadow: 0px 0.1em 0.2em rgb(45 35 66 / 40%),
        0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #3c4fe0 !important;
      transform: translateY(-0.1em);
    }
  }
}

.disabledRegBtn {
  opacity: 0.5;
}

.labels_color {
  input {
    color: var(--white) !important;
    caret-color: var(--white) !important;
  }

  .v-label {
    color: var(--white) !important;
  }

  fieldset {
    color: var(--white) !important;
  }
}

.reg_card-padding {
  padding-top: 10px !important;

  @media (min-width: 768px) {
    padding-top: 20px !important;
  }
}

.reg_card {
  @media (min-width: 501px) {
    overflow: hidden;
    border-radius: 12px !important;
  }

  margin: 0 auto;
  max-width: 1140px !important;
  background-color: var(--main-flamingo) !important;
  border-color: var(--red) !important;
  width: 100%;
  transition: none !important;
  transition-timing-function: unset !important;
}

.active_tab {
  color: #fde700 !important;
  background-color: #282141 !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.tabs_buttons {
  background-color: #282141;
  color: #ffffff;
}

.tab_content {
  background-color: var(--main-flamingo);
  color: #ffffff;
  padding: 10px;
}

.register_button {
  margin-top: 5px;
}

.enter_methods {
  font-family: Montserrat;
  margin-bottom: 20px;
}

.terms_col {
  max-width: 70%;
  height: 80px;
  font-family: Montserrat;
  font-size: 11px;
  text-align: left;
}

.button_tabs {
  display: none;
}

.mobile_banner {
  display: none;
}

@media (max-width: 1024px) {
  .textfield_col {
    //max-width: 85%;
  }

  .banner_image {
    width: 250px;
    height: 150px;
  }

  .bonus_text {
    font-size: 16px;
  }

  .register_button {
    margin-top: 15px;
  }
}

@media (max-width: 870px) {
  .mobile_banner {
    display: inline-flex;
  }

  .desktop_banner {
    display: none;
  }
}

@media (max-width: 850px) {
  .regButton {
    font-size: 12px !important;
  }

  .terms_col {
    max-width: 85%;
  }

  .register_button {
    margin-top: 20px;
  }

  .reg_container {
    padding-top: 85px !important;
  }

  .enter_methods {
    display: none;
  }
}
</style>

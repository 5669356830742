var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-chip-group',{staticClass:"links-label"},[_c('v-chip',{staticClass:"link-label",attrs:{"active-class":"_active","to":"/profile/info/data","label":"","dense":""}},[_c('div',[_vm._v(" "+_vm._s(_vm.$i18n.t('me')))])]),_c('v-chip',{staticClass:"link-label",attrs:{"active-class":"_active","to":"/profile/info/notifications","label":"","dense":""}},[_c('div',[_vm._v(" "+_vm._s(_vm.$i18n.t('notifications')))])]),_c('v-chip',{staticClass:"link-label d-flex align-center",attrs:{"label":"","dense":"","active-class":"_active","to":"/profile/info/verification"}},[_c('div',[_vm._v(_vm._s(_vm.$i18n.t('verification')))]),_c('v-spacer'),(
            (_vm.user.doc1_verified === 3 || _vm.user.doc2_verified === 3 || _vm.user.doc3_verified === 3 || 
            _vm.user.doc1_verified === 0 || _vm.user.doc2_verified === 0 || _vm.user.doc3_verified === 0)
            )?_c('font-awesome-icon',{staticStyle:{"color":"var(--red)"},attrs:{"icon":"exclamation-triangle"}}):_vm._e(),(
            (_vm.user.doc1_verified === 1 || _vm.user.doc2_verified === 1 || _vm.user.doc3_verified === 1) &&
            (_vm.user.doc1_verified !== 3 && _vm.user.doc2_verified !== 3 && _vm.user.doc3_verified !== 3) &&
            (_vm.user.doc1_verified !== 0 && _vm.user.doc2_verified !== 0 && _vm.user.doc3_verified !== 0)
            )?_c('v-icon',{attrs:{"color":"var(--yellow)"}},[_vm._v("timer")]):_vm._e(),((_vm.user.doc1_verified === 2 && _vm.user.doc2_verified === 2 && _vm.user.doc3_verified === 2))?_c('v-icon',{attrs:{"color":"var(--green-success)"}},[_vm._v("done")]):_vm._e()],1),_c('v-chip',{staticClass:"link-label",attrs:{"active-class":"_active","to":"/profile/info/security","label":"","dense":""}},[_c('div',[_vm._v(" "+_vm._s(_vm.$i18n.t('security'))+" ")])])],1),_c('router-view',{staticClass:"info_router"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
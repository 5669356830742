// countries.js
import axios from "axios";
const state = {
  countries: {
    items: [],
  },
  currentCountry: null,
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
  CONCAT_COUNTRIES(state, newGames) {
    state.countries.items = state.countries.items.concat(newGames.items);
    state.countries.page = newGames.page;
    state.countries.pages = newGames.pages;
    state.countries.size = newGames.size;
    state.countries.total = newGames.total;
  },
};

const getters = {
  getCountries: (state) => state.countries,
  getCurrentCountry: (state) => state.currentCountry,
};

const actions = {
  async getCurrentCountry({ commit }, country_code) {
    await axios
      .get(`/api/informational/active_countries/${country_code}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          commit("set", { type: "currentCountry", items: response.data });
        }
      })
      .catch((error) => {});
  },
  async getCountries({ commit }, { filters, oneMore }) {
    const params = new URLSearchParams();
    for (const key in filters) {
      if (Object.hasOwnProperty.call(filters, key)) {
        const value = filters[key];

        // Check value
        if (value != null && value !== "") {
          params.append(key, value);
        }
      }
    }
    const queryString = `?${params.toString()}`;
    await axios
      .get(`/api/informational/active_countries${queryString}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (oneMore) {
            commit("CONCAT_COUNTRIES", response.data);
          } else {
            commit("set", { type: "countries", items: response.data });
          }
        }
      })
      .catch((error) => {});
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

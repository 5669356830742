<template>
  <v-container fluid class="table-wrapper">
    <!-- Title -->
      
    
    <v-row class="title__link-v flex-column">
      <v-col class="d-flex align-center justify-center">
        <v-icon color="white" style="margin-right: 3px;">
          history
        </v-icon>
        <span style="color: var(--white);">{{ $i18n.t('paymentsHistory') }}</span>

      </v-col>

      <v-col class="pa-0 divider">
        <v-divider dark></v-divider>
      </v-col>
    </v-row>
 
    <!-- Title list -->
    <v-row class="row-title-item mb-2 mt-0">
      <v-col class="col__item title-item">
        {{ $i18n.t('dateTime') }}
      </v-col>
      <v-col class="col__item title-item">
        {{ $i18n.t('status') }}
      </v-col>
      <v-col class="col__item title-item">
        {{ $i18n.t('amount') }}
      </v-col>
      <v-col class="col__item title-item small-item">
        {{ $i18n.t('type') }}
      </v-col>
      <v-col class="col__item title-item small-item">
        {{ $i18n.t('way') }}
      </v-col>
    </v-row>
    <!-- List -->
    <template v-if="$store.state.userPayments.length > 0 && !loadingFlag">
    <!-- pc-table -->
      <v-row class="row-list-item" v-for="(payment, i) in paginatedPayments" :key="i"  :class="{'isWithDraw' : payment.payment_type == 'withdraw'}">
          <v-col class="col__item list-item" >
            {{ getLocalDateTime(payment.created_at) }}
          </v-col>
          <!--  -->
          <v-col class="col__item list-item"  >
            <v-chip small label
            :color="statusLabelColor(1)"
            :style="{'color': statusStyleColor(1)}"
            class="px-1"
            >
              {{ getStatusText(1) }}
            </v-chip>

          </v-col>
          <v-col class="col__item list-item d-flex align-center" >
            <span>
              {{ payment.amount }}
            </span>
              <span style="margin-left: 2px;">{{ user.currency }}</span>
            

          </v-col>
          <v-col class="col__item list-item small-item">
            {{ getTypeText(payment.payment_type) }}

          </v-col>
          <v-col class="col__item list-item small-item">
            {{ payment.name }}


          </v-col>
    </v-row>
    </template>
    <Preloader class="d-flex justify-center" v-if="loadingFlag"></Preloader>
    <!-- Paggination -->
    <v-row v-if="$store.state.userPayments.length > 0">
      <v-col>
        <v-pagination v-model="$store.state.currentPage"
          :length="Math.ceil($store.getters.getUserPayments.length / $store.state.itemsPerPage)"
          color="var(--red)"></v-pagination>
      </v-col>
    </v-row>
    
    <v-row class="justify-center">
    <!-- Title if payments = 0 -->
    <v-col class="d-flex align-center flex-column justify-center" v-if="$store.state.userPayments.length == 0 && !loadingFlag">
      <span class="mb-4 font-weight-bold tmb-style_fz24 tmb-style_white">
        {{ $t('historyEmpty') }}
      </span>
      <span class="tmb-style_fz16 tmb-style_white text-center">
        {{ $t('historyEmptySpan') }}
      </span>
    </v-col>
    
  </v-row>
    
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Preloader from "@/components/Preloader";
export default {
  name: "PaymentsHistory",
  components: { Preloader },
  data() {
    return {
      loadingFlag: false,
    }
  },
  async mounted() {
    //getUserPaymentsHistory
    this.loadingFlag = true;
    if (this.userPayments.length == 0) {
      await this.$store.dispatch('getUserPaymentsHistory');
    }
    this.loadingFlag = false;
  },
  computed: {
    ...mapGetters({
      userPayments: 'getUserPayments',
      user: 'getUserInfo',
    }),
    paginatedPayments() {
      const start = (this.$store.state.currentPage - 1) * this.$store.state.itemsPerPage;
      const end = start + this.$store.state.itemsPerPage;
      return this.$store.getters.getUserPayments.slice(start, end);

    }
  },
  methods: {
    getLocalDateTime(serverDate){
      const serverDateTime = new Date(serverDate);

      const userTimezoneOffset = new Date().getTimezoneOffset(); 

      const localDateTime = new Date(serverDateTime.setMinutes(serverDateTime.getMinutes() - userTimezoneOffset));

      return localDateTime.toLocaleString();
    },
    getStatusText(statusId) {
      switch (statusId) {
        case 0:
          return this.$i18n.t('processing');
        case 1:
          return this.$i18n.t('processed');
        case 2:
          return this.$i18n.t('processedWithFee');
        case 3:
          return this.$i18n.t('rejected');
      }
    },
    statusLabelColor(status) {
      switch (status) {
        case 0:
          return 'var(--yellow)';
        case 1:
          return 'var(--green-success)';
        case 2:
          return '#feedaf';
        case 3:
          return  'var(--red)';
          default: 
          return status
      }
    },
    statusStyleColor(status) {
      switch (status) {
        case 0:
          return '#8a5340';
        case 1:
          return 'var(--white)';
        case 2:
          return '#8a5340';
        case 3:
          return  'var(--white)';
          default: 
          return status
      }
    },
    getStatusColor(statusId) {
      switch (statusId) {
        case 0:
          return ''
        case 1:
          return 'success_status'
        case 2:
          return 'warning_status'
        case 3:
          return 'rejected_status'
      }
    },
    getTypeText(type) {
      switch (type) {
        case 'deposit':
          return this.$i18n.t('deposit1')
        case 'withdraw':
          return this.$i18n.t('withdraw')
      }
    },
    getMethodText(method) {
      for (let indx in this.$store.getters.getPayMethods) {
        if (this.$store.getters.getPayMethods[indx].codename === method) {
          return this.$store.getters.getPayMethods[indx].name
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.table-wrapper{
  border-radius: 4px;
  border: 1px solid var(--main-flamingo);
}
.divider{
  @media (min-width:650.98px){
      
  }
  margin-bottom: 24px!important;
}

.items-grid{
  padding: 20px 10px;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 12px;
}
.pc-table{
  @media (max-width:650px){
      display: none;
  }
}
.mobile-table{
  @media (min-width:650.98px){
    display: none;
}
}
.row-title-item {
  color: var(--white);
//  @media (max-width:650px){
//    display: none;
//}
}

.title-item {
  line-height: 1.5;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width:500px){
      font-size: 12px;
  }
//  @media (max-width:650.98px){
//    justify-content: flex-start;
//}
}

.col__item {
  
padding: 0;
}
.row-list-item {
  &:nth-child(odd) {
    background-color: var(--main-flamingo);

  }
  color: var(--white);

}
.isWithDraw{
  color: #ff6565!important;
  //position: relative;
  //&::before{
  //  content: '';
  //  position: absolute;
  //  z-index: 1;
  //  background-color: rgba(0, 0, 0, 0.15);
  //  width: 100%;
  //  height: 100%;
  //  top: 0;
  //  left: 0;
  //  
  //}
}
.list-item {
  
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  @media (max-width:500px){
    font-size: 10px;
}
//  @media (max-width:650.98px){
//    justify-content: flex-start;
//}
min-height: 40px; 
  @media (min-width:650.98px){
  }
  padding: 0;
}
.small-item{
  @media (max-width:650px){
    flex: 0 1 55px;  
  }
  
}
.row_style {
  color: var(--white);

}

.success_status {
  color: var(--green-success);
}

.rejected_status {
  color: var(--red);
}

.warning_status {
  color: #FFC107;
}
</style>
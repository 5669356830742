<template>
    <v-card class="bonus-back">
        <v-card-title class="bonus-back__header header-bonus">
          <div class="header-bonus__label">
            <font-awesome-icon style="font-size: 12px;" icon="gift" />
        </div>
          <div class="header-bonus__title">{{$t('depositSecondBonusTitle')}}</div>
          <!-- @click="toggleCardFlip(index)"  -->
          <div @click="unflipCard" class="header-bonus__label"><v-icon  color="white" style="font-size: 18px;">
            close
        </v-icon></div>
        </v-card-title>
        <v-card-text style="width: auto;" class="bonus-back__main main-bonus">
          <v-list class="main-bonus__list">
            <div class="main-bonus__item">
              <v-list-item-content class="main-bonus__content" v-for="(title, key) in typeTitle" :key="'content_' + key">
                <v-list-item-title class="main-bonus__title" style="min-width: 115px;">
                  {{ title }}
                </v-list-item-title>
                <v-list-item-subtitle class="main-bonus__text">
                  {{ propsData[key] }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </div>
          </v-list>
        </v-card-text>
      </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
    export default {
        name: 'BonusFutureOneBack',
        props: {
        index: Number,
        type: String,
        expire_in_days: String,
        bonus_percent: String,
        min_dep: String,
        currency_limits: Object,
        max_dep: String,
        wager: Number,
          
        },
        data(){
            return{

            }
        },
        computed:{
          ...mapGetters({
      depositBonuses: 'bonuses/getDepositBonuses',
      user: 'getUserInfo'
    }),
    userCurrencyMinDep() {
    return this.currency_limits[this.user.currency].min_dep || 0;
  },
  userCurrencyMaxDep() {
    return this.currency_limits[this.user.currency].max_dep || 0;
  },
  propsData() {
      return {
        index: this.index,
        type: this.getBonusType(this.type),
        expire_in_days: this.expire_in_days,
        bonus_percent: this.bonus_percent,
        wager: this.wager,
        min_dep: this.userCurrencyMinDep + ' ' + this.user.currency,
        max_dep: this.userCurrencyMaxDep + ' ' + this.user.currency,
      };
    },
  typeTitle() {
      return {
        type: this.$t('type'),
        wager: this.$t('wager'),
        bonus_percent: this.$t('bonusPercent') , 
        expire_in_days: this.$t('bonusExpire'), 
        min_dep: this.$t('minAmount'),
        max_dep: this.$t('maxAmount'),
      };
    },
        },
        methods: {
          getBonusType(type){
    switch (type) {
      case 'next_deposit':
        return this.$t('next_deposit');
      default:
        return type;
    }
  },
         unflipCard() {
          this.$store.commit('bonuses/flipBonus', { bonuses: 'depositBonuses', index: this.index });
    },
        },
    }
</script>

<style lang="scss" scoped>
.bonus-back {
  //background: linear-gradient(173.41deg,#0094ff 4.14%,#5607d6 87.72%)!important;
  //background: var(--dark-flamingo)!important;
  background: linear-gradient(to right, var(--white), var(--white))!important;
  
  color: var(--white)!important;
  //height: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
    border-radius: 16px!important;
  &__header {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
  }
  &__content{
    
  }
  &__main {
    flex: 1 1 100%;
    position: relative;
    z-index: 2;
  }

  &__timer {
    position: relative;
    z-index: 2;
  }
  
}
.header-bonus {
  padding: 10px;
  background-color: var(--dark-flamingo);

  &__label {
    height: 24px;
    justify-content: center;
    
    position: relative;
    width: 24px;
    align-items: center;
    display: flex;
    position: relative;
    transition: background-color 0.3s;
    &:last-child{
      cursor: pointer;
      
      @media (any-hover: hover){
        &:hover{
          &::before {
            background-color: hsla(0, 0%, 100%, .4);
          }
        }
      }
      
    }
    &::before {
      background-color: hsla(0, 0%, 100%, .2);
      border-radius: 50%;
      content: "";
      //height: calc(100% - 20px);
      height: 24px;
      width: 24px;
      position: absolute;
      //width: calc(100% - 20px);
    }
    
    }

  &__title {
    display: block;
    line-height: 1.5;
    max-width: calc(100% - 68px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    
  }
}
.main-bonus{
  padding: 0;
  padding-bottom: 50px;
  &__list {
    background-color: var(--white)!important;
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: inherit!important;
    
  }

  &__item {
    line-height: 1.5;
    
    min-height: auto;
    flex-direction: column;
    flex: 1 1 100%;
    
  }
  &__content{
    padding: 10px 20px 9px;
    display: flex;
    align-items: center;
    &:nth-child(even){
      background: #f2f6f9!important;
    }
    flex-wrap: nowrap;
  }
  &__title {
    color: var(--gray-text)!important;
    margin-right: 10px;
  }

  &__text {
    font-weight: 600;
    margin-left: auto;
    text-align: right;
    color: var(--black)!important;
   
      &::first-letter{
        text-transform: uppercase;
      }
    
  }
}
.timer-bonus {
  display: flex;
  gap: 10px;
  justify-content:center;align-items: center;
  &__icon {
  }

  &__text {
    font-weight: 700;
    color: var(--white);
  }
}
.bonus {

  &__header {
  }

  &__main {
  }
}
</style>
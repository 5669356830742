<template>
    <div class="user-dropdown all-shadow-hover" @mouseover="isHover = true" @mouseleave="isHover = false">
        <div class="user-dropdown__icon" 
        :class="{'_open' : isHover}"
        >
          <v-icon style="" color="var(--white)">person</v-icon>
          <!-- <img :src="`https://minio.takemybet.pro/avatars/${user.avatar.image}`"
              @click="$router.push('/profile/info/data')" /> -->
        </div>

        <div class="user-dropdown__content" v-if="isHover">
          <div class="user-dropdown__container">
            <div
              class="user-dropdown__account"
              
            >
              <div class="user-dropdown__item _user" @click="toProfile">
                <div class="user-dropdown__item-icon ">
                  <img
                    :src="`https://minio.takemybet.pro/avatars/${user.avatar.image}`"
                  />
                </div>
                <div class="user-dropdown__texts">
                  <div class="user-dropdown__item-title">
                    {{ $i18n.locale === 'ru' ? $t("myCabinet") + ' - ' + user.username : $i18n.locale === 'en' ? user.username + ' ' + $t("myCabinet") : '' }}
                </div>
                <div class="user-dropdown__item-description">
                  {{ $t("myCabinetInfo") }}
                </div>
                </div>
                
              </div>
              <ul class="user-dropdown__account-links">
            
                  <span 
                  v-for="(link, index) in userLinks"
                  :key="index"
                  @click="toRedirect(link.link)" class="user-dropdown__account-link">{{ link.title }}</span>
                
              </ul>
            </div>
            <div class="user-dropdown__item" @click="logOut">
              <div class="user-dropdown__item-icon">
                <v-icon style="font-size: 36px" color="var(--white)"
                  >power_settings_new</v-icon
                >
              </div>
              <div
                class="user-dropdown__item-title user-dropdown__item-title_is-logout"
              >
                {{ $t("exit") }}
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import { logOut } from '@/store/clear_store';
import {mapGetters} from 'vuex'
    export default {
        name: 'UserDropdown',
        data() {
            return {
              isHover: false,
                userLinks: [
        {
          link: "/profile/promo-my/bonus",
          title: this.$t("bonuses"),
        },
        {
          link: "/profile/cashbox/payhistory",
          title: this.$t("transactions"),
        },
        {
          link: "/profile/info/data",
          title: this.$t("myProfile"),
        },
        // {
        //   link: 'profile/promotions',
        //   title: this.$t('bets'),
        // },
      ],
            }
        },
        computed:{
            ...mapGetters({
                user: 'getUserInfo',
            })
        },
        methods: {
          toProfile(){
            if (this.$route.path !== '/profile/info/data') {
              this.$router.push('/profile/info/data');
            }
            this.isHover = false;
          },
          toRedirect(link){
            if (this.$route.path !== link) {
              this.$router.push(link);
            }
            this.isHover = false;
          },
            async logOut() {
              logOut();
              this.isHover = false;
            this.$store.commit('depositView/setMethodMoney', false)
            this.$store.commit('depositView/setMethodCrypto',false)
            this.$store.commit('depositView/setMethodExit',false)
            this.$store.commit('depositView/setDepositExitWindow',false)
            this.$store.commit('depositView/setDepositMethodsWindow',false)
            this.$store.commit('depositView/setDepositWindowError',false)
            this.$store.commit('depositView/setSelectedMethod', null)
            if (this.$route.path !== '/') {
              this.$router.push('/')
            }
            try {
              await this.$store.dispatch("logOut");
              //location.reload();
            } catch (error) {
              if (error.name == "NavigationDuplicated") {
                throw error;
              }
            }
            this.$store.commit('setDepositWindowError', false);
            this.isOpen = false;
            this.isVerification = false;
            this.isOpenCash = false;
            this.isOpenInfo = false;
            this.isOpenBonuses = false;
            this.isResponsibleGamblingOpen = false;
            this.menuOpen = false;
            this.isAllPromotionsOpen = false;
            this.isMenuOpen = false;
    },
        },
    }
</script>

<style lang="scss" scoped>
ul,
li {
  list-style: none;
}
@mixin hover-dropdown {
  border-radius: 6px;
  transition: background-color 0.3s;
  @media (any-hover: hover) {
    &:hover {
      background-color: #c935db;
    }
  }
}
@mixin icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--red);
  border-radius: 6px;
  flex: 0 0 36px;
  height: 36px;
  width: 36px;
}

.user-dropdown {
  position: relative;
  transition: transform 0.3s;
  display: flex;
  
  &__icon {
    //transition: all 0.3s;
    @include icon;
    img {
      max-width: 22px;
      max-height: 22px;
    }
    &._open{
      background-color: var(--light-flamingo);
      border-radius: 6px 6px 0 0;
    }
  }
  //@media (any-hover: hover) {
  //  &:hover {
  //    .user-dropdown__content {
  //      display: flex;
  //    }
  //    .user-dropdown__icon {
  //      background-color: var(--light-flamingo);
  //      border-radius: 6px 6px 0 0;
  //    }
  //  }
  //}
  &__content {
    //display: none;
    align-items: flex-end;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 100%;
    transition: all 0.3s;
    &::before {
      background: var(--light-flamingo);
      content: "";
      height: 10px;
      opacity: 1;
      width: 36px;
    }
  }

  &__container {
    background: var(--light-flamingo);
    border-radius: 16px 0 16px 16px;
    min-width: 450px;
    padding: 10px;
    box-shadow: 0 4px 12px rgba(22, 20, 33, 0.2);
  }

  &__account {
    border-bottom: 1px solid var(--white);
    margin: -10px -10px 10px;
    padding: 10px;
  }

  &__item {
    grid-column-gap: 20px;
    align-items: center;
    cursor: pointer;
    display: grid;
    grid-template-columns: 36px 1fr;
    padding: 10px 20px;
    text-align: left;
    @include hover-dropdown;
    &:first-child{
      padding: 10px 20px 10px 10px;
    }
    &._user {
      display: flex;
      grid-column-gap: 15px;
    }
  }
  &__texts{
    display: flex;
    flex-direction: column;
  }
  &__item-icon {
    grid-row: 1 / span 2;
    height: 36px;
    margin: 13px 0;
    width: 36px;
    flex: 0 0 36px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    ._user &{
      flex: 0 0 60px;
      height: 60px;
      width: 60px;
    }
  }
  
  &__item-title {
    color: var(--white);
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    &_is-logout {
      grid-row: 1 / span 2;
    }
  }

  &__item-description {
    color: var(--white-details);
  }

  &__account-links {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 5px 13px 0 57px;
  }

  &__account-link {
    @include hover-dropdown;
    cursor: pointer;
    font-weight: 600;
    line-height: 16px;
    padding: 12px 7px;
    text-decoration: none;
    color: var(--white);
  }
}
</style>
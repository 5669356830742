<template>
  <div>
    <!-- <v-row class="casinos_desktop">
      <v-col style="min-width: 870px" class="casinos_images">
        <v-row v-if="casinoGames.length === 0">
          <v-col style="color: var(--white);font-size: 20px" class="d-flex flex-column align-center">
            <span class="font-weight-bold">{{ $i18n.t('noResults') }}</span>
              <v-btn class="all-buttons-t moreGames_btn" @click="loadAllGames" :loading="loading">
                {{ $i18n.t('showGames') }}
              </v-btn>
              
          </v-col>
        </v-row>
        <div class="grid__wrapper" v-if="casinoGames.length > 0">
          
          <div v-for="index in showingGamesCount" :key="index" class="casino_img_col">
            <div class="image-container">
            <img v-if="casinoGames[index - 1]" :src="casinoGames[index - 1].logo" class="casino_image" style=""
              width="100%" height="100%" />
              <div class="casino_overlay">
                <v-btn class="all-buttons-t all-buttons-t_play play_button" @click="playGame(casinoGames[index - 1])">
  
                  <img src="@/assets/images/white-heart.svg" width="15px" height="15px" class="white-heart">
  
                  {{ $i18n.t('play') }}
                </v-btn>
                <div style="bottom: 25%" class="demo__link" @click="playGame(casinoGames[index - 1], true)">
                  {{ $i18n.t('demo') }}
                </div>
  
              </div>
            </div>
            <div class="gameName" v-if="casinoGames[index - 1]">
              {{ casinoGames[index - 1].name }}
            </div>
            
          </div>
          <div class="casino_img_col" v-for="index in showingBigGamesCount" :key="bigCasinoGames[index - 1].name">
            
            <div class="image-container">
            <img v-if="bigCasinoGames[index - 1] && bigCasinoGames[index - 1].logo" :src="bigCasinoGames[index - 1].logo"
              class="casino_image" style="" />
            </div>
            <div class="casino_overlay">
              <v-btn class="all-buttons-t all-buttons-t_play play_button" @click="playGame(bigCasinoGames[index - 1])">
                <img src="@/assets/images/white-heart.svg" width="15px" height="15px" class="white-heart">

                {{ $i18n.t('play') }}
              </v-btn>
              <div class="demo__link" style="bottom: 25%;" @click="playGame(bigCasinoGames[index - 1], true)">
                {{ $i18n.t('demo') }}
              </div>
            </div>
            <div class="gameName" v-if="bigCasinoGames[index - 1]">
              {{ bigCasinoGames[index - 1].name }}
            </div>
            

          </div>
          
          
        </div>

      </v-col>
    </v-row>
    <div class="show_1264 casinos_desktop">
    </div>
    <v-row class="casinos_mobile" style="max-width: 100%;margin: 0 auto">
      <div style="width: 100%;">
          <v-row v-if="casinoGames.length === 0">
            <v-col style="color: var(--white);font-size: 20px" class="d-flex flex-column align-center">
              <span class="font-weight-bold">{{ $i18n.t('noResults') }}</span>
                <v-btn class="all-buttons-t moreGames_btn" @click="loadAllGames" :loading="loading">
                  {{ $i18n.t('showGames') }}
                </v-btn>
                
            </v-col>
          </v-row>  
          <div class="mobile_grid_wrapper" v-if="casinoGames.length > 0">
            
            <div class="mobile__div-t mobile__div-t__click" v-for="index in showingGamesCount" :key="index">
              <div class="red-square-t">
                <img v-if="casinoGames[index - 1]" :src="casinoGames[index - 1].logo" class="casino_image d-block" width="100%"
                  height="100%" style="" />
                  
              </div>
              <div style="justify-content: space-evenly;" class="d-flex flex-column align-center casino_overlay casino_overlay_mobile">
                <span class="casino_overlay__span" style="color: var(--white)">
                  {{ casinoGames[index - 1] ? casinoGames[index - 1].name : '' }}

                </span>
                <v-btn class="all-buttons-t all-buttons-t_play play_button" @click="playGame(casinoGames[index - 1])">
                  <img src="@/assets/images/white-heart.svg" width="15px" height="15px" class="white-heart">
  
                  {{ $i18n.t('play') }}
                </v-btn>
                <div class="demo__link" style="bottom: 5%;" @click="playGame(casinoGames[index - 1], true)">
                  {{ $i18n.t('demo') }}
                </div>
  
              </div>
            </div>
            <div class="mobile__div-t mobile__div-t__click" v-for="index in showingBigGamesCount"
              :key="bigCasinoGames[index - 1].name">
  
              <div class="red-square-t">
                <img v-if="bigCasinoGames[index - 1]" :src="bigCasinoGames[index - 1].logo" class="casino_image"
                  style="width: 100%;height: auto;max-width: 450px;" />
              </div>
              <div class="gameName" style="font-size: 18px" v-if="bigCasinoGames[index - 1]">
                {{ bigCasinoGames[index - 1].name }}
              </div>
              <div style="justify-content: space-evenly;justify-items: center" class="d-flex flex-column align-center casino_overlay casino_overlay_mobile">
                <span class="casino_overlay__span" style="color: var(--white)">{{ casinoGames[index - 1].name }}</span>
                <v-btn class="all-buttons-t all-buttons-t_play play_button" @click="playGame(bigCasinoGames[index - 1])">
                  <img src="@/assets/images/white-heart.svg" width="15px" height="15px" class="white-heart">
  
                  {{ $i18n.t('play') }}
                </v-btn>
                <div class="demo__link" style="bottom: 5%;" @click="playGame(bigCasinoGames[index - 1], true)">
                  {{ $i18n.t('demo') }}
                </div>
              </div>
            </div>
          
        </div>
       
      </div>
    </v-row> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SwiperCore, Swiper, SwiperSlide} from 'swiper-vue2'
import "swiper/swiper-bundle.css";
import Axios from "axios";
export default {
  name: "CasinoGames",
  components: {
    // Swiper, 
    // SwiperSlide,
  },
  data() {
    return {
      searchFlag: false,
      navDrawer: false,
      showingGamesCount: this.calculateInitialShowingGamesCount(), //28
      gamesLoadingFlag: false,
      loading: false,
      provider: '',
      searchGame: '',
      dialogMobile: false,
      selectedGame: {},
      selectedGamesFilter: 'slots',
      mobileSearchFlag: false,
      isAddingGames: false,
      currentProviderIndex: 0,
      touchStartX: 0,
      touchEndX: 0,
      previousWidth: window.innerWidth,
    }
  },
  
  computed: {
    ...mapGetters({
      headerVisible: 'headerVisible',
      casinoGames: 'getGames',
      bigCasinoGames: 'bigGames',
      providerGames: 'getProviderGames',
      providerNextGames: 'getProviderNextGames',
      providerPreviousGames: 'getProviderPreviousGames',
      user: 'getUserInfo',
    }), 
    showingBigGamesCount() {
      let count = 0;
      let width = screen.width;
      // console.log('width = ',width, width > 1264,(width <= 1264 && width > 850))
      
      //switch (true) {
      //  case width > 1240:
      //    count = 0;
      //    //3 + Math.floor((this.showingGamesCount - 14) / 8)
      //    break
      //  case width <= 1240 && width > 850:
      //    count = 0; //14
      //    //rightColPos = '3/5';
      //    break
      //  case width <= 850:
      //    count = 0; //8
      //    //2 + Math.floor((this.showingGamesCount - 15) / 4)
      //    break
      //}
      console.log('count', count);
      return count > this.bigCasinoGames.length ? this.bigCasinoGames.length : count;
    },
  },
  async mounted() {
  await this.$store.dispatch('awaitGetGames', {
      provider: 'YggDrasil' });
  // Добавим слушателя события изменения размера окна
  window.addEventListener("resize", this.handleResize);
  this.handleResize();
},
created() {
  this.gameProviders = this.$store.getters.getGameProviders;
  window.addEventListener("resize", this.handleResize);
  console.log('.items', this.casinoGames);
  this.handleResize();
},
  watch: {
    dialogMobile(newVal, oldVal) {
      if (!newVal) {
        setTimeout(() => {
          console.log('cleared!')
          this.selectedGame = {
            game: '',
            id: '',
            logo: ''
          }
        }, 200);
      }
    },
    //casinoGames(newGames, oldGames) {
    //  this.showingGamesCount = newGames.length >= 28 ? 18 : newGames.length;
    //},
    casinoGames(newGames, oldGames) {
      let height = window.innerHeight;
      let width = window.innerWidth;
      if (newGames.length >= 28) {
        this.showingGamesCount = 20;
        if (height <= 500) {
          this.showingGamesCount = 15;
        } else if (width > 1240) {
          this.showingGamesCount = 18;
        } else if (width <= 1240 && width > 850) {
          this.showingGamesCount = 20;
        } else if (width <= 850) {
          this.showingGamesCount = 18;
        }
      } else {
        this.showingGamesCount = newGames.length; // Или любое другое значение, когда игр больше или равно 28
      }
    },
  },
  beforeDestroy() {
  window.removeEventListener("resize", this.boundHandleResize);
},
  methods: {
    handleResize() {
      const currentWidth = window.innerWidth;

    // Обрабатываем только изменение ширины влево
    if (currentWidth !== this.previousWidth) {
      // Логика обработки изменения ширины влево
      this.showingGamesCount = this.calculateInitialShowingGamesCount();
    }

    // Сохраняем текущую ширину для следующего сравнения
    this.previousWidth = currentWidth;
      
  },
  calculateInitialShowingGamesCount() {
    let height = window.innerHeight;
    let width = window.innerWidth;
    if (height <= 500) {
      return 15;
    }
    if (width > 1240) {
      return 18;
    } else if (width <= 1240 && width > 850) {
      return 20;
    } else if (width <= 850) {
      return 18;
    }
  },
//========================================================================================================================================================
async showMoreGames() {
  if (this.isAddingGames) {
    return;
  }

  this.isAddingGames = true;

  // Определение разрешения экрана и установка значения increment
  let increment;
  const screenWidth = window.innerWidth;
  let height = window.innerHeight;

    if (height < 500) {
      increment = 25;
      try {
        if (this.casinoGames.length >= this.showingGamesCount + increment) {
          this.showingGamesCount += increment;
        } else {
          this.showingGamesCount = this.casinoGames.length - 1;
        }
        // this.$store.dispatch('awaitGetGames', {size: this.showingGamesCount += increment})
      } finally {
        this.isAddingGames = false;
      }
      return;
    }
  if (screenWidth > 850 && screenWidth <= 1240) {
    increment = 25;
  } else if (screenWidth > 1240) {
    increment = 24;
  } else {
    // По умолчанию для мобильных устройств
    increment = 9;
  }

  
  try {
    if (this.casinoGames.length >= this.showingGamesCount + increment) {
      this.showingGamesCount += increment;
    } else {
      this.showingGamesCount = this.casinoGames.length - 1;
    }
  } finally {
    this.isAddingGames = false;
  }
    },
    async loadAllGames() {
      this.loading = true;
      await this.$store.dispatch('awaitGetGames', { provider: ''});
      this.loading = false;
    },
    async plusLvl(){
      const userId = this.user.id;
      const jwt = localStorage.getItem("jwt");
      console.log('userId, jwt', userId, jwt);
      this.$store.dispatch('plusLvl', userId)
    },
    async playGame(game, demoFlag = false) {
      this.$store.commit('setOpenMobileSearch', false);
      if (this.$store.getters.getUserInfo.id === null) {
        try {
          await this.$router.push('/reg')
      } catch (error) {
     if (error.name !== 'NavigationDuplicated') {
      throw error; 
    }
  }
      } else {
        if (this.$store.getters.getUserInfo.balance == 0 && !demoFlag) {
          // mb === 0
          try {
            await this.$router.push('/deposit');
      } catch (error) {
     if (error.name !== 'NavigationDuplicated') {
      throw error; 
    }
  }
        } else {
          await this.$router.push({ name: 'Game', params: { id: game.id, demo: demoFlag } });
        }
      }
    },
    mobileSelectGame(id) {
      this.selectedGame = id;
      this.dialogMobile = true;
    },
//========================================================================================================================================================
    
    onTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
    },
    async onTouchEnd(event) {
    this.touchEndX = event.changedTouches[0].clientX;


    if (this.touchStartX - this.touchEndX > 50) {
      // влево
      this.selectNextProvider();
      
    } else if (this.touchEndX - this.touchStartX > 50) {
     // вправо
      this.selectPreviousProvider();
    }
  },
  async selectNextProvider() {
  const nextProviderIndex = (this.currentProviderIndex + 1) % this.gameProviders.length;
  this.currentProviderIndex = nextProviderIndex;
  const currentProvider = this.gameProviders[nextProviderIndex].text;
  
  const nextProviderIndexWrapped = (nextProviderIndex + 1) % this.gameProviders.length;
  const nextProvider = this.gameProviders[nextProviderIndexWrapped].text;
  
  const previousProviderIndexWrapped = (nextProviderIndex > 0) ? (nextProviderIndex - 1) : (this.gameProviders.length - 1);
  const previousProvider = this.gameProviders[previousProviderIndexWrapped].text;
    await this.$store.dispatch('awaitGetGames', { 
      provider: currentProvider, 
      providerNext: nextProvider,
      providerPrevious: previousProvider,
      game: this.searchGame 
    });
  
},

async selectPreviousProvider() {
  const previousProviderIndex = (this.currentProviderIndex > 0) ? (this.currentProviderIndex - 1) : (this.gameProviders.length - 1);
  this.currentProviderIndex = previousProviderIndex;
  const currentProvider = this.gameProviders[previousProviderIndex].text;
  
  const nextProviderIndexWrapped = (previousProviderIndex + 1) % this.gameProviders.length;
  const nextProvider = this.gameProviders[nextProviderIndexWrapped].text;
  
  const previousProviderIndexWrapped = (previousProviderIndex > 0) ? (previousProviderIndex - 1) : (this.gameProviders.length - 1);
  const previousProvider = this.gameProviders[previousProviderIndexWrapped].text;
  
    await this.$store.dispatch('awaitGetGames', { 
      provider: currentProvider, 
      providerNext: nextProvider,
      providerPrevious: previousProvider,
      game: this.searchGame 
    });
  
},
//========================================================================================================================================================

//========================================================================================================================================================
getGameProviders() {
      return this.gameProviders[this.currentProviderIndex];
    },
//========================================================================================================================================================
getNextProvider() {
      const nextProviderIndex = (this.currentProviderIndex + 1) % this.gameProviders.length;
      return this.gameProviders[nextProviderIndex];
    },
//========================================================================================================================================================





    
   // bigBlockStyle(index) {
      //if (this.bigGamesIndexies.includes(index)) {
      //let indexOfIndex = index//this.big.indexOf(index);
      //let columnPos = '1/3';
     // if (!(indexOfIndex % 2 === 0)) {
      //  columnPos = '1/3';
     // } else {
        //1264 - 4 cols
        //850  - 3 cols
        //500  - 2 cols
       // let rightColPos = '5/7';
       // let width = screen.width;
       // switch (true) {
       //   case width > 1264:
       //     rightColPos = '5/7';
       //     break
       //   case 1264 >= width && width > 850:
       //     rightColPos = '3/5';
       //     break
       // }
        //console.log('rightcol pos = ', rightColPos)
      //  columnPos = indexOfIndex > 2 ?
      //    rightColPos : '3/5';
      //}
      //console.log('indexOfIndex',indexOfIndex)
      //console.log(`grid-row: ${indexOfIndex * 2 + 1} / ${indexOfIndex * 2 + 3};grid-column: ${columnPos}`);

     // return `grid-row: ${indexOfIndex * 2 + 1} / ${indexOfIndex * 2 + 3};grid-column: ${columnPos}`;
      /*} else {
        return '';
      }*/
    //},

    //bigMobileBlockStyle(index) {
    //  //if (this.bigGamesIndexies.includes(index)) {
    //  let pos = index * 4 + 2 //indexOfIndex * 2 + ((this.showingGamesCount - indexOfIndex * 2) / 2);
    //  return `grid-row: ${pos} / ${pos + 2};grid-column: 1/3`;
    //  /*} else {
    //    return '';
    //  }*/
//
    //}

  },
}
</script>


<style lang="scss" scoped>
.white-heart {
  width: 15px !important;
  height: 15px !important;
  margin-right: 5px;

  @media (max-width:500px) {
    width: 13px !important;
    height: 13px !important;
  }

  &_big {
    width: 15px !important;
    height: 15px !important;
  }
}

.casinos_images {
  @media (max-width:1330px) {
    padding: 0px;
  }

  @media (max-width:900px) {
    padding: 0 12px;
  }

  padding-top: 0px !important;
}

.casinos_desktop {
  justify-content: center;
}

.bottom_GameName {
  background: black;
  height: 25px;
  position: relative;
  top: -30px;
  z-index: 1;
  color: white;
  opacity: 0.8;
  overflow: hidden;
}

.gameName {
  height: 25px;
  max-width: calc(100% - 20px);
  overflow: hidden;
  color: white;
  margin: 0 auto;
  position: relative;
  top: -5px;

  @media (max-width:400px) {
    font-size: 12px;
  }

  @media (max-width:850px) {
    display: none;
  }

  //background: black;
  animation: disName 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
  visibility: hidden;
}

@keyframes disName {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

.demo__link {
  cursor: pointer;
  min-width: 75px;
  border: 1px solid var(--white);
  padding: 0 33.5px;

  @media (min-width:850.98px) {
    //position: absolute;
    //left: calc(50% - 20px); 
    padding: 0 33px;
    margin-top: 15px;
  }
  
  @media (min-width:500.98px) {
    min-width: 118px;
    padding: 0 3px;
  }
  @media (min-width:768.98px) {
    padding: 0 15px;
  }
  @media (max-width:850.98px) {}

  @media (max-width:500px) {
    //position: absolute;
    //left: calc(50% - 20px); 
    min-height: 25px;
    padding: 0 3px;
  }

  @media (max-width:450px) {
    //position: absolute;
    //left: calc(50% - 20px); 

    min-height: 20px;
  }

  min-height: 35px;
  display: flex;
  justify-content:center;
  align-items: center;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  transition: border 0.3s,
  color 0.3s;

  @media (any-hover: hover) {
    &:hover {
      border: 1px solid var(--red);
      color: var(--red);
    }
  }

}

.show_1264 {
  display: none;
}
.gridBarBlock {
  //max-width: 310px;
  grid-row: 1 / 4;
  grid-column: 5 / 7;
  margin-left: 15px;
}

.v-overlay__scrim {
  //background: url('../../assets/images/mafia_site_bg.png');
  background: none !important;
}
.grid__wrapper {
  display: grid;
  flex-wrap: wrap;
  //grid-template-columns: repeat(6, 1fr);
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 230px;
  //grid-auto-rows: 200px;
  //align-items: baseline;
  //grid-row-gap: 95px;
  grid-gap: 5px;
  width: 100%;
  @media (max-width:1240.98px) {
    grid-template-columns: repeat(5, 1fr);
    
  }
  @media (max-width:1050.98px) {
    grid-auto-rows: 210px;
  }
  @media (max-width:1020.98px) {
  }
  @media (max-width:940.98px) {
    grid-auto-rows: 190px;
  }
  
}



.image-container{
  width: 200px;
  height: 200px;
  @media (max-width:1240.98px) {
    width: 205px;
  height: 205px;
  }
  @media (max-width:1050.98px) {
    width: 182px;
  height: 182px;
  }
  @media (max-width:940.98px) {
    width: 165px;
  height: 165px;
  }
  
  margin-bottom: 8px;
  position: relative;
  &::before {
    content: '';
    display: block;
    border-radius: 16px;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 8;
    width: 100%;
    height: 100%;
    background-color: var(--red);
    animation: disappear 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    opacity: 1;
    visibility: visible;
  }

  &::after {
    content: '';
    position: absolute;
    border-radius: 16px;
    top: 0;
    left: 0;
    z-index: 8;
    width: 100%;
    height: 100%;
    background: url('../../assets/images/main_logo.png') center / 90px no-repeat;
    animation: disappear2 1s;
    opacity: 0.6;
    animation-delay: 1s;
    animation-fill-mode: forwards;

  }
}

.casino_overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__span {
    text-transform: uppercase;
    font-size: 16px;
    padding: 0 8px;
    line-height: 1.3;
    @media (max-width:500px) {
      font-size: 10px;
    }
  }

  z-index: 5;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height:100%;
  align-items: center;
  vertical-align: middle;
  //  -webkit-transform: translate(-50%, -58%);
  
  text-align: center;
  //background: rgba(0, 0, 0, 0.4);
  //backdrop-filter: blur(3px);
  //border: 1px solid var(--red);
  //box-sizing: border-box;
  //box-shadow: inset 0 0 10px var(--third-flamingo), inset 0 0 5px rgba(255, 255, 255, 0.35);
  border-radius: 16px;
  overflow: hidden;
  text-decoration: none;
  backdrop-filter: blur(2px);

  transition: all 0.3s ease-out;
  @media (min-width:849.98px) {
    &::before {
      content: '';
      background-color: rgba(white, 0.4);
      width: 60%;
      height: 100%;
      top: 0px;
      left: -125%;
      transform: skew(45deg);
      z-index: 5;
      position: absolute;
      transition: left 0.5s ease-out, background-color 0.5s;
    }
  }

  &_mobile {
    display: block;
    opacity: 0;
    visibility: hidden;

    @media (any-hover: hover) {
      display: none;

    }
  }

}

.casino_img_col {
  position: relative;
  display: flex;
    flex-direction: column;
    align-items: center;
  img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
    visibility: visible;

  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes disappear2 {
  0% {
    opacity: 0.6;
    visibility: visible;

  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.casino_image {
  border: transparent solid 0px;
  //object-fit: cover;
  border-radius: 16px;
  transition: opacity 0.5s;
  transition-delay: 0.2s;
}
.mobile_grid_wrapper {
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content:center;
  align-items: center;
  grid-gap: 6px;
  @media (max-height:500px) and (orientation: landscape) {
    grid-template-columns: repeat(5, 1fr);
    justify-content:center;
    align-items: center;
  }
}
.mobile__div-t {
  
  .red-square-t {
    &::before {
      content: '';
      display: block;
      border-radius: 16px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      width: 100%;
      height: 100%;

      background-color: var(--red);
      animation: disappear 1s;
      animation-delay: 1s;
      animation-fill-mode: forwards;
      opacity: 1;
      visibility: visible;

    }

    &::after {
      content: '';
      position: absolute;
      border-radius: 16px;
      top: 0;
      left: 0;
      z-index: 5;
      width: 100%;
      height: 100%;
      background: url('../../assets/images/main_logo.png') center / 80px no-repeat;
      animation: disappear2 1s;
      opacity: 0.6;
      animation-delay: 1s;
      animation-fill-mode: forwards;

      @media (max-width:425.98px) {
        background-size: 50px;
      }
    }
  }

  &__click {
    perspective: 1000px;
    transition: transform 0.8s;
    transform-style: preserve-3d;

    .casino_overlay_mobile {
      top: 0%;
      left: 0%;
      width: 100%;
      height: 100%;
      z-index: 1;
      transform: rotateY(180deg);
      backdrop-filter: blur(2px);
      background-color: rgba(0, 0, 0, 0.7);
      transition: opacity 0.5s, visibility 0.5s;
      transition-delay: 0.1s;

      .demo__link {
        @media (max-width:500px) {
          font-size: 12px;
          left: calc(50% - 17px) !important;
        }
      }
    }

    &:hover {
      transform: rotateY(180deg);

      .casino_overlay_mobile {
        opacity: 1;
        visibility: visible;

      }
    }

  }
}

.casino_img_col:hover .casino_image {
  opacity: 0.3;
}

.casino_img_col:hover .casino_overlay {
  opacity: 1;
  visibility: visible;

  &::before {
    left: 150%;
  }

}

.moreGames_btn {
  margin: 10px 0;
}

.casinos_mobile {
  display: none !important;
}

.image__column {
  flex: 0 0 auto !important;
  width: auto !important;
}




//media===============================================================================================================================

@media screen and (max-width: 1329.98px) {
  .moreGames_col {
    text-align: center;
    margin-right: 0px;
  }

  .hide_1264 {
    display: none;
  }

  .show_1264 {
    display: block;
  }

}


@media screen and (max-width: 850px) {


  .casinos_desktop {
    display: none !important;
  }

  .casinos_mobile {
    display: flex !important;
    margin: 0 !important;
  }

  .hide_dt {
    display: block;
  }

}

@media screen and (max-width: 500px),
(max-height: 500px) and (orientation: landscape) {}</style>

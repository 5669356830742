// lossLimit.js
const state = {};

const mutations = {};

const getters = {};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

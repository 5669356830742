
<template>
    <div class="wrapper-alert">
    <v-alert v-for="item in headerNotificationArray" :key="item.id" :type='item.type' dense :class="getClassObject(item)" 
    >
        <div class="flex-content">
          <span v-html="item.message"> </span>
          <span class="link" @click="clicked(item)">{{item.clickText}}</span>
        </div>
        <v-icon class="eternal-notif__close" @click="toDeleteEternal(item)">close</v-icon>
    </v-alert>
</div>
</template>

<script>
export default {
    name: 'HeaderNotifications',
    data() {
        return {
        }
    },
    computed:{
      headerNotificationArray() {
           return this.$store.state.alerts.headerNotifications;
       },
    },
    methods: {
      clicked(item){
        this.$store.dispatch('alerts/deleteHeader', item)
      },
    getClassObject(item) {
      return {
        'notification-h':item.type,
      };
    },
    toDeleteEternal(item){
      localStorage.setItem('deletedHeaderNotification', JSON.stringify(item));
      // console.log('item', localStorage.getItem('deletedNotification'));
        this.$store.dispatch('alerts/deleteHeader', item)
       },
  },
}
</script>

<style lang="scss" scoped>
.wrapper-alert{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 115;
  top: 0px;
  left: 0;
  pointer-events: none;
}
.notification-h{
  margin-bottom: 0px;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--white);
  width: 100%;
  max-height: 50px;
  z-index: 2;
  opacity: 1;
  font-size: 14px;
  visibility: visible;
  padding: 5px;
  transition: opacity 0.3s, visibility 0.3s, top 0.3s;
  @media (max-width:1100px){
    font-size: 12px;
  }
  @media (max-width:992px){
    max-height: 82px;
    height: 82px;
  }
}
.flex-content{
  display: flex;
  
  @media (min-width:992px){
    align-items: center;
  }
  @media (max-width:992px){
      flex-direction: column;
  }
  span{
    @media (max-width:992px){
      text-align: left;
  }
  }
}
.link{
  text-decoration: underline;
  cursor: pointer;
  @media (min-width:992px){
    margin-left: 4px;
  }
  
}
.notification-enter-active, .notification-leave-active {
  transition: opacity 0.3s;
}
</style>

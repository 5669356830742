<template>
    <FlipCardFutureOne :index="index">
        <template slot="front">
            <bonus-future-one-front
            :index="index" 
            :bonusId="cardFuture.id" 
            :cardFuture="cardFuture"
            :is_active="cardFuture.is_active"
            :is_used="cardFuture.is_used"
            :type="cardFuture.type"
            :expire_in_days="cardFuture.expire_in_days + ' ' + this.$t('day')"
            :expire_in_seconds="cardFuture.expire_in_seconds"
            :bonus_percent="cardFuture.bonus_percent + '%'"
            ></bonus-future-one-front>
        </template>
        <template slot="back">
            <bonus-future-one-back :index="index"
            :type="cardFuture.type"
            :bonus_percent="cardFuture.bonus_percent + '%'"
            :wager="cardFuture.wager"
            :expire_in_days="cardFuture.expire_in_days + ' ' + this.$t('day')" 
            :min_dep="cardFuture.min_dep + ' ' + this.user.currency"
            :max_dep="cardFuture.max_dep + ' ' + this.user.currency"
            :currency_limits="cardFuture.currency_limits"
            ></bonus-future-one-back>
        </template>
      </FlipCardFutureOne>

</template>

<script>
import { mapGetters } from 'vuex';
import BonusFutureOneBack from './BonusFutureOneBack.vue';
import BonusFutureOneFront from './BonusFutureOneFront.vue';
import FlipCardFutureOne from './FlipCardFutureOne.vue'
    export default {
        name: 'BonusFutureOne',
        components: {
            BonusFutureOneFront,
            BonusFutureOneBack,
            FlipCardFutureOne,
        },
        computed:{
            ...mapGetters({
                user: 'getUserInfo'
            })
        },
        props: {
        index: {
          type: Number,
          required: true,
        },
        bonusId: {
          type: Number,
          required: true,
        },
        cardFuture: {
          type: Object, 
          required: true,
        },
     },
        data() {
            return {
                
            }
        },
        
    }
</script>

<style lang="scss" scoped>

</style>
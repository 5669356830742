// alerts.js
const state = {
  alerts: [],
  //========================================================================================================================================================
  eternalNotifications: [],
  headerNotifications: [],
};
let nextId = 1;
const mutations = {
  PUSH(state, notification) {
    const id = nextId++;
    state.alerts.push({
      ...notification,
      id: id,
    });
    setTimeout(() => {
      state.alerts = state.alerts.filter(
        (notification) => notification.id !== notification.id
      );
    }, 4000);
  },
  //========================================================================================================================================================
  PUSH_ETERNAL(state, eNotif) {
    const id = nextId++;
    state.eternalNotifications.push({
      ...eNotif,
      id: id,
    });
  },
  DELETE_ETERNAL(state, eNotiftoRemove) {
    state.eternalNotifications = state.eternalNotifications.filter(
      (eNotif) => eNotif.id !== eNotiftoRemove.id
    );
  },
  DELETE_ALL_ETERNALS(state) {
    state.eternalNotifications = [];
  },

  //========================================================================================================================================================
  DELETE_HEADER(state, hNotiftoRemove) {
    state.headerNotifications = state.headerNotifications.filter(
      (hNotif) => hNotif.id !== hNotiftoRemove.id
    );
  },
  DELETE_ALL_HEADER(state) {
    state.headerNotifications = [];
  },
  PUSH_HEADER(state, hNotif) {
    const isNotifExists = state.headerNotifications.some(
      (notif) => notif.id === hNotif.id
    );
    if (!isNotifExists) {
      const id = nextId++;
      state.headerNotifications.push({
        ...hNotif,
        id: id,
      });
    }
  },
};

const getters = {};

const actions = {
  add({ commit }, notification) {
    commit("PUSH", notification);
  },
  //========================================================================================================================================================
  addEternal({ commit }, eNotif) {
    commit("PUSH_ETERNAL", eNotif);
  },
  deleteEternal({ commit }, eNotiftoRemove) {
    commit("DELETE_ETERNAL", eNotiftoRemove);
  },
  deleteAllEternals({ commit }) {
    commit("DELETE_ALL_ETERNALS");
  },
  //========================================================================================================================================================
  deleteAllHeader({ commit }) {
    commit("DELETE_ALL_HEADER");
  },
  deleteHeader({ commit }, eNotiftoRemove) {
    commit("DELETE_HEADER", eNotiftoRemove);
  },
  addHeader({ commit }, hNotif) {
    commit("PUSH_HEADER", hNotif);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

<template>
    <div>
            <div class="profile-wrapper">
                <div class="profile-box">
                    <v-card dark color="var(--light-flamingo)" class="profile-card" min-height="300" style="border-radius: 16px;">
                        <v-card-title class="pa-0">
                        </v-card-title>
                        <v-card-text class="profile-card__content profile-card-content" style="">
                            <v-icon>mdi-lock</v-icon>
                            <div class="profile-card-content__title">
                                {{ $i18n.t('changePass') }}
                            </div>
                            <div class="profile-card-content__text">
                                {{ $i18n.t('changePassText') }}
                            </div>
                        </v-card-text>
                        <v-card-actions class="profile-card__actions pa-0">
                            <v-btn class="all-buttons-fw" @click="toOpenDialog">{{ $t('changePass') }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </div>
            </div>
            <v-dialog v-model="changePassDialog" :persistent="formLoading" content-class="br-16" scrollable max-width="475px">
                <v-card>
                  <v-card-title class="justify-center">
                    <h3>{{ $t('changePass') }}</h3>
                  </v-card-title>
                  <v-card-text class="pb-0 py-2">
                    <v-form v-model="isValidInputs" ref="formInputs">
                        <v-text-field 
                        @focus="passErrorFlag = false"
                         :label="passErrorFlag ? $i18n.t('currentPassError') : $i18n.t('currentPass')" 
                         hide-details="auto"
                         outlined 
                         dense
                         color="var(--main-flamingo)" 
                         class="main-input input-sign input-auto" 
                         v-model="oldPass" 
                         :error="passErrorFlag"
                         :error-messages="passErrorMsgs"
                         :rules="[v => !!v || $t('requiredField')]"
                         required
                         :success="passSuccessFlag"
                         :type="showCurrentPassword ? 'text' : 'password'"
                         :append-icon="showCurrentPassword ? 'visibility_off' : 'visibility'"
                         @click:append="showCurrentPassword = !showCurrentPassword">
                         </v-text-field>
                         <v-text-field 
                         :label="passEqualyErrorFlag ? $i18n.t('newPassError') : $i18n.t('newPass')" 
                         :success="passSuccessFlag"
                         :rules="passwordRules"
                         hide-details="auto"
                         @input="clearErrors"
                         outlined 
                         dense
                         color="var(--main-flamingo)" 
                         class="main-input input-sign input-auto" 
                         :error="passEqualyErrorFlag"
                         filled
                         v-model="newPass" 
                         :type="showNewPassword ? 'text' : 'password'" 
                         :append-icon="showNewPassword ? 'visibility_off' : 'visibility'"
                         @click:append="showNewPassword = !showNewPassword"
                         >
                         </v-text-field>
                         <v-text-field 
                         :label="$i18n.t('confirmPass')"
                         :error="passEqualyErrorFlag"
                         :rules="passwordRules"
                         :success="passSuccessFlag"
                         outlined 
                         filled
                         hide-details="auto"
                         required
                         dense
                         color="var(--main-flamingo)" 
                         class="main-input input-sign input-auto" 
                         :type="showNewVerifyPassword ? 'text' : 'password'"
                         :append-icon="showNewVerifyPassword ? 'visibility_off' : 'visibility'"
                         @click:append="showNewVerifyPassword = !showNewVerifyPassword"
                          v-model="verifyPass" 
                          >
                         </v-text-field>
                    </v-form>
                  </v-card-text>
                  <!-- Close and Save -->
                  <v-card-actions class="mb-3">
                    <v-btn :disabled="!isValidInputs" v-if="!successUpdate" :loading="formLoading" class="all-buttons-fw all-buttons-fw_green" @click="toChangePassword">
                        {{ $t('save_changes') }}
                    </v-btn>
                    <v-btn color="green" class="all-buttons-fw all-buttons-fw_green" v-if="successUpdate">
                        <v-icon dark>
                          done
                        </v-icon>
                      </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>    
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
    export default {
        name: 'PlayerSecurity',
        data() {
            return {
                isValidInputs: true,
                changePassDialog: false,
                formLoading: false,
                showCurrentPassword: false,
                passErrorFlag: false,
                passSuccessFlag: false,
                passEqualyErrorFlag: false,
                showNewPassword: false,
                showNewVerifyPassword: false,
                successUpdate: false,
                passErrorMsgs: '',
                oldPass: '',
                newPass: '',
                verifyPass: '',
                passwordRules: [
                  v => !!v || this.$i18n.t('requiredField'),
                  v => (v && v.length >= 8) || this.$i18n.t('forExamplePass'),
                ],
            }
        },
        computed:{
          ...mapGetters({
            user: 'getUserInfo'
          })
        },
        methods: {
            toOpenDialog(){
                this.changePassDialog = true;
                this.passErrorFlag = false;
                this.$nextTick().then(() => {
                    this.$refs.formInputs.reset();
                    this.$refs.formInputs.resetValidation();
                });
            },
            clearErrors(){
              this.passEqualyErrorFlag = false;
            },
            async toChangePassword(){
                this.formLoading = true;
          // Check Equal in passwords
          if (this.newPass !== this.verifyPass) {
            this.passEqualyErrorFlag = true;
            this.newPass = '';
            this.verifyPass = '';
            this.formLoading = false;
            return;
          }
          const res = await this.$store.dispatch('updateUserPassword', {
            oldPass: this.oldPass,
            newPass: this.newPass
          });
          if (res) {
            this.successUpdate = true;
            this.changePassDialog = false;
            this.passErrorFlag = false;
            this.passEqualyErrorFlag = false;
            this.passSuccessFlag = true;
            this.$router.push('/signIn');
          } else{
            this.passErrorFlag = true;
            this.oldPass = '';
            this.$refs.formInputs.resetValidation();
          }
          this.formLoading = false;
            },
        },
    }
</script>

<style lang="scss" scoped>
::v-deep input:-webkit-autofill,
::v-deep input:-webkit-autofill:hover, 
::v-deep input:-webkit-autofill:focus, 
::v-deep input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
    //position: relative;
    //padding-top: 2px!important;
    //margin-top: 6px!important;
}
.profile-wrapper {
    grid-gap: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 20px;
}
.profile-box {
}
.profile-card {
    display: flex;
    flex-direction: column;
    padding: 20px;
		&__content {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            justify-content:center;align-items: center;
            line-height: 1;
            color: var(--white)!important;
		}
		&__actions {
		}
}
.profile-card-content {
    .v-icon{
        font-size: 45px;
    }
    &__title {
        font-size: 20px;
        text-transform: uppercase;
        margin: 10px 0;
    }

    &__text {
    }
}

</style>
<template>
        <v-dialog 
        :content-class="$isMobile() ? '' : 'passportDialog'" 
        transition 
        :fullscreen="$isMobile() ? true : false"
        v-model="isAva" 
        max-width="700"
        scrollable
        >
            <v-card elevation="0" class="card"
            :class="{
                'card_mobile': $isMobile(),
                'card_pc': !$isMobile(),
            }"
            >
                <avatars-header></avatars-header>
                <v-card-text class="dialog-content mt-2 pb-2">
                    <div v-if="loadingFlag" class="my-gallery">
                        <v-skeleton-loader
                        v-for="index in 20"
                        :key="index"
                        style="flex: 0 0 70px;margin: 5px"
                        width="70"
                        height="70"
                        type="card"
            ></v-skeleton-loader>
                    </div>
                    <vue-picture-swipe v-else 
                    
                    :items='avatars'></vue-picture-swipe>
                </v-card-text>

            </v-card>
        </v-dialog>
</template>

<script>
import AvatarsHeader from './AvatarsHeader'
import VuePictureSwipe from '@/components/Picture/VuePictureSwipe';
import { mapGetters } from 'vuex';
export default {
    components: {
        AvatarsHeader,
        VuePictureSwipe,
    },
    name: 'Avatars',
    data() {
        return {
            selectedAvatar: null,
            loadingFlag: false,
        }
    },
    async mounted() {
        if (!this.avatars.length) {
            this.loadingFlag = true;
            try {
                await this.$store.dispatch('avatars/awaitGetAvatars');
            } catch (error) {
                const notification = {
                    type: "error",
                message: this.$t('justError'),
            };
        this.$store.dispatch("alerts/add", notification);
    } finally{
        this.loadingFlag = false
    }
}
    },
    computed: {
        ...mapGetters({
            user: 'getUserInfo',
            avatars: 'avatars/getAvatars',
        }),
        isAva: {
            get() {
                return this.$store.state.regComponents.isAvaReg;
            },
            set(value) {
                this.$store.commit('regComponents/setAvaReg', value);
            },
        },
    },
    methods: {
    },
}
</script>

<style lang="scss">
.card {
    &_pc {
        max-width: 800px;
    }

    &_mobile {}
}

.my-gallery {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 80px);
    align-items: center;
    figure {
        a {
            // width: 75px;
            // height: 75px;
            display: inline-block;
            img {
                border-radius: 16px;
                max-width: 100%;
                max-height: 100%;
            }
        }

    }
}

.pswp__zoom-wrap {
    //
    //height: 250px;
    //margin: 0 30px;
}

.pswp__button--share {
    display: none;
}

.pswp__img {
    //padding: 0 30px;
    //height: 100%!important;
    object-fit: contain;
    border-radius: 16px;
    overflow: hidden;
}

.dialog-content {
    background-color: var(--white);

    &__title {
        color: #161421;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        margin: 0 0 10px;
    }
}</style>
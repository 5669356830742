<template>
    <v-card flat>
        <v-card-text>
          <v-row class="card-method-row">
            <template v-if="payMethods.length">
              <v-col
                class="card-method-col"
                v-for="(method, index) in filteredPayMethods"
                :key="'method_' + index"
              >
                <div
                  class="content-card-method"
                  @click="selectDepositMethod(method)"
                >
                  <div class="content-card-method__image">
                    <img
                      :src="require(`@/assets/images/${method.logo}`)"
                      alt=""
                    />
                  </div>
                </div>
                <span class="content-card-method__title">
                  {{ method.name }}
                </span>
              </v-col>
            </template>
            <template v-else>
              <v-col class="d-flex align-center">
                <v-skeleton-loader 
              v-for="index in 3"
              class="mr-2"
                :key="index"
                style="flex: 0 0 130px"
              width="130"
              height="100"
              type="card"  />
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
        name: 'DepositPayments',
        methods: {
            selectDepositMethod(method) {
                this.toDeposit = true;
                this.$store.commit(
                  "depositView/setSelectedMethod",
                  method
                );
                this.$store.commit("depositView/setDepositWindowError", true);
                //this.$store.commit("depositView/setMethodSelected", true);
                this.$store.commit("depositView/setMethodMoney", false);
                this.$store.commit("depositView/setMethodCrypto", false);
                this.$store.commit("depositView/setDepositMethodsWindow", false);
            },
        },
        data() {
          return {
            loadingFlag: false,
          }
        },
        mounted(){
          
        },
        computed: {
            ...mapGetters({
              payMethods: "getPayMethods",
            }),
            filteredPayMethods() {
              return this.payMethods.filter((method) => method.type === "DEPOSIT");
            },
        },        
    }
</script>

<style lang="scss" scoped>
.card-method-row {
    display: flex;
    flex-wrap: wrap;
    margin: -1.6666%;
    position: relative;
  }
  .card-method-col {
    align-items: center;
    display: flex;
    flex: 30% 0 0;
    flex-direction: column;
    margin: 1.6666%;
    overflow: hidden;
  }
  .content-card-method {
    align-items: center;
    border: 1px solid #dfe4e8;
    transition: background-color 0.3s;
    @media (any-hover: hover) {
      &:hover {
        background-color: #f2f6f9;
      }
    }
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    margin-bottom: 5px;
    transition: 0.25s;
    width: 100%;
    &__image {
      width: 70px;
      flex: 0 0 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  
    &__title {
      color: #585d6d;
      cursor: pointer;
      font-size: 11px;
      font-weight: 400;
      max-width: 95px;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>
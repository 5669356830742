import store from "./store";
import router from "../router/router";
import { stopTimer } from "../timerWorker";

export function logOut() {
  const remainTime = localStorage.getItem("remainingTimeInSeconds");
  store.commit("bonuses/set", {
    type: "spinsBonuses",
    items: [],
  });
  store.commit("levels/SET_LVL_ITEMS", []);
  store.commit("levels/SET_NEXT_BONUS_AMOUNT", "");
  store.commit("levels/SET_BET_AMOUNT", "");
  store.commit("levels/setProgressValue", "");
  store.commit("bonuses/set", {
    type: "depositBonuses",
    items: [],
  });
  store.commit("set", {
    type: "isInstaReg",
    items: false,
  });
  //store.commit("set", {
  //  type: "regData",
  //  items: null,
  //});
  store.commit("depositView/set", {
    type: "depositExitWindow",
    items: false,
  });
  store.commit("depositView/set", {
    type: "depositMethodsWindow",
    items: false,
  });
  store.commit("depositView/set", {
    type: "depositWindow",
    items: false,
  });
  store.commit("depositView/set", {
    type: "isSelectedMethod",
    items: false,
  });
  store.commit("depositView/set", {
    type: "isMethodMoney",
    items: false,
  });
  store.commit("depositView/set", {
    type: "isMethodCrypto",
    items: false,
  });
  store.commit("depositView/set", {
    type: "isMethodExit",
    items: false,
  });
  if (remainTime > 0) {
    stopTimer();
    store.dispatch("updateUserInfo", { time_limit: remainTime });
    setTimeout(() => {
      localStorage.removeItem("remainingTimeInSeconds");
      localStorage.removeItem("endTime");
    }, 50);
  }
}

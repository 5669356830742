import store from "./store/store";
export function validateSessionTime(maxSessionTime) {
  const errors = [];
  if (maxSessionTime < 1) {
    errors.push("Minimum session time is 10 minutes.");
  }
  return errors;
}

let timerInterval;

export function startTimer(maxSessionTimeInSeconds) {
  if (timerInterval) {
    clearInterval(timerInterval);
    timerInterval = null;
  }

  if (maxSessionTimeInSeconds <= -1) {
    localStorage.removeItem("endTime");
    localStorage.removeItem("remainingTimeInSeconds");
    return; // stop function
  }

  let endTime = localStorage.getItem("endTime");

  if (!endTime) {
    endTime = Date.now() + maxSessionTimeInSeconds * 1000;
    localStorage.setItem("endTime", endTime.toString());
  }

  function updateLocalStorage() {
    const remainingTimeInSeconds = Math.max(
      0,
      Math.floor((endTime - Date.now()) / 1000)
    );
    localStorage.setItem(
      "remainingTimeInSeconds",
      remainingTimeInSeconds.toString()
    );
  }

  function checkTime() {
    updateLocalStorage();
    const remainingTimeInSeconds = localStorage.getItem(
      "remainingTimeInSeconds"
    );

    if (remainingTimeInSeconds <= 0) {
      store.commit("setTimeoutModal", true);
      clearInterval(timerInterval);
      timerInterval = null;
    }
  }

  timerInterval = setInterval(checkTime, 1000);
}

export function stopTimer() {
  if (timerInterval) {
    clearInterval(timerInterval);
    localStorage.removeItem("endTime");
    localStorage.removeItem("remainingTimeInSeconds");
    timerInterval = null;
  }
}

// bonuses.js
import axios from "axios";
const state = {
  bonusTournamentFlipped: false,
  bonusNoLoginFlipped: false,
  spinsBonuses: [],
  depositBonuses: [],
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
  flipBonus(state, payload) {
    const { bonuses } = payload;
    state[bonuses][payload.index].flipped =
      !state[bonuses][payload.index].flipped;
  },
  noLoginFlip(state, value) {
    state.bonusNoLoginFlipped = value;
  },
  updateBonus(state, { list, id }) {
    const idx = state[`${list}Bonuses`].findIndex((item) => item.id === id);
    if (idx !== -1) {
      state[`${list}Bonuses`][idx].status = "active";
    }
  },
};

const getters = {
  getSpinsBonuses: (state) => state.spinsBonuses,
  getDepositBonuses: (state) => state.depositBonuses,
};

const actions = {
  async getUserBonuses({ commit, state }) {
    // /api/getUserBonuses
    const jwt = localStorage.getItem("jwt"); // jwt
    try {
      const response = await axios.get(`/api/bonuses/my`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      if (response.status === 200) {
        // deposit & freeSpins
        const userBonuses = response.data;
        const { deposit, free_rounds } = userBonuses;
        const flippedDep = deposit.map((dep) => ({
          ...dep,
          flipped: false,
        }));
        const flippedSpin = free_rounds.map((spin) => ({
          ...spin,
          flipped: false,
        }));
        state.depositBonuses = flippedDep;
        state.spinsBonuses = flippedSpin;
      }
    } catch (error) {
      console.log("error = ", error);
    }
  },
  async activateUserBonus({ dispatch, commit }, bonus) {
    let json = {
      bonus_id: bonus.id,
    };

    const jwt = localStorage.getItem("jwt");

    await axios
      .patch(`/api/bonuses/${bonus.id}/activate`, json, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        if (response.status === 204) {
          commit("updateBonus", { list: bonus.type, id: bonus.id });
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  async deleteUserBonus({ dispatch }, id) {
    const jwt = localStorage.getItem("jwt");
    await axios
      .delete(`/api/bonuses/my/${id}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

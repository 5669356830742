<template>
  <v-card class="front-bonus">
    <v-card-title class="front-bonus__header header-bonus">
      <div class="header-bonus__label">
        <font-awesome-icon style="font-size: 12px;" icon="gift" />
      </div>
      <!-- {{ $i18n.t('bonusesFree') }} -->
      <div class="header-bonus__title"> {{ $t('firstBonuses') }}</div>
      <div @click="flipCard" class="header-bonus__label">
        <font-awesome-icon icon="info" style="font-size: 12px;" />
      </div>
    </v-card-title>
    <v-card-text @click="depositRedirect" style="width: auto;" class="py-0 front-bonus__main main-bonus">
      <!--<div class="main-bonus__image">
              <img src="@/assets/images/bonus.png" width="100%" /> 
            </div>-->
      <div class="main-bonus__title">
        <span>200%</span>
        <strong>
          {{$t('bonusToDeposit')}}
        </strong>
        <p>+ 40 {{ $t('bonusesFree') }} </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'BonusWelcomeSpinsOneFront',
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      freeRoundsBonuses: 'getFreeRoundsBonuses',
      depositBonuses: 'getDepositBonuses',
      userBonuses: 'getUserBonuses',
      user: 'getUserInfo'
    }),
    bonusItems() {
      if (this.userBonuses && this.userBonuses.free_rounds) {
        return this.userBonuses.free_rounds.map(bonus => ({
          type: bonus.type,
          count: bonus.count,
          expire_in_days: bonus.expire_in_days + ' ' + this.$t('day'),
          game_name: bonus.game_name,
          wager: bonus.wager,
          lines: bonus.lines,
          
        }));
      } else {
        return [];
      }
    },
  },
  methods: {
    async depositRedirect() {
          await this.$router.push('/reg')
    },
    flipCard() {
      this.$store.commit('bonuses/noLoginFlip', true);
    },
  },
}
</script>

<style lang="scss" scoped>
.front-bonus {
  //background: linear-gradient(173.41deg,#0094ff 4.14%,#5607d6 87.72%)!important;
  background: var(--light-flamingo)!important;
  //background: url('../../../../../assets/images/bonusCard.jpg') 0 70% / cover no-repeat;
  color: var(--white) !important;
  //height: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px !important;

  &__header {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
  }

  &__content {}

  &__main {
    flex: 1 1 100%;
    position: relative;
    z-index: 2;
  }

  &__timer {
    position: relative;
    z-index: 2;
  }

}

.header-bonus {
  padding: 10px;

  &__label {
    height: 24px;
    justify-content: center;
    
    position: relative;
    width: 24px;
    align-items: center;
    display: flex;
    position: relative;
    transition: background-color 0.3s;
    &:last-child{
      cursor: pointer;
      
      @media (any-hover: hover){
        &:hover{
          &::before {
            background-color: hsla(0, 0%, 100%, .4);
          }
        }
      }
      
    }
    &::before {
      background-color: hsla(0, 0%, 100%, .2);
      border-radius: 50%;
      content: "";
      //height: calc(100% - 20px);
      height: 24px;
      width: 24px;
      position: absolute;
      //width: calc(100% - 20px);
    }
  }

  &__title {
    display: block;
    line-height: 1.5;
    max-width: calc(100% - 68px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;

  }
}

.main-bonus {

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  @media (any-hover: hover){
    &:hover{
      .main-bonus__title{
        transform: scale(1.1);
      }
    }
  }
  &__image {}

  &__title {
    text-transform: uppercase;
    font-weight: 900;
    color: var(--white) !important;
    line-height: 1;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s;
    span {
      font-size: 95px;
    }
    strong{
      font-size: 35px;
    }
    p {
      font-size: 25px;
    }
  }




}

</style>
<template>
  <aside class="right-aside">
    <div class="tournament" @click="$router.push('profile/promotions')">
        <font-awesome-icon class='tournament__icon' icon="trophy" />
    </div>
    <div class="chat">
        <span :class="{'ru' : $i18n.locale === 'ru'}">{{$t('help')}}</span>
    </div>
    <div
      class="lang"
      ref="langDropdown"
      :class="{ '_active': isLangDropdownOpen}"
      @click="openAllLang"
      v-click-outside="openLang"
      :style="langDropdownStyle"
      >
      <div
        class="lang__item"
        @click="changeLanguage(lang)"
        v-for="(lang, index) in sortedLanguages"
        :key="index"
      >
        <div class="heart-mask">
          <img :src="lang.logo" width="100%" height="100%" />
        </div>
        <!-- {{ lang }} -->
      </div>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "Aside",
  data() {
    return {
      isLangDropdownOpen: false,
      isLangBlock: false,
      translation: 0,
      languageImages: {
        en: "https://d1sc13y7hrlskd.cloudfront.net/flags/gb.png",
        ru: "https://d1sc13y7hrlskd.cloudfront.net/flags/ru.png",
      },
    };
  },
  //mounted() {
  //  document.addEventListener("click", this.closeLangDropdownOnClickOutside);
  //},
  //beforeDestroy() {
  //  document.removeEventListener("click", this.closeLangDropdownOnClickOutside);
  //},
  computed: {
    ...mapGetters({
      supportedLanguages: 'localization/getLocalizations'
    }),
    sortedLanguages() {
    const supportedLanguages = this.$store.getters['localization/getLocalizations'].slice(); 

    const currentLang = localStorage.getItem("locale") || 'en';

    const index = supportedLanguages.findIndex(lang => lang.language_code === currentLang);

    if (index !== -1) {
      const currentLangObj = supportedLanguages.splice(index, 1)[0];
      supportedLanguages.unshift(currentLangObj); 
    }

    return supportedLanguages;
  },
  //   supportedLanguages() {
  //   const allLanguages = Object.keys(this.$i18n.messages);
  //   const currentLang = this.$i18n.locale;

  //   if (!allLanguages.includes(currentLang)) {
  //     return allLanguages;
  //   }

  //   const languages = [currentLang, ...allLanguages.filter(lang => lang !== currentLang)];

  //   return languages;
  // },
  calcLang(){
    return ((this.supportedLanguages.length - 1) * 44);
  },
  langDropdownStyle() {
      if (this.isLangDropdownOpen) {
        return {
          transform: `translate3d(0px, 0, 0)`
        };
      } else {
        return {
          transform: `translate3d(${this.calcLang}px, 0, 0)`
        };
      }
    },
  },
  methods: {
    closeLangDropdownOnClickOutside(event) {
      const langDropdown = this.$refs.langDropdown;
      if (langDropdown && !langDropdown.contains(event.target)) {
        // close dropdown
        this.isLangDropdownOpen = false;
      }
    },
    openAllLang() {
        if (!this.isLangBlock) {
            
            this.isLangDropdownOpen = !this.isLangDropdownOpen;
        }
        
    },
    openLang() {
      if (this.isLangDropdownOpen) {
        
        this.isLangDropdownOpen = false;
      }
        
    },
    changeLanguage(code) {
        if (this.isLangDropdownOpen) {
      this.isLangBlock = true;
      // this.$i18n.locale = value;
      
      this.isLangDropdownOpen = false;
      // console.log(this.isLangDropdownOpen);
      // this.updateSupportedLanguagesOrder(value);
      const isCurrentLang = localStorage.getItem("locale");
      if (isCurrentLang !== code.language_code) {
        this.$store.dispatch('localization/awaitGetSelectLocal', code.language_code);
        location.reload();
      }
      setTimeout(() => {
        this.isLangBlock = false;
      }, 0);
    }
    },
    updateSupportedLanguagesOrder(lang) {
      const index = this.supportedLanguages.indexOf(lang);

      if (index !== -1) {
        this.supportedLanguages.splice(index, 1);
        this.supportedLanguages.unshift(lang);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.right-aside {
    gap: 5px;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    top: 244px;
    //width: 43px;
    z-index: 10;
    pointer-events: none;
    right: 0;
    @media (max-width:992px) {
        display: none;
      }
      > *{
        pointer-events: all;
      }
  }
.chat {
    background-color: var(--green-success);
    border-radius: 16px 0 0 16px;
    color: var(--white);
    text-transform: uppercase;
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 99px;
    justify-content: center;
    text-align: center;
    transition: background-color .3s ease;
    white-space: nowrap;
    width: 43px;
    @media (any-hover: hover){
        &:hover{
            background-color: #0cb471;
        }
    }
    span{
        transform: rotate(90deg);
        user-select: none;
        font-size: 22px;
        &.ru{
            font-size: 14px;
        }
    }
}
.tournament {
    align-items: center;
    background-color: var(--dark-d-flamingo);
    border-radius: 16px 0 0 16px;
    cursor: pointer;
    display: flex;
    font-size: 18px;
    height: 40px;
    justify-content: center;
    text-align: center;
    transition: all .3s ease;
    width: 43px;
		&__icon {
		}
}

.lang {
  padding:3px 4px;
  
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-radius: 16px 0 0 16px;
  justify-content: flex-start;
  transform: translate3d(50px, 0, 0);
  transition: transform 0.5s;
  white-space: nowrap;
  color: var(--white);
  font-weight: 600;
  cursor: pointer;
  background-color: var(--dark-flamingo);
  &._active{
    // transform: translate3d(5px, 0, 0);
  }
  
  &__item {
    &:not(:last-child) {
      margin-right: 5px;
  
    }
  }
  

}
.heart-mask {
  -webkit-mask-image: url("../../assets/images/white-heart.svg");
  mask-image: url("../../assets/images/white-heart.svg");
  -webkit-mask-size: cover;
  mask-size: cover;
  width: 38px;
  height: 38px;
  position: relative;
}
</style>

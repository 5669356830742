<template>
  <div>
    <v-chip-group class="links-label">
          <v-chip active-class="_active" to="/profile/info/data" label dense class="link-label">
            <div>  {{ $i18n.t('me') }}</div>
          </v-chip>
          <v-chip active-class="_active" to="/profile/info/notifications" label dense class="link-label">
            <div>  {{ $i18n.t('notifications') }}</div>
          </v-chip>
           <v-chip label dense
           class="link-label d-flex align-center"
              active-class="_active"
              to="/profile/info/verification"
          >
              <div>{{ $i18n.t('verification') }}</div>
              <v-spacer></v-spacer>
              <font-awesome-icon style="color: var(--red)" icon="exclamation-triangle" v-if="
              (user.doc1_verified === 3 || user.doc2_verified === 3 || user.doc3_verified === 3 || 
              user.doc1_verified === 0 || user.doc2_verified === 0 || user.doc3_verified === 0)
              " />
              <v-icon color="var(--yellow)" 
              v-if="
              (user.doc1_verified === 1 || user.doc2_verified === 1 || user.doc3_verified === 1) &&
              (user.doc1_verified !== 3 && user.doc2_verified !== 3 && user.doc3_verified !== 3) &&
              (user.doc1_verified !== 0 && user.doc2_verified !== 0 && user.doc3_verified !== 0)
              ">timer</v-icon>
              <v-icon color="var(--green-success)" v-if="(user.doc1_verified === 2 && user.doc2_verified === 2 && user.doc3_verified === 2)">done</v-icon>
          </v-chip>
          <v-chip active-class="_active" to="/profile/info/security" label dense class="link-label">
            <div>  {{ $i18n.t('security') }} </div>
          </v-chip>
        </v-chip-group>
    <router-view class="info_router">

    </router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "ProfileInfo",
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUserInfo',
    }),
   
  },
}
</script>

<style lang="scss" scoped>
.links {
  color: var(--white);
  font-size: 16px;
  text-transform: uppercase !important;
  padding: 0px;
}

@media (max-width: 500px) {

  .links {
    min-width: 200px !important;
    text-align: center;
    font-size: 16px;
  }
  .divider {
    display: none;
  }
}
.link {
  text-decoration: none;
  color: inherit;
  &._active {
  }
}
</style>
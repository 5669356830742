<template>
    <div class="block">
        <div class="block__header header" style="">
            <div class="header__title">
            🎮 {{ $i18n.t('providers') }}
            </div>
            <div>
                <v-btn class="all-buttons-t header__show-more" @click="viewAll">{{
                  $i18n.t('ViewAll') }}</v-btn>
            </div>
          </div>
          <!-- v-if="!loadingFlag" -->
          <template>
          <swiper
          slides-per-view="auto"
          :spaceBetween="5"
          class="block__provider provider"
        >
        <div class="side-gradient-left"></div>
            <swiper-slide
              v-for="(provider, index) in providerList.items"
              :key="index"
              class="provider__item"
            >
              <div class="provider__body" @click="selectProvider(provider)">
                <v-lazy v-model="isActive" class="provider__image">
                  <!-- <img v-if="provider.logo" :src="provider.logo" :alt="provider.text"> -->
                    <v-lazy-image
                    :src-placeholder="require('@/assets/images/loading.gif')"
                    @load="isActive = true" v-if="provider.logo" :src="provider.logo" />
                </v-lazy>
                <div class="provider__content">
                  <span class="provider__name"> {{ provider.text }} </span>
                  <!-- <span class="provider__count">{{
                      provider.enabled_games_count ? provider.enabled_games_count : 0 
                    }}
                  {{ $t('games') }}
                  </span> -->
                </div>
                
              </div>
            </swiper-slide>
          
          <div class="side-gradient-right"></div>
        </swiper>
      </template>
          <!-- <template v-else>
          <swiper
          slides-per-view="auto"
          :spaceBetween="5"
          class="block__provider provider"
        >
        <div class="side-gradient-left"></div>
          <swiper-slide
            v-for="i in 10"
            :key="i"
            class="provider__item"
            >
            <div class="provider__body">
              <v-skeleton-loader 
              style="flex: 0 0 100px"
              
              type="card"  />
            </div>
            </swiper-slide>
          <div class="side-gradient-right"></div>
        </swiper>
      </template> -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
    export default {
        name: 'ProviderSection',
        components:{
            Swiper,
            SwiperSlide,
        },
        data(){
            return{
              isActive: false,
              loadingFlag: true,
              errorFlag: false,
              intersectFlag: false,
            }
        },
        async mounted() {
          await this.getProviders();
        },
        computed:{
            ...mapGetters({
            providerList: 'getGameProviders'
        })
        },
        methods:{
          async getProviders(){
            if (this.providerList.items.length === 0) {
            this.errorFlag = false;
            try {
              await this.$store.dispatch('GetProviders');
              
            } catch (error) {
              this.errorFlag = true;
            } finally{
              this.loadingFlag = false;
            }
          }
          },
          onIntersect(entries, observer) {
            if (entries[0].isIntersecting && !this.intersectFlag) {
              this.intersectFlag = true;
              this.getProviders();
      }
          },
          viewAll(){
            this.$router.push(
              {
                name: 'GridBlock',
                params: {
                  type: `game-provider`,
                  info: 'all'
                },
              }
              )
          },
          selectProvider(prov){
            const provider = prov.string_id;
            // console.log('provider', provider);
      let url
      if (provider === 'All Games') {
        url = provider.split(' ')[0];
      } else{
        url = provider;
      }
    this.$router.push(
            {
              name: 'GridBlock',
              params: {
                type: `game-provider`,
                info: `${url}`
              },
            }
            )
    },
        },
    }
</script>

<style lang="scss" scoped>
.side-gradient-right{
    background: linear-gradient(90deg,rgba(43,39,64,0) 40%,var(--light-flamingo));
    position: absolute;
    width: 50px;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 2;
}
.side-gradient-left{
    background: linear-gradient(90deg,var(--light-flamingo),rgba(43,39,64,0));
    position: absolute;
    width: 6px;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}
@media (max-width:1140px){
    .side-gradient-left, .side-gradient-right{
        display: none;
    }
}
.swiper-container{
    overflow: hidden!important;
    @media (max-width:1140px){
        overflow: visible!important;
    }
}
.swiper-wrapper{
    display: flex;
    flex-direction: column;
}
.block {

    &__header {
    }
}
.header {
    display: flex;
    align-items: center;
    padding-top: 10px;
    &__title {
        font-weight: bold;
        text-align: left;
        flex: 1 1 100%;
        text-transform: uppercase;
        color: var(--white);
        font-size: 24px;
        margin-right: 5px;
        @media (max-width:992px){
            font-size: 18px;
        }
    }

    &__show-more {
    }
}

.provider {
  position: relative;
  
    &__item {
      width: 170px;
      display: flex;
      flex: 0 0 170px;
      flex-direction: column;
      padding: 10px 0;
      min-height: 100px;
      height: auto;
      @media (any-hover: hover){
        &:hover{
          .provider__image{
            transform: scale(1.08);
          }
        }
      }
    }

  &__body {
    height: 100%;
    //border: 1px solid var(--white);
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.3) !important;
    border-radius: 16px !important;
    background-color: var(--dark-d-flamingo);
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    transition: all 0.3s ease-out;
    overflow: hidden;
    color: var(--white);
    &::before {
      content: "";
      background-color: rgba(white, 0.4);
      width: 60%;
      height: 100%;
      top: 0px;
      left: -125%;
      transform: skew(45deg);
      z-index: 0;
      position: absolute;
      opacity: 0;
      transition: left 0.5s ease-out, opacity 0.1s;
    }
    &:hover {
      cursor: pointer;
      &::before {
        left: 150%;
        opacity: 1;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content:center;align-items: center;
    padding: 5px 0;
    background-color: var(--dark-flamingo);
  }
  &__name {
    text-transform: capitalize;
  }
  &__count {
  }
  &__image {
      flex: 1 1 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    transition: transform 0.3s;
    img {
      vertical-align: middle;
      max-height: 50px;
      max-width: 140px;
      object-fit: cover;
      -webkit-transform: translateZ(0);
      object-position: center center;
    }
  }
}
</style>
<template>
    <div class="header">
        <div class="header__content">
            <img :src="`https://minio.takemybet.pro/ui-style/${logo}`"
            height="50px"
            alt="logo">
        </div>
            <button class="close-dialog header__close" @click="toExitDialog">
                <v-icon color='var(--main-flamingo)'>mdi-close</v-icon>
              </button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

    export default {
        name: 'HeaderMobile',
        methods: {
            toExitDialog() {
                this.$emit('exit-dialog')
            }
        },
        computed:{
            ...mapGetters({
                logo: 'styles/getLogo',
            })
        }
    }
</script>

<style lang="scss" scoped>
.header {

    align-items: center;
    background: var(--main-flamingo);
    display: flex;
    height: 60px;
    justify-content: center;
    position: relative;
    left: 0;
    order: 1;
    //position: absolute;
    //top: -60px;
    width: 100%;
    z-index: 1;
    &__content {
    display: flex;
    justify-content:center;
    align-items: center;
    }

    &__close {
        right: 10px;
        top: 10px;
    }
}


</style>
<template>
    <div>
    <!-- Mobile -->
    <v-dialog
        v-if="$isMobile()"
        v-model="currenciesDialog"
        fullscreen 
        transition
    >
    <v-card class="dialog__card dialog-card-login">
        <v-card-title class="dialog__card-header dialog-card-header">
          <v-row class="dialog-card-header__items">
            <v-col class="dialog-card-header__item">
              <img :src="`https://minio.takemybet.pro/ui-style/${logo}`" class="dialog-card-header__logo" alt="main-logo" />
            </v-col>
            <v-col class="dialog-card-header__item">
              <span>
                {{ $i18n.t('enterCurrency') }}
              </span>

            </v-col>
            <v-col class="dialog-card-header__item">
              <v-btn class="dialog-card-header__close" @click="closeCurrenciesDialog">
                <v-icon color="black">close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-5 dialog__card-content dialog-content">
            <v-card-title class="px-0 py-0" style="color: var(--main-flamingo);
              font-size: 14px;
              font-weight: 400;
              line-height: 16px;
              margin: 0 0 10px;">{{ $i18n.t('enterCurrency') }}:</v-card-title>
            <Currencies></Currencies>

        </v-card-text>

      </v-card>
    </v-dialog>
    <!-- PC -->
    <v-dialog
        v-if="!$isMobile()"
        v-model="currenciesDialog"
        max-width="500"
        transition
        
        content-class="passportDialog"
    >
    <v-card class="dialog__card dialog-card-login">
        <v-card-title class="dialog__card-header dialog-card-header">
          <v-row class="dialog-card-header__items">
            <v-col class="dialog-card-header__item">
              <img :src="`https://minio.takemybet.pro/ui-style/${logo}`" class="dialog-card-header__logo" alt="main-logo" />
            </v-col>
            <v-col class="dialog-card-header__item">
              <span>
                {{ $i18n.t('enterCurrency') }}
              </span>

            </v-col>
            <v-col class="dialog-card-header__item">
              <v-btn class="dialog-card-header__close" @click="closeCurrenciesDialog">
                <v-icon color="black">close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-5 dialog__card-content dialog-content">
            <v-card-title class="px-0 py-0" style="color: var(--main-flamingo);
              font-size: 14px;
              font-weight: 400;
              line-height: 16px;
              margin: 0 0 10px;">{{ $i18n.t('enterCurrency') }}:</v-card-title>
            <Currencies></Currencies>

        </v-card-text>

      </v-card>
    </v-dialog>
</div>
</template>

<script>
import Currencies from '@/components/Currency/Currencies'
import { mapGetters } from 'vuex';
    export default {
        name: 'CurrenciesDialog',
        components:{
            Currencies
        },
        data() {
            return {
                
            }
        },
        
        computed:{
          ...mapGetters({
            logo: 'styles/getLogo',
          }),
            currenciesDialog: {
      get() {
        return this.$store.state.currencyY.currencyUpdate;
      },
      set(value) {
        this.$store.commit('currencyY/setCurrencyUpdate', value);
      },
        },
    },
    methods:{
        closeCurrenciesDialog(){
            this.$store.commit('currencyY/setCurrencyUpdate', false);
        }
    }
}
</script>

<style lang="scss" scoped>
.dialog-card-header {
    background: #f2f6f9;
    border-bottom: 1px solid #dfe4e8;
    padding: 3px 15px !important;
  
    .dialog_pc & {
      padding: 15px !important;
    }
  
    &__items {
      margin: 0;
      align-items: center;
      flex-wrap: nowrap;
  
      .dialog_pc & {
        justify-content: flex-end;
      }
  
    }
  
    &__item {
      flex: 0 1 33.333% !important;
      padding: 0;
      display: flex;
      justify-content: center;
  
      &:last-child {
        justify-content: flex-end;
      }
  
      &:first-child {
        justify-content: flex-start;
      }
  
      .dialog_pc & {
        &:first-child {
          justify-content: flex-end;
        }
      }
  
      span {
        color: var(--black);
        font-size: 15px;
        white-space: nowrap;
        font-weight: 600;
        line-height: 1.5;
        text-transform: uppercase;
      }
      @media (min-width:850px){
        flex: 1 1 100%!important;

      }
    }
  
    &__logo {
      width: 65px;
      height: 65px;
  
      img {}
    }
  
    &__close {
      padding: 0 !important;
      cursor: pointer;
      min-width: 36px !important;
      height: 36px !important;
      box-shadow: none !important;
      background-color: var(--white);
      align-items: center;
      background-color: var(--white) !important;
      border: none;
      border-radius: 6px;
  
    }
  }
</style>
<template>
    <FlipCardBonusNoLogin :index="index">
        <template slot="front">
            <BonusNoLoginFront :index="index"></BonusNoLoginFront>
        </template>
        <template slot="back">
            <BonusNoLoginBack :index="index"></BonusNoLoginBack>
        </template>
      </FlipCardBonusNoLogin>

</template>

<script>
import BonusNoLoginBack from './BonusNoLoginBack.vue';
import BonusNoLoginFront from './BonusNoLoginFront.vue';
import FlipCardBonusNoLogin from './FlipCardBonusNoLogin.vue'
    export default {
        name: 'BonusNoLogin',
        components: {
            BonusNoLoginFront,
            BonusNoLoginBack,
            FlipCardBonusNoLogin,
        },
        props: ['index'],
        data() {
            return {
                
            }
        },
        
    }
</script>

<style lang="scss" scoped>

</style>
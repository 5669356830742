<template>
    <div>
        <v-chip-group class="links-label">
            <v-chip active-class="_active" to="/profile/promo-my/bonus" label dense class="link-label">
              <div>  {{ $i18n.t('bonuses') }}</div>
              
    
            </v-chip>
            <v-chip active-class="_active" to="/profile/promo-my/level" label dense class="link-label">
              <div>  {{ $i18n.t('level') }}: {{ user.level_name }}</div>
              
    
            </v-chip>
          </v-chip-group>
        <router-view class="info_router">

        </router-view>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
    export default {
        name: "Promo",
        computed: {
            ...mapGetters({
                user: 'getUserInfo',
            })
        },
    }
</script>

<style lang="scss" scoped>
.show{
    @media (max-width:992px){
         display: none; 
    }
}
.link{
    text-decoration: none;
    color: var(--white);
    transition: font-weight 0.3s;
    @media (any-hover: hover){
        &:hover{
            font-weight: 600;
        }
    }
    &._active{
        font-weight: 600;
    }
}


    .links{
        color: var(--white);
        font-size: 16px;
        text-transform: uppercase!important;
        padding: 0px;
    }
    @media (max-width: 500px){
        .vert_divider{
            display: none;
        }
        .links{
            //font-size: 12px;
            min-width: 200px!important;
            text-align: left;



        }

    }
</style>
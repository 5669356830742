//banners.js;
import axios from "axios";
const state = {
  banners: [],
};

const mutations = {};

const getters = {
  getBanners: (state) => state.banners,
};

const actions = {
  async getBannersByType({ state }, type) {
    await axios
      .get(`/api/banners/?device_type=${type}&banner_type=main_slider`, {
        //params:{
        //  banner_type: 'main_slider',
        //  device_type: type
        //}
      })
      .then((response) => {
        if (response.status === 200) {
          state.banners = response.data;
        }
      })
      .catch((error) => {
        console.log("error = ", error);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

<template>
    <v-dialog v-model="imageDialog" content-class="br-16" :transition="false" max-width="700px" scrollable :persistent="loadingFlag">
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text class="">
                <div class="image-full">
                    <img v-if="imagePreview"
                        :src="imagePreview" />
                </div>
            </v-card-text>
            <v-card-actions>
                <v-col class="py-0">
                    <v-btn
                      @click="closeModal"
                      class="all-buttons-fw all-buttons-fw_green"
                      :disabled="loadingFlag"
                      >
                      {{ $t("toClose") }}
                    </v-btn>
                  </v-col>
        
                  <v-col class="py-0">
                    <v-btn
                      @click="confirmDeleteDoc"
                      :loading="loadingFlag"
                      class="all-buttons-fw"
                      >
                      {{ $t("deleteDoc") }}
                      </v-btn
                    >
                  </v-col>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: "VerifModalView",
    props:{
        imagePreview: {
            type: String,
            required: true,
        },
        docNumber:{
            type: String,
            required: true,
        },
        modalView: {
            type: Boolean,
            required: true,
        }
    },
    data() {
      return {
        loadingFlag: false,
        imageDialog: false,
      };
    },
    watch:{
        modalView(val){
            if(val){
                this.imageDialog = true
            }
        },
        imageDialog(val){
            if(val){
              this.$emit("close-modal");
            }
        }
    },
    methods: {
      closeModal() {
        this.$store.commit("verification/isDeleteDocModal", false);
        this.imageDialog = false;
        this.$emit("close-modal");
      },
      async confirmDeleteDoc() {
        this.loadingFlag = true;
        const success = await this.$store.dispatch("deleteDocs", {
          doc_number: this.docNumber,
        });
        if (success) {
            this.$emit("close-modal");
            this.imageDialog = false;
          await this.$store.dispatch("awaitGetUserInfo");
        } else {
          const notification = {
            type: "error",
            message: this.$t("justError"),
          };
          this.$store.dispatch("alerts/add", notification);
        }
        this.loadingFlag = false;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .image-full{
    // width: 100%;
    // height: 100%;
    flex: 0 0 650px;
    img{
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}
  .text{
      color: #161421; 
      text-align: center;
      justify-content:center;align-items: center;
      font-size: 16px; 
      line-height: 1.5;
      display: flex;
      flex-direction: column;
  }
  </style>
  
import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";

Vue.use(VueI18n);

let i18nInstance = new VueI18n({
  locale: "en", // По умолчанию английский
  fallbackLocale: "en",
  messages: {}, // Изначально пустой
});

export const i18nPromise = async (langKey) => {
  const currentLang = localStorage.getItem("locale") || "en";
  const lang = langKey || currentLang;

  async function fetchTranslations(lang) {
    const response = await axios.get(
      `/api/translations/${lang}?domain=takemybet.pro`
    );
    const responseLegal = await axios.get(
      `/api/translations/legal-pages/${lang}`
    );

    return {
      messages:
        responseLegal.status === 200
          ? Object.assign({}, response.data, responseLegal.data)
          : response.data,
      status: response.status,
    };
  }

  try {
    const { messages, status } = await fetchTranslations(lang);

    if (status === 200) {
      i18nInstance.setLocaleMessage(lang, messages); // Устанавливаем переводы
      i18nInstance.locale = lang; // Устанавливаем язык
    }
  } catch (error) {
    console.error("Failed to initialize i18n:", error);
    throw error;
  }

  return i18nInstance;
};

export default i18nInstance;

// alerts.js
import axios from "axios";
const state = {
  notifications: [],
  isHaveNewMessage: false,
};
const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
  PUSH_NEW_MESSAGE(state, message) {
    state.isHaveNewMessage = true;
    state.notifications.unshift(message);
  },
  SET_IS_HAVE_NEW_MESSAGE(state, value) {
    state.isHaveNewMessage = value;
  },
};

const getters = {
  getIsHaveNewMessage: (state) => state.isHaveNewMessage,
  getNotifications: (state) => state.notifications,
  getNotViewedNotifications: (state) =>
    state.notifications.filter((item) => !item.is_viewed),
};

const actions = {
  pushNewMessage({ commit }, message) {
    commit("PUSH_NEW_MESSAGE", message);
  },
  async awaitGetNotifications({ commit }) {
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    };
    await axios
      .get(`/api/notifications/user-notifications`, config)
      .then((response) => {
        if (response.status === 200) {
          const notifications = response.data.items.map((notification) => ({
            ...notification,
            loadingItem: false,
          }));
          commit("set", { type: "notifications", items: notifications });
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  async awaitViewedNotification({ commit, state }, data) {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const params = new URLSearchParams();
    for (const key in data) {
      params.append(key, data[key]);
    }
    await axios
      .put(`/api/notifications/user-notifications/${data.id}`, params, {
        headers,
      })
      .then((response) => {
        // if (response.status === 200) {
        // }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  async awaitDeleteNotification({ commit }, id) {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };
    await axios
      .delete(`/api/notifications/user-notifications/${id}`, { headers })
      .then((response) => {
        state.notifications = state.notifications.filter(
          (item) => item.id !== id
        );
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

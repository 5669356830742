// Promo.js
const state = {
  dialogPromo: false,
};

const mutations = {
  setDialogPromo(state, value) {
    state.dialogPromo = value;
  },
};

const getters = {};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

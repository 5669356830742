<template>
  <div class="d-flex" style="overflow: hidden;">
    <div class="color">
  <v-container class="deposit__wrapper">
    <div style="position: relative;z-index:4">
      <v-row class="mb-0">
        <v-col class="deposit__text">
          <span>{{ $i18n.t('pleaseDeposit') }}</span>
        </v-col>
      </v-row>
        <v-row class="mt-0">
          <v-col>
            <DepositForm/>
          </v-col>
        </v-row>
      <v-row class="mt-0">
        <v-col class="mx-auto text-center tmb-style_white tmb-style_fz12 pb-12" style="max-width: 500px">
          <div>
            {{ $i18n.t('dearPlayers') }},
          </div>
          <div>
            {{ $i18n.t('autoWithdrawText') }}
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- Dialog Instant Reg -->
    <v-dialog max-width="fit-content" v-model="clickRegDialog" scrollable>
        <v-card class="modal__card" style="color: var(--white)">
          <v-card-title class="justify-center" style="justify-content: center;">
            <div style="word-break: normal!important;">
              {{ $i18n.t('thxForReg') }}!
            </div>
          </v-card-title>
          <v-card-text class="pb-0" style="overflow-y: hidden;color: var(--white)">
            <v-row class="pb-3 ma-0" style="max-width: 350px;">
              <v-col>
                <div style="background-color: var(--main-flamingo);">
                  <v-row class="row-block">
                    <v-col class="column-block">
                      <div class="column-block_fw">
                      <v-icon color="var(--red)">
                        person
                      </v-icon>
                      {{ $i18n.t('login') }}: 
                    </div>
                      <b v-if="this.isRegData.id !== ''">{{ this.isRegData.id }}</b>
                      <v-skeleton-loader v-else
          type="card"
          style="flex: 0 0 90px"
          width="90"
          height="20"
        ></v-skeleton-loader>
                    </v-col>
                  </v-row>
                  <v-row class="row-block">
                    <v-col class="column-block">
                      <div class="column-block_fw">
                        <v-icon color="var(--red)" dark>
                          lock
                        </v-icon>
                        {{ $i18n.t('password') }}: 
                      </div>
                      <b v-if="this.isRegData.pass !== ''">{{ this.isRegData.pass }}</b>
                      <v-skeleton-loader v-else
          type="card"
          style="flex: 0 0 90px"
          width="90"
          height="20"
        ></v-skeleton-loader>
        
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col>
                      <v-btn :disabled="this.isRegData.id === '' || this.isRegData.pass === ''" class="btn__deposit-t" @click="copy" :class="{'_copied' : copiedFlag}">
                        <div >
                          <v-col v-if="!copiedFlag" class="d-flex align-center px-0 py-0" style="font-weight: 700;">
                            <v-icon >
                              content_copy
                              </v-icon>
                            {{ $i18n.t('copy') }}
                          </v-col>
                          <v-col v-else class="d-flex align-center px-0 py-0" style="font-weight: 700;">
                              <font-awesome-icon  icon="check"/>
                          </v-col>
                          
                        </div>
                       
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row style="min-width: 250px">
                    <v-col class='px-4 pt-2' style="font-size: 12px;line-height: 0.8rem;">
                      <b class="yellow_text">{{ $i18n.t('attention') }}!</b>
                      {{ $i18n.t('clickRegKeepPassText') }}
                      <div class="mt-2" style="font-weight: 700;">
                        {{ $i18n.t('clickRegUseTelOrEmailText') }}
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <!--                                        <img src="../assets/images/banner_1click_reg.png" class="banner_image" v-if="!clickRegSuccess"/>-->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
    </v-dialog>
  </v-container>
  <ul class="bg-bubbles">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul> 
</div>
<deposit-confirm-overlay v-if="depositWindow" />
</div>
</template>

<script>
import {mapGetters} from 'vuex';
import Preloader from "@/components/Preloader";
import DepositForm from '@/views/DepositPages/DepositForm'
import DepositConfirmOverlay from '@/views/DepositPages/DepositWindow/DepositConfirmOverlay.vue'

export default {
  name: "DepositMainPage",
  components: {
    // Preloader,
    DepositForm,
    DepositConfirmOverlay,
  },
  data() {
    return {
      loadingFlag: false,
      clickRegDialog: false,
      clickRegData: {
        id: '',
        pass: '',
      },
      copiedFlag: false,
    }
  },
  computed: {
    ...mapGetters({
      payMethods: 'getPayMethods',
      user: 'getUserInfo',
    }),
    depositWindow: {
      get() {
        return this.$store.state.depositView.depositWindow;
      },
      set(value) {
        this.$store.commit('depositView/setDepositWindowError', value);
      },
    },
    isInstaReg: {
      get() {
        return this.$store.state.isInstaReg;
      },
      set(value) {
        this.$store.commit("setIsInstaReg", value);
      },
    },
    isRegData: {
      get() {
        return this.$store.state.regData;
      },
      set(value) {
        this.$store.commit("updateRegData", value);
      },
    },
  },
  watch: {
    isRegData: {
    immediate: true,
    deep: true,
    handler(value) {
      if (value.id) {
        const updatedData = this.$store.state.regData;
        this.clickRegDialog = true;
        this.clickRegData.id = this.isRegData.id;
        this.clickRegData.pass = this.isRegData.pass;
      }
    }
  }
},
beforeRouteEnter(to, from, next) {
  next(vm => {
    vm.clickRegData = vm.isRegData;
  });
},
  async mounted() {
    if (localStorage.getItem('jwt')) {
        this.clickRegData = this.isRegData;
      }
      if (localStorage.getItem('jwt') && this.$store.getters.getUserInfo.id === null) {
        await this.$store.dispatch('awaitGetUserInfo');
      }
    //await this.$store.dispatch('bonuses/getUserBonuses');
  },
  methods: {
    copy() {
  if (this.copiedFlag) {
    this.clickRegDialog = false;
    return;
  }

  const textToCopy = this.isRegData.id.toString() + ' ' + this.isRegData.pass.toString();

  if (navigator.clipboard) {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        this.copiedFlag = true;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setTimeout(() => {
      this.clickRegDialog = false;
      this.copiedFlag = false;
    }, 750);
      });
  } else {
    const el = document.createElement('textarea');
    el.value = textToCopy;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    this.copiedFlag = document.execCommand('copy');
    document.body.removeChild(el);
    setTimeout(() => {
      this.clickRegDialog = false;
      this.copiedFlag = false;
    }, 750);
    
  }
}

  }
}
</script>

<style lang="scss" scoped>
.bg-bubbles{
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  
  z-index: 1;
  
  li{
    position: absolute;
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    // background-color: rgba(255, 255, 255, 0.25);
    background: url('../../assets/images/bubles/white-heart-bubble.svg') 0 / 100% no-repeat;
    bottom: -160px;
    opacity: .3;
    -webkit-animation: square 25s infinite;
    animation:         square 25s infinite;
    
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    &:nth-child(1){
      left: 10%;
      background: url('../../assets/images/bubles/cards-spades-bubble.svg') 0 / 100% no-repeat;
    }
    
    &:nth-child(2){
      left: 20%;
      background: url('../../assets/images/bubles/cards-diamonds-bubble.svg') 0 / 100% no-repeat;
      width: 80px;
      height: 80px;
      
      animation-delay: 2s;
      animation-duration: 17s;
    }
    
    &:nth-child(3){
      left: 25%;
      animation-delay: 4s;
      background: url('../../assets/images/bubles/cards-clubs-bubble.svg') 0 / 100% no-repeat;
    }
    
    &:nth-child(4){
      left: 40%;
      width: 60px;
      height: 60px;
      background: url('../../assets/images/bubles/cards-spades-bubble.svg') 0 / 100% no-repeat;
      animation-duration: 22s;
      
      // background-color: rgba(255, 255, 255, 0.25);
    }
    
    &:nth-child(5){
      left: 70%;
      background: url('../../assets/images/bubles/cards-clubs-bubble.svg') 0 / 100% no-repeat;
    }
    
    &:nth-child(6){
      left: 80%;
      width: 120px;
      height: 120px;
      
      animation-delay: 3s;
      // background-color: rgba(255, 255, 255, 0.25);
    }
    
    &:nth-child(7){
      left: 32%;
      width: 160px;
      height: 160px;
      
      animation-delay: 7s;
    }
    
    &:nth-child(8){
      left: 55%;
      width: 20px;
      height: 20px;
      
      animation-delay: 15s;
      animation-duration: 40s;
    }
    
    &:nth-child(9){
      left: 25%;
      width: 10px;
      height: 10px;
      
      animation-delay: 2s;
      animation-duration: 40s;
      // background-color: rgba(255, 255, 255, 0.25);
    }
    
    &:nth-child(10){
      left: 90%;
      width: 160px;
      height: 160px;
      
      animation-delay: 11s;
    }
  }
}

@-webkit-keyframes square {
  0%   { transform: translateY(0); }
  100% { transform: translateY(-1500px) rotate(600deg); }
}
@keyframes square {
  0%   { transform: translateY(0); }
  100% { transform: translateY(-1500px) rotate(600deg); }
}
.color{
  background-color: var(--main-flamingo);
  flex: 1 1 100%;
  position: relative;
  
}
.row-block{
  justify-content: center;
  margin: 0;
}
.column-block{
  padding: 0px;
  max-width: 202px;
  text-align: left;
  display: flex;
  align-items: center;
  &_fw{
flex: 1 1 100%;
display: flex;
    align-items: center;
  }
}
.btn__deposit-t{
  background-color: var(--red)!important;
  color: var(--white)!important;
  min-width: 200px!important;
  transition: background-color 0.3s;
  &._copied{
    background-color: var(--green-success)!important;
  }
}
.modal__card {
  text-align: center;
  border-radius: 12px !important;
  margin-left: auto;
  max-width: 1140px !important;
  border: 1px solid var(--white);
  background-color: var(--main-flamingo) !important;
  width: 100%;
  box-shadow: none!important;
}

.main__logo {
  width: 80px;
  height: 80px;
}

.deposit__text {
  font-size: 24px;
  padding-bottom: 0;
  text-align: center;
  color: var(--white); 
  font-weight: 900;
    line-height: 1.2;
  text-transform: uppercase;
  font-family: "Montserrat",sans-serif;
}

.paymentProvider_img {
  border: solid 1px;
}

.deposit__wrapper {
  -ms-overflow-style: none;
  overflow: hidden;
  padding-top: 90px;
  @media (max-width:992px){
      padding-top: 65px;
  }
  .is-email-confirmation-bar-visible &{
    padding-top: 100px;
    @media (max-width:992px){
      padding-top: 140px;
    }
  }
  
}

.deposit__wrapper::-webkit-scrollbar {
  display: none;
}

.paymethod__card {
  transition: box-shadow 0.3s;
  border-radius: 10px;
  box-shadow: 2px 2px 2px 2px var(--red);
  background-color: var(--main-flamingo) !important;
  border: solid 1px var(--red);
  max-width: 200px;
  color: var(--third-flamingo);
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  //height: 100px;
  img {
    //border-radius: 10px;
    height: 100px!important;
  width: 100%!important;
  }
  @media (any-hover: hover){
    &:hover{
      box-shadow: 0px 5px 30px var(--red);
    }
  }
}
.closeIcon_icon-t{
  cursor: pointer;
  transition: transform 0.3s;
  @media (any-hover: hover){
    &:hover{
      transform: rotate(-180deg);
    }
  }
}
.closeIcon_col {
  
  
  
}
@media only screen and (max-height: 500px) and (orientation: landscape) {
  .main__logo {
    display: none;
  }
  .closeIcon_icon-t{
    
  }
  .closeIcon_col {
    padding: 0;
    position: relative;
    top: 65px;
    cursor: pointer;
    svg{
      path{

      
      
      transition: transform 0.3s;
      @media (any-hover: hover){
        &:hover{
          transform: rotate(-180deg);
        }
      }
    }
    }
  }
  .overlayMethodImg_col {
    padding: 0;
    max-height: 110px;
    padding-top: 20px;

    img {
      height: 100px;
    }
  }


}
</style>

<template>
    <v-card class="reg-card"
    :class="$isMobile() ? '_mobile' : ''"
    >
        <v-card-title class="titles-reg">
              <div class="titles-reg__welcome">
                🎁 {{ $t('regWelcomeTitle') }}
              </div>
              <h1 class="titles-reg__bonus main-title">
                {{ $t('regBonusTitlePersent') }}
              </h1>
              <h2 class="titles-reg__subtext main-title">
                {{ $t('regBonusTitleDeposit') }}
              </h2>
              <!-- <div v-html="$i18n.t('regTitle')"></div> -->
        </v-card-title>
        <div class="instant-reg mt-2">
            <div
              class="column-wrapper justify-center fw"
            >
              <div
                class="instant-reg__text 
                justify-end
                lh-1
                align-center
                c-white
                font-weight-bold
                d-flex
                text-uppercase text-right fz-8 column-three"
              >
                {{ $i18n.t("clickReg") }}
              </div>
              <div class="
              mx-3
               d-flex justify-center align-center">
                <v-switch
                  v-model="isSwitchInstant"
                  hide-details
                  color="green accent-2"
                  inset
                  class="mt-0 custom-switch pt-0" 
                  @change="toInstant"
                ></v-switch>
              </div>
              <div
                class="instant-reg__text 
                align-center
                font-weight-bold
                c-white
                d-flex
                text-uppercase text-left fz-8 column-three"
              >
                e-mail
              </div>
            </div>
        </div>
      </v-card>
</template>

<script>
    export default {
        name: 'RegBannerBonuses',
        methods: {
            toInstant() {
        this.$store.commit("regComponents/setInstantReg", !this.isInstant);
    },
        },
        computed: {
            isInstant: {
      get() {
        return this.$store.state.regComponents.isInstantReg;
      },
      set(value) {
        this.$store.commit("regComponents/setInstantReg", value);
      },
    },
            isSwitchInstant: {
      get() {
        return this.$store.state.regComponents.isSwitchInstant;
      },
      set(value) {
        this.$store.commit("regComponents/setSwitchInstant", value);
      },
    },
}
    }
</script>

<style lang="scss" scoped>
.reg-card{
    background: linear-gradient(
      180deg,
      var(--main-flamingo) 0%,
      var(--red) 100%
    ),
    var(--red);
    border-radius: 16px 0 0 16px !important;
    height: 100%;
    padding: 10px 30px;
    z-index: 3;
    position: relative;
    max-width: none;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    &._mobile{
        padding: 15px;
        min-height: auto;
        border-radius: 16px!important;
    }
}
.titles-reg {
    display: flex;
    flex-direction: column;
    //justify-content:center;
    align-items: center;
    flex: 1 1 100%;
    padding-left: 0;
    padding-right: 0;
    ._mobile &{
        justify-content:center;
        padding: 0!important;
    }
    &__welcome {
      color: var(--white);
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 10px;
      font-weight: 900;
      text-align: center;
      text-transform: uppercase;
      ._mobile &{
        font-size: 12px;
        margin-bottom: 5px;
    }
    }
  
    &__bonus {
      margin-bottom: 5px;
      font-size: 40px;
      ._mobile &{
        font-size:  25px;
    }
    }
    &__subtext{
      font-size: 25px;
      ._mobile &{
        font-size:  16px;
    }
    }
  }
  .main-title{
    line-height: 1;
      font-weight: 900;
      -webkit-text-fill-color: transparent;
      background: linear-gradient(130deg, #ce84ff, #a78fff 32.81%, #ce84ff);
      background: var(--gold, linear-gradient(130deg, #e284ff 0, #f28fff 32.81%, #ff84ef 100%));
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
  }
</style>
import { render, staticRenderFns } from "./WelcomePC.vue?vue&type=template&id=6eb1e058&scoped=true&"
import script from "./WelcomePC.vue?vue&type=script&lang=js&"
export * from "./WelcomePC.vue?vue&type=script&lang=js&"
import style0 from "./WelcomePC.vue?vue&type=style&index=0&id=6eb1e058&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eb1e058",
  null
  
)

export default component.exports
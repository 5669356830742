//customScripts.js;
import axios from "axios";
const state = {
  customScripts: [],
};

const mutations = {};

const getters = {
  getScripts: (state) => state.customScripts,
};

const actions = {
  // async awaitGetScripts({ state }) {
  //   const test = {
  //     name: "recaptcha-script",
  //     type: 'script',
  //     code:[
  //           {
  //             attribute: 'src',
  //             content: 'https://www.google.com/recaptcha/api.js',
  //         },
  //           {
  //             attribute: 'async',
  //             content: '',
  //         },
  //           {
  //             attribute: 'defer',
  //             content: '',
  //         }
  //     ],
  //     locate: "head",
  //   };
  //   state.scripts.push(test);
  // },
  async awaitGetScripts({ state }) {
    await axios
      .get(`/api/seo-settings/ `, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          state.customScripts = response.data;
        }
      })
      .catch((error) => {
        console.log("error = ", error);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

// depositView.js
const state = {
  amount: 0,
  selectedMethod: {},
  //========================================================================================================================================================
  depositExitWindow: false,
  depositMethodsWindow: false,
  depositWindow: false,
  //========================================================================================================================================================
  isBonusWillActive: true,
  //========================================================================================================================================================

  isSelectedMethod: false,
  isMethodMoney: false,
  isMethodCrypto: false,
  isMethodExit: false,
  //========================================================================================================================================================
  isAlreadyDepositBonusUsed: false,
  //========================================================================================================================================================
};

const mutations = {
  SET_AMOUNT(state, value) {
    state.amount = value;
  },
  set(state, { type, items }) {
    state[type] = items;
  },
  setDepositWindowError(state, value) {
    state.depositWindow = value;
  },
  setDepositMethodsWindow(state, value) {
    state.depositMethodsWindow = value;
  },
  setSelectedMethod(state, value) {
    state.selectedMethod = value;
  },
  setDepositExitWindow(state, value) {
    state.depositExitWindow = value;
  },
  //========================================================================================================================================================
  setBonusWillActive(state) {
    state.isBonusWillActive = !state.isBonusWillActive;
  },
  //========================================================================================================================================================

  setMethodMoney(state, value) {
    state.isMethodMoney = value;
  },
  setMethodCrypto(state, value) {
    state.isMethodCrypto = value;
  },
  setMethodExit(state, value) {
    state.isMethodExit = value;
  },
  setMethodSelected(state, value) {
    state.isSelectedMethod = value;
  },
  //========================================================================================================================================================
  setAlreadyDepositBonusUsed(state, value) {
    state.isAlreadyDepositBonusUsed = value;
  },

  //========================================================================================================================================================
};

const getters = {
  getPayMethod: (state) => state.selectedMethod,
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

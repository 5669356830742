<template>
  <v-container>
    <!-- Title -->
    <v-row class="title__link-v flex-column">
      <v-col class="d-flex align-center justify-center">
        <v-icon color="white" style="margin-right: 3px;">
          monetization_on
        </v-icon>
        <span class="tmb-stye_white">{{ $i18n.t('withdraw') }}</span>

      </v-col>

      <v-col class="pa-0">
        <v-divider dark></v-divider>
      </v-col>
    </v-row>
    <!-- Title low balance -->
    <v-row v-if="user.balance === 0">
      <v-col class="tmb-stye_white tmb-stye_fz18">
        {{ $i18n.t('tooSmallBalance') }}
      </v-col>
    </v-row>
    <v-row>
      <!-- Title select -->
      <!-- Content -->
      <v-card class="deposit_row">
        <v-card-title class="text-left pb-0 text-uppercase" style="
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: var(--black-text);
      ">
        {{ $i18n.t('selectSumma') }}:
      </v-card-title>
        <!-- Input row -->
        <v-card-text>
        <v-row>
          <v-col style="color: var(--black-text);">
            <!-- Input -->
            <v-text-field class="withdraw_amount limit-input black-input pa-0" v-model="amount" type="number" hide-details
            @keydown="allowNumbers"
              @change="amountChange" color="white">

              <template v-slot:append>
                <div style="font-size: 48px;color:var(--dark-flamingo)!important;">{{ $store.getters.getUserInfo.currency }}</div>
              </template>
            </v-text-field>
            <v-col class="text-left px-0 py-1" style="font-size: 12px;">
              {{ $i18n.t('from') }} 1
              {{ $i18n.t('to') }} 100000
            </v-col>
          </v-col>
        </v-row>
      </v-card-text>
        <!-- <v-col class="py-0" style="color: var(--black-text);text-align: left">
            <v-text-field
                class="card_number"
                color="white"
                type="number" 
                hide-details 
                v-model="account"
                :label="$i18n.t('cardNumber')"
            >
            </v-text-field>
          </v-col>  -->
        <!-- Border -->
        <v-row class="mx-0">
          <v-col class="pt-0 pb-1 px-4">
            <v-divider dark style="border: 1px solid var(--black-text);border-radius:5px"></v-divider>
          </v-col>
        </v-row>
        <!-- Two balances -->
        <v-card-text>
        <v-row>
          <v-col cols='6' class="text-left pt-1" style="color: var(--black-text);font-size: 16px">

            <div class="d-flex">
              <b style="font-size: 14px;color: var(--black-text)">
                {{ user.balance }}
              </b>
              <b class='ml-1' style="font-size: 12px;color: var(--black-text);">
                {{ $store.getters.getUserInfo.currency }}
              </b>


            </div>
            <p class="mb-0" style="font-size: 12px;">
              {{ $i18n.t('realMoney') }}
            </p>

          </v-col>
          <v-col cols='6' class="text-right pt-1" style="color: var(--black-text);font-size: 16px">

            <div class="d-flex align-center" style="justify-content: flex-end;">
              <b style="font-size: 14px;color: var(--black-text)">
                {{ user.balance }}
              </b>
              <b class='ml-1' style="font-size: 12px;color: var(--black-text);">
                {{ $store.getters.getUserInfo.currency }}
              </b>

            </div>
            <p class="mb-0" style="font-size: 12px;">
              {{ $i18n.t('noPlayBonus') }}
              <router-link to="/profile/promo-my/bonus"
                style="font-weight:600;color:var(--black);text-decoration: underline;">{{ $i18n.t('bonus') }} </router-link>
            </p>
          </v-col>
        </v-row>
      </v-card-text>
        <!-- <v-row>
        <v-col class='text-center' style="color: var(--white-details);font-size: 12px;font-weight: 100;">
          {{$i18n.t('dearPlayers')}}
          <br>
          {{$i18n.t('autoWithdrawText')}}
        </v-col>
      </v-row> -->
        <!-- Button -->
        <v-card-text>
        <v-row class="justify-center">
          <v-col>
            <div class="deposit-payment-wrapper small-text mt-0 align-start">
              <div class="deposit-payment-time">
                {{ $t('commision') }}: {{ `${withdrawType.fee}%` }}
              </div>
            </div>
            <v-btn class="button-adaptive all-buttons-t all-buttons-fw" @click="getWithdrawUrl" v-if="!successWithdraw"
              :loading="loading"
              style="font-weight: 700;background-color: #00bd71!important;color:var(--white)!important;"
              :disabled="amount === '00' || amount === '0' || amount === '0.0' || amount === '0.00' || amount === ''" 
              >

              <span>{{ $i18n.t('doWithdraw') }}</span>
              <!-- <span style="width: 25px;height: 25px"><img src="@/assets/images/dollar.svg" style="width: 100%;height: 100%;"/></span> -->

            </v-btn>
            <v-btn color="#00bd71" class="button-adaptive" v-if="successWithdraw">
              <v-icon color="white">
                done
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      </v-card>
    </v-row>
    <!-- Limit Error -->
    <v-dialog v-model="wagerDialog" max-width="fit-content" persistent overlay-opacity="0.8">
      <div style="margin-left: auto;width: max-content" class="pb-2">
        <v-btn color="var(--main-flamingo)" @click="moveBack">
          <v-icon color="white">
            close
          </v-icon>
        </v-btn>
      </div>
      <v-card class="basic__card"
        style="background-color: var(--main-flamingo)!important;color:var(--black-text);max-width: 350px">
        <v-card-title>
          <v-row>
            <v-col style="text-align: center">
              {{ $i18n.t('widthrawNotAvaliable') }}
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text style="color: var(--black-text);font-size: 16px">
          {{ $i18n.t('playWagerText') }}
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog Error -->
    <v-dialog v-model="errDialog" max-width="fit-content" overlay-opacity="0.8">
      <v-card class="basic__card"
        style="background-color: var(--main-flamingo)!important;color: var(--black-text);max-width: 350px">
        <v-card-title>
          <v-row>
            <v-col style="text-align: center">
              {{ $i18n.t('error') }}
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text style="color: var(--black-text);font-size: 16px">
          {{ errorText }}
          <v-row>
            <v-col class="pa-0">
              <v-btn to="/profile/info/verification" v-if="verifyFlag">
                {{ $i18n.t('verification') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "WithDraw",
  data() {
    return {
      amount: '0.00',
      account: '',
      selectedAmount: '',
      withdrawType: '',
      successWithdraw: false,
      wagerDialog: false,
      image: null,
      imageArray: ["robot"],
      errorText: '',
      errDialog: false,
      verifyFlag: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUserInfo'
    }),
    amountFormatted() {
      return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.amount);
    },
  },
  mounted() {
    if ('wager' in this.user && 'wagering' in this.user) {
      if (this.user.wager !== null && this.user.wagering !== null) {
        //this.wagerDialog = this.user.wager > this.user.wagering;
      }
    }
  },
  methods: {
    selectMethod(method) {
      if (this.user.balance > 0) {
        this.withdrawType = method;

      }
    },
    async moveBack() {
      await this.$router.go(-1);
    },
    allowNumbers(event) {
      const allowedChars = /[0-9,]/;

      if (!allowedChars.test(event.key) && event.key !== 'Backspace') {
        event.preventDefault();
      }
    },
    amountChange() {
      let userBalance = this.$store.getters.getUserInfo.balance;
      const userBalanceNumber = parseFloat(userBalance);
      const amountNumber = parseFloat(this.amount);
      if (amountNumber > userBalanceNumber) {
        this.amount = userBalanceNumber;

      }
      if (this.amount > this.withdrawType.withdraw_max_limit) {
        this.amount = this.withdrawType.withdraw_max_limit;
      } else if (this.amount < this.withdrawType.withdraw_min_limit) {
        this.amount = this.withdrawType.withdraw_min_limit;
      } 
    },
    async getWithdrawUrl() {  
      //if(this.amount > this.user.balance){
      //  this.$store.commit('cashOut/setCashOutUpdate', true)
      //  setTimeout(() => {
      //    this.$store.commit('cashOut/setCashOutUpdate', false)
      //  }, 1500);
      //  return;
      //}
      //let userBalance = this.$store.getters.getUserInfo.balance;
      //const userBalanceNumber = parseFloat(userBalance);
      //const amountNumber = parseFloat(this.amount);
      
     if (this.amount > 0) {
      this.loading = true;
       this.verifyFlag = false;
       let [res, err] = await this.$store.dispatch('getWithdrawLink',
         { payWay: this.withdrawType.codename, paySum: parseFloat(this.amount), payAccount: this.account });
       if (res) {
         this.successWithdraw = true;
       } else {
         switch (err) {
           case 'invalid sum':
             this.errorText = this.$i18n.t('invalidWithdrawSum');
             break
           /*case 'fail':
             this.errorText = this.$i18n.t('failedWithdraw');
             break*/
           case 'verification need':
             this.verifyFlag = true;
             this.errorText = this.$i18n.t('needVerify');
             break
           default:
             this.errorText = err;
         }
         this.errDialog = true;
         this.loading = false;
         return;
       }
       this.loading = false;
      setTimeout(() => {
        location.reload();
      }, 1000);
     }
   },
  }
}
</script>
<style lang="scss" scoped>
.button-adaptive {
  max-width: 100%;
  min-width: 100%!important;
  @media (min-width:500px) {
    min-width: 350px!important;
    max-width: 350px;
  }
}
.v-btn--disabled{
  opacity: 0.5;
}
.col {
  &__fix {
    flex: 0 1 50%;

    &_big-t {}
  }
}

.container-mod {
  margin-top: 15px;
  max-width: 650px !important;

  @media (max-width:500px) {
    padding: 0;
  }
}

.uploader {
  .full-width .full-height {
    background-color: var(--main-flamingo);
  }
}
</style>
<style lang="scss" >
.paymethod__card {
  border-radius: 10px;
  box-shadow: 2px 2px 2px 2px var(--black-text);
  background: var(--main-flamingo);
  width: 100%;



  @media (min-width:500px) {
    margin: 0 auto;
    max-width: 200px;
    min-width: max-content;
  }

  color: white;
  border: solid 1px var(--black-text);

  cursor: pointer;
  display:flex;
  align-items:center;
  justify-content:center;
  height: 100px;

  img {
    border-radius: 10px;
    width: 100px;
    height: 100px;
  }
}

.deposit_row {
  //background-color: linear-gradient(99.4deg, var(--light-blue) 0%, var(--blue) 100%), var(--black-text);
  //border-radius: 5px;
  margin: 0 auto;
  margin-top: 12px!important;
  max-width: 390px !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--white)!important;
    box-shadow: 0 0 42px hsla(0,0%,100%,.05), 0 30px 50px rgba(22,20,33,.3);
    border-radius: 16px!important;
    @media (min-width:992px){
      margin-top: 0 !important;
    }
}

.limits__row {
  justify-content: center;
  color: white;
  max-width: 350px;
  font-size: 14px;
  line-height: 15px;
  padding-top: 5px;
}

.avaliable_withdraw {}

.withdraw_amount {}

.v-text-field__slot input {
  color: var(--black-text);
}

.seleced_type {
  background-color: var(--black-text);
  box-shadow: 0px 0px 10px var(--black-text);
}


.withdraw_amount input[type='number'] {
  -moz-appearance: textfield;
}

.withdraw_amount input::-webkit-outer-spin-button,
.withdraw_amount input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.card_number input[type='number'] {
  -moz-appearance: textfield;
}

.card_number input::-webkit-outer-spin-button,
.card_number input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.withdraw_btn_col {
  min-width: 180px;
}

.withdraw_type_btn {
  background: none;
  width: 100%;
}

@media (max-width: 500px) {
  .withdraw_btn_col {
    max-width: 150px;
    min-width: 50%;
  }

  .withdraw_type_btn {
    font-size: 13px !important;
  }
}
</style>

<template>
    <v-container>
            <v-row class="title__link-v flex-column"> 
              <v-col class="d-flex align-center justify-center">
                  <v-icon color="white" style="margin-right: 3px;">
                    insert_chart
                  </v-icon>
                  <span style="color: var(--white);">{{ $i18n.t('main') }}</span>
                
              </v-col>    
            
            <v-col class="pa-0">
              <v-divider dark></v-divider>
            </v-col>
          </v-row>
        <v-row>
            <v-col class="column-item">
                <v-card dark color="var(--light-flamingo)" class="pa-5 d-flex flex-column" min-height="300" style="border-radius: 16px;">
                    <v-card-title class="pa-0">
                        {{$t('balance')}}
                    </v-card-title>
                    <v-card-text class="mt-auto pa-0" style="line-height: 1;color: var(--white);font-size: 48px;font-weight: 700;">
                      {{ user.balance }} {{ user.currency }}
                    </v-card-text>
                    <v-card-actions class="mt-auto pa-0">
                        <v-btn class="all-buttons-fw" to="/deposit">{{ $t('deposit1') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col class="column-item">
              <v-card dark color="var(--light-flamingo)" class="pa-5 d-flex flex-column" min-height="300" style="border-radius: 16px;">
                  <v-card-title class="pa-0">
                     
                  </v-card-title>
                  <v-card-text class="pa-0" style="line-height: 1;color: var(--white);font-size: 48px;font-weight: 700;">
                    
                  </v-card-text>
                  <v-card-actions class="pa-0">
                      
                  </v-card-actions>
              </v-card>
          </v-col>
        </v-row>
        
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
    export default {
        name: 'ProfileMain',
        data() {
            return {
                
            }
        },
        computed:{
          ...mapGetters({
            user: 'getUserInfo'
          })
        },
        methods: {
            
        },
    }
</script>

<style lang="scss" scoped>
.column-item{
  @media (min-width:320px){
    flex: 0 1 100%;
    max-width: 100%; 
  }
  @media (min-width:740px){
    flex: 0 1 50%;
    max-width: 50%; 
  }
  @media (min-width:960px){
    flex: 0 1 33.333%;
    max-width: 33%; 
  }
  
}
</style>
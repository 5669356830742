<template>
  <v-card flat>
    <v-card-text></v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
        name: 'DepositCrypto',
        methods: {
        },
                ...mapGetters({
            }),
    }
</script>

<style lang="scss" scoped>

</style>
// sports.js
import axios from "axios";
const state = {};

const mutations = {};

const getters = {};

const actions = {
  async getSportUrl({ commit, dispatch }, data) {
    let url = false;
    await axios
      .get(`/api/sportsbook/session_url/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: {
          platform: data.platform, //
        },
      })
      .then((response) => {
        if (response.status === 200) {
          url = response.data;
        }
      })
      .catch((error) => {});
    return url;
  },

  async getSessionSportUrl({ commit, dispatch }, sportlink) {
    let url = false;
    await axios
      .post(
        `/api/games/get_session_url/sport`,
        sportlink
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        // },
      )
      .then((response) => {
        if (response.status === 200) {
          url = response.data;
        }
      })
      .catch((error) => {});
    return url;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

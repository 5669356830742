<template>
    <div>
          <div class="grid__wrapper" v-if="games.length > 0">
            <div v-for="(game, index) in games" :key="index" class="game__item"
                    >
              <div class="game__image-container">
                <img :src="game.logo" class="game__image" />
                <div class="game__overlay">
                  <v-btn class="game__play all-buttons-t all-buttons-fw_green" @click="playGame(game)">
                    <img src="@/assets/images/white-heart.svg" width="15px" height="15px" class="white-heart mr-1"
                      >
                    {{ $i18n.t('play') }}
                  </v-btn>
                  <v-btn class="game__demo all-buttons-t" @click="playGame(game, true)">
                    {{ $i18n.t('demo') }}
                  </v-btn>
                
                </div>
              </div>
            </div>
            
          </div>
          <div v-if="games.length === 0">
            <div class="tmb-style_white text-center" style="font-size: 20px">
              <p>
              {{ $t('searchNotFound') + ' ' + `'${searchName}'`}}
              </p>
              <span>
                {{ $t('searchNotFoundSpan') }}
              </span>
            </div>
          </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  export default {
    name: "PcGamesList",
    data() {
      return {
        searchFlag: false,
        navDrawer: false,
        showingGamesCount: 34, //28
        gamesLoadingFlag: false,
        loading: false,
        provider: '',
        searchGame: '',
        dialogMobile: false,
        selectedGame: {},
        selectedGamesFilter: 'slots',
        mobileSearchFlag: false,
        isAddingGames: false,
      }
    },
    computed: {
      ...mapGetters({
        games: 'gameModule/getSearchGames',
      }), 
      searchName: {
      get() {
        return this.$store.state.search.searchName;
      },
      set(value) {
        this.$store.commit("search/setSearchName", value);
      },
    },
    },
  
    methods: {
      async loadAllGames() {
        this.loading = true;
        await this.$store.dispatch("gameModule/getSearchedGames", {});
        this.loading = false;
      },
      async playGame(game, demoFlag = false) {
        this.$store.commit('setOpenMobileSearch', false);
        this.$store.commit('search/setSearchPC', false);
        this.$root.$emit('gameClicked');
        await this.$store.dispatch("gameModule/getSearchedGames", {});
        if (this.$store.getters.getUserInfo.id === null) {
          try {
            await this.$router.push('/reg')
        } catch (error) {
       if (error.name !== 'NavigationDuplicated') {
        throw error; 
      }
    }
        } else {
          if (this.$store.getters.getUserInfo.balance == 0 && !demoFlag) {
            // mb === 0
            try {
              await this.$router.push('/deposit');
        } catch (error) {
       if (error.name !== 'NavigationDuplicated') {
        throw error; 
      }
    }
            
          } else {
            let params = { id: game.string_id };
                if (demoFlag) {
                  params.demo = 'demo';
                }
                await this.$router.push({ name: 'Game', params });
          }
        }
        
      },
      mobileSelectGame(id) {
        this.selectedGame = id;
        this.dialogMobile = true;
      },
  
    },
  }
  </script>
  
  
  <style lang="scss" scoped>
  .white-heart {
    width: 15px !important;
    height: 15px !important;
    margin-right: 5px;
  
    @media (max-width:500px) {
      width: 13px !important;
      height: 13px !important;
    }
  
    &_big {
      width: 15px !important;
      height: 15px !important;
    }
  }
  .grid__wrapper {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  
  }
  @mixin imageGame {
    max-width: 100%;
    max-height: 100%;
    border-radius: 16px;
    overflow: hidden;
    transition: opacity 0.3s;
  }
  
  @mixin logoLoading {
    position: relative;
  
    &::before {
      content: '';
      display: block;
      border-radius: 16px;
      position: absolute;
      top: 0px;
      left: 0;
      z-index: 4;
      width: 100%;
      height: 100%;
      background-color: var(--red);
      animation: disappear 1s;
      animation-delay: 1s;
      animation-fill-mode: forwards;
      opacity: 1;
      visibility: visible;
    }
  
    &::after {
      content: '';
      position: absolute;
      border-radius: 16px;
      top: 0;
      left: 0;
      z-index: 5;
      width: 100%;
      height: 100%;
      background: url('../../assets/images/main_logo.png') center / 90px no-repeat;
      animation: disappear2 1s;
      opacity: 0.6;
      animation-delay: 1s;
      animation-fill-mode: forwards;
  
      @media (max-width:992px) {
        background-size: 60px;
      }
    }
  }
  
  .game {
    position: relative;
  
    &__item {
      box-sizing: border-box;
      display: inline-block;
      flex: 0 1 auto;
      line-height: 0;
      padding: 10px;
      position: relative;
      transition: transform .3s;
      width: 25%;
  
      @media (max-width:1100px) {
        padding: 5px;
      }
  
      @media (orientation: landscape) and (min-width: 600px) and (max-width:1024px) {
        width: 25%;
      }
  
      @media (max-width: 600px) {
        width: 33.33%;
        padding: 3px;
      }
      
    }
  
    &__image-container {
      @include logoLoading;
        &:hover {
          .game__overlay {
            opacity: 1;
            visibility: visible;
  
            &::before {
              left: 168%;
            }
          }
  
          .game__image {
            opacity: 0.5;
  
          }
      }
    }
  
    &__image {
      @include imageGame;
    }
  
    &__overlay {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 30px;
      align-items: center;
      z-index: 3;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 0%;
      left: 0%;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      text-align: center;
      border-radius: 16px;
      overflow: hidden;
      text-decoration: none;
      backdrop-filter: blur(2px);
      transition: all 0.3s ease-out;
  
      @media (min-width:849.98px) {
        &::before {
          content: '';
          background-color: rgba(white, 0.4);
          width: 60%;
          height: 100%;
          top: 0px;
          left: -125%;
          transform: skew(45deg);
          z-index: 3;
          position: absolute;
          transition: left 0.5s ease-out, background-color 0.5s;
        }
      }
    }
  
    &__play {
      width: 100%;
    }
  
    &__demo {
      margin-top: 10px;
      width: 100%;
    }
  
    &__name {
      height: 25px;
      max-width: calc(100% - 20px);
      overflow: hidden;
      color: var(--white);
      position: relative;
      top: -5px;
      animation: disName 1s;
      animation-delay: 1s;
      animation-fill-mode: forwards;
      opacity: 0;
      visibility: hidden;
  
      @keyframes disName {
        0% {
          opacity: 0;
          visibility: hidden;
        }
  
        100% {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
  //========================================================================================================================================================
// Kf
@keyframes disappear {
  0% {
    opacity: 1;
    visibility: visible;

  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes disappear2 {
  0% {
    opacity: 0.6;
    visibility: visible;

  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
  
 
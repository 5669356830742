
<template>
    <v-alert type='error' dense class="error" :class="{ '_active' : 
    errorUploadDoc 
}">
        <span v-if="errorUploadDoc">{{ $t('verifError') }}</span>
    </v-alert>
</template>

<script>
export default {
    name: 'AlertError',
    data() {
        return {
            
        }
    },
    computed:{
       errorUploadDoc() {
           return this.$store.state.verification.errorUploadDoc;
       },
      
    }
}
</script>

<style lang="scss" scoped>
.error{
position: absolute;
top: 55px;
color: var(--white);
left: 15px;
width: calc(100% - 30px);
z-index: 2;
opacity: 0;
visibility: hidden;
transition: opacity 0.3s, visibility 0.3s, top 0.3s;
@media (min-width:500.98px){
  right: 10px;
  left: unset;
    width: 350px;
}
&._active{
  opacity: 1;
  visibility: visible;
  top: 65px;
  @media (min-width:959.98px){
    top: 75px;
}
@media (min-width:992.98px){
  top: 85px;
}
}
}
</style>

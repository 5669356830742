<template>
    <div class="desktop-mini-footer">
        <div class="footer__row footer__row_second desktop-mini-footer__row" >
            <div class="footer__center">
                <button class="footer__button all-buttons-t all-buttons-t_mini px-2" @click="toCasino">
                    {{ $t('casino') }}
                </button>
            </div>
            <div class="footer__side">
                <div class="footer__username">
                    {{ user.username }} <span>({{ user.id }})</span>
                </div>
            </div>
            <div class="footer__side">
                <button @click="$emit('menu-open')" class="footer__button ">
                    <v-icon color="var(--white)">
                        list
                    </v-icon>
                </button>
                    <button @click="$emit('fullscreen')" class="footer__button ml-1">
                        <v-icon color="var(--white)">
                            {{ IsFullScreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}
                        </v-icon>
                    </button>
                    <button @click="$router.push('/')" class="footer__button ml-1">
                        <v-icon color="var(--white)">
                            mdi-close
                        </v-icon>
                    </button>
                    
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
    export default {
        name: 'DesktopMiniFooter',
        props:{
            IsFullScreen:{
                type: Boolean,
            },
        },
        data() {
            return {
                
            }
        },
        computed:{
            ...mapGetters({
                user: 'getUserInfo'
            })
        },
        methods: {
            toCasino(){
                try {
                    if (this.$router.currentRoute.path !== '/') {
                        this.$router.push('/');
                    }
                } catch (error) {
                    if (error.name !== 'NavigationDuplicated') {
                        throw error;
                    }
            }
        },
            logout(){
                this.$store.dispatch('logOut');
                this.$router.push('/login');
            },
        },
    }
</script>

<style lang="scss" scoped>
.desktop-mini-footer{
    height: 36px;
    display: flex;
    border-top: 1px solid var(--main-flamingo);
    background-color: var(--black);
    position: relative;
    &__row{
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 26px;
    @media (max-width:768.98px) {
        padding: 0 10px;
          
    }
    }
}
.footer{
    width: 100%;
    height: 136px;
    background-color: var(--black);
    &__row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 0 25px;
        @media (max-width:768.98px) {
            padding: 0 10px;
              
        }
        &_first {
            
            height: 101px;
            border-top: 2px solid #737375;
            background-image: linear-gradient(180deg, hsla(0, 0%, 100%, .25), #000);
            border-bottom: 1px solid #2b2d31;
        }
    
        &_second {
            height: 35px;
            flex: 1;
        }
    }
    
    &__side {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 2;
    }
    
    &__credit {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    &__credit-title {
        color: var(--white);
    font-size: 16px;
    text-transform: uppercase;
    }
    
    &__credit-value {
        color: transparent;
        background: #fff linear-gradient(180deg, var(--white), rgba(147, 27, 163, 0.35));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 32px;
        text-transform: uppercase;
    }


		&__center {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            justify-content: center;
            position: absolute;
            z-index: 1;
            display: flex;
            align-items: center;
		}

		&__button {
		}

		

		&__username {	
            font-weight: 700;
            color: var(--gray);
            font-size: 14px;
            text-transform: uppercase;
            margin-right: 90px;
            
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @media (max-width:500px){
                max-width: 130px;
                font-size: 12px;
                margin-right: 30px;
            }
            span{
                @media (max-width:500px){
                    font-size: 10px;
                }
            }
        }

}
</style>
import { render, staticRenderFns } from "./LossError.vue?vue&type=template&id=6817649f&scoped=true&"
import script from "./LossError.vue?vue&type=script&lang=js&"
export * from "./LossError.vue?vue&type=script&lang=js&"
import style0 from "./LossError.vue?vue&type=style&index=0&id=6817649f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6817649f",
  null
  
)

export default component.exports

<template>
    <div>
      <span>{{ formattedTime }}</span>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Timer',
    data() {
      return {
        currentTime: 0,
        formattedTime: '00:00:00',
        timerInterval: null,
      };
    },
    props: {
      expireInSeconds: {
        type: Number,
        default: 0,
      },
    },
    methods: {
      updateTimer() {
        if (this.currentTime >= this.expireInSeconds) {
          this.stopTimer();
        } else {
          this.formattedTime = this.formatTime(this.expireInSeconds - this.currentTime);
          this.currentTime++;
        }
      },
      formatTime(seconds) {
        const days = Math.floor(seconds / 86400); // 86400 секунд в дне (60 * 60 * 24)
        const hours = Math.floor((seconds % 86400) / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.round(seconds % 60);
  
        const formattedDays =  days + `${this.$t('dayMini')} `;
        const formattedHours = hours.toString().padStart(2, '0') + `${this.$t('hourMini')}`;
        const formattedMinutes = minutes.toString().padStart(2, '0') + `${this.$t('minMini')}`;
        const formattedSeconds = remainingSeconds.toString().padStart(2, '0') + `${this.$t('secMini')}`;
  
        return `${formattedDays}${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
      },
      startTimer() {
        this.currentTime = 0;
        this.formattedTime = this.formatTime(this.expireInSeconds);
  
        this.timerInterval = setInterval(this.updateTimer, 1000);
      },
      stopTimer() {
        clearInterval(this.timerInterval);
      },
    },
    mounted() {
      this.startTimer();
    },
    beforeDestroy() {
      this.stopTimer();
    },
  };
  </script>
  
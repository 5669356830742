<template>
    <v-chip-group column class="chip-items deposit-chips">
        <v-chip v-for="(currency, index) in currencies" style=""
        :key="index" :value="currency" label class="chip-item " outlined :style="{'background-color' : currency.selectToDeposit ? 'var(--black-flamingo)!important' : '#f2f6f9!important'}"
        @click="toggleSelectedCurrencyInstant(currency)">
          {{ currency.text }}
        </v-chip>
        <!-- <v-chip @click="openCurrenciesDialog" label class="text-uppercase chip-item" outlined style="color: var(--black-text)!important;background-color:#f2f6f9!important;">
          {{$t('more')}}
        </v-chip> -->
      </v-chip-group>
</template>

<script>
import { mapGetters } from 'vuex';
    export default {
        name: 'CurrenciesDeposit',
        data() {
            return {
                holdCurrency: '',
                selectCurrency: false,
            }
        },
        mounted() {
          const savedSelectToDeposit = localStorage.getItem('selectToDeposit');
          // const savedSelectToDeposit = this.user.currency;
  if (savedSelectToDeposit) {
    const selectedCurrency = this.currencies.find((currency) => currency.selectToDeposit);

    console.log(selectedCurrency);
    if (selectedCurrency) {
      const index = this.currencies.indexOf(selectedCurrency);
      this.currencies.splice(index, 1);
      this.currencies.unshift(selectedCurrency);
    }
  }
        },
        methods: {
          openCurrenciesDialog(){
                
                this.$store.commit('currencyY/setCurrencyUpdate', true);
            },
           async toggleSelectedCurrencyInstant(currency) {
    this.currencies.forEach(currency => {
      currency.selected = false;
    });
    this.holdCurrency = currency.text;
    //currency.selectToDeposit = true;
      //this.selectCurrency = true;
      //const currencyToSubmit = {
      //    currency: this.holdCurrency,
      //    
      //  };
      //  console.log(this.holdCurrency);
    //const [res, error] = await this.$store.dispatch('awaitEmailReg', currencyToSubmit);
    //if (res) {
    //    console.log("Успешно сменил валюту");
    //  } else if (error) {
    //    console.log("Успешно НЕ сменил валюту");
    //  }
},
        },
        computed:{
            ...mapGetters({
                currencies: 'getCurrent',
                user: 'getUserInfo'
            })
        }
    }
</script>

<style lang="scss" scoped>
.chip-item { 
    border-radius: 6px!important;
    position: relative;
    padding: 0 31px 0 32px;
    
    color: var(--black-text)!important;
    &:last-child{
      margin-right: 0!important;
    }
  }

</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-to-color margin-ctn"},[_c('div',{staticClass:"container-banner",staticStyle:{"position":"relative","z-index":"1"}},[(_vm.banners.length)?_c('swiper',{staticClass:"slider slider-fade",class:{'pb-0': _vm.loadingFlag},attrs:{"breakpoints":{
        '320': {
          slidesPerView: 'auto',
          spaceBetween: 10
        },
        '425': {
          slidesPerView: 'auto',
          spaceBetween: 10
        },
        '600': {
          slidesPerView: 'auto',
          spaceBetween: 10
        },
        '768': {
          slidesPerView: 'auto',
          spaceBetween: 10
        },
        '1024': {
          slidesPerView: 'auto',
          spaceBetween: 10
        }
      }}},_vm._l((_vm.banners),function(banner,index){return _c('swiper-slide',{key:index,staticClass:"fade",staticStyle:{"height":"100%","width":"100%"}},[_c('div',{staticClass:"bannerImg",on:{"click":function($event){return _vm.toRedirect(banner.link)}}},[(banner.image)?_c('img',{attrs:{"src":`https://minio.takemybet.pro/banners/${banner.image}`}}):_vm._e()])])}),1):_c('swiper',{staticClass:"slider slider-fade",attrs:{"breakpoints":{
        '320': {
          slidesPerView: 'auto',
          spaceBetween: 10
        },
        '425': {
          slidesPerView: 'auto',
          spaceBetween: 10
        },
        '600': {
          slidesPerView: 'auto',
          spaceBetween: 10
        },
        '768': {
          slidesPerView: 'auto',
          spaceBetween: 10
        },
        '1024': {
          slidesPerView: 'auto',
          spaceBetween: 10
        }
      }}},[_c('swiper-slide',{staticClass:"fade",staticStyle:{"height":"100%","width":"100%"}},[_c('v-skeleton-loader',{staticClass:"skeleton-full",attrs:{"width":"100%","height":"100%","type":"image"}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
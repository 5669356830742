<template>
  <div style="margin-top: 50px;background-color: var(--main-flamingo);">
    <v-overlay opacity="1" z-index="400" v-if="loading">
      <v-container>
        <img src="../assets/images/main_logo.png" height="250" width="250"/>
      </v-container>
      <p>
        <Preloader></Preloader>
      </p>
    </v-overlay>

    <div style="" v-if="frameUrl !== null">
      <iframe
          class="frame__container"
          frameborder="0"
          @load="loading = false"
          :src="frameUrl"
      ></iframe>
    </div>
    <div style="position: relative;">
      <img src="../assets/images/main_logo.png" height="250" width="auto" class="mt-3 mb-4"/>
      <div style="font-size: 18px;color: var(--white)">
        {{$i18n.t('Soon')}}
        <br>
        {{$i18n.t('SoonMoney')}}
        <br>
        <v-btn style="margin-top: 15px;" class="basicGoldButton" to="/">
          {{$i18n.t('onMain')}}
        </v-btn>
      </div>
    </div>

  </div>
</template>

<script>

    import Preloader from "../components/Preloader";
    export default {
      name: "TvBet",
      components: {Preloader},
      data(){
        return{
          loading: false,
          tvBetData: null
        }
      },
      computed:{
        frameUrl(){
          if (this.tvBetData !== null) {
            return `https://tvbetframe14.com/?lng=ru&clientId=2264&cur=RUB&tokenAuth=${this.tvBetData.auth_token}`;
          } else {
            return null;
          }
        }
      },
      async mounted() {
        //setTimeout(this.hideJdiv, 1500);
        //this.tvBetData = await this.$store.dispatch('getTvBetToken');
        /*new window.TvbetFrame({
          'lng'        : 'ru',
          'cur'        : 'RUB',
          'clientId'   : 2264,
          'tokenAuth'  : tvBetData.auth_token,
          'server'     : 'https://tvbetframe14.com',
          'floatTop'   :  false,
          'containerId': 'tvBetContainer',
          'singleGame' : false,
          https://tvbetframe14.com/?lng=ru&tokenAuth=jwhtJ1Nl97yzcp2PexZC3YT2A1t6T44Qtj2GQ7jZWOBzEzObil&clientId=2264&cur=RUB#/
        });*/
      },
      methods:{
        hideJdiv(){
          let jdiv = document.getElementsByTagName('jdiv')
          if (jdiv.length >= 1) {
            document.getElementsByTagName('jdiv')[1].style.display = 'none'
          } else {
            setTimeout(this.hideJdiv, 1500);
          }
        },
      }
    }
</script>

<style lang="scss" scoped>
  .basicGoldButton{
    font-weight: 400;
  min-height: 50px !important; 
  min-width: 220px;
  //background-color: var(--main-yellow)!important;
  //background-image: url('../assets/images/btn_bg.png');
  border-radius: 5px;
  background-color: var(--red) !important;
  color: var(--white) !important;
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  box-shadow: 0px 0.01em 0.01em rgb(45 35 66 / 100%), 0px 0.3em 0.7em -0.01em rgb(45 35 66 / 100%), inset 0px -0.01em 0px rgb(58 65 111 / 100%)!important;
  &:active {
    box-shadow: inset 0px 0.1em 0.6em #3c4fe0!important;
    transform: translateY(0em);
  }
  @media (any-hover: hover){
    &:hover{
      box-shadow: 0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #3c4fe0!important;
    transform: translateY(-0.1em);
    }
  }
  }
 .router_view{
  display: flex;
  justify-content:center;align-items: center;
 }
  .frame__container{
    //marig-top: 100px;
    position: fixed;
    left: 0px;
    width: 100vw;
    height: calc(100vh + 100px);
    max-width: 100vw;
  }
  @media(max-width: 500px){
    .frame__container{
      padding-bottom: 40px;
    }
  }



</style>
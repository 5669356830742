<template>
  <v-navigation-drawer
    v-model="navDrawer"
    class="burger-menu text-left"
    app
    temporary
    style="background-color: var(--main-flamingo); z-index: 120"
    :class="{ 'burger-menu_active': isPcMenuActive }"
  >
    <v-col class="d-flex align-center text-left pb-5" style="padding-left: 7px">
      <v-col style="padding: 0px; flex: 0 0 70px; width: 70px; height: 70px">
        <v-lazy-image
          @load="navDrawer = true"
          :src="`https://minio.takemybet.pro/ui-style/${logo}`"
          style="max-width: 100%; max-height: 100%"
        />
      </v-col>
      <v-col
        class="pa-0 mr-2"
        v-if="user.id !== null"
        style="
          text-align: right;
          color: var(--white);
          word-break: break-all;
          text-overflow: ellipsis;
        "
      >
        <div @click.stop="profileRedirect" style="cursor: pointer">
          <v-col
            style="padding: 0; font-size: 12px"
            v-if="user.username !== null"
            ><span
              style="
                max-width: 100px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              >{{ user.username }}</span
            ></v-col
          >
          <v-col style="padding: 0; font-size: 12px" v-else
            ><router-link
              to="/profile/info/data"
              style="text-decoration: none; color: var(--red)"
              ><div v-html="$t('setName')"></div></router-link
          ></v-col>

          <v-col
            style="margin-top: -3px; width: auto; padding: 0; font-size: 10px"
          >
            <span
              style="
                max-width: 130px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              id: {{ user.id }}
            </span>
          </v-col>
        </div>
      </v-col>
      <v-col
        v-if="user.id !== null"
        style="cursor: pointer"
        class="ava pa-0"
        @click.stop="profileRedirect"
      >
        <v-lazy-image
          @load="navDrawer = true"
          :src="`https://minio.takemybet.pro/avatars/${user.avatar.image}`"
        >
        </v-lazy-image>
      </v-col>
    </v-col>
    <header-menu-info
      @route-one="redirectToRouteOne"
      @route-zero="redirectToRoute"
      @route-three="redirectToRouteThree"
      :user="user"
      :userBonuses="userBonuses"
    />
    <div @click="navDrawer = false" class="pb-5">
      <v-row ref="row1">
        <v-col
          class="drawer_list_item"
          style="
            margin-left: 10px;
            border-bottom: solid 1px var(--dark-flamingo);
            cursor: pointer;
          "
          @click="homeRedirect"
        >
          <div class="container-content">
            <v-icon color="white">house</v-icon>

            <span>{{ $t("main") }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row ref="row2" v-if="user.id !== null">
        <v-col
          class=""
          @click.stop="toggleSpoilerBonuses"
          style="
            margin-left: 10px;
            border-bottom: solid 1px var(--dark-flamingo);
            cursor: pointer;
          "
        >
          <div>
            <v-card-title class="pa-0">
              <div
                style="flex: 1 1 auto; text-align: left"
                class="d-flex align-center spoiler__title before-line"
              >
                <font-awesome-icon
                  class="ml-0"
                  style="margin-right: 5px; font-size: 20px"
                  icon="gifts"
                />
                <span>{{ $t("Promotions") }}</span>
              </div>
              <v-icon
                color="var(--white)"
                class="icon-for-li"
                :class="{ _active: isOpenBonuses === true }"
              >
                keyboard_arrow_down
              </v-icon>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-expand-transition>
                <div v-if="isOpenBonuses">
                  <div style="padding: 20px 0 0 0"></div>
                  <v-col
                    v-if="user.id !== null"
                    class="drawer_list_item drawer_list_item-li"
                    style="padding: 5px 12px 10px"
                    @click.stop="achieveRedirect"
                  >
                    <div class="container-content">
                      <font-awesome-icon
                        class="ml-0"
                        style="margin-right: 5px; font-size: 20px"
                        icon="trophy"
                      />
                      <span>{{ $t("level") }}</span>
                    </div>
                  </v-col>
                  <v-col
                    v-if="user.id !== null"
                    class="drawer_list_item drawer_list_item-li"
                    style="padding: 10px 12px"
                    @click.stop="bonusRedirect"
                  >
                    <div class="container-content">
                      <v-icon color="white"> redeem </v-icon>
                      <span>{{ $t("bonuses") }}</span>
                    </div>
                  </v-col>
                  <v-col
                    v-if="user.id == null"
                    class="drawer_list_item drawer_list_item-li"
                    style="padding: 10px 12px 0px"
                    @click.stop="welcomeNoLoginBonusesRedirect"
                  >
                    <div class="container-content">
                      <font-awesome-icon
                        class="ml-0"
                        style="margin-right: 5px; font-size: 20px"
                        icon="gift"
                      />
                      <span>{{ $t("all_bonuses") }}</span>
                    </div>
                  </v-col>
                  <v-col
                    v-if="user.id !== null"
                    class="drawer_list_item drawer_list_item-li"
                    style="padding: 10px 12px 0px"
                    @click.stop="welcomeBonusesRedirect"
                  >
                    <div class="container-content">
                      <font-awesome-icon
                        class="ml-0"
                        style="margin-right: 5px; font-size: 20px"
                        icon="gift"
                      />
                      <span>{{ $t("all_bonuses") }}</span>
                    </div>
                  </v-col>
                </div>
              </v-expand-transition>
            </v-card-text>
          </div>
        </v-col>
      </v-row>
      <v-row ref="row3">
        <v-col
          v-if="user.id !== null"
          @click.stop="toggleSpoiler"
          style="
            margin-left: 10px;
            border-bottom: solid 1px var(--dark-flamingo);
            cursor: pointer;
          "
        >
          <div>
            <v-card-title class="pa-0">
              <div
                style="flex: 1 1 auto; text-align: left"
                class="spoiler__title before-line"
              >
                <v-icon color="white">person</v-icon>
                <span>{{ $t("profile") }}</span>
              </div>
              <v-icon
                color="var(--white)"
                class="icon-for-li"
                :class="{ _active: isOpen === true }"
              >
                keyboard_arrow_down
              </v-icon>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-expand-transition>
                <div v-if="isOpen">
                  <div style="padding: 20px 0 0 0"></div>
                  <v-col
                    class="drawer_list_item drawer_list_item-li"
                    style="padding: 5px 12px 10px 12px"
                    @click.stop="profileRedirect"
                  >
                    <div class="container-content">
                      <v-icon color="white"> settings </v-icon>
                      <span>{{ $t("myProfile") }}</span>
                    </div>
                  </v-col>
                  <v-col
                    v-if="user.id !== null"
                    class="drawer_list_item-li"
                    style="padding: 10px 5px 0px 12px"
                    @click.stop="toggleResponsibleGambling"
                  >
                    <div class="d-flex">
                      <div
                        class="text-uppercase"
                        style="flex: 1 1 auto; color: var(--white)"
                      >
                        {{ $t("responsibleGambling") }}
                      </div>
                      <v-icon
                        color="var(--white)"
                        class="icon-for-li"
                        style=""
                        :class="{
                          _active: isResponsibleGamblingOpen === true,
                        }"
                      >
                        keyboard_arrow_down
                      </v-icon>
                    </div>
                    <v-col class="" style="margin: 0 0 -15px 0">
                      <div>
                        <v-card-title class="pa-0"> </v-card-title>
                        <v-card-text class="pa-0">
                          <v-expand-transition>
                            <div v-if="isResponsibleGamblingOpen">
                              <div style="padding: 5px 0 0 0"></div>
                              <v-col
                                class="drawer_list_item drawer_list_item-li drawer_list_item-li-li"
                                style="padding: 0px 12px 10px 12px"
                                @click.stop="selfExclusionRedirect"
                              >
                                <div class="container-content">
                                  <v-icon color="white">
                                    phonelink_erase
                                  </v-icon>
                                  <span>{{ $t("selfExclusion") }}</span>
                                </div>
                              </v-col>
                              <v-col
                                class="drawer_list_item drawer_list_item-li drawer_list_item-li-li"
                                style="padding: 10px 12px"
                                @click.stop="limitsRedirect"
                              >
                                <div class="container-content">
                                  <div class="container-content">
                                    <v-icon color="white">
                                      network_locked
                                    </v-icon>
                                    <span>{{ $t("limits") }}</span>
                                  </div>
                                  <v-spacer></v-spacer>
                                  <v-icon
                                    style="transform: translate(17px, 0px)"
                                    color="var(--green-success)"
                                    v-if="
                                      user.balance_limit != null ||
                                      user.loss_limit != null ||
                                      user.wagering_limit != null
                                    "
                                    >done
                                  </v-icon>
                                  <!-- <font-awesome-icon v-if="user.balance_limit != null || user.loss_limit != null || user.wagering_limit != null" size="lg" style="color:var(--green-success)" icon="check-square"/> -->
                                </div>
                              </v-col>
                              <v-col
                                class="drawer_list_item drawer_list_item-li drawer_list_item-li-li"
                                style="padding: 10px 12px"
                                @click.stop="realityCheckRedirect"
                              >
                                <div class="container-content">
                                  <div class="container-content">
                                    <v-icon color="white"> alarm_on </v-icon>
                                    <span>{{ $t("realityCheckTitle") }}</span>
                                  </div>
                                  <v-spacer></v-spacer>
                                  <v-icon
                                    style="transform: translate(17px, 0px)"
                                    color="var(--green-success)"
                                    v-if="user.time_limit != null"
                                  >
                                    done
                                  </v-icon>
                                </div>
                              </v-col>
                            </div>
                          </v-expand-transition>
                        </v-card-text>
                      </div>
                    </v-col>
                  </v-col>

                  <v-col
                    class="drawer_list_item drawer_list_item-li"
                    style="padding: 10px 12px 0px 12px"
                    v-if="user.status_verification == null && user.id !== null"
                    @click.stop="verificationRedirect"
                  >
                    <div
                      class="container-content"
                      style="color: var(--red)"
                      :style="{
                        color:
                          (user.doc1_verified === 1 ||
                            user.doc2_verified === 1 ||
                            user.doc3_verified === 1) &&
                          user.doc1_verified !== 3 &&
                          user.doc2_verified !== 3 &&
                          user.doc3_verified !== 3 &&
                          user.doc1_verified !== 0 &&
                          user.doc2_verified !== 0 &&
                          user.doc3_verified !== 0
                            ? 'var(--yellow)'
                            : user.doc1_verified === 3 ||
                              user.doc2_verified === 3 ||
                              user.doc3_verified === 3
                            ? 'var(--red)'
                            : user.doc1_verified === 2 &&
                              user.doc2_verified === 2 &&
                              user.doc3_verified === 2
                            ? 'var(--green-success)'
                            : 'var(--red)', // if all documents === 0
                      }"
                    >
                      <v-icon style="color: inherit; margin-right: 3px">
                        add_moderator
                      </v-icon>
                      <span>{{ $t("verification") }}</span>
                      <v-spacer></v-spacer>
                      <font-awesome-icon
                        icon="exclamation-triangle"
                        v-if="
                          user.doc1_verified === 3 ||
                          user.doc2_verified === 3 ||
                          user.doc3_verified === 3 ||
                          user.doc1_verified === 0 ||
                          user.doc2_verified === 0 ||
                          user.doc3_verified === 0
                        "
                      />
                      <v-icon
                        color="var(--yellow)"
                        v-if="
                          (user.doc1_verified === 1 ||
                            user.doc2_verified === 1 ||
                            user.doc3_verified === 1) &&
                          user.doc1_verified !== 3 &&
                          user.doc2_verified !== 3 &&
                          user.doc3_verified !== 3 &&
                          user.doc1_verified !== 0 &&
                          user.doc2_verified !== 0 &&
                          user.doc3_verified !== 0
                        "
                        >timer</v-icon
                      >
                      <v-icon
                        color="var(--green-success)"
                        v-if="
                          user.doc1_verified === 2 &&
                          user.doc2_verified === 2 &&
                          user.doc3_verified === 2
                        "
                        >done</v-icon
                      >
                    </div>
                  </v-col>
                </div>
              </v-expand-transition>
            </v-card-text>
          </div>
        </v-col>
      </v-row>
      <v-row ref="row4" class="notifications" v-if="$isMobile()">
        <v-col
          v-if="user.id !== null"
          @click.stop="toggleNotif"
          style="
            margin-left: 10px;
            border-bottom: solid 1px var(--dark-flamingo);
            cursor: pointer;
          "
        >
          <div>
            <v-card-title class="pa-0">
              <div
                style="flex: 1 1 auto; text-align: left"
                class="spoiler__title before-line"
              >
                <v-icon color="white">notifications_active</v-icon>
                <span>{{ $t("notifications") }}</span>
              </div>
              <v-icon
                color="var(--white)"
                class="icon-for-li"
                :class="{ _active: isNotif === true }"
              >
                keyboard_arrow_down
              </v-icon>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-expand-transition>
                <div v-if="isNotif">
                  <div style="padding: 20px 0 0 0"></div>
                  <v-col
                    class="drawer_list_item drawer_list_item-li"
                    style="padding: 5px 12px 0 12px"
                    @click.stop="profileRedirect"
                  >
                  <NotificationItem />
                  </v-col>
                </div>
              </v-expand-transition>
            </v-card-text>
          </div>
        </v-col>
      </v-row>
      <v-row ref="row5"> </v-row>
      <v-row ref="row6" v-if="user.id != null">
        <v-col
          class=""
          @click.stop="toggleSpoilerCash"
          style="
            margin-left: 10px;
            border-bottom: solid 1px var(--dark-flamingo);
            cursor: pointer;
          "
        >
          <div>
            <v-card-title class="pa-0">
              <div
                style="flex: 1 1 auto; text-align: left"
                class="spoiler__title before-line"
              >
                <v-icon color="white">account_balance_wallet</v-icon>
                <span>{{ $t("cashbox") }}</span>
              </div>
              <v-icon
                color="var(--white)"
                class="icon-for-li"
                :class="{ _active: isOpenCash === true }"
              >
                keyboard_arrow_down
              </v-icon>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-expand-transition>
                <div v-if="isOpenCash">
                  <div style="padding: 20px 0 0 0"></div>
                  <v-col
                    class="drawer_list_item drawer_list_item-li"
                    style="padding: 5px 12px 10px 12px"
                    @click.stop="DepositRedirect"
                  >
                    <div class="container-content">
                      <v-icon color="white"> payment </v-icon>
                      <span>{{ $t("deposit2") }}</span>
                    </div></v-col
                  >
                  <v-col
                    class="drawer_list_item drawer_list_item-li"
                    style="padding: 10px 12px"
                    @click.stop="payHistoryRedirect"
                  >
                    <div class="container-content">
                      <v-icon color="white"> history </v-icon>
                      <span>{{ $t("paymentsHistory") }}</span>
                    </div>
                  </v-col>
                  <v-col
                    class="drawer_list_item drawer_list_item-li"
                    style="padding: 10px 12px 0px 12px"
                    @click.stop="withdrawRedirect"
                  >
                    <div class="container-content">
                      <v-icon color="white"> monetization_on </v-icon>
                      <span>{{ $t("withdraw") }}</span>
                    </div>
                  </v-col>
                </div>
              </v-expand-transition>
            </v-card-text>
          </div>
        </v-col>
      </v-row>
      <v-row ref="row7">
        <v-col
          class=""
          @click.stop="toggleSpoilerInfo"
          style="
            margin-left: 10px;
            border-bottom: solid 1px var(--dark-flamingo);
            cursor: pointer;
          "
        >
          <div>
            <v-card-title class="pa-0">
              <div
                style="flex: 1 1 auto; padding-left: 3px"
                class="d-flex align-center text-left spoiler__title before-line"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="white"
                  height="20px"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
                  />
                </svg>

                <span>{{ $t("information") }}</span>
              </div>
              <v-icon
                color="var(--white)"
                class="icon-for-li"
                :class="{ _active: isOpenInfo === true }"
              >
                keyboard_arrow_down
              </v-icon>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-expand-transition>
                <div v-if="isOpenInfo">
                  <div style="padding: 20px 0 0 0"></div>
                  <v-col
                    @click.stop="rulesRedirect"
                    class="drawer_list_item drawer_list_item-li"
                    style="padding: 5px 12px 2px 12px"
                  >
                    <!-- click -->
                    <div class="container-content">
                      <v-icon color="white">description</v-icon>
                      <span style="margin-left: 5px">{{
                        $t("termsAndCon")
                      }}</span>
                    </div>
                  </v-col>
                </div>
              </v-expand-transition>
            </v-card-text>
          </div>
        </v-col>
      </v-row>
      <v-row ref="row8">
        <v-col
          class=""
          @click.stop="istoggleMenu"
          style="
            margin-left: 10px;
            border-bottom: solid 1px var(--dark-flamingo);
            cursor: pointer;
          "
        >
          <div>
            <v-card-title class="pa-0">
              <div
                style="flex: 1 1 auto; padding-left: 3px"
                class="spoiler__title before-line text-left"
              >
                <div class="container-content" v-if="selectedLanguages">
                  <div class="heart-mask">
                    <!-- <span></span> -->
                    <img
                      :src="selectedLanguages.logo"
                      :alt="selectedLanguages.language_code"
                    />
                  </div>
                  <span>{{ selectedLanguages.language_code }}</span>
                </div>
              </div>
              <v-icon
                color="var(--white)"
                class="icon-for-li"
                :class="{ _active: isMenuOpen === true }"
              >
                keyboard_arrow_down
              </v-icon>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-expand-transition>
                <div v-if="isMenuOpen">
                  <div style="margin-bottom: -10px">
                    <div style="padding: 20px 0 0 0"></div>
                    <v-col
                      class="drawer_list_item drawer_list_item-li"
                      v-for="(lang, index) in sortedLanguages"
                      style="padding: 5px 12px 10px 0px"
                      :key="index"
                      @click="changeLanguage(lang)"
                    >
                      <div class="container-content justify-center">
                        <div class="heart-mask">
                          <!-- <span></span> -->
                          <img :src="lang.logo" />
                        </div>
                        <span>{{ lang.language_code }}</span>
                      </div>
                    </v-col>
                  </div>
                </div>
              </v-expand-transition>
            </v-card-text>
          </div>
        </v-col>
      </v-row>
      <v-row ref="row9" v-if="user.id != null">
        <v-col
          class="drawer_list_item"
          @click="logOut"
          style="
            margin-left: 10px;
            border-bottom: solid 1px var(--dark-flamingo);
            cursor: pointer;
          "
        >
          <div class="container-content">
            <v-icon color="white"> logout </v-icon>
            <span>{{ $t("exit") }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="" style="margin-left: 10px">
          <div class="container-content"></div>
        </v-col>
      </v-row>
      <v-row ref="row0">
        <v-col class="" style="margin-left: 10px">
          <div class="container-content"></div>
        </v-col>
      </v-row>
      <v-row v-if="$isMobile()">
        <v-col class="" style="margin-left: 10px">
          <div class="container-content"></div>
        </v-col>
      </v-row>
      <v-row v-if="$isMobile()">
        <v-col class="" style="margin-left: 10px">
          <div class="container-content"></div>
        </v-col>
      </v-row>
    </div>
  </v-navigation-drawer>
</template>

<script>
import HeaderMenuInfo from "./HeaderMenuInfo";
import NotificationItem from "./HeaderButtons/NotificationItem.vue";
import { logOut } from "@/store/clear_store";
import { stopTimer } from "../../timerWorker";
import { mapGetters } from "vuex";
export default {
  components: {
    HeaderMenuInfo,
    NotificationItem,
  },
  data() {
    return {
      isPcMenuActive: false,
      isOpen: false,
      isNotif: false,
      isVerification: false,
      isOpenCash: false,
      isOpenInfo: false,
      isOpenBonuses: false,
      isResponsibleGamblingOpen: false,
      menuOpen: false,
      isAllPromotionsOpen: false,
      isMenuOpen: false,
    };
  },
  watch: {
    // navDrawer(newValue, oldValue){
    //     if (newValue === true) {
    //     this.clearAnimations();
    //     this.animateRowComponents();
    //     }
    // },
  },
  computed: {
    ...mapGetters({
      logo: "styles/getLogo",
      user: "getUserInfo",
      userBonuses: "bonuses/getSpinsBonuses",
    }),
    navDrawer: {
      get() {
        return this.$store.state.navDrawer;
      },
      set(value) {
        this.$store.commit("SET_NAV_DRAWER", value);
      },
    },
    sortedLanguages() {
      const supportedLanguages =
        this.$store.getters["localization/getLocalizations"].slice();

      const currentLang = localStorage.getItem("locale") || "en";

      const index = supportedLanguages.findIndex(
        (lang) => lang.language_code === currentLang
      );

      if (index !== -1) {
        const currentLangObj = supportedLanguages.splice(index, 1)[0];
        supportedLanguages.unshift(currentLangObj);
      }

      return supportedLanguages;
    },
    selectedLanguages() {
      const supportedLanguages =
        this.$store.getters["localization/getLocalizations"].slice();

      const currentLang = localStorage.getItem("locale") || "en";

      const index = supportedLanguages.findIndex(
        (lang) => lang.language_code === currentLang
      );

      if (index !== -1) {
        const currentLangObj = supportedLanguages.splice(index, 1)[0];
        supportedLanguages.unshift(currentLangObj);
      }

      return supportedLanguages[0];
    },
  },
  methods: {
    //         animateRowComponents() {
    //   const row1 = this.$refs.row1 || this.$refs.row0;
    //   const row2 = this.$refs.row2 || this.$refs.row0;
    //   const row3 = this.$refs.row3 || this.$refs.row0;
    //   const row4 = this.$refs.row4 || this.$refs.row0;
    //   const row5 = this.$refs.row5 || this.$refs.row0;
    //   const row6 = this.$refs.row6 || this.$refs.row0;
    //   const row7 = this.$refs.row7 || this.$refs.row0;
    //   const row8 = this.$refs.row8 || this.$refs.row0;
    //   const row9 = this.$refs.row9 || this.$refs.row0;

    //   const timeline = gsap.timeline();

    //   timeline
    //     .fromTo(
    //       row1,
    //       { x: "-100%", opacity: 0 },
    //       { duration: 0.4, x: "0%", opacity: 1 },
    //       "+=0.3"
    //     )
    //     .fromTo(
    //       row2,
    //       { x: "-100%", opacity: 0 },
    //       { duration: 0.4, x: "0%", opacity: 1 },
    //       "-=0.3"
    //     )
    //     .fromTo(
    //       row3,
    //       { x: "-100%", opacity: 0 },
    //       { duration: 0.4, x: "0%", opacity: 1 },
    //       "-=0.3"
    //     )

    //     .fromTo(
    //       row4,
    //       { x: "-100%", opacity: 0 },
    //       { duration: 0.4, x: "0%", opacity: 1 },
    //       "-=0.3"
    //     )
    //     .fromTo(
    //       row5,
    //       { x: "-100%", opacity: 0 },
    //       { duration: 0.4, x: "0%", opacity: 1 },
    //       "-=0.5"
    //     )
    //     .fromTo(
    //       row6,
    //       { x: "-100%", opacity: 0 },
    //       { duration: 0.4, x: "0%", opacity: 1 },
    //       "-=0.5"
    //     )
    //     .fromTo(
    //       row7,
    //       { x: "-100%", opacity: 0 },
    //       { duration: 0.4, x: "0%", opacity: 1 },
    //       "-=0.5"
    //     )
    //     .fromTo(
    //       row8,
    //       { x: "-100%", opacity: 0 },
    //       { duration: 0.4, x: "0%", opacity: 1 },
    //       "-=0.5"
    //     )
    //     .fromTo(
    //       row9,
    //       { x: "-100%", opacity: 0 },
    //       { duration: 0.4, x: "0%", opacity: 1 },
    //       "-=0.5"
    //     );

    // },
    // clearAnimations() {
    //   gsap.killTweensOf(this.$refs.row1);
    //   gsap.killTweensOf(this.$refs.row2);
    //   gsap.killTweensOf(this.$refs.row3);
    //   gsap.killTweensOf(this.$refs.row4);
    //   gsap.killTweensOf(this.$refs.row5);
    //   gsap.killTweensOf(this.$refs.row6);
    //   gsap.killTweensOf(this.$refs.row7);
    //   gsap.killTweensOf(this.$refs.row8);
    //   gsap.set(this.$refs.row1, { clearProps: "all" });
    //   gsap.set(this.$refs.row2, { clearProps: "all" });
    //   gsap.set(this.$refs.row3, { clearProps: "all" });
    //   gsap.set(this.$refs.row4, { clearProps: "all" });
    //   gsap.set(this.$refs.row5, { clearProps: "all" });
    //   gsap.set(this.$refs.row6, { clearProps: "all" });
    //   gsap.set(this.$refs.row7, { clearProps: "all" });
    //   gsap.set(this.$refs.row8, { clearProps: "all" });

    //   gsap.killTweensOf(this.$refs.depositButton);
    //   gsap.set(this.$refs.depositButton, { clearProps: "all" });
    // },
    changeLanguage(code) {
      const isCurrentLang = localStorage.getItem("locale");
      if (isCurrentLang !== code.language_code) {
        this.$store.dispatch(
          "localization/awaitGetSelectLocal",
          code.language_code
        );
        location.reload();
      }
    },
    // Menu List Open
    istoggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    toggleSpoilerBonuses() {
      this.isOpenBonuses = !this.isOpenBonuses;
    },
    toggleSpoiler() {
      this.isOpen = !this.isOpen;
    },
    toggleNotif() {
      this.isNotif = !this.isNotif;
    },
    toggleSpoilerCash() {
      this.isOpenCash = !this.isOpenCash;
    },
    toggleSpoilerInfo() {
      this.isOpenInfo = !this.isOpenInfo;
    },
    toggleResponsibleGambling() {
      this.isResponsibleGamblingOpen = !this.isResponsibleGamblingOpen;
    },
    toggleAllPromotions() {
      this.isAllPromotionsOpen = !this.isAllPromotionsOpen;
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    //========================================================================================================================================================
    // Logout
    async logOut() {
      logOut();
      this.$store.commit("setIsInstaReg", false);
      const remainTime = localStorage.getItem("remainingTimeInSeconds");
      if (remainTime > 0) {
        stopTimer();
        this.$store.dispatch("updateUserInfo", { time_limit: remainTime });
        setTimeout(() => {
          localStorage.removeItem("remainingTimeInSeconds");
          localStorage.removeItem("endTime");
        }, 50);
      }
      this.$store.commit("depositView/setMethodMoney", false);
      this.$store.commit("depositView/setMethodCrypto", false);
      this.$store.commit("depositView/setMethodExit", false);
      this.$store.commit("depositView/setDepositExitWindow", false);
      this.$store.commit("depositView/setDepositMethodsWindow", false);
      this.$store.commit("depositView/setDepositWindowError", false);
      this.$store.commit("depositView/setSelectedMethod", null);
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
      try {
        await this.$store.dispatch("logOut");
        //location.reload();
      } catch (error) {
        if (error.name == "NavigationDuplicated") {
          throw error;
        }
      }
      this.$store.commit("setDepositWindowError", false);
      this.isOpen = false;
      this.isVerification = false;
      this.isOpenCash = false;
      this.isOpenInfo = false;
      this.isOpenBonuses = false;
      this.isResponsibleGamblingOpen = false;
      this.menuOpen = false;
      this.isAllPromotionsOpen = false;
      this.isMenuOpen = false;
    },
    //========================================================================================================================================================

    async moveHome() {
      if (this.$route.path !== "/") {
        await this.$router.push("/");
      }
    },
    //========================================================================================================================================================

    async redirectToHome(pathHome) {
      this.navDrawer = false;
      setTimeout(() => {
        this.isOpenCash = false;
        this.isOpen = false;
        this.isNotif = false;
        this.isMenuOpen = false;
        this.isResponsibleGamblingOpen = false;
        this.isOpenBonuses = false;
        this.isOpenInfo = false;
      }, 350);
      try {
        await this.$router.push(pathHome);
      } catch (error) {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      }
    },
    async homeRedirect() {
      await this.redirectToHome("/");
    },
    //========================================================================================================================================================

    async redirectToRoute(path) {
      this.navDrawer = false;
      setTimeout(() => {
        this.isOpen = false;
        this.isNotif = false;
        this.isMenuOpen = false;
        this.isResponsibleGamblingOpen = false;
        this.isOpenBonuses = false;
        this.isOpenInfo = false;
      }, 350);
      if (!path) {
        return;
      }
      try {
        await this.$router.push(path);
      } catch (error) {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      }
    },
    async balanceRedirect() {
      await this.redirectToRoute("/profile/cashbox/balance");
    },

    async DepositRedirect() {
      await this.redirectToRoute("/deposit");
    },

    async payHistoryRedirect() {
      await this.redirectToRoute("/profile/cashbox/payhistory");
    },
    async withdrawRedirect() {
      await this.redirectToRoute("/cashout");
    },
    //========================================================================================================================================================

    async redirectToRouteOne(pathOne) {
      this.navDrawer = false;
      setTimeout(() => {
        this.isOpenCash = false;
        this.isNotif = false;
        this.isResponsibleGamblingOpen = false;
        this.isMenuOpen = false;
        this.isOpenBonuses = false;
        this.isOpenInfo = false;
      }, 350);
      if (!pathOne) {
        return;
      }
      try {
        await this.$router.push(pathOne);
      } catch (error) {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      }
    },

    async verificationRedirect() {
      await this.redirectToRouteOne("/profile/info/verification");
    },

    async profileMainRedirect() {
      await this.redirectToRouteOne("/profile/info/main");
    },

    async profileRedirect() {
      if (this.user.id != null) {
        await this.redirectToRouteOne("/profile/info/data");
      } else {
        if (this.$route.path != "/reg") {
          await this.$router.push("/reg");
        }
      }
    },
    //========================================================================================================================================================

    async redirectToRouteTwo(pathTwo) {
      this.navDrawer = false;
      setTimeout(() => {
        this.isOpenCash = false;
        this.isMenuOpen = false;
        this.isNotif = false;
        this.isOpenBonuses = false;
        this.isOpenInfo = false;
      }, 350);

      try {
        await this.$router.push(pathTwo);
      } catch (error) {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      }
    },

    async limitsRedirect() {
      await this.redirectToRouteTwo("/profile/responsible-gambling/limits");
    },

    async selfExclusionRedirect() {
      await this.redirectToRouteTwo(
        "/profile/responsible-gambling/self-exclusion"
      );
    },
    async realityCheckRedirect() {
      await this.redirectToRouteTwo(
        "/profile/responsible-gambling/reality-check"
      );
    },
    //========================================================================================================================================================
    async redirectToRouteThree(pathThree) {
      this.navDrawer = false;
      setTimeout(() => {
        this.isOpenCash = false;
        this.isOpen = false;
        this.isResponsibleGamblingOpen = false;
        this.isMenuOpen = false;
        this.isNotif = false;
        this.isOpenInfo = false;
      }, 350);

      try {
        await this.$router.push(pathThree);
      } catch (error) {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      }
    },
    async bonusRedirect() {
      await this.redirectToRouteThree("/profile/promo-my/bonus");
    },
    async achieveRedirect() {
      await this.redirectToRouteThree("/profile/promo-my/level");
    },
    //========================================================================================================================================================

    async redirectToRouteFour(pathFour) {
      this.navDrawer = false;
      setTimeout(() => {
        this.isOpenCash = false;
        this.isOpen = false;
        this.isResponsibleGamblingOpen = false;
        this.isMenuOpen = false;
        this.isOpenBonuses = false;
        this.isNotif = false;
      }, 350);

      try {
        await this.$router.push(pathFour);
      } catch (error) {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      }
    },

    async rulesRedirect() {
      await this.redirectToRouteFour("/rules");
    },
    //========================================================================================================================================================

    async redirectToRouteFive(pathFive) {
      this.navDrawer = false;
      setTimeout(() => {
        this.isOpenCash = false;
        this.isOpen = false;
        this.isResponsibleGamblingOpen = false;
        this.isMenuOpen = false;
        this.isNotif = false;
      }, 350);

      try {
        await this.$router.push(pathFive);
      } catch (error) {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      }
    },

    async welcomeBonusesRedirect() {
      await this.redirectToRouteFive("/profile/promotions");
    },
    async welcomeNoLoginBonusesRedirect() {
      await this.redirectToRouteFive("/promotions-welcome");
    },
  },
};
</script>

<style lang="scss" scoped>
.spoiler__title {
  color: var(--white);
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  transition: font-weight 0.3s;
  @media (any-hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
  span {
    margin-left: 6px;
  }
}
.heart-mask {
  -webkit-mask-image: url("../../assets/images/white-heart.svg");
  mask-image: url("../../assets/images/white-heart.svg");
  -webkit-mask-size: cover;
  mask-size: cover;
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  &_aside {
    width: 40px;
    height: 40px;
    &::after {
      display: none;
    }
    &::before {
      display: none;
    }
    span {
      display: none;
    }
  }
}
.ava {
  display: flex;
  width: 70px;
  height: 70px;
  flex: 0 0 70px;
  position: relative;
  border: 1px solid var(--red);
  border-radius: 16px;
  overflow: hidden;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
.limit {
  position: absolute;
  top: 55px;
  color: var(--white);
  left: 15px;
  width: calc(100% - 30px);
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s, top 0.3s;
  @media (min-width: 500.98px) {
    right: 10px;
    left: unset;
    width: 350px;
  }
  &._active {
    opacity: 1;
    visibility: visible;
    top: 65px;
    @media (min-width: 959.98px) {
      top: 75px;
    }
    @media (min-width: 992.98px) {
      top: 85px;
    }
  }
}
.container-content {
  display: flex;
  align-items: center;
  span {
    margin-left: 6px;
  }
}
.drawer_list_item {
  text-transform: uppercase;
  font-weight: 600;
  transition: font-weight 0.3s;
  @media (any-hover: hover) {
    &:hover {
      .container-content {
        span {
          text-decoration: underline;
        }
      }
      cursor: pointer;
    }
  }

  &-li {
    font-weight: 500;
    font-size: 14px;
  }

  &-li-li {
    font-size: 14px;
  }
  .v-icon {
    text-decoration: none;
  }
}
.icon-for-li {
  transition: transform 0.3s;
  transform: translate(-10px, 0px) rotate(0deg);
  margin-left: 25px;
  font-size: 20px !important;
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  background-color: var(--red);
  border-radius: 50%;

  &._active {
    transform: translate(-10px, 0px) rotate(-180deg);
  }
}
.before-line {
  position: relative;

  &::before {
    content: "";
    background-color: var(--dark-flamingo);
    position: absolute;
    width: 260px;
    height: 1px;
    bottom: -13px;
    left: -10px;
  }
}
</style>

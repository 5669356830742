<template>
    <div @mouseover="toCheckNotifications" class="notification all-shadow-hover">
        <v-icon style=""
        class="bell" 
        :class="{'_message' : isHaveNewMessage}"
        color="var(--white)"
        >notifications_active</v-icon
      >
      <transition name="fade">
        <div class="have-new" v-if="isHaveNewMessage">
          
        </div>
      </transition>
      <div class="notification-dropdown">
        <div class="notification-dropdown__content">
          <div class="notifications-list__wrapper">
            <ul class="notifications-list">
              <NotificationItem />
            </ul>
          </div>
        </div>
      </div>
      </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NotificationItem from './NotificationItem.vue';
    export default {
        name: 'NotificationDropdown',
        components:{
            NotificationItem
        },
        computed: {
          ...mapGetters({
              isHaveNewMessage: 'notifications/getIsHaveNewMessage',
          })
        },
        methods: {
            toCheckNotifications() {
              if (this.isHaveNewMessage) {
                this.$store.commit('notifications/SET_IS_HAVE_NEW_MESSAGE', false);
              }
            }
        }
    }
</script>

<style lang="scss" scoped>
.bell{
  transition: all 0.3s;
  &._message{
    animation:bell 1.5s infinite;
    @keyframes bell {
      0% {
        transform: rotate(0deg) scale(1);
      }
      20% {
        transform: rotate(15deg) scale(1.1);
      }
      40% {
        transform: rotate(-10deg) scale(1); 
      }
      60% {
        transform: rotate(5deg) scale(1.05); 
      }
      80% {
        transform: rotate(-5deg) scale(1); 
      }
      100% {
        transform: rotate(0deg) scale(1);
      }
    }
  }
}
.have-new{
    position: absolute;
    top: 1px;
    right: 1px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--main-flamingo);
}
@mixin hover-dropdown {
  border-radius: 6px;
  transition: background-color 0.3s;
  @media (any-hover: hover) {
    &:hover {
      background-color: #c935db;
    }
  }
}
@mixin icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--red);
    border-radius: 6px;
    flex: 0 0 36px;
    height: 36px;
    width: 36px;
  }
.notification {
    @include icon;
    position: relative;
    transition: transform 0.3s;
    display: flex;
    @media (any-hover: hover) {
      &:hover {
        background-color: var(--light-flamingo);
        border-radius: 6px 6px 0 0;
        .notification-dropdown {
          display: flex;
        }
      }
    }
  }
  .notification-dropdown {
    border-radius: 10px;
    display: none;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    right: 0;
    top: 100%;
    width: 320px;
    &::before {
      background: var(--light-flamingo);
      content: "";
      height: 10px;
      opacity: 1;
      width: 36px;
    }
          &__content {
        box-shadow: 0 4px 12px rgba(22,20,33,.2);
        background: var(--light-flamingo);
        border-radius: 16px 0 16px 16px;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;
          }
  }
  .notifications-list {
    list-style-type: none;
      margin: 0;
      padding: 0;
          &__wrapper {
        height: 100%;
      overflow-y: auto;
      padding: 10px;
          }
  
          &__item {
            max-height: 300px;
          }
  }
</style>
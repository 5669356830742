<template>
  <v-card-title
    class="athorization-header pa-0"
  >
  <button type="button" @click="toRegDialog" class="athorization-header__tab"
  :class="{ 'is-active': $route.path === '/reg' }"
  >
  {{ $t('registration') }}
</button>
    <button type="button" @click="toLoginDialog" class="athorization-header__tab"
    :class="{ 'is-active': $route.path === '/enter' }"
    >
    {{ $t('login') }}
  </button>
   
  <!-- <div class="athorization-header__tab">
  </div> -->
  </v-card-title>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "RegHeader",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      logo: 'styles/getLogo',
    }),
  },
  methods: {
    toRegDialog() {
      //this.$store.commit("regComponents/setRegDialog", true);
      //this.$store.commit("regComponents/setLoginDialog", false);
      this.$router.push('/reg')
    },
    toLoginDialog() {
      //this.$store.commit("regComponents/setLoginDialog", true);
      //this.$store.commit("regComponents/setRegDialog", false);
      this.$router.push('/enter')
    },
  },
};
</script>

<style lang="scss" scoped>
.athorization-header {
  display: flex;
  align-items: center;
  &__tab {
    border-bottom: 1px solid #f2f6f9;
    color: var(--gray);
    cursor: pointer;
    flex: 1;
    line-height: 1.4;
    font-size: 14px;
    font-weight: 600;
    padding: 11px 10px;
    text-align: center;
    &.is-active {
      border-bottom: 2px solid var(--main-flamingo);
      color: var(--black);
    }
  }
}

</style>

<template>
  <v-container>
    <!-- Title -->
    <v-row class="title__link-v flex-column"> 
      <v-col class="d-flex align-center justify-center">
          <v-icon color="white" style="margin-right: 3px;">
            add_moderator
          </v-icon>
          <span style="color: var(--white);">{{ $i18n.t('verification') }}</span>
        
      </v-col>    
    
    <v-col class="pa-0">
      <v-divider dark></v-divider>
    </v-col>
  </v-row>
  <!-- Content -->
    <v-row>
      <v-col class="column-item"> 
      <VerifFaceAndCard @open-doc-view="openDocView"></VerifFaceAndCard> 
       </v-col>
        <v-col class="column-item">
          <VerifFaceAndPassport @open-doc-view="openDocView"></VerifFaceAndPassport>
        </v-col>
        <v-col class="column-item">
          <VerifPassport @open-doc-view="openDocView"></VerifPassport>
        </v-col>
      </v-row>
      <verif-modal-delete/>
      <verif-modal-view 
      :imagePreview="imagePreview"
      :docNumber="docNumber"
      :modalView="modalView"
      @close-modal="modalView = false"
      />
  </v-container>
</template>

<script>
import Vue from 'vue';
import Preloader from "../../Preloader";
import VerifFaceAndPassport from '@/components/Profile/PlayerInfo/VerifDocuments/VerifFaceAndPassport'
import VerifFaceAndCard from '@/components/Profile/PlayerInfo/VerifDocuments/VerifFaceAndCard'
import VerifPassport from '@/components/Profile/PlayerInfo/VerifDocuments/VerifPassport'
import VerifModalDelete from '@/components/Profile/PlayerInfo/VerifDocuments/VerifModalDelete'
import VerifModalView from '@/components/Profile/PlayerInfo/VerifDocuments/VerifModalView'
import {mapGetters} from 'vuex';

export default {
  name: "Verification",
  components: {
    //Preloader, 
    VerifModalView,
    VerifModalDelete,
    VerifFaceAndPassport,
    VerifFaceAndCard,
    VerifPassport,
  },
  data() {
    return {
      loading: false,
      docNumber: '', 
      modalView: false,
      imagePreview: '',
    }
  },
  computed: {
    currentDateForPassportFace() {
      return localStorage.getItem("currentDateForPassportFace");
    },
    selectedFileNameForPassportFace() {
      return localStorage.getItem("selectedFileNameForPassportFace");
    },
    //========================================================================================================================================================
    currentDateForCardFace() {
      return localStorage.getItem("currentDateForCardFace");
    },
    selectedFileNameForCardFace() {
      return localStorage.getItem("selectedFileNameForCardFace");
    },
    //========================================================================================================================================================
    currentDateForPassport() {
      return localStorage.getItem("currentDateForPassport");
    },
    selectedFileNameForPassport() {
      return localStorage.getItem("selectedFileNameForPassport");
    },
    //========================================================================================================================================================
    
    sizesValid() {
      return this.files.map(el => el.size).reduce((a, b) => {
        return a + b;
      }, 0) <= 150000000;
    },
    requiredFilesCount() {
      return ((this.user.doc1 === null ? 1 : 0) + (this.user.doc2 === null ? 1 : 0) + (this.user.doc3 === null ? 1 : 0));
    },
    ...mapGetters({
      verifySended: 'verifySended',
      user: 'getUserInfo',
    })
  },
  
  methods: {
    openDocView(docData) {
      // this.modalView = true;
      // this.docNumber = docData.docNumber;
      // this.imagePreview = docData.imagePreview;
    },
    toModalDeleteDoc(){
      this.$store.commit('setModalToDeleteDoc', true)
    },
  }
}
</script>

<style lang="scss" scoped>
.column-item{
  &.col{
    padding: 5px;
  }
  @media (max-width:1263px){
    flex: 0 1 50%;
    max-width: 50%; 
  }
  @media (max-width:768px){
    flex: 0 1 100%;
    max-width: 100%; 
  }
  
  @media (min-width:1264px){
    flex: 0 1 33.333%;
    max-width: 33%; 
  }
  
}


.icon__delete-t {
  transition: transform 0.3s;
  @media (any-hover: hover) {
    &:hover {
      transform: rotate(-180deg);
    }
  }
}
</style>

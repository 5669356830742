<template>
    <v-card class="bonus-back">
        <v-card-title class="bonus-back__header header-bonus">
          <div class="header-bonus__label">
            <font-awesome-icon style="font-size: 12px;" icon="gift" />
      </div>
        <div class="header-bonus__title"> {{ $t('firstBonuses') }}</div>
          <!-- @click="toggleCardFlip(index)"  -->
          <div @click="unflipCard" class="header-bonus__label"><v-icon  color="white" style="font-size: 18px;">
            close
        </v-icon></div>
        </v-card-title>
        <v-card-text style="width: auto;" class="bonus-back__main main-bonus">
          <v-list class="main-bonus__list">
            <div class="main-bonus__item">
              <v-list-item-content class="main-bonus__content ">
                <v-list-item-subtitle class="main-bonus__text text-left">
                  200%
          {{$t('bonusToDeposit')}}
        + 40 {{ $t('bonusesFree') }} 
                </v-list-item-subtitle>
              </v-list-item-content>
              
              <v-list-item-content class="main-bonus__content flex-wrap">
                <div class="d-flex align-center">
                <v-list-item-title class="main-bonus__title" style="min-width: 115px;">
                  1 {{ $t('bonusNoLoginDeposit') }}
                </v-list-item-title>
                <v-list-item-subtitle class="main-bonus__text">
                  200%
                </v-list-item-subtitle>
              </div>
                <div class="mt-2" style="color: var(--black);font-size:12px">{{$t('bonusNoLoginInfoValue')}}</div>
              </v-list-item-content>
              <v-list-item-content class="main-bonus__content">
                <v-list-item-title class="main-bonus__title" style="min-width: 115px;">
                  2 {{ $t('bonusNoLoginDeposit') }}
                </v-list-item-title>
                <v-list-item-subtitle class="main-bonus__text">
                  100%
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="main-bonus__content">
                <v-list-item-title class="main-bonus__title" style="min-width: 115px;">
                  3 {{ $t('bonusNoLoginDeposit') }}
                </v-list-item-title>
                <v-list-item-subtitle class="main-bonus__text">
                  100%
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="main-bonus__content">
                <v-list-item-title class="main-bonus__title" style="min-width: 115px;">
                  4 {{ $t('bonusNoLoginDeposit') }}
                </v-list-item-title>
                <v-list-item-subtitle class="main-bonus__text">
                  200%
                </v-list-item-subtitle>
              </v-list-item-content>
              
            
            </div>
          </v-list>
        </v-card-text>
      </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
    export default {
        name: 'BonusWelcomeSpinsOneBack',
        data(){
            return{
            }
        },
        computed:{
          ...mapGetters({
      freeRoundsBonuses: 'getFreeRoundsBonuses',
      depositBonuses: 'getDepositBonuses',
      userBonuses: 'getUserBonuses',
      user: 'getUserInfo'
    }),
    bonusItems() {
    if (this.userBonuses && this.userBonuses.free_rounds) {
      return this.userBonuses.free_rounds.map(bonus => ({
        type: bonus.type,
          count: bonus.count,
          expire_in_days: bonus.expire_in_days + ' ' + this.$t('day'),
          game_name: bonus.game_name,
          wager: bonus.wager,
          lines: bonus.lines,
        
      }));
    } else {
      return [];
    }
  },
  typeTitle() {
      return {
        type: this.$t('type'),
        wager: this.$t('wager'),
        count: this.$t('quantity') , 
        expire_in_days: this.$t('bonusExpire'), 
        game_name: this.$t('game'),
        lines: this.$t('lines'),
      };
    },
        },
        
        methods: {
          
         //setBonusWelcomeOneFlipped(){
         //  this.$store.commit('setBonusWelcomeOneFlipped', false)
         //}
         unflipCard() {
            this.$store.commit('bonuses/noLoginFlip', false);
    },
        },
    }
</script>

<style lang="scss" scoped>
.bonus-back {
  //background: linear-gradient(173.41deg,#0094ff 4.14%,#5607d6 87.72%)!important;
  //background: var(--dark-flamingo)!important;
  background: linear-gradient(to right, var(--white), var(--white))!important;
  
  color: var(--white)!important;
  //height: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
    border-radius: 16px!important;
  &__header {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
  }
  &__content{
    
  }
  &__main {
    flex: 1 1 100%;
    position: relative;
    z-index: 2;
  }

  &__timer {
    position: relative;
    z-index: 2;
  }
  
}
.header-bonus {
  padding: 10px;
  background-color: var(--dark-flamingo);

  &__label {
    height: 24px;
    justify-content: center;
    
    position: relative;
    width: 24px;
    align-items: center;
    display: flex;
    position: relative;
    transition: background-color 0.3s;
    &:last-child{
      cursor: pointer;
      
      @media (any-hover: hover){
        &:hover{
          &::before {
            background-color: hsla(0, 0%, 100%, .4);
          }
        }
      }
      
    }
    &::before {
      background-color: hsla(0, 0%, 100%, .2);
      border-radius: 50%;
      content: "";
      //height: calc(100% - 20px);
      height: 24px;
      width: 24px;
      position: absolute;
      //width: calc(100% - 20px);
    }
    
    }

  &__title {
    display: block;
    line-height: 1.5;
    max-width: calc(100% - 68px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    
  }
}
.main-bonus{
  padding: 0;
  padding-bottom: 50px;
  &__list {
    background-color: var(--white)!important;
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: inherit!important;
    
  }

  &__item {
    line-height: 1.5;
    
    min-height: auto;
    flex-direction: column;
    flex: 1 1 100%;
    
  }
  &__content{
    padding: 10px 20px 9px;
    display: flex;
    align-items: center;
    &:nth-child(even){
      background: #f2f6f9!important;
    }
    flex-wrap: nowrap;
  }
  &__title {
    color: var(--gray-text)!important;
    margin-right: 10px;
  }

  &__text {
    font-weight: 600;
    margin-left: auto;
    text-align: right;
    color: var(--black)!important;
  }
}
.timer-bonus {
  display: flex;
  gap: 10px;
  justify-content:center;align-items: center;
  &__icon {
  }

  &__text {
    font-weight: 700;
    color: var(--white);
  }
}
.bonus {

  &__header {
  }

  &__main {
  }
}
</style>